import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
import {
  Dialog,
  DialogContent,
  // DialogContentText,
  DialogActions,
  Button,
  // useTheme,
  // useMediaQuery,
  IconButton,
  makeStyles,
  // FormGroup,
  FormControlLabel,
  // Checkbox,
  TextField,
  Tabs,
  Tab,
  AppBar,
  Typography,
  Box,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import {
  Row,
  Col,
} from 'react-bootstrap';

// import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { translate, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { composePure, withProps } from '../../utils/composepure';
import AwesomeIcon from '../AwesomeIcon';
import theme from '../../scss/theme/colors';

import Props from './props';

const useStyles = makeStyles({
  button: {
    color: theme.blue.darker,
    marginTop: '10px',
  },
  iconButton: {
    padding: '0 5px 0 5px',
  },
  buttonModal: {
    backgroundColor: '#0083C7 !important',
  },
  FormGroup: {
    flexDirection: 'column',
  },
  FormControlLabel: {
    margin: 0,
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: '70vh',
  },
  textField: {
    width: '100%',
    minWidth: '300px',
  },
  headerGrid: {
    background: 'black',
    color: 'white',
  },
});

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  index: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
};

TabPanel.defaultProps = {
  children: '',
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

let copySalidas = [];
const ModalConfigureOutputs = ({
  t,
  saveCallback,
  equipoDB = {},
  enqueueSnackbar,
}) => {
  const classes = useStyles();

  const emptyOutput = {
    salidaID: 0,
    posicionFisica: '',
    numeroSalida: '',
    descripcion: '',
    firmwareMC: '',
    firmwareMP: '',
    capacidadCorriente: '',
    shuntCorriente: '',
    shuntTension: '',
    fondoEscala: '',
    tipoShunt: 1,
  };

  /* const emptyDevice = {
    description: '',
    firmwareHM: '',
    firmwareUC: '',
    firmwareEnsayo: '',
    serieParte: '',
  } */

  const [equipo, setEquipo] = useState({});
  const [salidaActual, setSalidaActual] = useState(emptyOutput);
  const [salidas, setSalidas] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [tabSelected, setTabSelected] = useState(0);

  useEffect(() => {
    copySalidas = salidas;
  }, [salidas]);

  const handleEditOutput = (id) => {
    const salidaSel = salidas.filter(s => s.numeroSalida === id)[0];
    setSalidaActual(salidaSel);
  };

  const handleDeleteOutput = (id) => {
    setSalidas(copySalidas.filter(s => s.numeroSalida !== id));
  };

  const handleAddUpdateOutput = () => {
    if (!salidaActual.numeroSalida) {
      enqueueSnackbar(t('default.nroSalidaRequerido', 'El número de la salida es requerido'), {
        variant: 'error',
      });
      return;
    }

    if (!salidaActual.descripcion) {
      enqueueSnackbar(t('default.salidaDescripcionRequerido', 'La descripción de la salida es requerida'), {
        variant: 'error',
      });
      return;
    }

    if (salidas.findIndex(x => x.numeroSalida === salidaActual.numeroSalida && salidaActual.salidaID !== x.salidaID) > -1) {
      enqueueSnackbar(t('default.existNroSalida', 'Ya existe una salida con ese número'), {
        variant: 'error',
      });
      return;
    }

    if (salidas.findIndex(x => x.descripcion === salidaActual.descripcion && salidaActual.salidaID !== x.salidaID) > -1) {
      enqueueSnackbar(t('default.existDescSalida', 'Ya existe una salida con esa descripción'), {
        variant: 'error',
      });
      return;
    }

    let newSalidas = [];
    if (salidaActual.salidaID === 0) {
      newSalidas = [
        ...salidas,
        {
          ...salidaActual,
          // salidaID: salidas.length === 0 ? 1 : Math.max(...salidas.map(s => s.salidaID)) + 1,
          salidaID: -1,
        },
      ];
      setSalidaActual({ ...emptyOutput });
    } else {
      const index = salidas.findIndex(s => s.salidaID === salidaActual.salidaID);
      newSalidas = [
        ...salidas.slice(0, index),
        {
          ...salidaActual,
          salidaID: salidas[index].salidaID,
        },
        ...salidas.slice(index + 1),
      ];
      setSalidaActual({ ...emptyOutput });
    }
    setSalidas(newSalidas);
    // copySalidas = [...newSalidas];
  };

  const columns = [{
    headerClasses: 'gf-notas-hide',
    dataField: 'descripcion',
    text: t('default.descripcion', 'Descripcion'),
    headerStyle: () => ({ width: '40%' }),
    sort: true,
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: 'numeroSalida',
    text: t('default.numeroSalida', 'Número Salida'),
    headerStyle: () => ({ width: '40%' }),
    sort: true,
  },
  {
    dataField: 'salidaID',
    text: '',
    formatter: (id, row) => (
      <>
        <IconButton
          className={classes.iconButton}
          aria-label="Editar"
          onClick={() => handleEditOutput(row.numeroSalida)}
          title={t('default.editar', 'Editar')}
        >
          <AwesomeIcon icon="edit" />
        </IconButton>
        <IconButton
          className={classes.iconButton}
          aria-label="Eliminar"
          onClick={() => handleDeleteOutput(row.numeroSalida)}
          title={t('default.eliminar', 'Eliminar')}
        >
          <AwesomeIcon icon="trash" />
        </IconButton>
      </>
    ),
  }];

  const handleOpenModal = () => {
    if (equipoDB.equipoID) {
      setEquipo({
        equipoID: equipoDB.equipoID,
        description: equipoDB.descripcion,
        firmwareHM: equipoDB.firmwareIHM,
        firmwareUC: equipoDB.firmwareUC,
        firmwareEnsayo: equipoDB.firmawareEnsayo,
        serieParte: equipoDB.serieParte,
      });
    }

    if (equipoDB.salidas) {
      setSalidas(equipoDB.salidas.map(s => ({
        salidaID: s.salidaID,
        posicionFisica: s.posicionFisica,
        numeroSalida: s.numero,
        descripcion: s.descripcion,
        firmwareMC: s.firmwareMC,
        firmwareMP: s.firmwareMP,
        capacidadCorriente: s.capacidadCorriente,
        shuntCorriente: s.shuntCorriente,
        shuntTension: s.shuntTension,
        fondoEscala: s.fondoEscala,
        tipoShunt: s.shuntCorriente || s.shuntTension ? 2 : 1,
      })));
    }
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const handleClickGuardar = () => {
    if (saveCallback) {
      console.log(saveCallback);

      if (!equipo.description) {
        enqueueSnackbar('La descripción del equipo es obligatoria', {
          variant: 'error',
        });
        return;
      }

      if (!salidas || salidas.length === 0) {
        enqueueSnackbar('Debe crear al menos una salida', {
          variant: 'error',
        });
        return;
      }

      const equipoModal = {
        equipoID: equipoDB.equipoID || 0,
        descripcion: equipo.description,
        firmwareIHM: equipo.firmwareHM,
        firmwareUC: equipo.firmwareUC,
        firmawareEnsayo: equipo.firmwareEnsayo,
        serieParte: equipo.serieParte,
        salidas: salidas.map(s => ({
          salidaID: s.salidaID,
          numero: s.numeroSalida,
          descripcion: s.descripcion,
          firmwareMC: s.firmwareMC,
          firmwareMP: s.firmwareMP,
          shuntCorriente: s.shuntCorriente,
          shuntTension: s.shuntTension,
          capacidadCorriente: s.capacidadCorriente,
          fondoEscala: s.fondoEscala,
          posicionFisica: s.posicionFisica,
        })),
      };
      saveCallback(equipoModal);
    }

    handleCloseModal();
  };

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t('default.pagPrev', 'página previa'),
    nextPageTitle: t('default.proxPag', 'próxima página'),
    firstPageTitle: t('default.primerPagina', 'primer página'),
    lastPageTitle: t('default.ultimaPagina', 'última página'),
  };

  return (
    <>
      <IconButton
        className={classes.button}
        aria-label="Salidas"
        onClick={handleOpenModal}
        title={t('default.salidas', 'Salidas')}
      >
        <AwesomeIcon icon="settings" />
      </IconButton>
      <Dialog
        // classes={{ paper: classes.dialogPaper }}
        maxWidth="lg"
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <AppBar position="static">
            <Tabs value={tabSelected} onChange={(event, newValue) => setTabSelected(newValue)}>
              <Tab className="gf-font-size-menu" label={t('default.equipo', 'Equipo')} {...a11yProps(0)} />
              <Tab className="gf-font-size-menu" label={t('default.salida', 'Salidas')} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={tabSelected} index={0} className="gf-padding-in-modal">
            <Row>
              <Col xs={6} className="gf-one-line">
                <TextField
                  id="descripcion"
                  label={t('default.description', 'Descripcion')}
                  className={`${classes.textField} gf-modal-file-size`}
                  type="text"
                  margin="normal"
                  value={equipo.description}
                  onChange={evt => setEquipo({
                    ...equipo,
                    description: evt.target.value,
                  })}
                />
              </Col>
              <Col xs={6} className="gf-one-line">
                <TextField
                  id="firmwareHM"
                  label={t('default.firmwareIHM', 'Firmware Interfaz Hombre Máquina')}
                  className={`${classes.textField} gf-modal-file-size`}
                  type="text"
                  margin="normal"
                  value={equipo.firmwareHM}
                  onChange={evt => setEquipo({
                    ...equipo,
                    firmwareHM: evt.target.value,
                  })}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="gf-one-line">
                <TextField
                  id="firmwareUC"
                  label={t('default.firmwareUC', 'Firmware Unidad Control')}
                  className={`${classes.textField} gf-modal-file-size`}
                  type="text"
                  margin="normal"
                  value={equipo.firmwareUC}
                  onChange={evt => setEquipo({
                    ...equipo,
                    firmwareUC: evt.target.value,
                  })}
                />
              </Col>
              <Col xs={6} className="gf-one-line">
                <TextField
                  id="firmwareEnsayo"
                  label={t('default.firmwareEnsayo', 'Firmware Ensayo OnOff')}
                  className={`${classes.textField} gf-modal-file-size`}
                  type="text"
                  margin="normal"
                  value={equipo.firmwareEnsayo}
                  onChange={evt => setEquipo({
                    ...equipo,
                    firmwareEnsayo: evt.target.value,
                  })}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="gf-one-line">
                <TextField
                  id="serieParte"
                  label={t('default.serieParte', 'Serie Parte')}
                  className={`${classes.textField} gf-modal-file-size`}
                  type="text"
                  margin="normal"
                  value={equipo.serieParte}
                  onChange={evt => setEquipo({
                    ...equipo,
                    serieParte: evt.target.value,
                  })}
                />
              </Col>
            </Row>
          </TabPanel>
          <TabPanel value={tabSelected} index={1}>
            <Row>
              <Col xs={6} className="gf-one-line">
                <TextField
                  id="numeroSalida"
                  label={t('default.numeroSalida', 'Número Salida')}
                  className={`${classes.textField} gf-modal-file-size`}
                  type="text"
                  margin="normal"
                  value={salidaActual.numeroSalida}
                  onChange={(evt) => {
                    const regex = /^[0-9\b]+$/;
                    if (regex.test(evt.target.value) || !evt.target.value) {
                      setSalidaActual({
                        ...salidaActual,
                        numeroSalida: parseInt(evt.target.value, 10),
                      });
                    }
                  }}
                />
              </Col>
              <Col xs={6} className="gf-one-line">
                <TextField
                  id="salidaDescripcion"
                  label={t('default.descripcion', 'Descripcion')}
                  className={`${classes.textField} gf-modal-file-size`}
                  type="text"
                  margin="normal"
                  value={salidaActual.descripcion}
                  onChange={evt => setSalidaActual({
                    ...salidaActual,
                    descripcion: evt.target.value,
                  })}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="gf-one-line">
                <TextField
                  id="firmwareMCS"
                  label={t('default.firmwareMC', 'Firmware Medición y Control')}
                  className={`${classes.textField} gf-modal-file-size`}
                  type="text"
                  margin="normal"
                  value={salidaActual.firmwareMC}
                  onChange={evt => setSalidaActual({
                    ...salidaActual,
                    firmwareMC: evt.target.value,
                  })}
                />
              </Col>
              <Col xs={6} className="gf-one-line">
                <TextField
                  id="firmwareMPS"
                  label={t('default.firmwareMP', 'Firmware Medición y Potencial')}
                  className={`${classes.textField} gf-modal-file-size`}
                  type="text"
                  margin="normal"
                  value={salidaActual.firmwareMP}
                  onChange={evt => setSalidaActual({
                    ...salidaActual,
                    firmwareMP: evt.target.value,
                  })}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} />
              <Col xs={6} className="gf-one-line">
                <RadioGroup
                  name="tipoShunt"
                  value={salidaActual.tipoShunt}
                  onChange={evt => setSalidaActual({
                    ...salidaActual,
                    tipoShunt: parseInt(evt.target.value, 10),
                  })}
                  row
                >
                  <FormControlLabel value={1} control={<Radio color="primary" />} label={t('default.capacidadModular', 'Capacidad Modular')} />
                  <FormControlLabel value={2} control={<Radio color="primary" />} label={t('default.shunt', 'Shunt')} />
                </RadioGroup>
              </Col>
            </Row>
            { salidaActual.tipoShunt === 1 && (
              <Row>
                <Col xs={6} className="gf-one-line">
                  <TextField
                    id="capacidadCorriente"
                    label={t('default.capacidadCorriente', 'Capacidad Corriente [A]')}
                    className={`${classes.textField} gf-modal-file-size`}
                    type="number"
                    margin="normal"
                    value={salidaActual.capacidadCorriente}
                    onChange={evt => setSalidaActual({
                      ...salidaActual,
                      capacidadCorriente: evt.target.value,
                    })}
                  />
                </Col>
              </Row>
            )}
            { salidaActual.tipoShunt === 2 && (
              <Row>
                <Col xs={6} className="gf-one-line">
                  <TextField
                    id="corriente"
                    label={t('default.corriente', 'Corriente [A]')}
                    className={`${classes.textField} gf-modal-file-size`}
                    type="number"
                    margin="normal"
                    value={salidaActual.shuntCorriente}
                    onChange={evt => setSalidaActual({
                      ...salidaActual,
                      shuntCorriente: evt.target.value,
                    })}
                  />
                </Col>
                <Col xs={6} className="gf-one-line">
                  <TextField
                    id="tension"
                    label={t('default.tension', 'Tensión [mV]')}
                    className={`${classes.textField} gf-modal-file-size`}
                    type="number"
                    margin="normal"
                    value={salidaActual.shuntTension}
                    onChange={evt => setSalidaActual({
                      ...salidaActual,
                      shuntTension: evt.target.value,
                    })}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={6} className="gf-one-line">
                <TextField
                  id="fondoEscala"
                  label={t('default.fondoEscala', 'Fondo Escala')}
                  className={`${classes.textField} gf-modal-file-size`}
                  type="number"
                  margin="normal"
                  value={salidaActual.fondoEscala}
                  onChange={evt => setSalidaActual({
                    ...salidaActual,
                    fondoEscala: evt.target.value,
                  })}
                />
              </Col>
              <Col xs={6} className="gf-one-line">
                <TextField
                  id="posicionFisica"
                  label={t('default.posicionFisica', 'Posición Física')}
                  className={`${classes.textField} gf-modal-file-size`}
                  type="number"
                  margin="normal"
                  value={salidaActual.posicionFisica}
                  onChange={evt => setSalidaActual({
                    ...salidaActual,
                    posicionFisica: evt.target.value,
                  })}
                />
              </Col>
            </Row>
            <Row style={{ margin: '10px 0' }}>
              <Button
                onClick={handleAddUpdateOutput}
                color="primary"
                variant="contained"
                className={classes.buttonModal}
              >
                {salidaActual.salidaID === 0 ? t('default.agregar', 'Agregar') : t('default.actualizar', 'Actualizar')}
              </Button>
              { salidaActual.salidaID > 0 && (
                <Button
                  onClick={() => setSalidaActual(emptyOutput)}
                  color="primary"
                  autoFocus
                  variant="contained"
                  className={classes.buttonModal}
                  style={{ marginLeft: '10px' }}
                >
                  <Trans i18nKey="default.cancelar">Cancelar</Trans>
                </Button>
              )}
            </Row>
            <Row>
              <Col xs={12} className="gf-modal-table">
                <BootstrapTable
                  keyField="salidaID"
                  striped
                  hover
                  condensed
                  bootstrap4
                  headerClasses={classes.headerGrid}
                  data={salidas}
                  columns={columns}
                  pagination={paginationFactory(options)}
                />
              </Col>
            </Row>
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            color="primary"
            variant="contained"
            className={classes.buttonModal}
          >
            <Trans i18nKey="default.cerrar">Cerrar</Trans>
          </Button>
          <Button
            onClick={handleClickGuardar}
            color="primary"
            autoFocus
            variant="contained"
            className={classes.buttonModal}
          >
            <Trans i18nKey="default.guardar">Guardar</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ModalConfigureOutputs.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({
    t: props.t,
    enqueueSnackbar: props.enqueueSnackbar,
  })),
)(ModalConfigureOutputs);

export default withSnackbar(translate('common')(AppCompose));
