import React from 'react';
import {
  Carousel,
} from 'react-bootstrap';
import props from './props';
import CardCarouselItem from '../CardCarouselItem';
// import './style.css';

/** @description componente carousel reutilizable.
 * Empleado en REctifiersOutputList
 */
const CardCarrousel = ({
  salidas,
  fecha,
  t,
  language,
}) => (
  <Carousel
    fade
    slide={false}
    controls={salidas && salidas.length > 10}
    indicators={salidas && salidas.length <= 10}
    interval={null}
  >
    {salidas.map(out => (
      <Carousel.Item
        key={`${Math.random()}-${out.salidaID} `}
      >
        <CardCarouselItem
          key={out.salidaID}
          out={out}
          fecha={fecha}
          t={t}
          language={language}
        />
      </Carousel.Item>
    ))}
  </Carousel>
);

CardCarrousel.propTypes = props;
export default CardCarrousel;
