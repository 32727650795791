import React, { useState, Fragment } from 'react';

import {
  TextField,
  Container,
  Grid,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  withStyles,
  IconButton,
  Button,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputLabel,
} from '@material-ui/core';

import {
  Row,
} from 'react-bootstrap';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import ListConfiguracionAlarmas from '../../components/ListConfiguracionAlarmas';

import { composePure, lifecycle, withProps } from '../../utils/composepure';
import AwesomeIcon from '../../components/AwesomeIcon';
import store from '../../store';
import theme from '../../scss/theme/colors';
import Actions from '../../actions';
// import Select from 'react-select';
import PageWrapper from '../../components/PageWrapper';

import Props from './props';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#0083C7 !important',
  },
});

const GrayCheckbox = withStyles({
  root: {
    // color: '#E6E6E6',
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
    '&$checked': {
      color: theme.gray.light,
    },
    padding: '0.5rem',
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

/* const property = key => (
  x => (x[key])
);

const flatten = (a, b) => (
  a.concat(b)
);

const getUnique = (arr, comp) => {
  const unique = arr
    .map(e => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e]).map(e => arr[e]);

  return unique;
}; */

/** @description pagina de configuracion de alarmas donde podremos crear nuevas alarmas, para
 * determinado dispositivo seleccionado, con parametros configurables y envio de alertas, ya
 * sea via mail o en sistema.
 */
const ConfiguracionAlarmas = ({
  location,
  history,
  alarmasConfiguradas,
  // variablesAll,
  t,
  language,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    nombre: '',
    value: '',
    limiteInferior: '',
    limiteSuperior: '',
    tiempoEntreAvisos: '',
    variable: '0',
    ackOperador: false,
    ackEmails: false,
    emails: [],
    configuracionAlarmaID: 0,
    openConfirmDelete: false,
    configuracionAlarmaDelete: 0,
    openErrorValidation: false,
    errorValidation: '',
    mostrarParametros: true,
    salida: '0',
  });

  // const variables = variablesAll.filter(x => x.tipoUnidadMonitoreoID === location.rectificador.tipoUnidadMonitoreoID);
  /* const valoresEquipo = location.rectificador.valores.filter(x => !x.listaValorID).map(v => ({
    configuracionReporteID: v.configuracionReporteID,
    nombreES: v.nombreEs,
    nombreEN: v.nombreEn,
  })); */

  const variablesEquipo = location.rectificador.alertas.map(a => ({
    configuracionReporteID: a.configuracionReporteID,
    nombreES: a.nombreES,
    nombreEN: a.nombreEN,
    esDatoAlarma: true,
  }));

  /* const variablesEquipo = [
    ...valoresEquipo,
    ...alarmasEquipo,
  ]; */

  const valoresSalida = location.rectificador.salidas[0].valores.filter(x => !x.listaValorID).map(v => ({
    configuracionReporteID: v.configuracionReporteID,
    nombreES: v.nombreEs,
    nombreEN: v.nombreEn,
    esDatoAlarma: false,
  }));

  const alarmasSalida = location.rectificador.salidas[0].alertas.map(a => ({
    configuracionReporteID: a.configuracionReporteID,
    nombreES: a.nombreES,
    nombreEN: a.nombreEN,
    esDatoAlarma: true,
  }));

  const variablesSalida = [
    ...valoresSalida,
    ...alarmasSalida,
  ];

  const variables = state.salida === '0' ? variablesEquipo : variablesSalida;

  const handleDelete = (row) => {
    setState({
      ...state,
      openConfirmDelete: true,
      configuracionAlarmaDelete: row.configuracionAlarmaID,
    });
  };

  const handleDeleteConfirm = () => {
    store.dispatch(Actions.Cuadros.deleteConfAlarma(state.configuracionAlarmaDelete));
    setState({ ...state, openConfirmDelete: false });
    if (state.configuracionAlarmaDelete === state.configuracionAlarmaID) {
      setState({
        nombre: '',
        value: '',
        limiteInferior: '',
        limiteSuperior: '',
        tiempoEntreAvisos: '',
        variable: '0',
        ackOperador: false,
        ackEmails: false,
        emails: [],
        configuracionAlarmaID: 0,
        configuracionAlarmaDelete: 0,
        openErrorValidation: false,
        errorValidation: '',
        mostrarParametros: true,
      });
    }
  };

  const handleEditar = (row) => {
    // const variableSel = variables.filter(x => x.configuracionReporteID === row.variableID);
    // const variableSel = variablesAll.filter(x => x.configuracionReporteID === row.variableID);
    setState(
      {
        ...row,
        emails: row.emails.split(';'),
        variable: row.variableID,
        ackEmails: row.emails.length > 0,
        mostrarParametros: !row.esDatoAlarma,
      },
    );
  };

  const handleChangeSalida = (evt) => {
    setState({
      ...state,
      salida: evt.target.value,
    });
  };

  const handleChangeVariable = (evt) => {
    const variableSel = variables.filter(x => x.configuracionReporteID === evt.target.value);
    // const variableSel = variablesAll.filter(x => x.configuracionReporteID === evt.target.value);
    setState(
      {
        ...state,
        variable: evt.target.value,
        mostrarParametros: !variableSel[0].esDatoAlarma,
      },
    );
  };

  const validarDatosIngresados = () => {
    if (!state.nombre) {
      setState({ ...state, openErrorValidation: true, errorValidation: 'Debe ingresar un nombre para la configuración' });
      return false;
    }

    if (state.variable === '0') {
      setState({ ...state, openErrorValidation: true, errorValidation: 'Debe seleccionar una variable para la configuración' });
      return false;
    }

    if (state.tiempoEntreAvisos === '') {
      setState({ ...state, openErrorValidation: true, errorValidation: 'Debe seleccionar el tiempo entre avisos para la configuración' });
      return false;
    }

    return true;
  };

  const handleClickCancelar = () => {
    setState({
      nombre: '',
      value: '',
      limiteInferior: '',
      limiteSuperior: '',
      tiempoEntreAvisos: '',
      variable: '0',
      ackOperador: false,
      ackEmails: false,
      emails: [],
      configuracionAlarmaID: 0,
      configuracionAlarmaDelete: 0,
      openErrorValidation: false,
      errorValidation: '',
      mostrarParametros: true,
    });
  };

  const handleClickGuardar = () => {
    if (validarDatosIngresados()) {
      // const variableSel = variables.filter(x => x.configuracionReporteID === state.variable);
      const variableSel = variables.filter(x => x.configuracionReporteID === state.variable);
      if (state.configuracionAlarmaID === 0) {
        store.dispatch(Actions.Cuadros.saveConfAlaras(
          {
            ...state,
            emails: state.emails.join(';'),
            rectificadorID: location.rectificador.rectificadorID,
            variableID: state.variable,
            variableES: variableSel[0].nombreES,
            salidaNro: state.salida,
          },
        ));
      } else {
        store.dispatch(Actions.Cuadros.updateConfAlarma(
          {
            ...state,
            emails: state.emails.join(';'),
            variableID: state.variable,
            salidaNro: state.salida,
          },
        ));
      }
      setState({
        nombre: '',
        value: '',
        limiteInferior: '',
        limiteSuperior: '',
        tiempoEntreAvisos: '',
        variable: '0',
        ackOperador: false,
        ackEmails: false,
        emails: [],
        configuracionAlarmaID: 0,
        mostrarParametros: true,
      });
    }
  };

  const addEmail = () => {
    setState({
      ...state,
      emails: [
        ...state.emails,
        '',
      ],
    });
  };

  const removeEmail = (index) => {
    setState({
      ...state,
      emails: [
        ...state.emails.splice(0, index),
        ...state.emails.splice(index + 1),
      ],
      ackEmails: state.emails.length > 1,
    });
  };

  const handleChangeEmail = (evt) => {
    setState({
      ...state,
      ackEmails: !state.ackEmails, // evt.target.checked,
      emails: evt.target.checked ? [''] : [],
    });
  };

  const handleTextEmail = (index, value) => {
    setState({
      ...state,
      emails: [
        ...state.emails.slice(0, index),
        value,
        ...state.emails.slice(index + 1),
      ],
    });
  };

  return (
    <PageWrapper
      history={history}
    >
      <Row>
        <Container maxWidth="xl" className="FilterBarHeader">
          <Row>
            <Grid container>
              <Grid item xs={1}>
                <IconButton
                  // className={classes.button}
                  aria-label="left"
                  onClick={() => {
                    if (location.rectificador) {
                      history.push({
                        pathname: '/moreInfo',
                        rectificador: location.rectificador,
                        t,
                      });
                    } else {
                      history.push('/');
                    }
                  }}
                  style={{
                    border: '2px solid rgba(0, 0, 0, 0.54)',
                  }}
                >
                  <AwesomeIcon icon="left" />
                </IconButton>
              </Grid>
              <Grid item xs={5} className="gf-font-size-conf-alarm">
                <h3>{t('default.confAlarma', 'Configuración de Alarmas')}</h3>
              </Grid>
            </Grid>
          </Row>
        </Container>
      </Row>
      <Row>
        <Container style={{ marginTop: '85px' }} className="gf-config-alarm gf-config-alarm-body">
          <Grid container>
            <Grid item xs={6} className="gf-config-alarm-body-inside">
              <fieldset className="panel gf-config-alarm-body-inside-fieldset" style={{ width: '90%' }}>
                <legend className="panelTitulo">{t('default.informacionBasica', 'Información Básica')}</legend>
                <span>{location.rectificador.nombre}</span>
                <Grid item style={{ marginTop: '25px' }}>
                  <TextField
                    id="txtNombre"
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: '80%' }}
                    value={state.nombre}
                    onChange={evt => setState({ ...state, nombre: evt.target.value })}
                    label={`${t('default.nombre', 'Nombre')}...`}
                  // placeholder={`${t('default.nombre', 'Nombre')}...`}
                  />
                </Grid>
                <Grid item style={{ marginTop: '15px' }}>
                  <InputLabel htmlFor="salidaId" className="MuiInputLabel-shrink">{`${t('default.salida', 'Salida')}...`}</InputLabel>
                  <Select
                    // placeholder={`${t('default.variable', 'Variable')}...`}
                    className="basic-multi-select"
                    name="salida"
                    style={{ width: '80%' }}
                    value={state.salida}
                    // options={variables}
                    // onChange={evt => setState({ ...state, variable: evt.target.value })}
                    onChange={handleChangeSalida}
                    inputProps={{
                      name: 'salida',
                      id: 'salidaId',
                    }}
                    variant="standard"
                    margin="dense"
                  >
                    <MenuItem value="0">
                      {t('default.equipo', 'Equipo')}
                    </MenuItem>
                    {location.rectificador.salidas.map(v => (
                      <MenuItem key={v.salidaID} value={v.salidaID}>{`${t('default.salida', 'Salida')} ${v.salidaID}`}</MenuItem>
                    ))}
                  </Select>
                  <InputLabel htmlFor="variableId" style={{ marginTop: 15 }} className="MuiInputLabel-shrink">{`${t('default.variable', 'Variable')}...`}</InputLabel>
                  <Select
                    // placeholder={`${t('default.variable', 'Variable')}...`}
                    className="basic-multi-select"
                    name="variable"
                    style={{ width: '80%' }}
                    value={state.variable}
                    // options={variables}
                    // onChange={evt => setState({ ...state, variable: evt.target.value })}
                    onChange={handleChangeVariable}
                    inputProps={{
                      name: 'variable',
                      id: 'variableId',
                    }}
                    variant="standard"
                    margin="dense"
                  >
                    {/* <MenuItem value="0" disabled>
                      {`${t('default.variable', 'Variable')}...`}
                    </MenuItem> */}
                    {variables.map(v => (
                      <MenuItem key={v.configuracionReporteID} value={v.configuracionReporteID}>{v.nombreES}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </fieldset>
              {/* <Grid item xs={12} style={{ marginTop: '35px', display: state.mostrarParametros ? 'inline-block' : 'none' }}> */}
              <fieldset className="panel gf-config-alarm-body-inside-fieldset" style={{ display: state.mostrarParametros ? 'inline-block' : 'none', width: '90%' }}>
                <legend className="panelTitulo">{t('default.parametros', 'Parametros')}</legend>
                <Row>
                  <TextField
                    id="txtDesde"
                    type="number"
                    /* InputLabelProps={{
                      shrink: true,
                    }} */
                    style={{ marginLeft: '10px', marginRight: '10px', width: '25%' }}
                    value={state.limiteInferior}
                    onChange={evt => setState({ ...state, limiteInferior: evt.target.value })}
                    label={t('default.menorA', 'Menor a')}
                    margin="normal"
                  />
                </Row>
                <Row>
                  <TextField
                    id="txtHasta"
                    type="number"
                    /* InputLabelProps={{
                      shrink: true,
                    }} */
                    style={{ marginLeft: '10px', marginRight: '10px', width: '25%' }}
                    value={state.limiteSuperior}
                    onChange={evt => setState({ ...state, limiteSuperior: evt.target.value })}
                    label={t('default.mayorA', 'Mayor a')}
                    margin="dense"
                  />
                </Row>
              </fieldset>
              {/* </Grid> */}
            </Grid>
            <Grid item xs={6} className="gf-config-alarm-body-inside">
              <fieldset className="panel gf-config-alarm-body-inside-fieldset" style={{ height: '94%' }}>
                <legend className="panelTitulo">{t('default.opciones', 'Opciones')}</legend>
                <TextField
                  id="txtTiempoEntreAvisos"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={state.tiempoEntreAvisos}
                  onChange={evt => setState({ ...state, tiempoEntreAvisos: evt.target.value })}
                  label={`${t('default.tiempoEntreAvisos', 'Tiempo entre avisos')} [Min]`}
                // placeholder={`${t('default.tiempoEntreAvisos', 'Tiempo entre avisos')}...`}
                />
                <Grid container style={{ marginTop: '15px' }}>
                  <Grid item xs={4} style={{ marginTop: '5px' }}>
                    <span>{t('default.enviarAlertas', 'Enviar alertas')}</span>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={7}>
                    <FormControlLabel
                      // className={classes.FormControlLabel}
                      control={
                        <GrayCheckbox key="chkOperador" checked={state.ackOperador} onChange={() => setState({ ...state, ackOperador: !state.ackOperador })} />
                      }
                      label={t('default.enSistema', 'En Sistema')}
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: '15px' }}>
                  <Grid item xs={5} />
                  <Grid item xs={7}>
                    <FormControlLabel
                      // className={classes.FormControlLabel}
                      control={
                        <GrayCheckbox key="chkEmail" checked={state.ackEmails} onChange={handleChangeEmail} />
                      }
                      label="E-mails"
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={12}>
                    {state.emails.map((e, index) => (
                      <Fragment>
                        <TextField
                          id={`${index}txtEmail`}
                          style={{ width: '80%' }}
                          type="email"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={e}
                          onChange={evt => handleTextEmail(index, evt.target.value)}
                          label="Email..."
                          margin="dense"
                        />
                        <IconButton
                          // className={classes.button}
                          aria-label="minus"
                          onClick={() => removeEmail(index)}
                        >
                          <AwesomeIcon icon="minus" />
                        </IconButton>
                      </Fragment>
                    ))}
                    {state.emails.length > 0 && (
                      <IconButton
                        // className={classes.button}
                        aria-label="plus"
                        onClick={addEmail}
                      >
                        <AwesomeIcon icon="plus" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </fieldset>
            </Grid>
          </Grid>
        </Container>
      </Row>
      <Grid item xs={4} style={{ marginTop: '50px', marginLeft: '10.5%' }} className="gf-config-alarm-button">
        <Button
          onClick={handleClickCancelar}
          color="primary"
          autoFocus
          variant="contained"
          className={classes.button}
        >
          {t('default.cancelar', 'Cancelar')}
        </Button>
        <Button
          onClick={handleClickGuardar}
          color="primary"
          autoFocus
          variant="contained"
          className={classes.button}
          style={{ marginLeft: '25px' }}
        >
          {t('default.guardar', 'Guardar')}
        </Button>
      </Grid>
      <Grid item xs={12} className="">
        <ListConfiguracionAlarmas language={language} listaAlarmas={alarmasConfiguradas} handleEditar={handleEditar} handleDelete={handleDelete} />
      </Grid>
      {/* Popup de confirmación de eliminación */}
      <Dialog
        open={state.openConfirmDelete}
        onClose={() => setState({ ...state, openConfirmDelete: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('default.confirmarEliminarConfAlarma', '¿Está seguro que desea eliminar la configuración de la alarma?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setState({ ...state, openConfirmDelete: false })} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={state.openErrorValidation}
        onClose={() => setState({ ...state, openErrorValidation: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {state.errorValidation}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setState({ ...state, openErrorValidation: false })} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

    </PageWrapper>
  );
};

ConfiguracionAlarmas.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
  lifecycle({
    componentDidMount() {
      const { rectificadorID, tipoUnidadMonitoreoID } = this.props.location.rectificador;
      store.dispatch({ type: 'FETCH_CONFIGURACIONALARMAS', payload: { rectificadorID } });
      store.dispatch({ type: 'FETCH_CONFIGURACIONREPORTE', payload: { rectificadorID, tipoUnidadMonitoreoID } });
    },
  }),
  connect(state => ({
    alarmasConfiguradas: state.Cuadros.ConfiguracionAlarmas || [],
    variablesAll: state.Rectifiers.configuracionReportes,
    language: state.Language.current,
  })),
)(ConfiguracionAlarmas);

export default translate('common')(AppCompose);

// export default ConfiguracionAlarmas;
