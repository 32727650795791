/* eslint-disable prefer-template */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Nav,
  NavDropdown,
  Navbar,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { composePure, withProps } from '../../utils/composepure';
import Props from './props';

/** @description Componente contendor para el menu de navegacion principal.
 */
const MainHeaderNav = ({
  t,
  history,
  userData,
}) => {
  const [activeKey, setActiveKey] = React.useState('1');
  const goTo = (path) => {
    history.push(path);
  };

  const handleOnSelect = (key) => {
    switch (key) {
      case '2.1':
      case '2.2':
      case '2.3':
        setActiveKey('2');
        break;
      case '3.1':
      case '3.2':
      case '3.3':
      case '3.4':
      case '3.5':
        setActiveKey('3');
        break;
      default:
        setActiveKey('1');
    }
  };

  return (
    <Fragment>
      <Navbar collapseOnSelect expand="lg" bg="gf-menu" variant="dark">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="gf-navbar-toggle" />
        <Navbar.Collapse id="responsive-navbar-nav" className="gf-navbar-collapse">
          <Nav className="mr-auto" activeKey={activeKey} onSelect={handleOnSelect}>
            <Nav.Link as={Link} eventKey={1} to="/">
              <span>{t('default.dispositivos', 'Dispositivos')}</span>
            </Nav.Link>
            <NavDropdown
              eventKey={2}
              active={activeKey === '2'}
              title={<span>{t('default.tablero', 'Tableros')}</span>}
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item
                eventKey={2.1}
                onClick={() => goTo('/tableros')}
              >
                {t('default.mediciones', 'Mediciones')}
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={2.2}
                onClick={() => goTo('/disponibilidad')}
              >
                {t('default.disponibilidad', 'Disponibilidad')}
              </NavDropdown.Item>
              <NavDropdown.Item eventKey={2.3} onClick={() => goTo('/operaciones')}>{t('default.operaciones', 'Operaciones')}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              eventKey={3}
              active={activeKey === '3'}
              title={<span>{t('default.registro', 'Registro')}</span>}
              id="collasible-nav-dropdown"
            >
              { userData.permisos.findIndex(x => x.opcionCodigo === 'GM') > -1 && (
                <NavDropdown.Item
                  className="gf-navbar-collapse-hide-element"
                  eventKey={3.1}
                  onClick={() => goTo('/grupos')}
                >
                  {t('default.grupos', 'Grupos')}
                </NavDropdown.Item>
              )}
              <NavDropdown.Item
                eventKey={3.2}
                onClick={() => goTo('/cuadrosAlarmas')}
              >
                {t('default.alarmas', 'Alarmas')}
              </NavDropdown.Item>
              <NavDropdown.Item eventKey={3.4} onClick={() => goTo('/cuadrosComandos')}>
                {t('default.comandos', 'Comandos')}
              </NavDropdown.Item>
              <NavDropdown.Item eventKey={3.3} onClick={() => goTo('/cuadrosNovedades')}>
                {t('default.novedades', 'Novedades')}
              </NavDropdown.Item>
              <NavDropdown.Item eventKey={3.5} onClick={() => goTo('/cuadrosNotificaciones')}>
                {t('default.notificaciones', 'Notificaciones')}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Fragment>
  );
};

MainHeaderNav.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
  connect(state => ({
    userData: state.Users.userData,
  })),
)(MainHeaderNav);

export default translate('common')(AppCompose);
