import {
  FILTER_SET_ALERT_TYPE_FILTER,
  FILTER_SET_KEYWORD_FILTER,
  FILTER_SET_GROUP_FILTER,
  FILTER_SET_GROUPDISPONIBILIDAD_FILTER,
  FILTER_SET_BRANDS_FILTER,
  FILTER_SET_ALERT_TYPES,
} from '../actionTypes/filters';

const initialState = {
  alerts: [],
  group: [],
  keyword: '',
  brands: [],
  alertTypes: [],
  groupDisponibilidad: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_SET_BRANDS_FILTER:
      return {
        ...state,
        brands: action.payload.value,
      };
    case FILTER_SET_KEYWORD_FILTER:
      return {
        ...state,
        keyword: action.payload.value,
      };

    case FILTER_SET_ALERT_TYPE_FILTER:
      return {
        ...state,
        alerts: action.payload.value,
      };

    case FILTER_SET_GROUP_FILTER:
      return {
        ...state,
        group: action.payload.value,
      };
    case FILTER_SET_GROUPDISPONIBILIDAD_FILTER:
      return {
        ...state,
        groupDisponibilidad: action.payload.value,
      };
    case FILTER_SET_ALERT_TYPES:
      return {
        ...state,
        alertTypes: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
