import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  Row,
} from 'react-bootstrap';
import {
  Grid,
  Container,
  Button,
  Paper,
  InputBase,
} from '@material-ui/core';

import Moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import { es, enUS } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
import { translate, Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import Actions from '../../actions';
// import PageWrapper from '../../components/PageWrapper';
import ListDisponibilidad from '../../components/ListDisponibilidad';
// import PieChartDisponibilidad from '../../components/PieChartDisponibilidad';
import PieChartDisponibilidadAMC from '../../components/PieChartDisponibilidadAMC';
import {
  composePure,
  withState,
  // lifecycle,
  withHandlers,
  withProps,
} from '../../utils/composepure';
import {
  getGroupDisponibilidadFilter,
} from '../../selectors';
import theme from '../../scss/theme/colors';
// import store from '../../store';
import Props from './props';

registerLocale('es', es);
registerLocale('enUS', enUS);

const useStyles = makeStyles({
  gfMargin: {
    marginLeft: '75%',
    position: 'absolute',
  },
  gfMaxWidth: {
    maxWidth: '75%',
  },
  gfMedia3: {
    display: props => props.display,
    '@media (max-width:992px)': {
      display: 'contents !important',
    },
  },
  gfMedia4: {
    position: props => props.position,
    '@media (max-width:992px)': {
      position: 'initial',
      marginLeft: '33%',
    },
    '@media (max-width:700px)': {
      marginLeft: '16%',
    },
    '@media (max-width:400px)': {
      marginLeft: '8%',
    },
  },
  gfMedia: {
    display: props => props.display,
    '@media (max-width:992px)': {
      display: 'none',
    },
  },
  gfMedia2: {
    maxWidth: props => props.maxWidth,
    '@media (max-width:992px)': {
      maxWidth: '95px !important',
    },
  },
  gfPadding: {
    paddingLeft: '3%',
    width: '15%',
  },
  gfPaddingMedia: {
    paddingLeft: props => props.paddingLeft,
    '@media (max-width:992px)': {
      paddingLeft: '3% !important',
      width: '30%',
    },
    '@media (max-width:507px)': {
      paddingLeft: '1% !important',
    },
  },
  gfPadding2: {
    paddingLeft: '10%',
    width: '32%',
  },
  gfPadding3: {
    paddingLeft: '3%',
    width: '21%',
  },
  button: {
    backgroundColor: '#0083C7 !important',
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    boxShadow: 'none',
    border: `1px solid ${theme.gray.light}`,
    minHeight: 38,
  },
  input: {
    fontSize: 12,
    marginLeft: 8,
    flex: 1,
  },
  datePickerInput: {
    borderRadius: '4px',
    height: '36px',
    padding: '2px 8px',
  },
});

/** @description pagina para la visualizacion de Vista Disponibilidad, campos para filtrar a la
 * hora de buscar y visualizacion en forma de tabla con graficos tipo PIE.
 */
const VistaDisponibilidad = ({
  disponibilidades,
  grupos,
  // onChangeGroup,
  handleConsultar,
  working,
  t,
  language,
  userId,
}) => {
  const [filter, setFilter] = useState();
  const handleChangeInput = (evt) => {
    setFilter(evt.target.value);
  };
  const filteredData = getGroupDisponibilidadFilter(
    disponibilidades,
    grupos,
    filter,
  ).map(item => ({
    ...item,
    category: grupos.find(cat => cat.grupoId === item.grupoId),
  }));

  const defaultGroup = {
    label: language === 'EN' ? 'All' : 'Todos',
    value: -1,
  };

  const actualDate = new Date();
  const minDate = (new Date()).setMonth(actualDate.getMonth() - 6);
  const oneWeekAgeDate = (new Date()).setDate(actualDate.getDate() - 7);
  const [startDate, setStartDate] = useState(oneWeekAgeDate);
  const [endDate, setEndDate] = useState(actualDate);
  const [grupoSelected, setGrupoSelected] = useState(-1);
  const [firstLoad, setFirstLoad] = useState(true);
  const classes = useStyles();

  const handleChangeStart = (newValue) => {
    setStartDate(newValue);
  };

  const handleChangeEnd = (newValue) => {
    setEndDate(newValue);
  };

  useEffect(() => {
    handleConsultar(startDate, endDate, grupoSelected, userId);
    setFirstLoad(false);
  }, []);

  return (
    <Fragment>
      <Row>
        <Container maxWidth="xl" className="FilterBarHeader">
          <Row>
            <Grid container className="gf-dispo-container">
              <Grid item xs={2} className="gf-dispo-picker2">
                <DatePicker
                  className={`${classes.datePickerInput} gf-dispo-picker`}
                  selected={startDate}
                  selectsStart
                  dateFormat="dd/MM/yyyy"
                  minDate={minDate}
                  maxDate={endDate}
                  onChange={handleChangeStart}
                  locale={language === 'EN' ? enUS : es}
                />
              </Grid>
              <Grid item xs={2} className="gf-dispo-picker2">
                <DatePicker
                  className={`${classes.datePickerInput} gf-dispo-picker`}
                  selected={endDate}
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  // maxDate={endDate}
                  onChange={handleChangeEnd}
                  minDate={startDate}
                  locale={language === 'EN' ? enUS : es}
                />
              </Grid>
              <Grid item xs={1} className="gf-dispo-button">
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.button} gf-dispo-button gf-dispo-font`}
                  onClick={() => { handleConsultar(startDate, endDate, grupoSelected, userId); setFirstLoad(false); }}
                >
                  <Trans i18nKey="default.consultar">Consultar</Trans>
                </Button>
              </Grid>
              <Grid item xs={2} className="gf-dispo-search">
                <Paper className={classes.root}>
                  <InputBase
                    className={`${classes.input} gf-dispo-button`}
                    type="text"
                    placeholder={`${t('default.buscar', 'Buscar')}...`}
                    inputProps={{ 'aria-label': `${t('default.buscar', 'Buscar')}...` }}
                    onChange={handleChangeInput}
                    value={filter}
                  />
                </Paper>
              </Grid>
              <Grid item xs={3} className="gf-dispo-combo">
                <Select
                  defaultValue={defaultGroup}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  // isMulti
                  name="color"
                  options={[defaultGroup, ...grupos]}
                  onChange={newValue => setGrupoSelected(newValue.value)}
                  // onChange={onChangeGroup}
                />
              </Grid>
            </Grid>
          </Row>
        </Container>
      </Row>
      <Row style={{ marginTop: '100px' }}>
        <Grid container>
          <Grid item xs={9} className="gf-dispo-media-grid">
            {
              filteredData.length === 0 && !working && !firstLoad ? (
                <span>
                  <Trans i18nKey="default.noData">No hay datos para mostrar</Trans>
                </span>
              ) : (
                <Container>
                  <Row style={{ marginLeft: '30px' }}>
                    <Grid container>
                      <Grid xs={4} className="gf-dispo-body-legend">
                        <div style={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: '#E85C80',
                          display: 'inline-block',
                        }}
                        />
                        <span>{` ${t('default.redLabel', 'Conectado menos del 80% del tiempo')}`}</span>
                      </Grid>
                      <Grid xs={4} className="gf-dispo-body-legend">
                        <div style={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: '#E7D118',
                          display: 'inline-block',
                        }}
                        />
                        <span>{` ${t('default.yellowLabel', 'Datos erróneos')}`}</span>
                      </Grid>
                      <Grid xs={4} className="gf-dispo-body-legend">
                        <div style={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: '#86E876',
                          display: 'inline-block',
                        }}
                        />
                        <span>{` ${t('default.greenLabel', 'Conectado mas del 80% del tiempo')}`}</span>
                      </Grid>
                    </Grid>
                  </Row>
                  <ListDisponibilidad disponibilidades={filteredData} />
                </Container>
              )
            }
          </Grid>
          <Grid item xs={3} className="gf-dispo-graph gf-dispo-media-graph">
            {
              filteredData.length > 0 && (
                <PieChartDisponibilidadAMC filteredData={filteredData} t={t} />
              )
            }
          </Grid>
        </Grid>
      </Row>
    </Fragment>
  );
};

VistaDisponibilidad.propTypes = Props;

const AppCompose = composePure(
  // withState('loading', 'setLoading', true),
  // withState('loaded', 'setLoaded', false),
  /* lifecycle({
    componentDidMount() {
      // store.dispatch({ type: 'INIT_VISTADISPONIBILIDAD_STATE' });
      const firstDate = new Date();
      firstDate.setMonth(firstDate.getMonth() - 1);
      const lastDate = new Date();
      store.dispatch(Actions.Disponibilidad.fetchDisponibilidad(firstDate, lastDate));
    },
  }), */
  connect(state => ({
    working: state.Universal.request.process,
    disponibilidades: state.Disponibilidad.Disponibilidad,
    grupos: state.Groups.docs.map(cat => ({
      value: cat.grupoId,
      label: cat.nombre,
    })),
    language: state.Language.current,
    userId: state.Users.userData.usuarioId,
  }),
  dispatch => ({
    fetchConsultar: (startDate, endDate, grupoSelected, userId) => {
      const fechaDesde = new Date(startDate);
      fechaDesde.setHours(0);
      fechaDesde.setMinutes(0);
      fechaDesde.setSeconds(0);
      const fechaHasta = new Date(endDate);
      fechaHasta.setHours(23);
      fechaHasta.setMinutes(59);
      fechaHasta.setSeconds(59);
      dispatch(Actions.Disponibilidad.fetchDisponibilidad(Moment(fechaDesde).format('YYYY-MM-DDTHH:mm:ss'), Moment(fechaHasta).format('YYYY-MM-DDTHH:mm:ss'), grupoSelected, userId));
    },
    // onChangeGroup: group => dispatch(Actions.Filters.setGroupDisponibilidad(group || [])),
  })),
  withState('startDate', 'setStartDate', ''),
  withState('endDate', 'setEndDate', ''),
  withState('grupoSelected', 'setGrupoSelected'),
  withHandlers({
    handleConsultar: ({
      fetchConsultar,
    }) => (startDate, endDate, grupoSelected, userId) => {
      fetchConsultar(startDate, endDate, grupoSelected, userId);
    },
  }),
  withProps(props => ({ t: props.t })),
)(VistaDisponibilidad);

export default translate('common')(hot(module)(AppCompose));
