const initialState = {
  token: null,
  userData: null,
  logged: false,
  errorLogin: null,
  errorRegister: null,
  errorRecovery: null,
  recoveryToken: null,
  resultSavePerfil: '',
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_ERROR_LOGIN':
      return { ...state, errorLogin: action.payload };

    case 'USER_SET_RECOVERY_TOKEN':
      return { ...state, recoveryToken: action.payload.recoveryToken };

    case 'USER_ERROR_RECOVERY_CLEAN':
      return { ...state, errorRecovery: null };

    case 'USER_ERROR_REGISTER':
      return { ...state, errorRegister: action.payload.response.data };

    case 'USER_ERROR_RECOVERY':
      return { ...state, errorRecovery: action.payload };

    case 'USER_RESET_ERROR_LOGIN':
      return { ...state, errorLogin: null };

    case 'USER_RESET_ERROR_REGISTER':
      return { ...state, errorRegister: null };

    case 'USER_SET_DATA':
      return { ...state, userData: { ...action.payload }, logged: true };

    case 'USER_SET_AUTH_TOKEN':
      return { ...state, token: action.payload.token, logged: true };

    case 'SET_USERPREFERENCE':
      return {
        ...state,
        userData: {
          ...state.userData,
          [action.payload.preferencia]: action.payload.valor,
          // mostrarBotonCrearEquipo: action.payload.preferencia === 'MostrarBotonCrearEquipo' ?  : state.userData.mostrarBotonCrearEquipo,
          // paginaInicio: action.payload.preferencia === 'PaginaInicio' ? action.payload.valor : state.userData.paginaInicio,
        },
      };
    case 'ERROR_UPDATEUSER':
      return {
        ...state,
        resultSavePerfil: 'error',
      };
    case 'CUSTOMERROR_UPDATEUSER':
      return {
        ...state,
        resultSavePerfil: action.payload,
      };
    case 'SET_SAVEUSERDATA':
      return {
        ...state,
        resultSavePerfil: 'ok',
        userData: {
          ...state.userData,
          nombre: action.payload.nombre,
          apellido: action.payload.apellido,
          legajo: action.payload.legajo,
          dni: action.payload.dni,
          idiomaId: action.payload.idiomaId,
          vistaPreferidaId: action.payload.vistaPreferidaId,
          mediosContacto: action.payload.mediosContacto,
          zonaHoraria: action.payload.zonaHorariaID,
          profilePicture: action.payload.profilePicture,
        },
      };
    case 'USER_CLEAN_DATA':
      return initialState;
    default:
      return state;
  }
};

export default reducer;
