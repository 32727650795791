import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { composePure } from './utils/composepure';
import MainWrapper from './MainWrapper';
import Login from './containers/Login';
import Dashboard from './containers/Dashboard';
import ConfiguracionAlarmas from './containers/ConfiguracionAlarmas';
import VariableDetail from './components/VariableDetail';
// import VistaCuadros from './containers/VistaCuadros';
// import VistaDisponibilidad from './containers/VistaDisponibilidad';
// import VistaTableros from './containers/VistaTableros';
import RectifierMoreInfo from './components/RectifierMoreInfo';
import NewDevice from './containers/NewDevice';
import ABMGrupos from './containers/ABMGrupos';
import Operaciones from './containers/Operaciones';
import PerfilUsuario from './containers/PerfilUsuario';
import VistaAyuda from './containers/VistaAyuda';
import Forbidden from './containers/Forbidden';

import store from './store';
import Actions from './actions';

const isLogged = (component, logged) => {
  if (logged) return component;

  return Login;
};

const checkPermisos = (path, permisos, logged, component) => {
  if (!logged) return Login;

  if (path === '/newDevice' && permisos.findIndex(x => x.opcionCodigo === 'CRECT') === -1) {
    return Forbidden;
  }
  return component;
};

const Router = ({
  logged,
  permisos,
}) => {
  let idleTimer = null;
  const [state, setState] = useState({
    timeout: process.env.REACT_APP_TIMEOUT,
    showModal: false,
    userLoggedIn: false,
    isTimedOut: false,
  });

  const _onAction = () => {
    setState({ ...state, isTimedOut: false });
  };

  const _onActive = () => {
    setState({ ...state, isTimedOut: false });
  };

  const _onIdle = () => {
    const { isTimedOut } = state;
    if (isTimedOut && logged) {
      store.dispatch(Actions.Users.fetchLogout());
    } else {
      setState({ ...state, showModal: true });
      idleTimer.reset();
      setState({ ...state, isTimedOut: true });
    }
  };

  return (
    <>
      <IdleTimer
        ref={(ref) => { idleTimer = ref; }}
        element={document}
        onActive={_onActive}
        onIdle={_onIdle}
        onAction={_onAction}
        debounce={250}
        timeout={state.timeout}
      />
      <MainWrapper>
        <main>
          <Switch>
            <Route exact path="/" component={isLogged(Dashboard, logged)} />
            <Route path="/list" component={isLogged(Dashboard, logged)} />
            <Route path="/map" component={isLogged(Dashboard, logged)} />
            <Route path="/cuadrosNovedades" component={isLogged(Dashboard, logged)} />
            <Route path="/cuadrosAlarmas" component={isLogged(Dashboard, logged)} />
            <Route path="/cuadrosComandos" component={isLogged(Dashboard, logged)} />
            <Route path="/cuadrosNotificaciones" component={isLogged(Dashboard, logged)} />
            {/* <Route exact path="/disponibilidad" component={isLogged(VistaDisponibilidad, logged)} /> */}
            <Route path="/disponibilidad" component={isLogged(Dashboard, logged)} />
            {/* Vista Tableros */}
            <Route path="/tableros" component={isLogged(Dashboard, logged)} />
            <Route path="/variableDetail" component={isLogged(VariableDetail, logged)} />
            <Route path="/variableDetailAMC" component={isLogged(Dashboard, logged)} />
            <Route path="/configuracionAlarmas" component={isLogged(ConfiguracionAlarmas, logged)} />
            <Route path="/moreInfo" component={isLogged(RectifierMoreInfo, logged)} />
            {/* <Route path="/newDevice" component={isLogged(NewDevice, logged, permisos)} /> */}
            <Route path="/newDevice" component={checkPermisos('/newDevice', permisos, logged, NewDevice)} />
            <Route path="/grupos" component={isLogged(ABMGrupos, logged)} />
            <Route path="/operaciones" component={isLogged(Operaciones, logged)} />
            <Route path="/perfilUsuario" component={isLogged(PerfilUsuario, logged)} />
            <Route path="/ayuda" component={isLogged(VistaAyuda, logged)} />
            <Route path="/login" component={Login} />
          </Switch>
        </main>
      </MainWrapper>
    </>
  );
};

Router.propTypes = {
  logged: propTypes.bool.isRequired,
  permisos: propTypes.arrayOf(propTypes.object).isRequired,
};

export default composePure(
  connect(state => ({
    logged: state.Users.logged,
    permisos: state.Users.logged ? state.Users.userData.permisos : [],
  }), undefined),
)(Router);
