export const RECTIFIERS_FETCH_ALL = 'RECTIFIERS_FETCH_ALL';
export const RECTIFIERS_SET_SELECTED = 'RECTIFIERS_SET_SELECTED';
export const RECTIFIERS_FETCH_INICIOALARMA = 'RECTIFIERS_FETCH_INICIOALARMA';
export const FETCH_CONFIGURACIONREPORTE = 'FETCH_CONFIGURACIONREPORTE';
export const FETCH_AVAILABLEDEVICES = 'FETCH_AVAILABLEDEVICES';
export const FETCH_ZONAHORARIAS = 'FETCH_ZONAHORARIAS';
export const FETCH_TIPOSRECTIFICADOR = 'FETCH_TIPOSRECTIFICADOR';
export const REMOVE_AVAILABLEDEVICE = 'REMOVE_AVAILABLEDEVICE';
export const ADD_DEVICE = 'ADD_DEVICE';
export const REMOVE_RECTIFICADOR = 'REMOVE_RECTIFICADOR';
export const REMOVE_DEVICE = 'REMOVE_DEVICE';
export const FETCH_DEVICEBYID = 'FETCH_DEVICEBYID';
export const ADD_RECTIFIERTOGROUP = 'ADD_RECTIFIERTOGROUP';
export const REMOVE_RECTIFIERTOGROUP = 'REMOVE_RECTIFIERTOGROUP';
export const SET_ADDDEVICETOGROUP = 'SET_ADDDEVICETOGROUP';
export const SET_REMOVEDEVICEFROMGROUP = 'SET_REMOVEDEVICEFROMGROUP';
export const GET_LASTREPORT = 'GET_LASTREPORT';
export const GET_LOCATIONDEVICE = 'GET_LOCATIONDEVICE';
export const SET_LOCATIONDEVICE = 'SET_LOCATIONDEVICE';
export const GUARDAR_REACTIFICADOR = 'GUARDAR_REACTIFICADOR';
