import React, { Fragment, useState } from 'react';

// import Select from 'react-select';
import {
  Row,
} from 'react-bootstrap';
import {
  Grid,
  Container,
  IconButton,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  // TextField,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'moment';
import SalidaItem from '../SalidaItem';
// import FilterAlert from '../FilterAlert';
import AwesomeIcon from '../AwesomeIcon';

// import MoreDataChart from '../MoreDataChart';
import PageWrapper from '../PageWrapper';

import ModalReporteActual from '../ModalReporteActual';
import RectifierDatePicker from '../RectifierDatePicker';
// import GridComandos from '../GridComandos';
import GridNovedades from '../GridNovedades';

import Minimap from '../Minimap';
import theme from '../../scss/theme/colors';

import './style.css';

// import { translate } from 'react-i18next';
import store from '../../store';
import Actions from '../../actions';

import {
  composePure,
  lifecycle,
  // withProps,
} from '../../utils/composepure';

import Props from './props';

/* const property = key => (
  x => (x[key])
);

const flatten = (a, b) => (
  a.concat(b)
); */

/* const getUnique = (arr, comp) => {
  const unique = arr
    .map(e => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e]).map(e => arr[e]);

  return unique;
}; */

const useStyles = makeStyles({
  button: {
    color: theme.blue.darker,
  },
  buttonModal: {
    backgroundColor: '#0083C7 !important',
  },
  dialogPaper: {
    // minHeight: '80vh',
    maxHeight: '80vh',
  },
  textField: {
    width: '100%',
  },
  icon: {
    display: 'block',
  },
});

/** @description pagina secundaria accedida desde mas Dispositivos.
 * Muestra mas detalle del equipo seleccionado. Ubicación en mapa, estado, datos de salida y tendencia.
 * Posibilidad de agregar nuevas novedades.
 */
const RectifierMoreInfo = ({
  location,
  language,
  history,
  // variablesMultipleSalida,
  userId,
  tendencias,
  permisos,
}) => {
  const classes = useStyles();

  const [openReporteActual, setOpenReporteActual] = useState(false);
  const [openCMDReport, setOpenCMDReport] = useState(false);
  const handleCloseCMDReport = () => setOpenCMDReport(false);
  const handleClickOpenReport = () => setOpenCMDReport(true);

  // const [novedad, setNovedad] = useState('');
  const novedadInp = React.createRef();

  const [openDialogNovedad, setOpenDialogNovedad] = useState(false);
  const handleCloseDialogNovedad = () => setOpenDialogNovedad(false);
  const handleClickOpenDialogNovedad = () => setOpenDialogNovedad(true);

  const handleGuardarNovedad = () => {
    store.dispatch(Actions.Cuadros.saveNovedad(location.rectificador.rectificadorID, novedadInp.current.value, userId));
    handleCloseDialogNovedad();
  };

  const position = location.rectificador ? {
    latitud: location.rectificador.latitud,
    longitud: location.rectificador.longitud,
  } : null;

  const getTooltip = (on, fechaInicioAlarma) => {
    if (!on) {
      return '';
    }

    if (!fechaInicioAlarma) {
      return location.t('default.noData', 'No hay datos disponibles');
    }

    return `${location.t('default.enAlarmaDesde', 'En alarma desde el')} ${Moment(fechaInicioAlarma).format('DD/MM/YYYY HH:mm:ss')}`;
  };

  return (
    <PageWrapper
      history={history}
    >
      <Fragment>
        <Row>
          <Container maxWidth="xl" className="FilterBarHeader">
            <Row>
              <Grid container>
                <Grid item xs={1}>
                  <IconButton
                    // className={classes.button}
                    aria-label="left"
                    onClick={() => history.push({
                      pathname: '/',
                      prevPath: 'moreInfo',
                    })}
                    style={{
                      border: '2px solid rgba(0, 0, 0, 0.54)',
                    }}
                  >
                    <AwesomeIcon icon="left" />
                  </IconButton>
                </Grid>
                <Grid item xs={5} className="gf-font-size-more-info">
                  <h3>{location.rectificador.nombre}</h3>
                </Grid>
              </Grid>
            </Row>
          </Container>
        </Row>
        <Row>
          <Grid item xs={3} className="leftGrid gf-media-mapa">
            <Minimap location={position} />
          </Grid>
          <Grid
            item
            className="rigthGrid gf-media-mapa gf-media-body"
          >
            <fieldset className="panel gf-media-body-inside">
              <legend className="panelTitulo">
                {location.t('default.estado', 'Estado')}
              </legend>
              <div className="valoresRectificador">
                <ul className="listaValores">
                  {location.rectificador.valores.map(v => (
                    <li key={v.reporteValorID} className="y-center">
                      <span className="ml-4">{`${language === 'EN' ? v.nombreEn : v.nombreEs} : ${v.listaValorID ? v.listaValor : v.valor}`}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="alarmasRectificador">
                <ul style={{ columnCount: 2 }}>
                  {location.rectificador.alertas.map(a => (
                    <li title={getTooltip(a.on, a.fechaInicioAlarma)} className="mb-1 y-center FilterAlert breakPage">
                      <span style={{ color: a.on ? 'red' : 'green' }} className="ml-4">{language === 'EN' ? a.nombreEN : a.nombreES}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="botonera gf-media-body-inside-botonera">
                <IconButton
                  className={`${classes.icon} gf-media-body-inside-float`}
                  aria-label="download"
                  onClick={() => setOpenReporteActual(true)}
                  title={location.t('default.reporteActual', 'Reporte Actual')}
                >
                  <AwesomeIcon icon="download" />
                </IconButton>
                <ModalReporteActual
                  openReporteActual={openReporteActual}
                  setOpenReporteActual={setOpenReporteActual}
                  rectificadoresSeleccionados={[location.rectificador]}
                  userId={userId}
                  language={language}
                  t={location.t}
                  classes={classes}
                />
                <RectifierDatePicker
                  classes={classes}
                  openCMD={openCMDReport}
                  handleCloseCMD={handleCloseCMDReport}
                  toggleCalendar={handleClickOpenReport}
                  rectificadorID={location.rectificador.rectificadorID}
                />
                { permisos.findIndex(p => p.opcionCodigo === 'CA') > -1 && (
                  <Link to={{
                    pathname: '/configuracionAlarmas',
                    rectificador: location.rectificador,
                  }}
                  >
                    <IconButton
                      className={`${classes.icon} gf-media-body-inside-float`}
                      aria-label="confAlarma"
                      title={location.t('default.confAlarma', 'Configuración de Alarmas')}
                      // onClick={() => setOpenReporteActual(true)}
                    >
                      <AwesomeIcon icon="confAlarma" />
                    </IconButton>
                  </Link>
                )}
                { permisos.findIndex(p => p.opcionCodigo === 'CR') > -1 && (
                  <Link to={{
                    pathname: '/newDevice',
                    rectificador: location.rectificador,
                    origin: 'moreInfo',
                  }}
                  >
                    <IconButton
                      className={`${classes.icon} gf-media-body-inside-float`}
                      aria-label="confDevice"
                      // onClick={() => setOpenReporteActual(true)}
                      title={location.t('default.confDevice', 'Configuración de Equipo')}
                    >
                      <AwesomeIcon icon="settings" />
                    </IconButton>
                  </Link>
                )}
              </div>
            </fieldset>
          </Grid>
          {/* <Grid item style={{ margin: '10px' }}>
          </Grid> */}
        </Row>
        <Row>
          {/* filteredData.length > 0 && (
            <MoreDataChart data={filteredData} variable={state.variableSelected} language={language} />
          ) */}
        </Row>
      </Fragment>
      <Row>
        <Grid container>
          <Grid item xs={12} className="gf-media-mapa gf-media-body">
            <fieldset className="panel gf-media-body-inside">
              <legend className="panelTitulo">{`${location.t('default.fechaDatos', 'Fecha datos')}: ${Moment(location.rectificador.fecha).format('DD/MM/YYYY HH:mm:ss')}`}</legend>
              {location.rectificador.salidas.map((s) => {
                console.log(location.rectificador);
                const data = tendencias ? tendencias.filter(t => t.salidaNro === s.salidaID) : [];
                return (
                  <SalidaItem
                    key={s.salidaID}
                    salida={s}
                    language={language}
                    t={location.t}
                    variables={data}
                    expired={location.rectificador.expired}
                    rectificador={location.rectificador}
                    tendenciesAllOutputs={tendencias}
                  />
                );
              })}
            </fieldset>
          </Grid>
        </Grid>
      </Row>
      <Row>
        <Grid container>
          <Grid className="gf-media-mapa gf-media-body">
            <fieldset className="panel gf-media-body-inside">
              <legend className="panelTitulo">
                {location.t('default.novedades', 'Novedades')}
              </legend>
              <IconButton
                className={classes.icon}
                aria-label="plusNovedad"
                // onClick={() => setOpenReporteActual(true)}
                title={location.t('default.createNovedad', 'Crear novedad')}
                onClick={handleClickOpenDialogNovedad}
                style={{
                  backgroundColor: '#007bff',
                  color: '#fff',
                  float: 'right',
                  marginBottom: '15px',
                }}
              >
                <AwesomeIcon icon="plus" />
              </IconButton>
              {/* <Button
                className={classes.buttonModal}
                variant="contained"
                color="primary"
                onClick={handleClickOpenDialogNovedad}
              >
                Crear Novedad
              </Button> */}
              <GridNovedades rectificadorID={location.rectificador.rectificadorID} />
            </fieldset>
          </Grid>
        </Grid>
      </Row>
      {/*
        history.location.pathname === '/variableDetailAMC' && (
          <VariableDetailAMC variable={variableSelected} />
        )
      */}
      <Dialog
        // classes={{ paper: classes.dialogPaper }}
        open={openDialogNovedad}
        onClose={handleCloseDialogNovedad}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Grid item xs={12}>
            {/* <TextField
              id="txtNovedad"
              type="text"
              style={{ width: '80%' }}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              rows={4}
              value={novedad}
              onChange={evt => setNovedad(evt.target.value)}
              placeholder={location.t('default.novedad', 'Novedad')}
            /> */}
            <textarea
              id="txtNovedad"
              style={{ width: '80%' }}
              className={classes.textField}
              rows="4"
              // value={novedad}
              // onChange={evt => setNovedad(evt.target.value)}
              ref={novedadInp}
              placeholder={location.t('default.novedad', 'Novedad')}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" className={classes.buttonModal} onClick={handleCloseDialogNovedad}>{location.t('default.cerrar', 'Cerrar')}</Button>
          <Button color="primary" variant="contained" className={classes.buttonModal} onClick={handleGuardarNovedad}>{location.t('default.guardar', 'Guardar')}</Button>
        </DialogActions>
      </Dialog>
    </PageWrapper>
  );
};

RectifierMoreInfo.propTypes = Props;

const AppCompose = composePure(
  lifecycle({
    componentDidMount() {
      const { location } = this.props;
      console.log(location.rectificador);
      if (!location.rectificador.expired) {
        store.dispatch(Actions.Variables.getVariablesByRectificador(location.rectificador.rectificadorID, true));
      }
    },
  }),
  connect(state => ({
    language: state.Language.current,
    variablesMultipleSalida: state.Variables.VariablesMultipleSalidas,
    userId: state.Users.userData.usuarioId,
    permisos: state.Users.userData.permisos,
    tendencias: state.Variables.Variables,
  })),
)(RectifierMoreInfo);

export default AppCompose;

// export default translate('common')(AppCompose);
