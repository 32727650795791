import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import { apiClient, uploadFile } from '../utils/apiClient';
import Actions from '../actions';

const { Universal } = Actions;

/** @description Llamada al EndPoint para llamada a modulos
*/
export function* requestModule(action) {
  try {
    const token = yield select(state => state.Users.token);
    if (action.process) yield put({ type: 'FETCH_MODULE', process: true });
    const params = {
      microservice: action.microservice || '',
      module: 'about',
      query: {},
      method: 'get',
      token,
      data: {},
      ...action,
    };

    if (action.dispatchBefore) {
      yield put({ type: action.dispatchBefore, action, flow: 'init' });
    }

    const response = yield call(apiClient, params);

    if (action.dispatchAction) {
      yield put({ type: action.dispatchAction, payload: response, flow: 'end' });
    } else {
      if (params.method === 'get') {
        yield put(Universal.fetchSuccess(params.module, Object.assign({}, params, response)));
      }
      if (params.method === 'patch') {
        yield put(Universal.updateSuccess(params.module, Object.assign({}, params, response)));
      }
    }

    if (action.process) yield put({ type: 'FETCH_REQUEST_END' });
    if (action.dispatchFinal) {
      yield put({
        type: action.dispatchFinal,
        action,
        payload: response,
        error: false,
        flow: 'end',
      });
    }
  } catch (e) {
    yield put(Universal.requestError(action.module, { ...action.payload, error: e }));
    if (e.response && e.response.status === 401) {
      yield put(Actions.Users.fetchLogout());
      window.location.href = '/login';
    }
  }
}

/** @description Llamada al EndPoint para actualizar imagenes
*/
export function* uploadImage(action) {
  try {
    const { form, folder, file } = action.data;
    const newfile = yield uploadFile(form, file, folder);
    yield put({ type: 'UNIVERSAL_UPLOAD_FILE_SUCCESS', newfile });
    action.data.cb(newfile);
  } catch (e) {
    console.info('Error en uploadImage: ', e);
    yield put(Actions.Universal.requestError({
      payload: Object.assign({}, action.payload, { error: e }),
    }));
  }
}

export default {
  requestModule,
  uploadImage,
};
