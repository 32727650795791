const FETCH_TIPOMEDIOCONTACTO = 'FETCH_TIPOMEDIOCONTACTO';
const FETCH_EMPRESASALL = 'FETCH_EMPRESASALL';
const FETCH_MARCASALL = 'FETCH_MARCASALL';
const FETCH_TIPOUNIDADALL = 'FETCH_TIPOUNIDADALL';
const FETCH_UNIDADESCOMUNICACIONALL = 'FETCH_UNIDADESCOMUNICACIONALL';
const FETCH_LINEASALL = 'FETCH_LINEASALL';
const FETCH_TIPOCOMUNICACIONALL = 'FETCH_TIPOCOMUNICACIONALL';
const SAVE_UNIDADCOMUNICACION = 'SAVE_UNIDADCOMUNICACION';
const SET_NEWUC = 'SET_NEWUC';
const UPDATEUC = 'UPDATEUC';
const DELETE_UC = 'DELETE_UC';
const SET_DELETE_UC = 'SET_DELETE_UC';
const FETCH_PLANES = 'FETCH_PLANES';
const FETCH_CUENTASIPGATEWAY = 'FETCH_CUENTASIPGATEWAY';
const SAVE_LINEA = 'SAVE_LINEA';
const SET_NEWLINEA = 'SET_NEWLINEA';
const UPDATE_LINEA = 'UPDATE_LINEA';
const DELETE_LINEA = 'DELETE_LINEA';
const SET_DELETE_LINEA = 'SET_DELETE_LINEA';

export const getTiposMedioContacto = () => ({
  type: FETCH_TIPOMEDIOCONTACTO,
});

export const getEmpresasAll = () => ({
  type: FETCH_EMPRESASALL,
});

export const getMarcasAll = () => ({
  type: FETCH_MARCASALL,
});

export const getTipoUnidadAll = () => ({
  type: FETCH_TIPOUNIDADALL,
});

export const getUCDisponibles = () => ({
  type: FETCH_UNIDADESCOMUNICACIONALL,
});

export const getLineasAll = () => ({
  type: FETCH_LINEASALL,
});

export const getTipoComunicacionAll = () => ({
  type: FETCH_TIPOCOMUNICACIONALL,
});

export const saveUnidadComunicacion = payload => ({
  type: SAVE_UNIDADCOMUNICACION,
  payload,
});

export const setNewUC = payload => ({
  type: SET_NEWUC,
  payload,
});

export const updateUC = payload => ({
  type: UPDATEUC,
  payload,
});

export const deleteUC = payload => ({
  type: DELETE_UC,
  payload,
});

export const setDeleteUC = payload => ({
  type: SET_DELETE_UC,
  payload,
});

export const getPlanes = () => ({
  type: FETCH_PLANES,
});

export const getCuentasIPGateway = () => ({
  type: FETCH_CUENTASIPGATEWAY,
});

export const saveLinea = payload => ({
  type: SAVE_LINEA,
  payload,
});

export const setNewLinea = payload => ({
  type: SET_NEWLINEA,
  payload,
});

export const updateLinea = payload => ({
  type: UPDATE_LINEA,
  payload,
});

export const deleteLinea = payload => ({
  type: DELETE_LINEA,
  payload,
});

export const setDeleteLinea = payload => ({
  type: SET_DELETE_LINEA,
  payload,
});

export default {
  getTiposMedioContacto,
  getEmpresasAll,
  getMarcasAll,
  getTipoUnidadAll,
  getUCDisponibles,
  getLineasAll,
  getTipoComunicacionAll,
  saveUnidadComunicacion,
  setNewUC,
  updateUC,
  deleteUC,
  setDeleteUC,
  getCuentasIPGateway,
  getPlanes,
  saveLinea,
  setNewLinea,
  updateLinea,
  deleteLinea,
  setDeleteLinea,
};
