import axios from 'axios';
import config from '../config';

const BASE_URL = config.ENDPOINT_URL;

const headers = (token) => {
  if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.common.apikey = config.API_KEY;
};

/** @description metodo para realizar la conexion al EndPoint con el agregado del token.
*/
export const apiClient = async (params) => {
  try {
    headers(params.token || '');
    if (params.headers) {
      Object.keys(params.headers).forEach((k) => {
        (axios.defaults.headers.common[k] = params.headers[k]);
        return true;
      });
    }

    const options = {
      method: params.method.toLowerCase(),
      url: [params.microservice, params.module].join('/'),
      baseURL: params.BASE_URL || BASE_URL,
    };

    if (params.query && Object.keys(params.query).length > 0) options.params = { ...params.query };
    if (params.data && Object.keys(params.data).length > 0) options.data = { ...params.data };
    const data = await axios.request(options);
    /* .catch((error) => {
      console.log(error.response);
    }); */
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

/** @description metodo generico de conexion al EndPoint para subir los archivos.
*/
export const uploadFile = (form, file, folder) => new Promise((resolve, reject) => {
  const oData = new FormData(form);

  oData.append('folder', folder);
  oData.append('upfile', file);

  const oReq = new XMLHttpRequest();

  oReq.open('POST', config.UPLOAD_URL, true);
  oReq.onload = () => {
    if (oReq.status === 200) {
      return resolve(JSON.parse(oReq.response));
    }
    return reject(new Error(`Error ${oReq.status}`));
  };
  oReq.setRequestHeader('apikey', config.API_KEY);
  oReq.send(oData);
});
