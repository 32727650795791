import React, { useState } from 'react';

import {
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Container,
  FormControl,
  Button,
  makeStyles,
} from '@material-ui/core';

import {
  Row,
} from 'react-bootstrap';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import PageWrapper from '../../components/PageWrapper';
import OperacionesChart from '../../components/OperacionesChart';

import { composePure, withProps } from '../../utils/composepure';

import Actions from '../../actions';
import store from '../../store';

import Props from './props';

const useStyles = makeStyles({
  buttonModal: {
    backgroundColor: '#0083C7 !important',
  },
});

const getUnique = (arr, comp) => {
  const unique = arr
    .map(e => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e]).map(e => arr[e]);

  return unique;
};

/** @description pagina para la consulta a traves de diferentes filtros y tipos de oparaciones
 * para los diversos dispositivos. El resultado es visualizado a traves de graficos de linea.
 */
const Operaciones = ({
  history,
  devices,
  t,
  language,
  variablesOperacion,
}) => {
  const classes = useStyles();

  const [deviceId, setDeviceId] = useState('');
  const [salidaA, setSalidaA] = useState('');
  const [variableA, setVariableA] = useState('');
  const [operacion, setOperacion] = useState('');
  const [salidaB, setSalidaB] = useState('');
  const [variableB, setVariableB] = useState('');

  const deviceSelected = devices.filter(d => d.rectificadorID === deviceId)[0];
  const salidas = deviceSelected ? deviceSelected.salidas : [];
  const salidaASelected = salidas.filter(s => s.salidaID === salidaA)[0];
  const variablesA = salidaASelected ? salidaASelected.valores.filter(x => !x.listaValorID) : [];
  const salidaBSelected = salidas.filter(s => s.salidaID === salidaB)[0];
  const variablesB = salidaBSelected ? salidaBSelected.valores.filter(x => !x.listaValorID) : [];

  const handleClickSearch = () => {
    store.dispatch(Actions.Variables.getVariablesOperacion(deviceId, salidaA, variableA, salidaB, variableB));
  };

  const handleChangeOperacion = (evt) => {
    setOperacion(evt.target.value);
  };

  return (
    <PageWrapper
      history={history}
    >
      <Row>
        <Container maxWidth="xl" className="FilterBarHeader">
          <Grid xs={3}>
            <h3>{t('default.operaciones', 'Operaciones')}</h3>
          </Grid>
        </Container>
      </Row>
      <Row style={{ marginTop: '50px' }}>
        <Container>
          <Grid container style={{ marginTop: 25 }}>
            <Grid item xs={4}>
              <FormControl style={{ width: '80%' }}>
                <InputLabel id="ddlDeviceLabel">{`${t('default.dispositivo', 'Equipo')}...`}</InputLabel>
                <Select
                  labelId="ddlDeviceLabel"
                  id="ddlDevice"
                  value={deviceId}
                  onChange={evt => setDeviceId(evt.target.value)}
                >
                  {devices.map(d => (
                    <MenuItem key={d.rectificadorID} value={d.rectificadorID}>{d.nombre}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: '80%' }}>
                <InputLabel id="labelOperacion">{`${t('default.operacion', 'Operacion')}...`}</InputLabel>
                <Select
                  labelId="labelOperacion"
                  className="basic-multi-select"
                  value={operacion}
                  onChange={handleChangeOperacion}
                  inputProps={{
                    name: 'ddlOperaciones',
                    id: 'ddlOperaciones',
                  }}
                  variant="standard"
                >
                  <MenuItem key={1} value={1}>{t('default.suma', 'Suma')}</MenuItem>
                  <MenuItem key={2} value={2}>{t('default.resta', 'Resta')}</MenuItem>
                  <MenuItem key={3} value={3}>{t('default.multiplicacion', 'Multiplicación')}</MenuItem>
                  <MenuItem key={4} value={4}>{t('default.division', 'División')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '80%' }}>
                <InputLabel htmlFor="ddlSalidasA">{`${t('default.salida', 'Salida')} A...`}</InputLabel>
                <Select
                  className="basic-multi-select"
                  value={salidaA}
                  onChange={evt => setSalidaA(evt.target.value)}
                  inputProps={{
                    name: 'ddlSalidasA',
                    id: 'ddlSalidasA',
                  }}
                  variant="standard"
                >
                  {salidas.map(s => (
                    <MenuItem key={s.salidaID} value={s.salidaID}>{`${t('default.salida', 'Salida')} ${s.salidaID}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: '80%' }}>
                <InputLabel htmlFor="ddlVariablesA">{`${t('default.variable', 'Variable')} A...`}</InputLabel>
                <Select
                  className="basic-multi-select"
                  value={variableA}
                  onChange={evt => setVariableA(evt.target.value)}
                  inputProps={{
                    name: 'ddlVariablesA',
                    id: 'ddlVariablesA',
                  }}
                  variant="standard"
                >
                  {variablesA.map(v => (
                    <MenuItem key={v.configuracionReporteID} value={v.configuracionReporteID}>{language === 'EN' ? v.nombreEn : v.nombreEs}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '80%' }}>
                <InputLabel htmlFor="ddlSalidasB">{`${t('default.salida', 'Salida')} B...`}</InputLabel>
                <Select
                  className="basic-multi-select"
                  style={{ width: '80%' }}
                  value={salidaB}
                  onChange={evt => setSalidaB(evt.target.value)}
                  inputProps={{
                    name: 'ddlSalidasB',
                    id: 'ddlSalidasB',
                  }}
                  variant="standard"
                >
                  {salidas.map(s => (
                    <MenuItem key={s.salidaID} value={s.salidaID}>{`${t('default.salida', 'Salida')} ${s.salidaID}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: '80%' }}>
                <InputLabel htmlFor="ddlVariablesB">{`${t('default.variable', 'Variable')} B...`}</InputLabel>
                <Select
                  // placeholder={`${t('default.variable', 'Variable')}...`}
                  className="basic-multi-select"
                  style={{ width: '80%' }}
                  value={variableB}
                  onChange={evt => setVariableB(evt.target.value)}
                  inputProps={{
                    name: 'ddlVariablesB',
                    id: 'ddlVariablesB',
                  }}
                  variant="standard"
                >
                  {variablesB.map(v => (
                    <MenuItem key={v.configuracionReporteID} value={v.configuracionReporteID}>{language === 'EN' ? v.nombreEn : v.nombreEs}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Row className="gf-oper-button" style={{ marginRight: '11%', marginTop: '20px', float: 'right' }}>
            <Button
              className={classes.buttonModal}
              variant="contained"
              color="primary"
              onClick={handleClickSearch}
            >
              {t('default.buscar', 'Buscar')}
            </Button>
          </Row>
          <Row className="gf-oper-graph" style={{ marginTop: 80 }}>
            {variablesOperacion && variablesOperacion.length > 0 && (
              <OperacionesChart operationData={variablesOperacion} operation={operacion} language={language} />
            )}
          </Row>
        </Container>
      </Row>
    </PageWrapper>
  );
};

Operaciones.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
  connect(state => ({
    devices: getUnique(state.Rectifiers.docs.filter(x => x.grupoId), 'rectificadorID'),
    language: state.Language.current,
    variablesOperacion: state.Variables.VariablesOperacion,
  })),
)(Operaciones);

export default translate('common')(AppCompose);
