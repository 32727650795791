import React from 'react';

/* import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography'; */
import { connect } from 'react-redux';
import { MobilePDFReader } from 'react-read-pdf';

import { translate } from 'react-i18next';
import { composePure, withProps } from '../../utils/composepure';

// import Image from '../../components/Image';

import Config from '../../config';
import PageWrapper from '../../components/PageWrapper';

import Props from './props';

/* const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel); */

/* const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary); */

/* const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails); */

const EnglishManual = () => (
  <MobilePDFReader
    url={`${Config.ENDPOINT_URL}/Account/GetPdfManual/EN`}
  />
);

const SpanishManual = () => (
  <MobilePDFReader
    url={`${Config.ENDPOINT_URL}/Account/GetPdfManual/ES`}
  />
);

/** @description pagina que se visualiza al ingresar en Ayuda. La misma estará presente en diferentes
 * idiomas.
 */
const VistaAyuda = ({
  history,
  t,
  language,
}) => {
  console.log(t('default.ayuda', 'Ayuda'));
  const pdfUrl = `${Config.ENDPOINT_URL}/Account/GetPdfManual/${language}`;
  console.log(pdfUrl);
  /* const [expanded, setExpanded] = React.useState();

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  }; */

  return (
    <PageWrapper
      history={history}
    >
      <div style={{ overflow: 'scroll', height: 800 }}>
        {language === 'EN' ? (<EnglishManual />) : (<SpanishManual />)}
      </div>
      {/*
      <div style={{ marginTop: 25 }}>
        <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>{t('default.menuSuperior', 'Menu Superior')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <p><b>{t('default.funcionesGenerales', 'Funciones generales')}</b></p>
              <p>
                {t('default.FGO1',
                  'Al ingresar, se visualiza la pantalla principal con todos los rectificadores que el usuario puede ver. Veremos primero las funciones generales que serán visibles en todo momento.')}
              </p>
              <p><b>{t('default.menuUsuario', 'Menú de usuario')}</b></p>
              <p>{t('default.MUO1', 'En el menú superior, en su parte derecha, se encuentra un ícono:')}</p>
              <Image src="iconUsuario" style={{ marginTop: -10 }} />
              <p>{t('default.MUO2', 'Al presionarlo despliega un menú con datos del perfil:')}</p>
              <Image src="panelUsuario" />
              <p><b>{t('default.menuNotificaciones', 'Menu de notificaciones')}</b></p>
              {t('default.MNO1', 'En el menú superior, se encuentra el ícono:')}
              <Image src="iconNotificacion" />
              {t('default.MNO2', 'Este indicará oportunamente cuando haya alguna notificación disponible. Estas notificaciones pueden deberse a lo siguiente:')}
              <ul>
                <li>{t('default.MNO3', 'Nuevo reporte disponible.')}</li>
                <li>{t('default.MNO4', 'Comandos enviados o agendados.')}</li>
                <li>{t('default.MNO5', 'Nueva alarma.')}</li>
              </ul>
              {t('default.MNO6', 'Al tocar en este ícono, se despliega el menú donde informa de las últimas notificaciones')}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>{t('default.menuFiltros', 'Menu de Filtros')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <p>
                {t('default.MFO1',
                  'En la parte superior se encuentra un menú que permite filtrar, según distintos criterios, los equipos visibles. El menú también permite cambiar la forma de visualización de equipos.')}
              </p>
              <Image src="menuFiltros" fluid />
              {t('default.MFO2', 'Los filtros permitidos son:')}
              <ul>
                <li>{`${t('default.estado', 'Estado')}:`}</li>
                <ul>
                  <li>{t('default.estadoNormal', 'Normal: cuando no tiene alarmas (de equipo o de salida) y haya transmitido por última vez en menos de 10 días.')}</li>
                  <li>{t('default.estadoEnAlarma', 'En alarma: de equipo o de salida')}</li>
                  <li>{t('default.estadoInactivo', 'Inactivo: cuando su última transmisión tiene más de 10 días de antigüedad.')}</li>
                </ul>
                <li>{t('default.MFO3', 'Tipo de unidad: permite visualizar los equipos por tipo. Esto es útil para el envío de comandos o exportación de reportes.')}</li>
                <li>{t('default.MFO4', 'Grupo: visualiza equipos según grupos o zonas definidas.')}</li>
              </ul>
              <p>{t('default.MFO5', 'Es posible hacer una búsqueda escribiendo el nombre del equipo en el buscador previsto para tal fin.')}</p>
              <b>{t('default.nota', 'Nota')}</b>
              {t('default.notaO1', ':los filtros se mantienen entre las diferentes vistas')}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>{t('default.visualizacionEquipos', 'Visualización de equipos')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <p><b>{t('default.tituloTarjetas', 'Listado de rectificadores: tarjetas')}</b></p>
              {t('default.tarjetasO1', 'Es la vista por defecto de equipos. Se accede presionando el botón:')}
              <Image src="iconCards" />
              <p>
                {t('default.tarjetasO2', 'Cada rectificador es representado por una “tarjeta” donde se ve la información principal de cada rectificador y los últimos datos recibidos. ')}
                {t('default.tarjetasO3', 'Desde cada tarjeta se puede seleccionar cada salida de los rectificadores y así ver los datos actuales de la misma.')}
              </p>
              <Image src="cardsExample" />
              <p>
                {t('default.tarjetasO4', 'La tarjeta muestra el estado en su parte superior derecha por medio de un círculo.')}
                {t('default.tarjetasO5', 'El estado, como dijimos puede ser “normal”, “en alarma” e “inactivo”; esto se visualiza con colores verde, rojo y amarillo respectivamente.')}
                {t('default.tarjetasO6', 'Al pasar el curso por encima, se describe su estado.')}
              </p>
              {t('default.tarjetasO7', 'Al pasar el cursor por encima de alguna alarma, puede visualizarse desde el momento en que se encuentra en ese estado.')}
              <p><b>{t('default.tituloGrilla', 'Listado de rectificadores (grilla)')}</b></p>
              <p>{t('default.grillaO1', 'Se accede presionando el bóton:')}</p>
              <Image src="iconList" />
              <p>{t('default.grillaO2', 'Esta pantalla proporciona una visualización más compacta permitiendo ver de forma simplificada una mayor cantidad de rectificadores.')}</p>
              <p>{t('default.grillaO3', 'Al presionar sobre un equipo, se permite la selección de este. La selección funciona de la misma forma que la explicada en la vista de tarjetas.')}</p>
              <Image src="listDevices" fluid />
              <p><b>{t('default.tituloMapas', 'Listado de rectificadores (mapas)')}</b></p>
              {t('default.mapasO1', 'En esta forma de visualizar los rectificadores se pueden ver el total de los mismos, o la agrupación seleccionada, sobre un mapa, centrado y con zoom en los equipos visibles.')}
              {t('default.mapasO2', ' Al presionar sobre cada icono se tendrá la opción de ver a la tarjeta de cada rectificador.')}
              <p>{t('default.mapasO3', 'El icono y el color de los rectificadores dependerá del estado de los mismos: normal (verde) o en alarma (rojo).')}</p>
              <Image src="map" fluid />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>{t('default.seleccionEquipos', 'Selección de equipos')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <p>{t('default.seleccionEquiposO1', 'Es posible seleccionar los equipos por medio del cuadro de seleccionar en la parte superior izquierda de la tarjeta.')}</p>
              <p>{t('default.seleccionEquiposO2', 'También es posible seleccionarlos desde la vista de lista:')}</p>
              <p>
                {t('default.seleccionEquiposO3',
                  'A medida que se seleccionan aparecen en una barra inferior, desde donde será posible realizar el envío de comandos y exportación de reportes para los equipos seleccionados.')}
              </p>
              <Image src="footer" fluid />
              <p>{t('default.seleccionEquiposO4', 'Una vez seleccionados será posible el envío de comandos y la exportación de reportes.')}</p>
              <p><b>{t('default.envioComandos', 'Envío de comandos')}</b></p>
              <p>
                {t('default.envioComandosO1', 'El envío de comandos a los equipos seleccionados se realiza tocando en el botón:')}
                <Image src="iconComandos" />
              </p>
              <p>
                {t('default.envioComandosO2', 'Esta función está disponible solo si los equipos seleccionados son del mismo tipo.')}
                {t('default.envioComandosO3', 'Puede utilizarse el filtro dispuesto para esto para una mejor visualización.')}
                {t('default.envioComandosO4', 'Desde aquí se despliega una ventana que permite elegir los comandos necesarios a enviar.')}
              </p>
              <Image src="modalComandos" />
              <p>{t('default.envioComandosO5', 'Una vez enviado o programado el envío de un comando, se activará la notificación correspondiente y se cargará en el registro de comandos enviados.')}</p>
              <p><b>{t('default.exportarReporteActual', 'Exportación de reporte actual')}</b></p>
              <p>
                {t('default.exportarReporteActualO1', 'La exportación de reporte actual para los equipos seleccionados se accede tocando en el botón:')}
                <Image src="iconReporteActual" />
              </p>
              <p>{t('default.exportarReporteActualO2', 'Desde aquí se despliega una ventana que permite, opcionalmente, elegir e-mails para su envío.')}</p>
              <br />
              <Image src="modalReporteActual" />
              <p>
                {t('default.exportarReporteActualO3', 'Una vez que se consulta el reporte, se deja orden de generación de este.')}
                {t('default.exportarReporteActualO4', 'Cuando está listo, se enviará al e-mail correspondiente (si corresponde) y también se activa la notificación del sistema que permite su descarga.')}
                {t('default.exportarReporteActualO5', 'Se deja constancia de la exportación en el registro de notificaciones.')}
              </p>
              <p><b>{t('default.exportarReporteHistorico', 'Exportación de reporte histórico')}</b></p>
              <p>
                {t('default.exportarReporteHistoricoO1', 'La exportación de reporte histórico para los equipos seleccionados se accede tocando en el botón:')}
                <Image src="iconReporteHistorico" />
              </p>
              <p>
                {t('default.exportarReporteHistoricoO2', 'Desde aquí se despliega una ventana que permite elegir las fechas y opcionalmente, elegir e-mails para su envío.')}
              </p>
              <br />
              <Image src="modalReporteHistorico" />
              <p>
                {t('default.exportarReporteHistoricoO3', 'Una vez que se consulta el reporte, se deja orden de generación de este.')}
                {t('default.exportarReporteHistoricoO4', 'Cuando está listo, se enviará al e-mail correspondiente (si corresponde) y también se activa la notificación del sistema que permite su descarga.')}
                {t('default.exportarReporteHistoricoO5', 'Se deja constancia de la exportación en el registro de notificaciones.')}
              </p>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>{t('default.registro', 'Registros')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <p><b>{t('default.registroTitulo', 'Registros de alarma/comandos/novedades/notificaciones')}</b></p>
              <p>{t('default.registroO1', 'Se accede a estos desde el menú superior “Registro”.')}</p>
              <Image src="menuRegistro" />
              <p>
                {t('default.registroO2',
                  'Una vez dentro es posible visualizar las últimas 100 alarmas, comandos o novedades según corresponda, y su estado. Al hacer doble click en el campo de notas, es posible agregar una nota aclaratoria.')}
              </p>
              <Image src="listaAlarmas" />
              <p>{t('default.registroO3', 'Todas las ventanas de registro comparten funcionalidades dispuestas en la barra superior:')}</p>
              <Image src="comboTipoRegistro" />
              <ul>
                <li>
                  <b>{`${t('default.buscador', 'Buscador')}:`}</b>
                  {t('default.registroO4', 'en este caso buscará alarmas y equipos.')}
                </li>
                <li>
                  <b>{`${t('default.selectorRegistro', 'Selector de registro')}:`}</b>
                  {t('default.registroO5', 'el usuario puede moverse entre registros.')}
                </li>
              </ul>
              <p>
                {t('default.registroO6', 'Desde cualquier pantalla de registro es posible acceder al registro de notificaciones.')}
                {t('default.registroO7', 'Este registro puede accederse directamente desde el panel de notificaciones (este acceso no está implementado aún).')}
              </p>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel square expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>{t('default.tablero', 'Tableros')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <p><b>Tablero de mediciones</b></p>
              <p>Se accede desde el menú superior “Tableros”.</p>
              <Image src="menuTableros" />
              <p>Una vez dentro es posible elegir equipo y salida, para visualizar datos. Se visualizan los datos presentes en la tarjeta del dispositivo.</p>
              <Image src="chartDispositivos" />
              <p><b>Tablero de disponibilidad</b></p>
              <Image src="menuTableros" />
              <p>
                Una vez dentro es posible elegir un rango de fechas para visualizar la disponibilidad de los equipos.
                 Por defecto se visualizan todos los equipos que transmitan horómetro, aunque es posible filtrar según búsqueda.
              </p>
              <Image src="tableDisponibilidad" />
              <p>
                Los equipos cuya disponibilidad sea superior al 80% se marca en verde, y por debajo de este valor se usa el rojo.
                 Los equipos marcados en amarillo representan equipos que deben revisarse, ya que reportan un tiempo de uso superior al que es posible.
              </p>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div> */}
    </PageWrapper>
  );
};

VistaAyuda.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({
    t: props.t,
  })),
  connect(state => ({
    language: state.Language.current,
  })),
)(VistaAyuda);

export default translate('common')(AppCompose);
