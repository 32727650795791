import React from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Container,
  Grid,
  IconButton,
} from '@material-ui/core';

import { translate } from 'react-i18next';
import Moment from 'moment';

import AwesomeIcon from '../AwesomeIcon';

import { composePure, withProps, lifecycle } from '../../utils/composepure';
import Actions from '../../actions';
import store from '../../store';
import Props from './props';

/** @description Componente especifico para mostrar las Novedades efectuadas en forma de lista.
 * Se muestra descripcion, fecha, rectificador, usuario y accion de eliminar.
 */
const GridNovedades = ({
  novedades,
  t,
  // working,
  filter,
  rectificadorID,
}) => {
  const filteredDescripcion = novedades.filter(n => !filter || n.descripcion.toLowerCase().indexOf(filter.toLowerCase()) > -1);
  const filteredDevice = novedades.filter(n => !filter || n.rectificador.toLowerCase().indexOf(filter.toLowerCase()) > -1);
  const filteredData = [...new Set([...filteredDescripcion, ...filteredDevice])];

  const handleModifyNovedad = (oldValue, newValue, row) => {
    if (oldValue !== newValue) {
      store.dispatch({ type: 'CUADROS_UPDATE_NOVEDAD', payload: row });
    }
  };

  const cellEdit = cellEditFactory({
    mode: 'dbclick',
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row) => handleModifyNovedad(oldValue, newValue, row),
  });

  const handleDelete = (row) => {
    store.dispatch(Actions.Cuadros.deleteNovedad(row.novedadId));
  };

  const labelNovedad = t('default.novedad', 'Novedad');
  const labelFecha = t('default.fecha', 'Fecha');
  const labelDispositivo = t('default.dispositivo', 'Dispositivo');
  const labelUsuario = t('default.usuario', 'Usuario');

  const columns = [{
    headerClasses: 'gf-notas-hide',
    dataField: 'descripcion',
    text: labelNovedad,
    headerStyle: () => ({ width: '40%' }),
    sort: true,
    title: () => t('default.editTooltip', 'Doble click para editar'),
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: 'fecha',
    text: labelFecha,
    formatter: fecha => Moment(fecha).format('DD/MM/YYYY HH:mm:ss'),
    headerStyle: () => ({ width: '15%' }),
    sort: true,
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: 'rectificador',
    text: labelDispositivo,
    headerStyle: () => ({ width: '20%' }),
    hidden: rectificadorID > 0,
    sort: true,
  },
  {
    headerClasses: 'gf-hide-columns',
    dataField: 'usuario',
    text: labelUsuario,
    // hidden: rectificadorID > 0,
    sort: true,
  },
  {
    headerClasses: 'gf-hide-columns',
    dataField: 'novedadId',
    text: t('default.eliminar', 'Eliminar'),
    formatter: (cell, row) => (
      <IconButton
        // className={classes.button}
        className="gf-hide-columns"
        aria-label="trash"
        onClick={() => handleDelete(row)}
      >
        <AwesomeIcon icon="trash" />
      </IconButton>
    ),
    align: 'center',
    headerAlign: 'center',
  },
  ];

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t('default.pagPrev', 'página previa'),
    nextPageTitle: t('default.proxPag', 'próxima página'),
    firstPageTitle: t('default.primerPagina', 'primer página'),
    lastPageTitle: t('default.ultimaPagina', 'última página'),
  };

  return (
    <Container maxWidth="xl" className={`${rectificadorID > 0 ? '' : 'ListView'} gf-font-and-padi`}>
      <Grid container>
        <Grid item xs={12} className="gf-overflow">
          <BootstrapTable
            keyField="novedadId"
            striped
            hover
            condensed
            bootstrap4
            data={filteredData}
            cellEdit={cellEdit}
            columns={columns}
            pagination={rectificadorID ? null : paginationFactory(options)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

GridNovedades.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
  lifecycle({
    componentDidMount() {
      const { rectificadorID } = this.props;
      if (rectificadorID) {
        store.dispatch(Actions.Cuadros.getNovedadesByDevice(rectificadorID));
      } else {
        store.dispatch({ type: 'CUADROS_FETCH_NOVEDADES' });
      }
    },
  }),
  connect(state => ({
    // working: state.Universal.request.process,
    novedades: state.Cuadros.Novedades,
  })),
)(GridNovedades);

export default translate('common')(AppCompose);
