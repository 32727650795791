import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Badge,
  FormControlLabel,
  withStyles,
  Checkbox,
} from '@material-ui/core/';
import {
  Row,
  Container,
  Col,
  Button,
} from 'react-bootstrap';
// import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { translate } from 'react-i18next';

import theme from '../../scss/theme/colors';

import { composePure } from '../../utils/composepure';
import Actions from '../../actions';
import store from '../../store';
import Props from './props';

import ButtonIcon from '../ButtonIcon';

const useStyles = makeStyles(mui => ({
  list: {
    width: 350,
  },
  margin: {
    margin: mui.spacing(2),
  },
  FormControlLabel: {
    margin: 0,
  },
}));

const GrayCheckbox = withStyles({
  root: {
    color: '#E6E6E6',
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
    '&$checked': {
      color: theme.gray.light,
    },
    padding: '0.5rem',
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

/** @description Componente que se utiliza para crear el elemento Boton de Notificacion e incorpora
 * las funcionalidades de accion onClic y apertura de Slider por derecha.
 */
const NotificationButton = ({
  notificaciones,
  userId,
  t,
  language,
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const [notificacionSel, setNotificacionSel] = useState([]);

  const sideMenu = (
    side,
    notifications,
  ) => {
    const compare = (a, b) => {
      if (a.fecha < b.fecha) {
        return 1;
      }

      if (a.fecha > b.fecha) {
        return -1;
      }

      return 0;
    };

    const sortedNotifications = notifications.filter(x => !x.eliminada).concat().sort(compare);

    /* const handleDeleteNotification = (notificacionId) => {
      store.dispatch({ type: 'DELETE_NOTIFICACIONES', payload: { userId, notificacionId } });
    }; */

    const handleDeleteNotificationAll = () => {
      const notificacionesSel = sortedNotifications.filter(x => notificacionSel.includes(x.notificacionId));
      store.dispatch(Actions.Notificaciones.deleteNotificacionesAll(userId, notificacionesSel));
    };

    const handleDownloadReport = (notificacion) => {
      /* const blob = new Blob([notificacion.fileContent], {
        // type: 'application/vnd.ms-excel',
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      saveAs(blob, notificacion.fileName); */
      const id = notificacion.notificacionId;
      const tipoReporte = notificacion.tipoNotificacion;
      store.dispatch({ type: 'DOWNLOAD_REPORT', payload: { id, tipoReporte, language } });
    };

    const handleReadNotification = () => {
      const notificacionesSel = sortedNotifications.filter(x => notificacionSel.includes(x.notificacionId) && !x.leido);
      store.dispatch(Actions.Notificaciones.markReaded(userId, notificacionesSel));
      setNotificacionSel([]);
    };

    const handleChangeCheckbox = (id) => {
      const indexSelNotification = notificacionSel.findIndex(x => x === id);

      if (indexSelNotification === -1) {
        setNotificacionSel([...notificacionSel, id]);
      } else {
        setNotificacionSel(notificacionSel.filter(x => x !== id));
      }
    };

    const handleSelAll = (evt) => {
      if (evt.target.checked) {
        setNotificacionSel(sortedNotifications.map(x => (
          x.notificacionId
        )));
      } else {
        setNotificacionSel([]);
      }
    };

    return (
      <div
        className={classes.list}
        role="presentation"
        onKeyDown={toggleDrawer(side, false)}
      >
        <Container fluid className="NotificationMenu">
          <Row>
            {/* <Col xs={9} className="x-center">
              <h3>Notificaciones</h3>
            </Col> */}
            <Col xs={7}>
              <FormControlLabel
                className={classes.FormControlLabel}
                control={
                  <GrayCheckbox key="chkMarLeido" onChange={handleSelAll} />
                }
                label={t('default.seleccionarTodos', 'Seleccionar Todos')}
              />
            </Col>
            <Col xs={5} style={{ textAlign: 'right' }}>
              <Fragment>
                <ButtonIcon
                  icon="read"
                  color="blue"
                  onClick={handleReadNotification}
                  title={t('default.leer', 'Leer')}
                />
                <ButtonIcon
                  icon="clear"
                  color="blue"
                  onClick={handleDeleteNotificationAll}
                  title={t('default.eliminar', 'Eliminar')}
                />
              </Fragment>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Link to="/cuadrosNotificaciones" style={{ marginLeft: 10 }} onClick={toggleDrawer(side, false)}>
                {t('default.verRegistro', 'Ver Registro')}
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {sortedNotifications.map(n => (
                <div key={n.notificacionId} className="NotificationMenu__item">
                  <GrayCheckbox
                    key={`chkSel${n.notificacionId}`}
                    onChange={evt => handleChangeCheckbox(parseInt(evt.target.value, 10))}
                    value={n.notificacionId}
                    checked={notificacionSel.findIndex(x => x === n.notificacionId) > -1}
                  />
                  <p style={{ fontWeight: n.leido ? 'normal' : 'bold' }}>{language === 'EN' ? n.eventoIngles : n.evento}</p>
                  {(n.tipoNotificacion === 'ReporteHistorico' || n.tipoNotificacion === 'ReporteActual') && (
                    <ButtonIcon
                      icon="download"
                      color="blue"
                      onClick={() => handleDownloadReport(n)}
                    />
                  )}
                  {/* <button type="button" onClick={() => handleDeleteNotification(n.notificacionId)} className="NotificationMenu__item__close">x</button> */}
                </div>
              ))}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Button onClick={toggleDrawer(side, false)} style={{ marginLeft: 10 }}>
                {t('default.ocultarPanel', 'Ocultar panel')}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
  return (
    <div>
      <Badge
        className={classes.margin}
        badgeContent={notificaciones.filter(x => !x.leido && !x.eliminada).length.toString()}
        max={99}
        color="secondary"
        invisible={notificaciones.filter(x => !x.leido && !x.eliminada).length === 0}
      >
        <ButtonIcon
          icon="bell"
          color="blue"
          onClick={toggleDrawer('right', true)}
        />
      </Badge>
      <div>
        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer('right', false)}
        >
          {sideMenu('right', notificaciones)}
        </Drawer>
      </div>
    </div>
  );
};

NotificationButton.propTypes = Props;

const AppCompose = composePure(
  connect(state => ({
    notificaciones: state.Notificaciones.NotificacionesRecibidas,
    userId: state.Users.userData.usuarioId,
    language: state.Language.current,
  })),
)(NotificationButton);

export default translate('common')(AppCompose);
