import { combineReducers, createStore, applyMiddleware } from 'redux';
import { save, load } from 'redux-localstorage-simple';

import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import reducers from './reducers';
import sagas from './sagas';

const reducersCombined = combineReducers(reducers);

const sagaMiddleware = createSagaMiddleware();

const options = {
  namespace: 'telemetria',
  states: ['Users'],
};

const skipLoggerActions = [''];

const mylogger = createLogger({
  predicate: (getState, action) => skipLoggerActions.indexOf(action.type) === -1,
});

const store = createStore(
  reducersCombined,
  load(options),
  applyMiddleware(sagaMiddleware, mylogger, save(options)),
);

sagaMiddleware.run(sagas);

window.store = store;
export default store;
