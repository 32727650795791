/* eslint-disable no-unused-expressions */
import React, { Fragment, useState } from 'react';
import {
  Grid,
  IconButton,
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import AwesomeIcon from '../AwesomeIcon';
import theme from '../../scss/theme/colors';
import RectifierDatePicker from '../RectifierDatePicker';
// import RectifierCMD from '../RectifierCMD';
import ModalSendComands from '../ModalSendComands';
import SelectedRectifiers from '../SelectedRectifiers';
import ModalReporteActual from '../ModalReporteActual';
import { composePure, withProps } from '../../utils/composepure';

import store from '../../store';
import Actions from '../../actions';

import Props from './props';

const useStyles = makeStyles({
  button: {
    color: theme.blue.darker,
  },
  buttonModal: {
    backgroundColor: '#0083C7 !important',
  },
  dialogPaper: {
    // minHeight: '80vh',
    maxHeight: '80vh',
  },
  textField: {
    width: '100%',
  },
});

const getEmail = (userData) => {
  const emails = userData.mediosContacto.filter(x => x.tipoMedioContactoID === 1)[0];
  if (emails) {
    return emails.medioContactoValor;
  }

  return '';
};

/** @description Componente que contiene los botones para las acciones especificadas de los rectificadores
 * Creacion y acciones.
 */
const RectifierActionButtons = ({
  rectificadores,
  userId,
  userData,
  language,
  t,
}) => {
  const classes = useStyles();
  const [openCMD, setOpenCMD] = useState(false);
  const [openCMDReport, setOpenCMDReport] = useState(false);
  // const handleChangeCheckbox = name => event => setState({ ...state, [name]: event.target.checked });
  const handleClickOpen = () => setOpenCMD(true);
  const handleCloseCMD = () => setOpenCMD(false);
  const handleClickOpenReport = () => setOpenCMDReport(true);
  const handleCloseCMDReport = () => setOpenCMDReport(false);

  const [openReporteActual, setOpenReporteActual] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const handleCloseConfirmDelete = () => setOpenConfirmDelete(false);

  const handleDeleteRectificadores = () => {
    const ids = rectificadores.filter(r => r.selected).map(x => x.rectificadorID).join(',');
    store.dispatch(Actions.Rectifiers.removeRectificador(ids));
  };

  return (
    <Fragment>
      <Grid item xs={3}>
        <SelectedRectifiers
          classes={classes}
          t={t}
        />
      </Grid>
      <Grid item xs={3}>
        <IconButton
          className={classes.button}
          aria-label="download"
          onClick={() => setOpenReporteActual(true)}
          title={t('default.reporteActual', 'Reporte Actual')}
        >
          <AwesomeIcon icon="download" />
        </IconButton>
        <ModalReporteActual
          openReporteActual={openReporteActual}
          setOpenReporteActual={setOpenReporteActual}
          rectificadoresSeleccionados={rectificadores.filter(r => r.selected)}
          userId={userId}
          language={language}
          t={t}
          classes={classes}
          email={getEmail(userData)}
        />
      </Grid>
      <Grid item xs={3}>
        <RectifierDatePicker
          classes={classes}
          openCMD={openCMDReport}
          handleCloseCMD={handleCloseCMDReport}
          toggleCalendar={handleClickOpenReport}
          /* state={state}
          handleChange={handleDateChange}
          toggleCalendar={toggleCalendar} */
        />
      </Grid>
      <Grid item xs={3}>
        { userData.permisos.findIndex(p => p.opcionCodigo === 'EC') > -1 && (
          <ModalSendComands
            openCMD={openCMD}
            handleCloseCMD={handleCloseCMD}
            handleClickOpen={handleClickOpen}
          />
        )}
      </Grid>
      <Grid item xs={3}>
        { userData.permisos.findIndex(p => p.opcionCodigo === 'CRECT') > -1 && (
          <IconButton
            className={classes.button}
            aria-label="minus"
            onClick={() => setOpenConfirmDelete(true)}
            title={t('default.ocultarEquipo', 'Ocultar equipos')}
          >
            <AwesomeIcon icon="minus" />
          </IconButton>
        )}
      </Grid>
      <Dialog
        // classes={{ paper: classes.dialogPaper }}
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Grid item xs={12}>
            <DialogContentText id="alert-dialog-description">
              {t('default.confirmDeleteRectificador', '¿Desea eliminar equipos seleccionados?')}
            </DialogContentText>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" className={classes.buttonModal} onClick={handleDeleteRectificadores}>{t('default.si', 'Si')}</Button>
          <Button color="primary" variant="contained" className={classes.buttonModal} onClick={handleCloseConfirmDelete}>{t('default.no', 'No')}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

RectifierActionButtons.propTypes = Props;

const AppCompose = composePure(
  connect(state => ({
    rectificadores: state.Rectifiers.docs.filter(x => x.grupoId),
    userId: state.Users.userData.usuarioId,
    language: state.Language.current,
    userData: state.Users.userData,
  })),
  withProps(props => ({ t: props.t })),
)(RectifierActionButtons);

export default translate('common')(AppCompose);

// export default RectifierActionButtons;
