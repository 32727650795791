import React from 'react';
import Chart from 'react-google-charts';

import Moment from 'moment';

import Props from './props';

const getValue = (valorA, valorB, operacion) => {
  switch (operacion) {
    case 1:
      return valorA + valorB;
    case 2:
      return valorA - valorB;
    case 3:
      return valorA * valorB;
    case 4:
      return valorB === 0 ? null : valorA / valorB;
    default:
      return valorA + valorB;
  }
};

const getOperationSimbol = (operacion) => {
  switch (operacion) {
    case 1:
      return '+';
    case 2:
      return '-';
    case 3:
      return '*';
    case 4:
      return '/';
    default:
      return '+';
  }
};

/** @description Componente especifico para operaciones y creacion del grafico de linea.
 */
const OperacionesChart = ({
  operationData,
  operation,
  language,
}) => {
  const data = [['Fecha', 'Valor', { role: 'tooltip', type: 'string', p: { html: true } }]];

  operationData.forEach((x) => {
    const value = getValue(parseFloat(x.valor1), parseFloat(x.valor2), operation);
    data.push([new Date(x.fechaRecepcion.toString()), value, `<div style="background-color: black; color: white; padding: 5px; width: 170px">
    <span>
    <b>Fecha:</b>
    ${Moment(x.fechaRecepcion).format('DD/MM/YYYY HH:mm:ss')}</span></br><span><b>Valor: ${value}</b>
    </span>
    </div>`]);
  });

  const variableA = language === 'EN' ? operationData[0].variableEN1 : operationData[0].variableES1;
  const variableB = language === 'EN' ? operationData[0].variableEN2 : operationData[0].variableES2;

  const options = {
    title: `${variableA}${operationData[0].unidadMedida1} ${getOperationSimbol(operation)} ${variableB}${operationData[0].unidadMedida2}`,
    curveType: 'function',
    legend: { position: 'none' },
    areaOpacity: 0.24,
    lineWidth: 3,
    hAxis: {
      format: 'dd/MM/yyyy',
    },
    // backgroundColor: 'transparent',
    chartArea: {
      // backgroundColor: 'transparent',
      width: '85%',
      // height: '90%',
    },
    pointSize: 2,
    animation: {
      startup: true,
      easing: 'linear',
      duration: 1500,
    },
    tooltip: { isHtml: true, trigger: 'visible' },
    /* series: {
      1: { curveType: 'function' },
    }, */
  };

  return (
    <Chart
      chartType="LineChart"
      data={data}
      graph_id="LineChartOperaciones"
      width="100%"
      height="500px"
      options={options}
    />
  );
};

OperacionesChart.propTypes = Props;

export default OperacionesChart;
