import React, { useEffect } from 'react';
import {
  Container,
  Row,
} from 'react-bootstrap';

import moment from 'moment';

import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import {
  composePure,
  withProps,
} from '../../utils/composepure';

import store from '../../store';
import Actions from '../../actions';

import MainHeader from '../MainHeader';
import MainContent from '../MainContent';
import propTypes from './props';

/** @description Componente generico para reutilizar en la WEB, ya que la misma es el header de
 * las distintas paginas empleadas en el desarrollo.
 */
const PageWrapper = ({
  children,
  history,
  logged,
  userData,
  i18n,
}) => {
  const clickSpanishHandle = () => {
    store.dispatch(Actions.Language.changeLanguage('ES'));
    localStorage.setItem('language', 'ES');
    i18n.changeLanguage('es');
    moment.locale('es');
  };

  const clickEnglishHandle = () => {
    console.log('Cambio lenguaje en Page Wrapper');
    store.dispatch(Actions.Language.changeLanguage('EN'));
    localStorage.setItem('language', 'EN');
    i18n.changeLanguage('en');
    moment.locale('en');
  };

  useEffect(() => {
    if (logged && userData && userData.idiomaId) {
      if (userData.idiomaId === 1) {
        clickSpanishHandle();
      } else {
        clickEnglishHandle();
      }
    }
  }, [logged]);

  return (
    <Container fluid>
      <Row className="header-fixed">
        <MainHeader
          history={history}
        />
      </Row>
      <MainContent>
        {children}
      </MainContent>
    </Container>
  );
};

PageWrapper.propTypes = propTypes;

const App = composePure(
  withProps(props => ({ i18n: props.i18n })),
  withProps(props => ({ t: props.t })),
  connect(state => ({
    logged: state.Users.logged,
    userData: state.Users.userData,
  })),
)(PageWrapper);

export default translate('common')(App);
