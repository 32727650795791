const initialState = {
  tiposMedioContacto: [],
  empresas: [],
  marcas: [],
  tipoUnidad: [],
  unidadesComunicacion: [],
  lineas: [],
  tipoComunicacion: [],
  resultSaveUC: '',
  planes: [],
  cuentasIPGateway: [],
  resultSaveLinea: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NEWLINEA':
      return {
        ...state,
        lineas: [
          ...state.lineas,
          action.payload,
        ],
        resultSaveLinea: 'ok',
      };
    case 'SET_RESULTSAVELINEA_ERROR':
      return {
        ...state,
        resultSaveLinea: 'error',
      };
    case 'UPDATE_LINEA':
      // eslint-disable-next-line no-case-declarations
      const indexLinea = state.lineas.findIndex(x => x.lineaId === action.payload.lineaId);
      return {
        ...state,
        lineas: [
          ...state.lineas.slice(0, indexLinea),
          action.payload,
          ...state.lineas.slice(indexLinea + 1),
        ],
        resultSaveLinea: 'ok',
      };
    case 'SET_PLANES':
      return {
        ...state,
        planes: action.payload.data,
      };
    case 'SET_CUENTASIPGATEWAY':
      return {
        ...state,
        cuentasIPGateway: action.payload.data,
      };
    case 'SET_DELETE_LINEA':
      return {
        ...state,
        lineas: state.lineas.filter(x => x.lineaId !== action.payload),
      };
    case 'SET_DELETE_UC':
      return {
        ...state,
        unidadesComunicacion: state.unidadesComunicacion.filter(x => x.unidadComunicacionID !== action.payload),
      };
    case 'RESET_RESULTSAVELINEA':
      return {
        ...state,
        resultSaveLinea: '',
      };
    case 'RESET_RESULTSAVEUC':
      return {
        ...state,
        resultSaveUC: '',
      };
    case 'SET_RESULTSAVEUC_ERROR':
      return {
        ...state,
        resultSaveUC: 'error',
      };
    case 'SET_NEWUC':
      return {
        ...state,
        unidadesComunicacion: [
          ...state.unidadesComunicacion,
          {
            ...action.payload,
            disponible: true,
          },
        ],
        resultSaveUC: 'ok',
      };
    case 'UPDATEUC':
      // eslint-disable-next-line no-case-declarations
      const index = state.unidadesComunicacion.findIndex(x => x.unidadComunicacionID === action.payload.unidadComunicacionID);
      return {
        ...state,
        unidadesComunicacion: [
          ...state.unidadesComunicacion.slice(0, index),
          {
            ...state.unidadesComunicacion[index],
            ...action.payload,
          },
          ...state.unidadesComunicacion.slice(index + 1),
        ],
        resultSaveUC: 'ok',
      };
    case 'SET_TIPOSMEDIOCONTACTO':
      return {
        ...state,
        tiposMedioContacto: action.payload.data,
      };
    case 'SET_EMPRESASALL':
      return {
        ...state,
        empresas: action.payload.data,
      };
    case 'SET_MARCASALL':
      return {
        ...state,
        marcas: action.payload.data,
      };
    case 'SET_TIPOUNIDADALL':
      return {
        ...state,
        tipoUnidad: action.payload.data,
      };
    case 'SET_UNIDADESCOMUNICACION':
      return {
        ...state,
        unidadesComunicacion: action.payload.data,
      };
    case 'SET_LINEASALL':
      return {
        ...state,
        lineas: action.payload.data,
      };
    case 'SET_TIPOCOMUNICACION':
      return {
        ...state,
        tipoComunicacion: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
