import React from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { translate } from 'react-i18next';
import {
  Container,
  Grid,
  IconButton,
} from '@material-ui/core';

import AwesomeIcon from '../AwesomeIcon';
import { composePure, withProps } from '../../utils/composepure';
import Props from './props';

/** @description Componente especifico para mostrar la configuracion de alarmas efectuadas en
 * forma de lista.
 * Se muestra nombre, variable, tipo, salida, limite superior e inferior y accion de editar.
 */
const ListConfiguracionAlarmas = ({
  listaAlarmas,
  handleEditar,
  handleDelete,
  t,
  language,
}) => {
  const columns = [{
    headerClasses: 'gf-listConfigAlarm-grid-size',
    dataField: 'nombre',
    text: t('default.nombre', 'Nombre'),
    headerStyle: () => ({ width: '25%' }),
    sort: true,
    filter: textFilter(),
  },
  {
    headerClasses: 'gf-listConfigAlarm-grid-size',
    dataField: language === 'EN' ? 'variableEN' : 'variableES',
    text: t('default.variable', 'Variable'),
    headerStyle: () => ({ width: '30%' }),
    sort: true,
    filter: textFilter(),
  },
  {
    headerClasses: 'gf-listConfigAlarm-grid-size',
    dataField: language === 'EN' ? 'tipoEN' : 'tipoES',
    text: t('default.tipo', 'Tipo'),
    headerStyle: () => ({ width: '10%' }),
    sort: true,
  },
  {
    headerClasses: 'gf-listConfigAlarm-grid-size',
    dataField: 'salidaNro',
    text: t('default.salida', 'Salida'),
    headerStyle: () => ({ width: '10%' }),
    formatter: cell => (cell === 0 ? 'Equipo' : cell),
    sort: true,
  },
  {
    headerClasses: 'gf-listConfigAlarm-grid-size',
    dataField: 'limiteInferior',
    text: t('default.limiteInferior', 'Limite Inferior'),
    headerStyle: () => ({ width: '10%' }),
    sort: true,
  },
  {
    headerClasses: 'gf-listConfigAlarm-grid-size',
    dataField: 'limiteSuperior',
    text: t('default.limiteSuperior', 'Limite Superior'),
    headerStyle: () => ({ width: '10%' }),
    sort: true,
  },
  {
    headerClasses: 'gf-listConfigAlarm-grid-size',
    dataField: 'configuracionAlarmaID',
    text: t('default.editar', 'Editar'),
    formatter: (cell, row) => (
      <IconButton
        // className={classes.button}
        aria-label="edit"
        onClick={() => handleEditar(row)}
      >
        <AwesomeIcon icon="edit" />
      </IconButton>
    ),
    align: 'center',
    headerAlign: 'center',
  },
  {
    headerClasses: 'gf-listConfigAlarm-grid-size',
    dataField: 'configuracionAlarmaID',
    text: t('default.eliminar', 'Eliminar'),
    formatter: (cell, row) => (
      <IconButton
        // className={classes.button}
        aria-label="trash"
        onClick={() => handleDelete(row)}
      >
        <AwesomeIcon icon="trash" />
      </IconButton>
    ),
    align: 'center',
    headerAlign: 'center',
  },
  ];

  return (
    <Container maxWidth="xl" className="ListView gf-config-alarm-list-config-alarm">
      <Grid container>
        <Grid item xs={12}>
          <BootstrapTable
            keyField="configuracionAlarmaID"
            striped
            hover
            condensed
            bootstrap4
            data={listaAlarmas}
            columns={columns}
            pagination={paginationFactory()}
            filter={filterFactory()}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

ListConfiguracionAlarmas.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
)(ListConfiguracionAlarmas);

export default translate('common')(AppCompose);
