import React, { useState } from 'react';
import {
  Card,
  CardContent,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
} from '@material-ui/core/';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import {
  Container,
  Row,
  Col,
  Form,
  Alert,
} from 'react-bootstrap';

import { translate, Trans } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import store from '../../store';

import Actions from '../../actions';
import {
  composePure,
  withHandlers,
  withInit,
  withProps,
} from '../../utils/composepure';

import propTypes from './props';
import Image from '../../components/Image';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
  },
  card: {
    width: '100vw',
    height: '100vh',
  },
  button: {
    width: '33.33%',
    backgroundColor: '#0083C7 !important',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%',
    marginTop: '16px',
  },
}));

/** @description pagina inicial de login para poder acceder al sistema constando con credenciales
 * aprobadas de acceso.
 * Seleccion de lenguaje.
 */
const Login = ({
  handleLogin,
  errorLogin,
  resetError,
  i18n,
  t,
}) => {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const clickSpanishHandle = () => {
    store.dispatch(Actions.Language.changeLanguage('ES'));
    localStorage.setItem('language', 'ES');
    i18n.changeLanguage('es');
  };

  const clickEnglishHandle = () => {
    console.log('Cambio lenguaje en Login');
    store.dispatch(Actions.Language.changeLanguage('EN'));
    localStorage.setItem('language', 'EN');
    i18n.changeLanguage('en');
  };

  return (
    <Container fluid className="Home">
      <Row className={`${classes.card} xy-center`}>
        <Form
          onSubmit={(evt) => {
            evt.preventDefault();
            handleLogin(username, password);
          }}
        >
          <Card className="Home__logo">
            <CardContent>
              <Row>
                <Col xs={3} className="y-center">
                  <Image src="logo" fluid />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="outlined-email-input" shrink>{t('default.usuario', 'Usuario')}</InputLabel>
                    <TextField
                      id="outlined-email-input"
                      // label={t('default.usuario', 'Usuario')}
                      className={classes.textField}
                      type="text"
                      name="email"
                      autoComplete="email"
                      margin="normal"
                      // variant="outlined"
                      variant="standard"
                      value={username || ''}
                      onChange={evt => setUsername(evt.target.value)}
                    />
                  </FormControl>
                </Col>
                <Col xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="outlined-password-input" shrink>{t('default.contrasenia', 'Contraseña')}</InputLabel>
                    <TextField
                      id="outlined-password-input"
                      // label={t('default.contrasenia', 'Contraseña')}
                      className={classes.textField}
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="current-password"
                      margin="normal"
                      // variant="outlined"
                      variant="standard"
                      value={password || ''}
                      onChange={evt => setPassword(evt.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={evt => evt.preventDefault()}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="x-end">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    type="submit"
                  >
                    <Trans i18nKey="default.ingresar">Ingresar</Trans>
                  </Button>
                </Col>
              </Row>
              {errorLogin && (
                <Row xs={12}>
                  <Col>
                    <Alert variant="danger" dismissible onClose={resetError}>
                      <Trans i18nKey="default.loginIncorrecto">Usuario o contraseña incorrectos</Trans>
                    </Alert>
                  </Col>
                </Row>
              )}
              <Row className="Home__lang">
                <Col xs={12} className="x-end">
                  <Col xs={4} className="Home__lang__flags">
                    <button
                      type="button"
                      style={{ backgroundColor: 'transparent' }}
                      onClick={clickSpanishHandle}
                    >
                      <Image src="es" fluid />
                    </button>
                    <button
                      type="button"
                      style={{ backgroundColor: 'transparent' }}
                      onClick={clickEnglishHandle}
                    >
                      <Image src="en" fluid />
                    </button>
                  </Col>
                </Col>
              </Row>
            </CardContent>
          </Card>
        </Form>
      </Row>
    </Container>
  );
};

Login.propTypes = propTypes;
export default translate('common')(composePure(
  withProps(props => ({ i18n: props.i18n })),
  withProps(props => ({ t: props.t })),
  connect(state => ({
    logged: state.Users.logged,
    errorLogin: state.Users.errorLogin,
    isMobile: state.Universal.isMobile,
    userData: state.Users.userData,
  }),
  dispatch => ({
    fetchLogin: (userName, password) => {
      dispatch(Actions.Users.fetchLogin(userName, password));
    },
    resetError: () => dispatch(Actions.Users.resetError()),
  })),
  withHandlers({
    handleLogin: ({
      fetchLogin,
    }) => (username, password) => {
      fetchLogin(username, password);
    },
  }),
  withInit(['_'], ({ resetError }) => resetError()),
)(Login));
