import {
  CUADROS_FETCH_COMANDOS,
  CUADROS_FETCH_ALARMAS,
  CUADROS_FETCH_NOVEDADES,
  CUADROS_FETCH_NOTIFICACIONES,
  CUADROS_ADDNOTA_COMANDOS,
  CUADROS_ADDNOTA_ALARMAS,
  VISTACUADROS_SET_COMANDOS,
  VISTACUADROS_SET_ALARMAS,
  CUADROS_UPDATE_NOVEDAD,
  VISTACUADROS_SET_NOVEDADES,
  SET_CONFIGURACIONALARMAS,
  SAVE_CONFIGURACIONALARMA,
  ADD_CONFIGURACIONALARMA,
  UPDATE_CONFIGURACIONALARMA,
  SET_CONFIGURACIONALARMA,
  DELETE_CONFIGURACIONALARMA,
  REMOVE_CONFIGURACIONALARMA,
  FETCHNOVEDADES_BYDEVICE,
  SAVE_NOVEDAD,
  ADD_NOVEDAD,
  VISTACUADROS_UPDATE_COMANDO,
  REMOVE_NOVEDAD,
  SET_REMOVE_NOVEDAD,
} from '../actionTypes/cuadros';

export const getComandosEnviados = userId => ({
  type: CUADROS_FETCH_COMANDOS,
  payload: {
    userId,
  },
});

export const getAlarmas = userId => ({
  type: CUADROS_FETCH_ALARMAS,
  payload: {
    userId,
  },
});

export const getNovedades = userId => ({
  type: CUADROS_FETCH_NOVEDADES,
  payload: {
    userId,
  },
});

export const getNotificaciones = userId => ({
  type: CUADROS_FETCH_NOTIFICACIONES,
  payload: {
    userId,
  },
});

export const addNotaComando = comando => ({
  type: CUADROS_ADDNOTA_COMANDOS,
  payload: {
    comando,
  },
});

export const addNotaAlarma = alarma => ({
  type: CUADROS_ADDNOTA_ALARMAS,
  payload: {
    alarma,
  },
});

export const setComando = comando => ({
  type: VISTACUADROS_SET_COMANDOS,
  payload: {
    comando,
  },
});

export const setAlarma = alarma => ({
  type: VISTACUADROS_SET_ALARMAS,
  payload: {
    alarma,
  },
});

export const updateNovedad = novedad => ({
  type: CUADROS_UPDATE_NOVEDAD,
  payload: {
    novedad,
  },
});

export const setNovedad = novedad => ({
  type: VISTACUADROS_SET_NOVEDADES,
  payload: {
    novedad,
  },
});

export const setConfAlarmas = confAlarmas => ({
  type: SET_CONFIGURACIONALARMAS,
  payload: {
    confAlarmas,
  },
});

export const saveConfAlaras = confAlarma => ({
  type: SAVE_CONFIGURACIONALARMA,
  payload: {
    confAlarma,
  },
});

export const addConfAlarma = confAlarma => ({
  type: ADD_CONFIGURACIONALARMA,
  payload: {
    confAlarma,
  },
});

export const updateConfAlarma = confAlarma => ({
  type: UPDATE_CONFIGURACIONALARMA,
  payload: confAlarma,
});

export const setConfAlarma = confAlarma => ({
  type: SET_CONFIGURACIONALARMA,
  payload: confAlarma,
});

export const deleteConfAlarma = configuracionAlarmaID => ({
  type: DELETE_CONFIGURACIONALARMA,
  payload: {
    configuracionAlarmaID,
  },
});

export const removeConfAlarma = configuracionAlarmaID => ({
  type: REMOVE_CONFIGURACIONALARMA,
  payload: {
    configuracionAlarmaID,
  },
});

export const getNovedadesByDevice = rectificadorID => ({
  type: FETCHNOVEDADES_BYDEVICE,
  payload: {
    rectificadorID,
  },
});

export const saveNovedad = (rectificadorId, descripcion, usuarioId) => ({
  type: SAVE_NOVEDAD,
  payload: {
    rectificadorId,
    descripcion,
    usuarioId,
  },
});

export const addNovedad = novedad => ({
  type: ADD_NOVEDAD,
  payload: novedad,
});

export const updateNotaComando = comando => ({
  type: VISTACUADROS_UPDATE_COMANDO,
  payload: comando,
});

export const deleteNovedad = novedadId => ({
  type: REMOVE_NOVEDAD,
  payload: {
    novedadId,
  },
});

export const setRemoveNovedad = novedadId => ({
  type: SET_REMOVE_NOVEDAD,
  payload: {
    novedadId,
  },
});

export default {
  getComandosEnviados,
  getAlarmas,
  getNovedades,
  getNotificaciones,
  addNotaComando,
  setComando,
  addNotaAlarma,
  updateNovedad,
  setNovedad,
  setConfAlarmas,
  saveConfAlaras,
  addConfAlarma,
  updateConfAlarma,
  setConfAlarma,
  deleteConfAlarma,
  removeConfAlarma,
  getNovedadesByDevice,
  saveNovedad,
  addNovedad,
  updateNotaComando,
  deleteNovedad,
  setRemoveNovedad,
};
