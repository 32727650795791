import {
  LANGUAGE_CHANGE,
} from '../actionTypes/language';

const initialState = {
  current: localStorage.getItem('language') ? localStorage.getItem('language') : 'ES',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_CHANGE:
      return { ...state, current: action.payload };
    default:
      return state;
  }
};

export default reducer;
