import React from 'react';
import AwesomeIcon from '../AwesomeIcon';
import Props from './props';

/** @description componente Icono para botones generico, con la posibilidad de setear colores y
 * funcionalidad en el onClick
 */
const ButtonIcon = ({
  icon,
  onClick,
  color = 'default',
  size = 'lg',
  ...iconProps
}) => (
  <button
    className={`ButtonIcon--${color}`}
    type="button"
    onClick={onClick}
  >
    <AwesomeIcon icon={icon} size={size} {...iconProps} />
  </button>
);

ButtonIcon.propTypes = Props;
export default ButtonIcon;
