import {
  FORMS_UPDATE_FIELD_VALUE,
  FORMS_SET_DEFAULT_VALUES,
} from '../actionTypes/forms';

const setFieldValue = (form, key, value) => ({
  type: FORMS_UPDATE_FIELD_VALUE,
  key,
  value,
  form,
});

const setDefaultValue = (form, value) => ({
  type: FORMS_SET_DEFAULT_VALUES,
  form,
  value,
});

export default {
  setFieldValue,
  setDefaultValue,
};
