import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { composePure } from '../../utils/composepure';
import { getRectifiersFiltered } from '../../selectors';
import FilterBarHeader from '../FilterBarHeader';
import PageWrapper from '../../components/PageWrapper';
import CardsView from '../CardsView';
import ListView from '../../components/ListView';
import MapsView from '../../components/MapsView';
// import Login from '../Login';
import RectifierSelectionBar from '../RectifierSelectionBar';

import VistaDisponibilidad from '../VistaDisponibilidad';
import VistaCuadros from '../VistaCuadros';
import VistaTableros from '../VistaTableros';

import propTypes from './props';

/** @description Este contenedor dispone el acceso a cada uno de las paginas que vamos a poder
 * recorrer desde nuestro menu de navegacion.
 */
const Dashboard = ({
  rectifiers,
  history,
  Filters,
  groups,
  language,
  userData,
  location,
  // requestError,
}) => {
  const filteredData = getRectifiersFiltered(
    rectifiers,
    Filters.group,
    Filters.alerts,
    Filters.keyword,
    Filters.brands,
  ).map(item => ({
    ...item,
    category: groups.find(cat => cat.grupoId === item.grupoId),
  }));

  /* useEffect(() => {
    if (requestError && requestError.response.status === 401) {
      history.push('/login');
    }
  }); */

  useEffect(() => {
    if (!location.prevPath) {
      // eslint-disable-next-line default-case
      switch (userData.vistaPreferidaId) {
        case 2:
          history.push('/list');
          break;
        case 3:
          history.push('/map');
          break;
      }
    }
  }, []);
  return (
    <PageWrapper
      history={history}
    >
      {(history.location.pathname === '/' || history.location.pathname === '/list' || history.location.pathname === '/map') && (
        <Row>
          <FilterBarHeader
            history={history}
          />
        </Row>
      )}
      <div style={{ paddingBottom: '50px' }}>
        {history.location.pathname === '/' && (
          <CardsView
            filteredData={filteredData}
          />
        )}
        {history.location.pathname === '/list' && (
          <ListView
            filteredData={filteredData}
          />
        )}
        {history.location.pathname === '/map' && (
          <MapsView
            markers={filteredData}
            history={history}
          />
        )}
        {history.location.pathname === '/disponibilidad' && (
          <VistaDisponibilidad />
        )}
        {(history.location.pathname === '/cuadrosNovedades' || history.location.pathname === '/cuadrosAlarmas'
          || history.location.pathname === '/cuadrosComandos' || history.location.pathname === '/cuadrosNotificaciones') && (
            <VistaCuadros history={history} language={language} />
        )}
        {(history.location.pathname === '/tableros' || history.location.pathname === '/variableDetail'
          || history.location.pathname === '/variableDetailAMC') && (
            <VistaTableros history={history} location={location} />
        )}
      </div>
      {(history.location.pathname === '/' || history.location.pathname === '/list') && (
        <RectifierSelectionBar
          cardSelecteds={filteredData}
        />
      )}
    </PageWrapper>
  );
};

Dashboard.propTypes = propTypes;
export default composePure(
  connect(state => ({
    rectifiers: state.Rectifiers.docs.filter(x => x.grupoId),
    Filters: state.Filters,
    groups: state.Groups.docs,
    language: state.Language.current,
    userData: state.Users.userData,
    // requestError: state.Universal.request.msg,
  }),
  undefined),
)(Dashboard);
