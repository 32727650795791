import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  // InputBase,
  FilledInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core/';
import { translate } from 'react-i18next';
import AwesomeIcon from '../AwesomeIcon';
import {
  composePure,
  withHandlers,
  // withState,
  withProps,
} from '../../utils/composepure';
import Actions from '../../actions';
import Props from './props';
import theme from '../../scss/theme/colors';
import store from '../../store';

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxShadow: 'none',
    border: `1px solid ${theme.gray.light}`,
    minHeight: 38,
    ' & .MuiFilledInput-input': {
      padding: 0,
    },
  },
  input: {
    fontSize: 12,
    marginLeft: 8,
    flex: 1,
    backgroundColor: 'white',
  },
});

/** @description Componente especifico para realizar busquedas reutilizable. Input con accion
 * automatica de busqueda y boton de limpieza de input.
 */
const InputSearch = ({
  value,
  onSubmitHandle,
  // setValue,
  t,
}) => {
  const classes = useStyles();

  const handleChangeInput = (evt) => {
    // setValue(evt.target.value);
    store.dispatch(Actions.Filters.setKeyword(evt.target.value.toLowerCase()));
  };

  const handleClear = () => {
    // setValue('');
    store.dispatch(Actions.Filters.setKeyword(''));
  };

  return (
    <form onSubmit={onSubmitHandle}>
      <Paper className={classes.root}>
        <FilledInput
          className={classes.input}
          type="text"
          placeholder={`${t('default.buscar', 'Buscar')}...`}
          inputProps={{ 'aria-label': `${t('default.buscar', 'Buscar')}...` }}
          onChange={handleChangeInput}
          value={value}
          style={{ padding: '6px 0 7px' }}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={handleClear}
              >
                <AwesomeIcon icon="clear" />
              </IconButton>
            </InputAdornment>
          )}
        />
      </Paper>
    </form>
  );
};

InputSearch.propTypes = Props;
export default translate('common')(composePure(
  withProps(props => ({ t: props.t })),
  connect(
    (state, ownProps) => {
      console.log(ownProps);
      console.log(state.Filters.keyword);
      return {
        value: state.Filters.keyword,
      };
    },
    dispatch => ({
      setKeywordFilter: value => dispatch(Actions.Filters.setKeyword(value)),
    }),
  ),
  // withState('value', 'setValue', ''),
  withHandlers({
    onSubmitHandle: ({ setKeywordFilter, value }) => (evt) => {
      evt.preventDefault();
      setKeywordFilter(value.toLowerCase());
    },
  }),
)(InputSearch));
