import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Radio,
  RadioGroup,
  // TextField,
} from '@material-ui/core/';
import {
  Button,
} from 'react-bootstrap';

import moment from 'moment';

import { translate, Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { composePure, withProps } from '../../utils/composepure';
import Actions from '../../actions';
import AccountSection from '../AccountSection';
import theme from '../../scss/theme/colors';
import store from '../../store';
import Props from './props';

const useStyles = makeStyles({
  list: {
    width: 350,
    maxWidth: '100%',
    overflowX: 'hidden',
  },
});

const GreenSwitch = withStyles({
  switchBase: {
    color: theme.gray.light,
    '&$checked': {
      color: theme.green.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.green.main,
    },
  },
  checked: {},
  track: {},
})(Switch);

/** @description Componente interno de AccountButton que dibuja la pagina slider por derecha.
 * Contiene las funcionalidades de cambio de idioma.
 */
const AccountDrawer = ({
  // side,
  toggleDrawer,
  closeRightSide,
  swicthHandleChange,
  handleChangePaginaInicio,
  // state,
  userData,
  logoutHandle,
  i18n,
  t,
}) => {
  const classes = useStyles();
  const clickSpanishHandle = () => {
    store.dispatch(Actions.Language.changeLanguage('ES'));
    localStorage.setItem('language', 'ES');
    i18n.changeLanguage('es');
    moment.locale('es');
  };

  const clickEnglishHandle = () => {
    console.log('Cambio lenguaje en Account Drawer');
    store.dispatch(Actions.Language.changeLanguage('EN'));
    localStorage.setItem('language', 'EN');
    i18n.changeLanguage('en');
    moment.locale('en');
  };

  /* const [state, setState] = React.useState({
    usaProxy: false,
    proxyUrl: '',
    userName: '',
    domain: '',
    password: '',
  });

  const handleSaveProxySettings = () => {
    store.dispatch(Actions.Users.saveProxySettings(state.proxyUrl, state.domain, state.userName, state.password, userData.usuarioId));
  }; */

  const handleClose = () => {
    closeRightSide();
  };

  return (
    <div
      className={classes.list}
      role="presentation"
      onKeyDown={toggleDrawer()}
      // onKeyDown={toggleDrawer(side, false)}
    >
      <AccountSection
        logoutHandle={logoutHandle}
        userData={userData}
      />
      <FormGroup row>
        <List className="AccountDrawer">
          <h3 className="">
            <Trans i18nKey="default.ajustesVisuales">Ajustes</Trans>
          </h3>
          <ListItem className="AccountDrawer__item">
            <span className="AccountDrawer__item__float-span">
              <Trans i18nKey="default.paginaInicio">Página de Inicio</Trans>
            </span>
            <RadioGroup aria-label="paginaInicio" name="paginaInicio" value={userData.vistaPreferidaId} onChange={handleChangePaginaInicio}>
              <FormControlLabel value={1} control={<Radio />} label={t('default.tarjeta', 'Tarjeta')} />
              <FormControlLabel value={2} control={<Radio />} label={t('default.lista', 'Lista')} />
              <FormControlLabel value={3} control={<Radio />} label={t('default.mapa', 'Mapa')} />
            </RadioGroup>
          </ListItem>
          { userData.permisos.findIndex(x => x.opcionCodigo === 'CRECT') > -1 && (
            <ListItem className="AccountDrawer__item">
              <span className="AccountDrawer__item__float-span">
                <Trans i18nKey="default.crearEquipo">Crear Equipo</Trans>
              </span>
              <FormControlLabel
                control={(
                  <GreenSwitch
                    checked={userData.mostrarBotonCrearEquipo}
                    onChange={swicthHandleChange('mostrarBotonCrearEquipo')}
                    value="checkedC"
                    color="primary"
                  />
                )}
                label={t('default.mostrar', 'Mostrar')}
              />
            </ListItem>
          )}
          <ListItem className="x-around">
            <Button
              variant={i18n.language === 'es' ? 'primary' : 'secondary'}
              onClick={clickSpanishHandle}
            >
              Español
            </Button>
            <Button
              variant={i18n.language === 'en' ? 'primary' : 'secondary'}
              onClick={clickEnglishHandle}
            >
              English
            </Button>
          </ListItem>
        </List>
        <Button onClick={handleClose} style={{ marginLeft: 10 }}>
          {t('default.ocultarPanel', 'Ocultar panel')}
        </Button>
      </FormGroup>
    </div>
  );
};

AccountDrawer.propTypes = Props;
export default translate('common')(composePure(
  withProps(props => ({ i18n: props.i18n })),
  withProps(props => ({ t: props.t })),
  connect(state => ({
    userData: state.Users.userData,
  }),
  dispatch => ({
    logoutHandle: () => dispatch(Actions.Users.fetchLogout()),
  })),
)(AccountDrawer));
