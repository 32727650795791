import React from 'react';
import {
  Row,
  Col,
} from 'react-bootstrap';
import PropTypes from './props';

/** @description Componente contenedor principal de la WEB.
 */
const MainContent = ({
  children,
}) => (
  <Row>
    <Col xs={12} className="main-content">
      {children}
    </Col>
  </Row>
);

MainContent.propTypes = PropTypes;
export default MainContent;
