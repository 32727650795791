import {
  put,
} from 'redux-saga/effects';

function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

/** @description Llamada al EndPoint para obtener disponibilidad.
* URL: Rectificador/GetDisponibilidadByUser/${grupoSelected}/${fechaDesdeFormatted}/${fechaHastaFormatted}/${action.payload.userId}
*/
export const getDisponibilidad = requestModule => function* getDisponibilidadSaga(action) {
  try {
    const fechaDesde = action.payload.startDate;
    const fechaHasta = action.payload.endDate;
    const fechaDesdeFormatted = formatDate(fechaDesde);
    const fechaHastaFormatted = formatDate(fechaHasta);
    const { grupoSelected } = action.payload;
    const query = {};
    yield requestModule({
      dispatchAction: 'DISPONIBILIDAD_SET_DISPONIBILIDAD',
      module: `Rectificador/GetDisponibilidadByUser/${grupoSelected}/${fechaDesdeFormatted}/${fechaHastaFormatted}/${action.payload.userId}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export default {

};
