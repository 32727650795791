import {
  GET_NOTIFICACIONES,
  DELETE_NOTIFICACIONES,
  DELETE_NOTIFICACIONES_ALL,
  DELETE_SET_NOTIFICATION,
  DOWNLOAD_REPORT,
  MARK_READED,
  SET_MARK_READED,
  DELETE_SET_NOTIFICATION_ALL,
} from '../actionTypes/notificaciones';


export const getNotificaciones = userId => ({
  type: GET_NOTIFICACIONES,
  payload: {
    userId,
  },
});

export const deleteNotificaciones = (userId, notificacionId) => ({
  type: DELETE_NOTIFICACIONES,
  payload: {
    userId,
    notificacionId,
  },
});

export const deleteNotificacionesAll = (userId, notifications) => ({
  type: DELETE_NOTIFICACIONES_ALL,
  payload: {
    userId,
    notifications,
  },
});

export const setDeleteNotificacionesAll = notificaciones => ({
  type: DELETE_SET_NOTIFICATION_ALL,
  payload: {
    notificaciones,
  },
});

export const setDeleteNotificacion = notificacionId => ({
  type: DELETE_SET_NOTIFICATION,
  payload: {
    notificacionId,
  },
});

export const downloadReport = (id, tipoNotificacion) => ({
  type: DOWNLOAD_REPORT,
  payload: {
    id,
    tipoNotificacion,
  },
});

export const markReaded = (userId, notifications) => ({
  type: MARK_READED,
  payload: {
    userId,
    notifications,
  },
});

export const setMarkReaded = notificaciones => ({
  type: SET_MARK_READED,
  payload: {
    notificaciones,
  },
});

export default {
  getNotificaciones,
  deleteNotificaciones,
  setDeleteNotificacion,
  downloadReport,
  markReaded,
  setMarkReaded,
  deleteNotificacionesAll,
  setDeleteNotificacionesAll,
};
