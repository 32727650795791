import React from 'react';
import {
  Container,
  Grid,
  IconButton,
} from '@material-ui/core';
import { connect } from 'react-redux';

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { translate } from 'react-i18next';
import Moment from 'moment';

import AwesomeIcon from '../AwesomeIcon';

import { composePure, withProps, lifecycle } from '../../utils/composepure';
import Actions from '../../actions';
import store from '../../store';
import Props from './props';

/** @description Componente especifico para mostrar los comandos en forma de lista.
 * Se muestra fecha, comando, dispositivo, estado y notas.
 */
const GridComandos = ({
  comandosEnviados,
  t,
  filter,
  rectificadorId,
  language,
}) => {
  const filteredComando = comandosEnviados.filter(c => !filter
    || (language === 'EN' && c.comandoEN.toLowerCase().indexOf(filter.toLowerCase()) > -1)
    || (language === 'ES' && c.comandoES.toLowerCase().indexOf(filter.toLowerCase()) > -1));
  const filetedEquipo = comandosEnviados.filter(c => !filter || c.dispositivo.toLowerCase().indexOf(filter) > -1);
  const filteredData = [...new Set([...filteredComando, ...filetedEquipo])];

  const handleAddNote = (oldValue, newValue, row) => {
    if (oldValue !== newValue && (oldValue || newValue)) {
      const params = {
        comandoEnviadoID: row.comandoEnviadoID,
        notas: newValue,
      };
      store.dispatch({ type: 'CUADROS_ADDNOTA_COMANDOS', payload: params });
    }
  };

  const cellEdit = cellEditFactory({
    mode: 'dbclick',
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row) => handleAddNote(oldValue, newValue, row),
  });

  const columns = [{
    headerClasses: 'gf-notas-hide',
    dataField: 'fecha',
    text: t('default.fecha', 'Fecha'),
    formatter: fecha => Moment(fecha).format('DD/MM/YYYY HH:mm:ss'),
    headerStyle: () => ({ width: '15%' }),
    sort: true,
    editable: false,
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: language === 'EN' ? 'comandoEN' : 'comandoES',
    text: t('default.comando', 'Comando'),
    headerStyle: () => ({ width: '25%' }),
    style: () => ({ overflowWrap: 'break-word' }),
    sort: true,
    editable: false,
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: 'dispositivo',
    text: t('default.dispositivo', 'Dispositivo'),
    formatter: (cell, row) => `${cell} - ${language === 'EN' ? 'Output: ' : 'Salida: '}${row.salidaNro}`,
    headerStyle: () => ({ width: '25%' }),
    sort: true,
    editable: false,
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: language === 'ES' ? 'estadoES' : 'estado',
    text: t('default.estado', 'Estado'),
    headerStyle: () => ({ width: '10%' }),
    sort: true,
    editable: false,
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: 'reporteID',
    text: t('default.respuesta', 'Respuesta'),
    formatter: reporteID => reporteID && (
      <IconButton
        aria-label="excel"
        onClick={() => {
          store.dispatch({ type: 'DOWNLOAD_COMMANDRESPONSE', payload: { reporteID, language } });
        }}
      >
        <AwesomeIcon icon="excel" />
      </IconButton>
    ),
    headerStyle: () => ({ width: '7%' }),
    editable: false,
  },
  {
    headerClasses: 'd-none d-md-block d-lg-block',
    dataField: 'notas',
    text: t('default.notas', 'Notas'),
    headerStyle: () => ({ width: '25' }),
    style: () => ({ overflowWrap: 'break-word' }),
    title: () => t('default.editTooltip', 'Doble click para editar'),
    editable: (content, row) => !row.esAgendado,
  },
  ];

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t('default.pagPrev', 'página previa'),
    nextPageTitle: t('default.proxPag', 'próxima página'),
    firstPageTitle: t('default.primerPagina', 'primer página'),
    lastPageTitle: t('default.ultimaPagina', 'última página'),
  };

  return (
    <Container maxWidth="xl" style={{ height: '100%' }} className={`${rectificadorId ? '' : 'ListView'} gf-font-and-padi`}>
      <Grid container>
        <Grid item xs={12}>
          <BootstrapTable
            keyField="comandoEnviadoID"
            striped
            hover
            condensed
            bootstrap4
            cellEdit={cellEdit}
            data={filteredData}
            columns={columns}
            pagination={rectificadorId ? null : paginationFactory(options)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};


GridComandos.propTypes = Props;

const AppCompose = composePure(
  lifecycle({
    componentDidMount() {
      const { rectificadorId } = this.props;
      if (rectificadorId) {
        // store.dispatch({ type: 'FETCH_COMANDOSBYDEVICE', payload: { rectificadorId } });
        store.dispatch(Actions.Comandos.getComandosByDevice(rectificadorId));
      } else {
        store.dispatch({ type: 'CUADROS_FETCH_COMANDOS' });
      }
    },
  }),
  connect(state => ({
    working: state.Universal.request.process,
    comandosEnviados: state.Cuadros.Comandos,
    language: state.Language.current,
  })),
  withProps(props => ({ t: props.t })),
)(GridComandos);

export default translate('common')(AppCompose);
