import React, { Fragment, useState } from 'react';
import {
  Container,
  Grid,
  IconButton,
  makeStyles,
  Button,
} from '@material-ui/core';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Moment from 'moment';
import { connect } from 'react-redux';
import {
  Row,
} from 'react-bootstrap';

// import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';

import DatePicker, { registerLocale } from 'react-datepicker';
import { es, enUS } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
import AwesomeIcon from '../AwesomeIcon';
import Image from '../Image';
import { composePure, lifecycle, withProps } from '../../utils/composepure';

import store from '../../store';
import Actions from '../../actions';

// import Chart from 'react-google-charts';
import GoogleChart from '../GoogleChart';

// import MapsView from '../MapsView';
import { Props } from './props';
import PageWrapper from '../PageWrapper';

registerLocale('es', es);
registerLocale('enUS', enUS);

const useStyles = makeStyles({
  datePickerInput: {
    borderRadius: '4px',
    height: '36px',
    padding: '2px 8px',
  },
});

const actualDate = new Date();
const minDate = (new Date()).setMonth(actualDate.getMonth() - 6);
const oneMonthAgeDate = (new Date()).setMonth(actualDate.getMonth() - 1);

/** @description Pagina completa con componentes para la visualizacion detalle de variables de un
 * dispositivo rectificador. Tabla de datos y grafico de google para su especificacion.
 */
const VariableDetail = ({
  history,
  location,
  t,
  data = [],
  language,
}) => {
  const [startDate, setStartDate] = useState(oneMonthAgeDate);
  const [endDate, setEndDate] = useState(actualDate);
  const classes = useStyles();

  const handleChangeStart = (newValue) => {
    setStartDate(newValue);
  };

  const handleChangeEnd = (newValue) => {
    setEndDate(newValue);
  };

  const salidas = [...new Set(location.data.map(x => x.salidaNro))];
  // const reportes = [...new Set(location.data.map(x => x.reporteID))];
  const reportes = [...new Set(data.map(x => x.reporteID))];

  let columns = [{
    headerClasses: 'gf-table-size',
    dataField: 'fechaRecepcion',
    text: t('default.fecha', 'Fecha'),
    formatter: fechaRecepcion => Moment(fechaRecepcion).format('DD/MM/YYYY HH:mm:ss'),
    headerStyle: () => ({ width: '15%' }),
    sort: true,
  }];

  const columnasSalidas = salidas.map(s => ({
    dataField: s,
    text: location.language === 'EN' ? `Output ${s}` : `Salida ${s}`,
    formatter: (cell, row) => (
      <Fragment>
        <span>{row[s]}</span>
        {row[`${s}_esAnomalo`] && (
          <Image title="Dato anomalo" src="anomalo" />
        )}
      </Fragment>
    ),
  }));

  columns = columns.concat(columnasSalidas);

  let filteredData = [];

  if (data.length > 0) {
    reportes.forEach((reporteID) => {
      const row = {};
      // const filterByReport = location.data.filter(x => x.reporteID === reporteID);
      console.log(data);
      const filterByReport = data.filter(x => x.reporteID === reporteID);
      row.fechaRecepcion = (new Date(filterByReport[0].fechaRecepcion.toString()));
      salidas.forEach((salida) => {
        const valorSalida = filterByReport.filter(x => x.salidaNro === salida);
        row[salida] = valorSalida.length > 0 ? parseFloat(valorSalida[0].valor.replace(',', '.')) : null;
        console.log(valorSalida);
        row[`${salida}_esAnomalo`] = valorSalida.length > 0 ? valorSalida[0].esAnomalo : false;
      });
      filteredData.push(row);
    });
  }

  // Ordenamos el array
  function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const fechaA = a.fechaRecepcion;
    const fechaB = b.fechaRecepcion;

    let comparison = 0;
    if (fechaA > fechaB) {
      comparison = 1;
    } else if (fechaA < fechaB) {
      comparison = -1;
    }
    return comparison;
  }

  filteredData = filteredData.sort(compare);

  const handleConsultar = (fechaInicio, fechaFin) => {
    // Moment(scheduledDate).format('YYYY-MM-DDTHH:mm:ss')
    const fechaDesde = new Date(fechaInicio);
    fechaDesde.setHours(0);
    fechaDesde.setMinutes(0);
    fechaDesde.setSeconds(0);
    const fechaHasta = new Date(fechaFin);
    fechaHasta.setHours(23);
    fechaHasta.setMinutes(59);
    fechaHasta.setSeconds(59);
    store.dispatch(Actions.Variables.getVariableByRectAndConfReporte(location.rectificadorID, location.variable, Moment(fechaDesde).format('YYYY-MM-DDTHH:mm:ss'), Moment(fechaHasta).format('YYYY-MM-DDTHH:mm:ss')));
  };

  const handleGoBack = () => {
    if (location.isMoreInfo) {
      history.push({
        pathname: '/moreInfo',
        rectificador: location.device,
        t,
      });
    } else {
      // history.goBack();
      history.push({
        pathname: '/tableros',
        rectificador: location.rectificadorID,
      });
    }
  };

  return (
    <PageWrapper
      history={history}
    >
      <Row>
        <Container maxWidth="xl" className="FilterBarHeader">
          <Row>
            <Grid container>
              <Grid item xs={1}>
                <IconButton
                  // className={classes.button}
                  aria-label="left"
                  onClick={handleGoBack}
                  style={{
                    border: '2px solid rgba(0, 0, 0, 0.54)',
                  }}
                >
                  <AwesomeIcon icon="left" />
                </IconButton>
              </Grid>
              <Grid item xs={5} className="gf-font-size-var-detail">
                <h3>{location.rectificador}</h3>
              </Grid>
              {/* <Grid item xs={2} /> */}
              <Grid item xs={2} className="gf-font-size-var-detail-date">
                <DatePicker
                  className={`${classes.datePickerInput} gf-datepicker-size`}
                  selected={startDate}
                  selectsStart
                  dateFormat="dd/MM/yyyy"
                  minDate={minDate}
                  maxDate={endDate}
                  onChange={handleChangeStart}
                  locale={language === 'EN' ? enUS : es}
                />
              </Grid>
              <Grid item xs={3} className="gf-font-size-var-detail-date">
                <DatePicker
                  className={`${classes.datePickerInput} gf-datepicker-size`}
                  selected={endDate}
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  // maxDate={endDate}
                  onChange={handleChangeEnd}
                  minDate={startDate}
                  locale={language === 'EN' ? enUS : es}
                />
              </Grid>
              <Grid item xs={1} className="gf-font-size-var-detail-button">
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.button} gf-button-size`}
                  onClick={() => handleConsultar(startDate, endDate)}
                >
                  {t('default.consultar', 'Consultar')}
                </Button>
              </Grid>
            </Grid>
          </Row>
        </Container>
      </Row>
      <Container maxWidth="xl">
        <Grid container className="ListView gf-var-graph">
          {data.length > 0 ? (
            <Fragment>
              <Grid item xs={12}>
                <GoogleChart filteredData={data} language="ES" />
              </Grid>
              <Grid item xs={12}>
                <BootstrapTable
                  keyField="alarmaID"
                  striped
                  hover
                  condensed
                  bootstrap4
                  data={filteredData}
                  columns={columns}
                  pagination={paginationFactory()}
                />
              </Grid>
            </Fragment>
          ) : (
            <h4>{t('default.noDataForInterval', 'No hay datos para el intervalo seleccionado')}</h4>
          )}
        </Grid>
      </Container>
    </PageWrapper>
  );
};

VariableDetail.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
  lifecycle({
    componentDidMount() {
      if (!this.props.data) {
        const { rectificadorID, variable } = this.props.location;
        store.dispatch(Actions.Variables.getVariableByRectAndConfReporte(rectificadorID, variable, new Date(oneMonthAgeDate), new Date(actualDate)));
      }
    },
  }),
  connect(state => ({
    // data: ownProps.location.data || state.Variables.VariablesMultipleSalidas,
    data: state.Variables.VariablesMultipleSalidas,
    language: state.Language.current,
  })),
)(VariableDetail);

export default translate('common')(AppCompose);
