import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
  IconButton,
  makeStyles,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';

import {
  Row,
  Col,
} from 'react-bootstrap';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { connect } from 'react-redux';
// import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import format from 'date-fns/format';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

import { withSnackbar } from 'notistack';
import { translate } from 'react-i18next';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import AwesomeIcon from '../AwesomeIcon';
import theme from '../../scss/theme/colors';

import store from '../../store';
import Actions from '../../actions';

import { composePure, withProps, lifecycle } from '../../utils/composepure';

import Props from './props';

// registerLocale('es', es);
// registerLocale('enUS', enUS);

const useStyles = makeStyles(th => ({
  button: {
    color: theme.blue.darker,
    marginTop: '10px',
  },
  iconButton: {
    padding: '0 5px 0 5px',
  },
  buttonModal: {
    backgroundColor: '#0083C7 !important',
  },
  formControl: {
    margin: th.spacing(1),
    minWidth: 120,
    width: '100%',
    marginTop: '16px',
  },
  FormGroup: {
    flexDirection: 'column',
  },
  FormControlLabel: {
    margin: 0,
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: '70vh',
  },
  textField: {
    margin: th.spacing(1),
    width: '100%',
    minWidth: '300px',
  },
  headerGrid: {
    background: 'black',
    color: 'white',
  },
}));

const emptyLinea = {
  lineaId: -1,
  lineaNume: '',
  nroSim: '',
  fechaAlta: null,
  email: '',
  empresaId: -1,
  empresa: '',
  planId: -1,
  planDescripcion: '',
  interfazOperacion: -1,
  cuentaIpGatewayId: -1,
};

class ESLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    // const loc = this.locale;
    // console.log('Locale: ', loc);
    return format(date, 'd MMMM yyyy', { locale: this.locale });
  }
}

const ModalLineas = ({
  t,
  enqueueSnackbar,
  empresas,
  planes = [],
  cuentasIPGateway = [],
  lineas,
  language,
  resultSaveLinea,
  lineaDB,
}) => {
  useEffect(() => {
    if (resultSaveLinea === 'ok') {
      enqueueSnackbar(t('default.successSaveLinea', 'La linea se guardó correctamente'), {
        variant: 'success',
      });
    }

    if (resultSaveLinea === 'error') {
      enqueueSnackbar(t('default.errorSaveLinea', 'Ocurrió un error al guardar la linea'), {
        variant: 'error',
      });
    }

    return () => {
      store.dispatch({ type: 'RESET_RESULTSAVELINEA' });
    };
  }, [resultSaveLinea]);

  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [lineaDelete, setLineaDelete] = useState();
  const [state, setState] = useState(emptyLinea);

  const handleOpenModal = () => {
    if (lineaDB > -1) {
      const lineaSelected = lineas.filter(uc => uc.lineaId === lineaDB)[0];
      setState(lineaSelected);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleEditLinea = (id) => {
    const lineaSelected = lineas.filter(uc => uc.lineaId === id)[0];
    setState(lineaSelected);
  };

  const handleDeleteLinea = (id) => {
    setLineaDelete(id);
    setOpenConfirm(true);
  };

  const confirmDeleteLinea = () => {
    if (lineaDelete) {
      store.dispatch(Actions.Maestros.deleteLinea(lineaDelete));
    }
    setLineaDelete(null);
    setOpenConfirm(false);
  };

  const handleAddUpdateLinea = () => {
    if (!state.empresaId || state.empresaId === -1) {
      enqueueSnackbar(t('default.empresaRequerido', 'La empresa es requerida'), {
        variant: 'error',
      });
      return;
    }

    if (!state.planId || state.planId === -1) {
      enqueueSnackbar(t('default.planRequerido', 'El plan es requerido'), {
        variant: 'error',
      });
      return;
    }

    if (!state.fechaAlta) {
      enqueueSnackbar(t('default.fechaAltaRequerido', 'La fecha alta es requerida'), {
        variant: 'error',
      });
      return;
    }

    if (!state.lineaNume) {
      enqueueSnackbar(t('default.lineaNumeRequerido', 'El número de linea es requerido'), {
        variant: 'error',
      });
      return;
    }

    const dto = {
      ...state,
      empresa: empresas.filter(x => x.empresaID === state.empresaId)[0].nombre,
      planDescripcion: planes.filter(x => x.planId === state.planId)[0].planDescrip,
      cuentaIpGatewayId: state.cuentaIpGatewayId === -1 ? null : state.cuentaIpGatewayId,
    };

    store.dispatch(Actions.Maestros.saveLinea(dto));
    setState(emptyLinea);
  };

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t('default.pagPrev', 'página previa'),
    nextPageTitle: t('default.proxPag', 'próxima página'),
    firstPageTitle: t('default.primerPagina', 'primer página'),
    lastPageTitle: t('default.ultimaPagina', 'última página'),
  };

  const columns = [{
    headerClasses: 'gf-notas-hide',
    dataField: 'empresa',
    text: t('default.razonSocial', 'Razón Social'),
    headerStyle: () => ({ width: '20%' }),
    sort: true,
    filter: textFilter({
      className: 'customTextFilter',
    }),
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: 'planDescripcion',
    text: t('default.descripcionPlan', 'Descripción del Plan'),
    headerStyle: () => ({ width: '30%' }),
    sort: true,
    filter: textFilter({
      className: 'customTextFilter',
    }),
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: 'lineaNume',
    text: t('default.numeroLinea', 'Número Linea'),
    headerStyle: () => ({ width: '20%' }),
    sort: true,
    filter: textFilter({
      className: 'customTextFilter',
    }),
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: 'nroSim',
    text: t('default.nroSim', 'Número SIM'),
    headerStyle: () => ({ width: '20%' }),
    sort: true,
    filter: textFilter({
      className: 'customTextFilter',
    }),
  },
  {
    dataField: 'lineaId',
    text: '',
    formatter: id => (
      <>
        <IconButton
          className={classes.iconButton}
          aria-label="Editar"
          onClick={() => handleEditLinea(id)}
          title={t('default.editar', 'Editar')}
        >
          <AwesomeIcon icon="edit" />
        </IconButton>
        <IconButton
          className={classes.iconButton}
          aria-label="Eliminar"
          onClick={() => handleDeleteLinea(id)}
          title={t('default.eliminar', 'Eliminar')}
        >
          <AwesomeIcon icon="trash" />
        </IconButton>
      </>
    ),
  }];

  return (
    <>
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('default.confirmDeleteLinea', '¿Está seguro que desea eliminar la linea?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDeleteLinea} color="primary" autoFocus>
            {t('default.si', 'Si')}
          </Button>
          <Button onClick={() => setOpenConfirm(false)} color="primary" autoFocus>
            {t('default.no', 'No')}
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        className={classes.button}
        aria-label="Lineas"
        onClick={handleOpenModal}
        title={t('default.linea', 'Lineas')}
      >
        <AwesomeIcon icon="settings" />
      </IconButton>
      <Dialog
        maxWidth="lg"
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t('default.linea', 'Lineas')}</DialogTitle>
        <DialogContent>
          <Row>
            <Col xs={6}>
              <TextField
                id="lineNumber"
                label={t('default.numeroLinea', 'Número de Línea')}
                className={`${classes.textField}`}
                type="text"
                margin="normal"
                value={state.lineaNume}
                onChange={evt => setState({
                  ...state,
                  lineaNume: evt.target.value,
                })}
              />
            </Col>
            <Col xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="empresaId">{t('default.razonSocial', 'Razón Social')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="empresaId"
                  value={state.empresaId}
                  variant="standard"
                  onChange={(evt) => {
                    setState({
                      ...state,
                      empresaId: evt.target.value,
                    });
                  }}
                >
                  {empresas.map(e => (
                    <MenuItem key={e.empresaID} value={e.empresaID}>{e.nombre}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="planId">{t('default.descripcionPlan', 'Descripción del Plan')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="planId"
                  value={state.planId}
                  variant="standard"
                  onChange={(evt) => {
                    setState({
                      ...state,
                      planId: evt.target.value,
                    });
                  }}
                >
                  {planes.map(p => (
                    <MenuItem key={p.planId} value={p.planId}>{p.planDescrip}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col xs={6}>
              <TextField
                id="nroSim"
                label={t('default.nroSim', 'Número SIM')}
                className={`${classes.textField}`}
                type="text"
                margin="normal"
                value={state.nroSim}
                onChange={(evt) => {
                  const regex = /^[0-9\b]+$/;
                  if (regex.test(evt.target.value) || !evt.target.value) {
                    setState({
                      ...state,
                      nroSim: evt.target.value,
                    });
                  }
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormControl className={classes.formControl}>
                {/* <DatePicker
                  name="fechaAlta"
                  selected={state.fechaAlta}
                  selectsStart
                  dateFormat="dd/MM/yyyy"
                  onChange={newValue => setState({ ...state, fechaAlta: newValue })}
                  locale={language === 'EN' ? enUS : es}
                  label={t('default.fechaAlta', 'Fecha Alta')}
                /> */}
                <MuiPickersUtilsProvider utils={language === 'EN' ? DateFnsUtils : ESLocalizedUtils} locale={language === 'EN' ? enLocale : esLocale}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label={t('default.fechaAlta', 'Fecha Alta')}
                    format="dd/MM/yyyy"
                    value={state.fechaAlta}
                    onChange={(date) => {
                      setState({
                        ...state,
                        fechaAlta: date,
                      });
                    }}
                    cancelLabel={t('default.cancelar', 'Cancelar')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Col>
            <Col xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="interfazOperacion">{t('default.interfazOperacion', 'Interfaz de Operación')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="interfazOperacion"
                  value={state.interfazOperacion}
                  variant="standard"
                  onChange={(evt) => {
                    setState({
                      ...state,
                      interfazOperacion: evt.target.value,
                    });
                  }}
                >
                  <MenuItem key={1} value={1}>IPGATEWAY</MenuItem>
                  <MenuItem key={2} value={2}>EMAIL</MenuItem>
                  <MenuItem key={3} value={3}>GPRS</MenuItem>
                </Select>
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <TextField
                id="email"
                label="E-mail"
                className={`${classes.textField}`}
                type="text"
                margin="normal"
                value={state.email}
                onChange={evt => setState({
                  ...state,
                  email: evt.target.value,
                })}
              />
            </Col>
            <Col xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="cuentaIpGatewayId">{t('default.cuentaIPGateway', 'Cuenta IP Gateway')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="cuentaIpGatewayId"
                  value={state.cuentaIpGatewayId}
                  variant="standard"
                  onChange={(evt) => {
                    setState({
                      ...state,
                      cuentaIpGatewayId: evt.target.value,
                    });
                  }}
                >
                  {cuentasIPGateway.map(i => (
                    <MenuItem key={i.cuentaIPGatewayID} value={i.cuentaIPGatewayID}>{i.cuentaIPGatewayDescrip}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
          </Row>
          <Row style={{ margin: '10px 0' }}>
            <Button
              onClick={handleAddUpdateLinea}
              color="primary"
              variant="contained"
              className={classes.buttonModal}
            >
              {state.lineaId === -1 ? t('default.agregar', 'Agregar') : t('default.actualizar', 'Actualizar')}
            </Button>
            { state.lineaId > -1 && (
              <Button
                onClick={() => setState(emptyLinea)}
                color="primary"
                autoFocus
                variant="contained"
                className={classes.buttonModal}
                style={{ marginLeft: '10px' }}
              >
                {t('default.cancelar', 'Cancelar')}
              </Button>
            )}
          </Row>
          <Row>
            <Col xs={12} className="gf-modal-table">
              <BootstrapTable
                keyField="lineaId"
                striped
                hover
                condensed
                bootstrap4
                headerClasses={classes.headerGrid}
                data={lineas}
                columns={columns}
                pagination={paginationFactory(options)}
                filter={filterFactory()}
              />
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            color="primary"
            variant="contained"
            className={classes.buttonModal}
          >
            {t('default.cerrar', 'Cerrar')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ModalLineas.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({
    t: props.t,
    enqueueSnackbar: props.enqueueSnackbar,
  })),
  lifecycle({
    componentDidMount() {
      store.dispatch(Actions.Maestros.getLineasAll());
      store.dispatch(Actions.Maestros.getPlanes());
      store.dispatch(Actions.Maestros.getCuentasIPGateway());
    },
  }),
  connect(state => ({
    lineas: state.Maestros.lineas,
    planes: state.Maestros.planes,
    cuentasIPGateway: state.Maestros.cuentasIPGateway,
    resultSaveLinea: state.Maestros.resultSaveLinea,
  })),
)(ModalLineas);

export default withSnackbar(translate('common')(AppCompose));
