import React from 'react';
import {
  Checkbox,
  withStyles,
} from '@material-ui/core';
import theme from '../../scss/theme/colors';
import Props from './props';

const GreenCheckbox = withStyles({
  root: {
    color: '#E6E6E6',
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
    '&$checked': {
      color: theme.gray.light,
    },
    padding: '0.5rem',
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

/** @description Componente generico de creacion de checkbox asociados al rectificador.
 */
const RectifierCheckbox = ({
  state,
  handleChange,
  value,
}) => (
  <GreenCheckbox
    checked={state}
    onChange={() => handleChange(value)}
    value={value}
  />
);

RectifierCheckbox.propTypes = Props;
export default RectifierCheckbox;
