import {
  FETCH_GROUPS,
  CREATE_GROUP,
  SET_ADDGROUP,
  DELETE_GROUP,
  SETDELETE_GROUP,
} from '../actionTypes/groups';

export const fetchGroups = userId => ({
  type: FETCH_GROUPS,
  payload: {
    userId,
  },
});

export const createGroup = (grupo, subgrupo) => ({
  type: CREATE_GROUP,
  payload: {
    grupo,
    subgrupo,
  },
});

export const setAddGroup = (grupoId, grupo, subgrupo) => ({
  type: SET_ADDGROUP,
  payload: {
    grupoId,
    grupo,
    subgrupo,
  },
});

export const deleteGroup = grupoId => ({
  type: DELETE_GROUP,
  payload: {
    grupoId,
  },
});

export const setDeleteGroup = grupoId => ({
  type: SETDELETE_GROUP,
  payload: {
    grupoId,
  },
});

export default {
  fetchGroups,
  createGroup,
  setAddGroup,
  deleteGroup,
  setDeleteGroup,
};
