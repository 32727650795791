export const USER_LOGIN = 'USER_LOGIN';
export const USER_RESET_ERROR_LOGIN = 'USER_RESET_ERROR_LOGIN';
export const USER_SET_DATA = 'USER_SET_DATA';
export const USER_SET_AUTH_TOKEN = 'USER_SET_AUTH_TOKEN';
export const USER_SET_REGISTER_DATA = 'USER_SET_REGISTER_DATA';
export const USER_FETCH_LOGOUT = 'USER_FETCH_LOGOUT';
export const USER_FETCH_UPDATE_DATA = 'USER_FETCH_UPDATE_DATA';
export const SAVE_PREFERENCES = 'SAVE_PREFERENCES';
export const SET_USERPREFERENCE = 'SET_USERPREFERENCE';
export const SAVE_PROXYSETTINGS = 'SAVE_PROXYSETTINGS';
export const SAVE_USERDATA = 'SAVE_USERDATA';
export const SET_SAVEUSERDATA = 'SET_SAVEUSERDATA';

export const fetchLogin = (username, password) => ({
  type: USER_LOGIN,
  payload: {
    username,
    password,
  },
});

export const resetError = () => ({
  type: USER_RESET_ERROR_LOGIN,
});


export const setData = data => ({
  type: USER_SET_DATA,
  payload: {
    ...data,
  },
});

export const setAuthToken = token => ({
  type: USER_SET_AUTH_TOKEN,
  payload: {
    token,
  },
});

export const setRegisterData = data => ({
  type: USER_SET_REGISTER_DATA,
  payload: {
    ...data,
  },
});

export const fetchLogout = () => ({
  type: USER_FETCH_LOGOUT,
});

export const fetchUpdateUser = (data, cb = () => {}) => ({
  type: USER_FETCH_UPDATE_DATA,
  payload: {
    ...data,
  },
  cb,
});

export const savePreferences = (userId, preferencia, valor) => ({
  type: SAVE_PREFERENCES,
  payload: {
    userId,
    preferencia,
    valor,
  },
});

export const setPreferences = userPreference => ({
  type: SET_USERPREFERENCE,
  payload: userPreference,
});

export const saveUserData = (nombre, apellido, legajo, dni, zonaHorariaID, vistaPreferidaId,
  idiomaId, password, passwordNuevo, usuarioId, userName, entidadId, mediosContacto, profilePicture) => ({
  type: SAVE_USERDATA,
  payload: {
    nombre,
    apellido,
    legajo,
    dni,
    zonaHorariaID,
    vistaPreferidaId,
    idiomaId,
    password,
    passwordNuevo,
    usuarioId,
    userName,
    entidadId,
    mediosContacto,
    profilePicture,
  },
});

export const setSaveUserData = payload => ({
  type: SET_SAVEUSERDATA,
  payload,
});

export default {
  fetchLogin,
  resetError,
  setData,
  setAuthToken,
  setRegisterData,
  fetchLogout,
  fetchUpdateUser,
  savePreferences,
  setPreferences,
  saveUserData,
  setSaveUserData,
};
