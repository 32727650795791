import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
// import { Redirect } from 'react-router';
import { composePure } from '../../utils/composepure';
import store from '../../store';
import Actions from '../../actions';
import './style.css';
import propTypes from './props';

/** @description Componente que dispone de un Timer para la sesion.
 */
const NetworkWorking = ({
  working,
  requestError,
  // logged,
}) => {
  useEffect(() => {
    if (requestError && requestError.response && requestError.response.status === 401) {
      // return (<Redirect to="/login" />);
      return () => store.dispatch(Actions.Users.fetchLogout());
    }

    return undefined;
  });

  /* if (requestError && requestError.response.status === 401) {
    return (<Redirect to="/login" />);
  } */
  return (
    <Fragment>
      <div className="cover-spin" style={{ display: working ? 'block' : 'none' }} />
      {/* <div style={{
        position: 'absolute',
        // backgroundColor: '#f4df42',
        top: '50%',
        right: '50%',
        display: working ? 'block' : 'none',
        zIndex: 500000,
        padding: 5,
      }}
      >
        <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div> */}
    </Fragment>
  );
};


NetworkWorking.propTypes = propTypes;

export default composePure(
  connect(state => ({
    working: state.Universal.request.process,
    requestError: state.Universal.request.msg,
    // logged: state.Users.logged,
  })),
)(NetworkWorking);
