import React from 'react';
import { render } from 'react-dom';
import './scss/main.scss';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import { SnackbarProvider } from 'notistack';

import commonEn from './translations/en/common.json';
import commonEs from './translations/es/common.json';

// import i18n from './i18n';

import App from './App';

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem('language') ? localStorage.getItem('language').toLowerCase() : 'es',
  resources: {
    en: {
      common: commonEn,
    },
    es: {
      common: commonEs,
    },
  },
});

render(
  <SnackbarProvider maxSnack={3}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </SnackbarProvider>,
  document.getElementById('root'),
);
