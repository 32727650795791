import config from '../config';

export const UNIVERSAL_FETCH_MODULE = 'UNIVERSAL_FETCH_MODULE';
export const FETCH_MODULE_SUCCESS = 'FETCH_MODULE_SUCCESS';
export const FETCH_MODULE_ERROR = 'FETCH_MODULE_ERROR';
export const UNIVERSAL_REMOVE_MODULE = 'UNIVERSAL_REMOVE_MODULE';
export const UNIVERSAL_UPDATE_MODULE = 'UNIVERSAL_UPDATE_MODULE';
export const UPDATE_MODULE_SUCCESS = 'UPDATE_MODULE_SUCCESS';
export const UNIVERSAL_INSERT_MODULE = 'UNIVERSAL_INSERT_MODULE';
export const UNIVERSAL_UPLOAD_FILE = 'UNIVERSAL_UPLOAD_FILE';
export const TOGGLE_PROP = 'TOGGLE_PROP';
export const ADD_TO_TEMPORAL_STORAGE = 'ADD_TO_TEMPORAL_STORAGE';
export const REMOVE_TO_TEMPORAL_STORAGE = 'REMOVE_TO_TEMPORAL_STORAGE';
export const SET_TEMPORAL_STATE = 'SET_TEMPORAL_STATE';

const fetch = (module, payload, microservice = 'service') => ({
  ...payload,
  type: UNIVERSAL_FETCH_MODULE,
  host: config.ENDPOINT_URL,
  microservice,
  module,
});

const fetchSuccess = (module, payload) => ({
  type: FETCH_MODULE_SUCCESS,
  module,
  payload,
});

const requestError = (module, payload) => ({
  type: FETCH_MODULE_ERROR,
  module,
  payload,
});

const remove = (module, payload, microservice = 'service') => ({
  ...payload,
  type: UNIVERSAL_REMOVE_MODULE,
  host: config.ENDPOINT_URL,
  module,
  method: 'delete',
  microservice,
});

const update = (module, payload, microservice = 'service') => ({
  type: UNIVERSAL_UPDATE_MODULE,
  host: config.ENDPOINT_URL,
  module,
  method: 'patch',
  microservice,
  data: payload.data,
});

const updateSuccess = payload => ({
  type: UPDATE_MODULE_SUCCESS,
  host: config.ENDPOINT_URL,
  module: payload.module,
  data: payload.data,
});

const insert = (module, payload, microservice = 'service') => ({
  type: UNIVERSAL_INSERT_MODULE,
  host: config.ENDPOINT_URL,
  method: 'put',
  module,
  microservice,
  data: payload.data,
});

const upload = data => ({
  type: UNIVERSAL_UPLOAD_FILE,
  data,
});

const toggleProp = key => ({
  type: TOGGLE_PROP,
  key,
});

const addToTemporal = (storage, item) => ({
  type: ADD_TO_TEMPORAL_STORAGE,
  item,
  storage,
});


const removeToTemporal = (storage, item, key) => ({
  type: REMOVE_TO_TEMPORAL_STORAGE,
  storage,
  item,
  key,
});


const setState = (state, value) => ({
  type: SET_TEMPORAL_STATE,
  state,
  value,
});


export default {
  fetch,
  fetchSuccess,
  requestError,
  remove,
  update,
  insert,
  toggleProp,
  addToTemporal,
  updateSuccess,
  removeToTemporal,
  setState,
  upload,
};
