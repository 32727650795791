import { put } from 'redux-saga/effects';

/** @description Llamada al EndPoint para obtener tipos de filtros.
 * URL: Alarma/GetAlertTypes
*/
export const getFilterTypes = requestModule => function* getFilterTypesSage() {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'FILTER_SET_ALERT_TYPES',
      module: 'Alarma/GetAlertTypes',
      query,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};
export default {

};
