import {
  put,
  select,
  call,
} from 'redux-saga/effects';

import Actions from '../actions';

/** @description Llamada al EndPoint para obtener rectificadores segun usuario.
 * URL: Rectificador/ByUser/${state.Users.userData.usuarioId}
*/
export const getRectifiers = requestModule => function* getCardsSaga() {
  try {
    const query = {};
    const state = yield select();
    yield requestModule({
      dispatchAction: 'RECTIFIERS_SET_DOCS',
      module: `Rectificador/ByUser/${state.Users.userData.usuarioId}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener configuracion de reporte segun rectificador.
 * URL: Rectificador/GetConfReporteByRectificador/${action.payload.rectificadorID}
*/
export const getConfReporte = requestModule => function* getConfReporteSaga(action) {
  try {
    const state = yield select();
    const confReportes = state.Rectifiers.configuracionReportes.filter(x => x.tipoUnidadMonitoreoID === action.payload.tipoUnidadMonitoreoID);
    if (confReportes.length > 0) {
      yield put(Actions.Rectifiers.setConfReporte(confReportes));
    } else {
      const query = {};
      yield requestModule({
        dispatchAction: 'SET_CONFIGURACIONREPORTE',
        module: `Rectificador/GetConfReporteByRectificador/${action.payload.rectificadorID}`,
        query,
        process: false,
      });
    }
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener grupos segun usuario.
 * URL: Grupos/ByUser/${state.Users.userData.usuarioId}
*/
export const getGroups = requestModule => function* getCategoriesSaga() {
  try {
    const state = yield select();
    const query = {};
    yield requestModule({
      dispatchAction: 'GROUPS_SET_DOCS',
      module: `Grupos/ByUser/${state.Users.userData.usuarioId}`,
      query,
      process: false,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener rectificadores disponibles.
 * URL: Rectificador/GetAvailable/${state.Users.userData.usuarioId}
*/
export const getAvailableDevices = requestModule => function* getAvailableDevicesSaga() {
  try {
    const state = yield select();
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_AVAILABLEDEVICES',
      module: `Rectificador/GetAvailable/${state.Users.userData.usuarioId}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener rectificadores disponibles para un cieto grupo.
 * URL: Rectificador/GetAvailable/${state.Users.userData.usuarioId}
*/
export const getAvailableDevicesForGroup = requestModule => function* getAvailableDevicesForGroupSaga(action) {
  try {
    const state = yield select();
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_AVAILABLEDEVICES',
      module: `Rectificador/GetAvailableForGroup/${state.Users.userData.usuarioId}/${action.payload.grupoId}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener todos los tipos de rectificadores.
 * URL: Rectificador/TipoRectificadorAll
*/
export const getTiposRectificador = requestModule => function* getTiposRectificadorSaga() {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_TIPOSRECTIFICADOR',
      module: 'Rectificador/TipoRectificadorAll',
      query,
      process: false,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener todas las zonas horarias.
 * URL: ZonaHoraria/All
*/
export const getZonaHorarias = requestModule => function* getZonaHorariasSaga() {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_ZONAHORARIA',
      module: 'ZonaHoraria/All',
      query,
      process: false,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener dispositivos segun identificador.
 * URL: Rectificador/GetDeviceById/${action.payload}
*/
export const getDeviceById = requestModule => function* getDeviceByIdSaga(action) {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_DEVICEEDIT',
      module: `Rectificador/GetDeviceById/${action.payload}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener ultimos reportes.
 * URL: Rectificador/DetailById/${action.payload.id}
*/
export const getLastReport = requestModule => function* getLastReportSaga(action) {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_RECTIFIERLASTREPORT',
      module: `Rectificador/DetailById/${action.payload.id}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener inicio de alarma.
 * URL: Rectificador/GetInicioAlarma/${action.payload.value}
*/
export const getInicioAlarma = requestModule => function* getInicioAlarmaSaga(action) {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_INICIOALARMA',
      module: `Rectificador/GetInicioAlarma/${action.payload.value}`,
      query,
      process: false,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para agregar nuevo dispositivo.
 * URL: Rectificador/AddDevice
*/
export const addDevice = apiClient => function* addDeviceSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Rectificador/AddDevice',
      method: 'put',
      data: action.payload,
      process: true,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Rectifiers.removeDeviceAvailable(action.payload.rectificador));
    }

    return true;
  } catch (err) {
    console.log(err);
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para crear o modificar un rectificador.
 * URL: Rectificador/GuardarRectificador
*/
export const crearRectificador = apiClient => function* crearRectificadorSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Rectificador/GuardarRectificador',
      method: 'post',
      data: action.payload,
      process: true,
    };

    const response = yield call(apiClient, params);

    if (response.status === 200) {
      yield put(Actions.Rectifiers.updateRectierAfterSave({ ...response.data, isNew: action.payload.rectificadorID === 0 }));
    }

    return true;
  } catch (err) {
    console.log(err);
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};


/** @description Llamada al EndPoint para remover dispositivo existente.
 * URL: Rectificador/RemoveRectificador/${action.payload}
*/
export const removeDevice = apiClient => function* addDeviceSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: `Rectificador/RemoveRectificador/${action.payload}`,
      method: 'put',
      // data: action.payload,
      process: true,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Rectifiers.removeDevice(action.payload));
    }

    return true;
  } catch (err) {
    console.log(err);
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para agregar nuevo dispositivo a un grupo.
 * URL: Rectificador/AddDeviceToGroup/${rectificadorId}/${grupoId}
*/
export const addDeviceToGroup = apiClient => function* addDeviceToGroupSaga(action) {
  try {
    const { rectificador, grupoId } = action.payload;
    const params = {
      query: {},
      microservice: '',
      module: `Rectificador/AddDeviceToGroup/${rectificador.rectificadorID}/${grupoId}`,
      method: 'post',
      process: true,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Rectifiers.setAddDeviceToGroup(rectificador, grupoId));
    }
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para remover dispositivo de un grupo.
 * URL: Rectificador/RemoveDeviceToGroup/${rectificadorId}/${grupoId}
*/
export const removeDeviceFromGroup = apiClient => function* removeDeviceFromGroupSaga(action) {
  try {
    const { rectificadorId, grupoId } = action.payload;
    const params = {
      query: {},
      microservice: '',
      module: `Rectificador/RemoveDeviceToGroup/${rectificadorId}/${grupoId}`,
      method: 'delete',
      process: true,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Rectifiers.setRemoveDeviceToGroup(rectificadorId, grupoId));
    }
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para crear un nuevo grupo.
 * URL: Grupos/CrearGrupo
*/
export const createGrupo = apiClient => function* createGrupoSaga(action) {
  try {
    const state = yield select();
    const grupoParam = {
      ...action.payload,
      entidadId: state.Users.userData.entidadId,
    };
    const params = {
      query: {},
      microservice: '',
      module: 'Grupos/CrearGrupo',
      method: 'post',
      data: grupoParam,
      process: true,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Groups.setAddGroup(response.data, action.payload.grupo, action.payload.subgrupo));
    }
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para eliminar grupo.
 * URL: Grupos/EliminarGrupo/${action.payload.grupoId}
*/
export const deleteGroup = apiClient => function* deleteGroupSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: `Grupos/EliminarGrupo/${action.payload.grupoId}`,
      method: 'delete',
      process: true,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Groups.setDeleteGroup(action.payload.grupoId));
    }
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener localizacion del dispositivo.
 * URL: Rectificador/GetLocacionGPS/${action.payload.id}
*/
export const getLocacionDevice = requestModule => function* getLocacionDeviceSaga(action) {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_LOCATIONDEVICE',
      module: `Rectificador/GetLocacionGPS/${action.payload.id}`,
      query,
      process: false,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export default {

};
