import {
  RECTIFIERS_FETCH_ALL,
  RECTIFIERS_FETCH_INICIOALARMA,
  RECTIFIERS_SET_SELECTED,
  FETCH_CONFIGURACIONREPORTE,
  FETCH_AVAILABLEDEVICES,
  FETCH_ZONAHORARIAS,
  FETCH_TIPOSRECTIFICADOR,
  REMOVE_AVAILABLEDEVICE,
  ADD_DEVICE,
  REMOVE_RECTIFICADOR,
  REMOVE_DEVICE,
  FETCH_DEVICEBYID,
  ADD_RECTIFIERTOGROUP,
  REMOVE_RECTIFIERTOGROUP,
  SET_ADDDEVICETOGROUP,
  SET_REMOVEDEVICEFROMGROUP,
  GET_LASTREPORT,
  GET_LOCATIONDEVICE,
  GUARDAR_REACTIFICADOR,
} from '../actionTypes/rectifiers';

export const guardarRectificador = rectificador => ({
  type: GUARDAR_REACTIFICADOR,
  payload: rectificador,
});

export const fetchRectifiers = userId => ({
  type: RECTIFIERS_FETCH_ALL,
  payload: {
    userId,
  },
});

export const setSelected = cardId => ({
  type: RECTIFIERS_SET_SELECTED,
  payload: {
    value: cardId,
  },
});

export const deSelected = cardId => ({
  type: RECTIFIERS_SET_SELECTED,
  payload: {
    value: cardId,
  },
});

export const getInicioAlarma = alarmaID => ({
  type: RECTIFIERS_FETCH_INICIOALARMA,
  payload: {
    value: alarmaID,
  },
});

export const getConfReporte = (rectificadorID, tipoUnidadMonitoreoID) => ({
  type: FETCH_CONFIGURACIONREPORTE,
  payload: {
    rectificadorID,
    tipoUnidadMonitoreoID,
  },
});

export const setConfReporte = confReportes => ({
  type: 'SET_CONFIGURACIONREPORTE',
  payload: {
    data: confReportes,
  },
});

export const getAvailableDevices = () => ({
  type: FETCH_AVAILABLEDEVICES,
});

export const getAvailableDevicesForGroup = grupoId => ({
  type: 'FETCH_AVAILABLEDEVICES_FORGROUP',
  payload: {
    grupoId,
  },
});

export const getZonaHorarias = () => ({
  type: FETCH_ZONAHORARIAS,
});

export const getTiposRectificador = () => ({
  type: FETCH_TIPOSRECTIFICADOR,
});

export const removeDeviceAvailable = equipo => ({
  type: REMOVE_AVAILABLEDEVICE,
  payload: { equipo },
});

export const addRectifier = parametros => ({
  type: ADD_DEVICE,
  payload: parametros,
});

export const removeRectificador = ids => ({
  type: REMOVE_RECTIFICADOR,
  payload: ids,
});

export const removeDevice = ids => ({
  type: REMOVE_DEVICE,
  payload: ids,
});

export const getRectificadorById = id => ({
  type: FETCH_DEVICEBYID,
  payload: id,
});

export const addDeviceToGroup = (rectificador, grupoId) => ({
  type: ADD_RECTIFIERTOGROUP,
  payload: {
    rectificador,
    grupoId,
  },
});

export const removeDeviceFromGroup = (rectificadorId, grupoId) => ({
  type: REMOVE_RECTIFIERTOGROUP,
  payload: {
    rectificadorId,
    grupoId,
  },
});

export const setAddDeviceToGroup = (rectificador, grupoId) => ({
  type: SET_ADDDEVICETOGROUP,
  payload: {
    rectificador,
    grupoId,
  },
});

export const setRemoveDeviceToGroup = (rectificadorId, grupoId) => ({
  type: SET_REMOVEDEVICEFROMGROUP,
  payload: {
    rectificadorId,
    grupoId,
  },
});

export const getLastReport = id => ({
  type: GET_LASTREPORT,
  payload: {
    id,
  },
});

export const getLocacionDevice = id => ({
  type: GET_LOCATIONDEVICE,
  payload: {
    id,
  },
});

export const updateRectierAfterSave = payload => ({
  type: 'UPDATERECTIFIER_AFTERSAVE',
  payload,
});

export default {
  fetchRectifiers,
  setSelected,
  deSelected,
  getInicioAlarma,
  getConfReporte,
  setConfReporte,
  getAvailableDevices,
  getZonaHorarias,
  getTiposRectificador,
  removeDeviceAvailable,
  addRectifier,
  removeRectificador,
  removeDevice,
  getRectificadorById,
  addDeviceToGroup,
  removeDeviceFromGroup,
  setAddDeviceToGroup,
  setRemoveDeviceToGroup,
  getLastReport,
  getLocacionDevice,
  guardarRectificador,
  updateRectierAfterSave,
  getAvailableDevicesForGroup,
};
