import React from 'react';
import Chart from 'react-google-charts';

import Moment from 'moment';

import Props from './props';

/** @description Componente pequeño de grafico de lineas para tendencias.
 */
const MiniChart = ({
  tendencias,
}) => {
  const id = `LineChart_${Math.random() * 10}`;
  const data = [];
  data.push([
    'Fecha',
    'Valor',
    // { type: 'string', role: 'style' },
    { role: 'tooltip', type: 'string', p: { html: true } },
  ]);
  tendencias.forEach((i) => {
    data.push([
      new Date(i.fechaRecepcion),
      parseFloat(i.valor.replace(',', '.')),
      // i.esAnomalo ? 'point { size: 6; fill-color: #FF0000 }' : null,
      `<div style="background-color: black; color: white; padding: 5px; width: 170px">
      <span>
      <b>Fecha:</b>
      ${Moment(i.fechaRecepcion).format('DD/MM/YYYY HH:mm:ss')}</span></br><span><b>Valor:</b> ${i.valor}${i.unidadMedida !== null ? i.unidadMedida : ''}
      </span>
      </div>`,
    ]);
  });

  const options = {
    backgroundColor: '#DEE0E1',
    hAxis: {
      format: 'dd/MM/yyyy HH:mm:ss',
      gridlines: {
        color: 'transparent',
      },
      textPosition: 'none',
    },
    animation: {
      startup: true,
      easing: 'linear',
      duration: 1500,
    },
    tooltip: { isHtml: true, trigger: 'visible' },
    curveType: 'function',
    legend: { position: 'none' },
    areaOpacity: 0.24,
    lineWidth: 3,
    chartArea: {
      width: '85%',
      height: '60%',
    },
  };
  return (
    <Chart
      width="100%"
      height="125px"
      chartType="LineChart"
      // style={{ marginBottom: 10 }}
      id={id}
      loader={<div>Loading Chart</div>}
      data={data}
      options={options}
      rootProps={{ 'data-testid': '1' }}
    />
  );
};

MiniChart.propTypes = Props;

export default MiniChart;
