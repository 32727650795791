import React, { useState, useEffect } from 'react';

import {
  Row,
  Col,
} from 'react-bootstrap';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  makeStyles,
  InputLabel,
  FormControl,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Container,
  IconButton,
  Input,
  Checkbox,
  ListItemText,
} from '@material-ui/core';

import { connect } from 'react-redux';

// import Select from 'react-select';

import { translate } from 'react-i18next';
import { withSnackbar } from 'notistack';

import PageWrapper from '../../components/PageWrapper';
import Minimap from '../../components/Minimap';

import { composePure, withProps, lifecycle } from '../../utils/composepure';

import store from '../../store';
import Actions from '../../actions';

import AwesomeIcon from '../../components/AwesomeIcon';
import ModalConfigureOutputs from '../../components/ModalConfigureOutputs';
import ModalUnidadComunicacion from '../../components/ModalUnidadComunicacion';
import ModalLineas from '../../components/ModalLineas';

import './style.css';
import Props from './props';

const useStyles = makeStyles(theme => ({
  input: {
    width: '90%',
    fontSize: 12,
    marginLeft: 8,
    flex: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%',
    marginTop: '16px',
  },
  button: {
    width: '90%',
    backgroundColor: '#0083C7 !important',
  },
  buttonSecondary: {
    width: '90%',
    backgroundColor: '#6c757d !important',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

// let firstLoad = true;

/** @description pagina secundaria accedida desde mas Dispositivos para realizar la carga de un
 * nuevo dispositivo. Asociar a empresa, colocar en una ubicacion geografica.
 */
const NewDevice = ({
  history,
  location,
  t,
  grupos,
  zonasHoraria,
  tiposRectificador,
  permisos,
  user,
  empresas,
  marcas,
  tiposUnidad,
  unidadesComunicacion,
  listaRectificadores,
  enqueueSnackbar,
}) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [equipoModal, setEquipoModal] = useState({
    salidas: [],
  });

  const callbackModalEquipo = (data) => {
    console.log(data);
    setEquipoModal(data);
  };

  const classes = useStyles();

  const [state, setState] = useState({
    position: {
      latitud: null,
      longitud: null,
    },
    empresaId: 0,
    marcaId: 0,
    tipoUnidadId: 0,
    nroSerie: '',
    unidadComunicacionId: 0,
    numeroLinea: '',
    numeroRTU: 0,
    cantSalidas: 0,
    equipo: 0,
    grupo: [],
    groupName: '',
    tipo: 0,
    zonaHoraria: 0,
    ubicacion: '',
    alertMessage: '',
    nombreEquipo: '',
    openConfirm: false,
    positionDevice: {
      latitud: null,
      longitud: null,
    },
    id: 0,
    manualPosition: true,
  });

  useEffect(() => {
    if (unidadesComunicacion.length > 0 && state.unidadComunicacionId > 0) {
      const uc = unidadesComunicacion.filter(x => x.unidadComunicacionID === state.unidadComunicacionId)[0];
      setState({
        ...state,
        numeroLinea: uc ? uc.lineaNume : '',
        lineaID: uc ? uc.lineaID : -1,
      });
    }
  }, [unidadesComunicacion, state.unidadComunicacionId]);

  const loadDevice = (id) => {
    const rectificador = listaRectificadores.filter(x => x.rectificadorID === id)[0];
    console.log(rectificador);
    if (rectificador) {
      setState({
        ...state,
        position: {
          latitud: rectificador.latitud,
          longitud: rectificador.longitud,
        },
        open: false,
        equipo: rectificador.rectificadorID,
        grupo: rectificador.agrupacionValorID,
        tipo: rectificador.tipoRectificadorID,
        zonaHoraria: rectificador.zonaHorariaID,
        empresaId: rectificador.empresaID,
        marcaId: rectificador.marcaID,
        tipoUnidadId: rectificador.unitTypeID,
        nroSerie: rectificador.serialNumber,
        unidadComunicacionId: rectificador.comunicacionUnitID,
        locacionID: rectificador.locacionID,
        // numeroLinea: unidadesComunicacion.filter(x => x.unidadComunicacionID === rectificador.comunicacionUnitID)[0].lineaNume,
        numeroRTU: rectificador.numeroRTU,
        ubicacion: rectificador.ubicacion,
        nombreEquipo: rectificador.rectificadorNombre,
        id,
        manualPosition: !rectificador.posicionAutomatica,
      });

      setEquipoModal(rectificador.equipo);
    }
  };

  if (location.rectificador && listaRectificadores.length > 0 && firstLoad) {
    loadDevice(location.rectificador.rectificadorID);
    if (listaRectificadores.filter(x => x.rectificadorID === location.rectificador.rectificadorID)[0]) {
      setFirstLoad(false);
    }
  }

  /* const handleChangeDevice = (evt) => {
    loadDevice(evt.target.value);
  }; */

  const handleChangeZonaHoraria = (evt) => {
    setState({
      ...state,
      zonaHoraria: evt.target.value,
    });
  };

  const handleChangeTipo = (evt) => {
    setState({
      ...state,
      tipo: evt.target.value,
    });
  };

  const handleChangeEmpresa = (evt) => {
    setState({
      ...state,
      empresaId: evt.target.value,
    });
  };

  const handleChangeMarca = (evt) => {
    setState({
      ...state,
      marcaId: evt.target.value,
    });
  };

  const handleChangeTipoUnidad = (evt) => {
    setState({
      ...state,
      tipoUnidadId: evt.target.value,
    });
  };

  const handleChangeUC = (evt) => {
    const uc = unidadesComunicacion.filter(x => x.unidadComunicacionID === evt.target.value)[0];
    setState({
      ...state,
      unidadComunicacionId: evt.target.value,
      numeroLinea: uc ? uc.lineaNume : '',
      lineaID: uc ? uc.lineaID : -1,
    });
  };

  const handleChangeGrupo = (evt) => {
    setState({
      ...state,
      grupo: evt.target.value,
    });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleGuardar = () => {
    const empresa = user.esAdministrador ? state.empresaId : user.empresaID;

    if (!state.grupo || state.grupo.length === 0) {
      enqueueSnackbar('Seleccione al menos un grupo', {
        variant: 'error',
      });
      setState({
        ...state,
        openConfirm: false,
      });
      return;
    }

    if (!state.nombreEquipo) {
      enqueueSnackbar('El nombre del rectificador es obligatorio', {
        variant: 'error',
      });
      setState({
        ...state,
        openConfirm: false,
      });
      return;
    }

    if (!empresa) {
      enqueueSnackbar('Seleccione la empresa', {
        variant: 'error',
      });
      setState({
        ...state,
        openConfirm: false,
      });
      return;
    }

    if (!state.marcaId) {
      enqueueSnackbar('Seleccione la marca', {
        variant: 'error',
      });
      setState({
        ...state,
        openConfirm: false,
      });
      return;
    }

    if (!state.tipoUnidadId) {
      enqueueSnackbar('Seleccione el tipo de unidad', {
        variant: 'error',
      });
      setState({
        ...state,
        openConfirm: false,
      });
      return;
    }

    if (!state.nroSerie) {
      enqueueSnackbar('El número de serie es obligatorio', {
        variant: 'error',
      });
      setState({
        ...state,
        openConfirm: false,
      });
      return;
    }

    console.log(equipoModal);
    if (!equipoModal || !equipoModal.salidas || equipoModal.salidas.length === 0) {
      enqueueSnackbar('Debe crear al menos una salida', {
        variant: 'error',
      });
      setState({
        ...state,
        openConfirm: false,
      });
      return;
    }

    const rectifier = {
      rectificadorID: location.rectificador ? location.rectificador.rectificadorID : 0,
      rectificadorNombre: state.nombreEquipo,
      locacionID: state.locacionID,
      empresaID: empresa,
      marcaID: state.marcaId,
      unitTypeID: state.tipoUnidadId,
      comunicacionUnitID: state.unidadComunicacionId === 0 ? null : state.unidadComunicacionId,
      numeroRTU: state.numeroRTU,
      agrupacionValorID: state.grupo,
      tipoRectificadorID: state.tipo === 0 ? null : state.tipo,
      zonaHorariaID: state.zonaHoraria,
      latitud: state.position.latitud,
      longitud: state.position.longitud,
      ubicacion: state.ubicacion,
      serialNumber: state.nroSerie,
      posicionAutomatica: !state.manualPosition,
      equipo: equipoModal,
    };
    console.log(rectifier);

    store.dispatch(Actions.Rectifiers.guardarRectificador(rectifier));

    if (!location.rectificador) {
      setState({
        position: {
          latitud: null,
          longitud: null,
        },
        empresaId: 0,
        marcaId: 0,
        tipoUnidadId: 0,
        nroSerie: '',
        unidadComunicacionId: 0,
        numeroLinea: '',
        numeroRTU: 0,
        cantSalidas: 0,
        equipo: 0,
        grupo: [],
        groupName: '',
        tipo: 0,
        zonaHoraria: 0,
        ubicacion: '',
        alertMessage: '',
        nombreEquipo: '',
        openConfirm: false,
        positionDevice: {
          latitud: null,
          longitud: null,
        },
        id: 0,
        manualPosition: true,
        locacionID: 0,
      });

      setEquipoModal({
        salidas: [],
      });
    } else {
      setState({
        ...state,
        alertMessage: '',
        openConfirm: false,
      });
    }
  };

  const handleGoBack = () => {
    switch (location.origin) {
      case 'card':
        history.push('/');
        break;
      case 'moreInfo':
        history.push({
          pathname: '/moreInfo',
          rectificador: location.rectificador,
          t,
        });
        break;
      case 'list':
        history.push('/list');
        break;
      default:
        history.push('/');
        break;
    }
  };

  return (
    <PageWrapper
      history={history}
    >
      <Row>
        <Container maxWidth="xl" className="FilterBarHeader">
          <Row>
            <Grid container>
              <Grid item xs={1} className="gf-arrow-padding">
                <IconButton
                  // className={classes.button}
                  aria-label="left"
                  onClick={handleGoBack}
                  style={{
                    border: '2px solid rgba(0, 0, 0, 0.54)',
                  }}
                >
                  <AwesomeIcon icon="left" />
                </IconButton>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="equipoTxt"
                  label={t('default.equipo', 'Equipo')}
                  className={classes.input}
                  type="text"
                  style={{ marginTop: '0' }}
                  value={state.nombreEquipo}
                  onChange={evt => setState({ ...state, nombreEquipo: evt.target.value })}
                />
              </Grid>
            </Grid>
          </Row>
        </Container>
      </Row>
      <Dialog
        open={state.openConfirm}
        onClose={() => setState({ ...state, openConfirm: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {location.rectificador ? t('default.guardarCambios', '¿Desea guardar los cambios en el equipo?') : t('default.confirmMostrarEquipo', '¿Está seguro de agregar el equipo?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGuardar} color="primary" autoFocus>
            {t('default.si', 'Si')}
          </Button>
          <Button onClick={() => setState({ ...state, openConfirm: false })} color="primary" autoFocus>
            {t('default.no', 'No')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={state.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {state.alertMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Row style={{ marginTop: '100px' }}>
        <Grid container>
          <Grid item className="gf-grid-style">
            <div className="gf-media-float-l" style={{ display: 'contents' }}>
              <Minimap location={state.position} />
            </div>
            <div className="gf-media-float-r">
              <TextField
                id="latitud"
                label={t('default.latitud', 'Latitud')}
                className={classes.input}
                type="number"
                margin="normal"
                disabled={!state.manualPosition}
                value={state.position.latitud || ''}
                InputProps={{
                  readOnly: permisos.findIndex(p => p.opcionCodigo === 'EL') === -1,
                }}
                onChange={evt => setState({ ...state, position: { ...state.position, latitud: evt.target.value } })}
              />
              <TextField
                id="longitud"
                label={t('default.longitud', 'Longitud')}
                className={classes.input}
                type="number"
                margin="normal"
                value={state.position.longitud || ''}
                disabled={!state.manualPosition}
                InputProps={{
                  readOnly: permisos.findIndex(p => p.opcionCodigo === 'EL') === -1,
                }}
                onChange={evt => setState({ ...state, position: { ...state.position, longitud: evt.target.value } })}
              />
              <TextField
                id="ubicacion"
                label={t('default.ubicacion', 'Ubicación')}
                className={classes.input}
                type="text"
                margin="normal"
                value={state.ubicacion}
                InputProps={{
                  readOnly: permisos.findIndex(p => p.opcionCodigo === 'EL') === -1,
                }}
                onChange={evt => setState({ ...state, ubicacion: evt.target.value })}
              />
              <div className={classes.buttonsContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={!state.manualPosition ? classes.button : classes.buttonSecondary}
                  style={{ width: '25%', marginRight: '1%' }}
                  onClick={() => {
                    const { positionDevice } = state;
                    setState(
                      {
                        ...state,
                        position: positionDevice,
                        manualPosition: false,
                      },
                    );
                  }}
                  type="button"
                  InputProps={{
                    readOnly: permisos.findIndex(p => p.opcionCodigo === 'EL') === -1,
                  }}
                >
                  {t('default.automatico', 'Automatico')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={state.manualPosition ? classes.button : classes.buttonSecondary}
                  style={{ width: '25%' }}
                  onClick={() => setState(
                    {
                      ...state,
                      manualPosition: true,
                    },
                  )}
                  type="button"
                  InputProps={{
                    readOnly: permisos.findIndex(p => p.opcionCodigo === 'EL') === -1,
                  }}
                >
                  {t('default.manual', 'Manual')}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item className="gf-media-form">
            {/* <fieldset className="panel" style={{ position: 'relative' }}> */}
            <legend style={{ paddingTop: '20px' }}>
              Datos
            </legend>
            <div className="gf-media-float-l gf-media-width">
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="empresaId">{t('default.empresa', 'Empresa')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="empresaId"
                  value={user.esAdministrador ? state.empresaId : user.empresaID}
                  variant="standard"
                  inputProps={{
                    readOnly: !user.esAdministrador,
                  }}
                  onChange={handleChangeEmpresa}
                >
                  {empresas.map(e => (
                    <MenuItem key={e.empresaID} value={e.empresaID}>{e.nombre}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="marcaId">{t('default.marca', 'Marca')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="marcaId"
                  value={state.marcaId}
                  variant="standard"
                  inputProps={{
                    readOnly: permisos.findIndex(p => p.opcionCodigo === 'EMTUS') === -1,
                  }}
                  onChange={handleChangeMarca}
                >
                  {marcas.map(m => (
                    <MenuItem key={m.marcaID} value={m.marcaID}>{m.nombre}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="tipoUnidadId">{t('default.tipoUnidad', 'Tipo de Unidad')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="tipoUnidadId"
                  value={state.tipoUnidadId}
                  variant="standard"
                  inputProps={{
                    readOnly: permisos.findIndex(p => p.opcionCodigo === 'EMTUS') === -1,
                  }}
                  onChange={handleChangeTipoUnidad}
                >
                  {tiposUnidad.map(tu => (
                    <MenuItem key={tu.tipoUnidadMonitoreoID} value={tu.tipoUnidadMonitoreoID}>{tu.tipoUnidadMonitoreo}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="nroSerie"
                label={t('default.nroSerie', 'Número de serie')}
                className={classes.input}
                type="text"
                margin="normal"
                value={state.nroSerie}
                InputProps={{
                  readOnly: permisos.findIndex(p => p.opcionCodigo === 'EMTUS') === -1,
                }}
                onChange={evt => setState({ ...state, nroSerie: evt.target.value })}
              />
              <FormControl className={classes.formControl} disabled={permisos.findIndex(p => p.opcionCodigo === 'UMLRTU') === -1}>
                <Row>
                  <Col xs={11}>
                    <InputLabel htmlFor="unidadComunicacionId" style={{ paddingLeft: '17px' }}>{t('default.unidadComunicacion', 'Unidad de comunicación')}</InputLabel>
                    <Select
                      className="basic-multi-select"
                      name="unidadComunicacionId"
                      value={state.unidadComunicacionId}
                      variant="standard"
                      onChange={handleChangeUC}
                      style={{ width: '100%' }}
                    >
                      <MenuItem key={0} value={0} />
                      {unidadesComunicacion.filter(x => x.disponible || (state.unidadComunicacionId === x.unidadComunicacionID)).map(uc => (
                        <MenuItem key={uc.unidadComunicacionID} value={uc.unidadComunicacionID}>{uc.descripcion}</MenuItem>
                      ))}
                    </Select>
                  </Col>
                  <Col xs={1}>
                    {permisos.findIndex(p => p.opcionCodigo === 'UMLRTU') > -1 && (
                      <ModalUnidadComunicacion user={user} empresas={empresas} marcas={marcas} unidadesComunicacion={unidadesComunicacion} ucDB={state.unidadComunicacionId} />
                    )}
                  </Col>
                </Row>
              </FormControl>
              <FormControl className={classes.formControl} style={{ margin: 0 }}>
                <Row>
                  <Col xs={11}>
                    <TextField
                      id="numeroLinea"
                      label={t('default.numeroLinea', 'Número de linea')}
                      className={classes.input}
                      style={{ width: '100%' }}
                      type="text"
                      margin="normal"
                      value={state.numeroLinea}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Col>
                  <Col xs={1}>
                    {permisos.findIndex(p => p.opcionCodigo === 'UMLRTU') > -1 && (
                      <ModalLineas empresas={empresas} lineaDB={state.lineaID} />
                    )}
                  </Col>
                </Row>
              </FormControl>
            </div>
            <div className="gf-media-float-r gf-media-width">
              <TextField
                id="numeroRTU"
                label={t('default.numeroRTU', 'Número de RTU')}
                className={classes.input}
                style={{ marginTop: '0' }}
                type="number"
                margin="normal"
                value={state.numeroRTU || ''}
                InputProps={{
                  readOnly: permisos.findIndex(p => p.opcionCodigo === 'UMLRTU') === -1,
                }}
                onChange={evt => setState({ ...state, numeroRTU: evt.target.value })}
              />
              <TextField
                id="cantSalidas"
                label={t('default.cantSalidas', 'Cantidad de salidas')}
                className={classes.input}
                type="number"
                margin="normal"
                value={equipoModal.salidas.length}
                InputProps={{
                  readOnly: true,
                }}
              />
              <ModalConfigureOutputs equipoDB={equipoModal} saveCallback={callbackModalEquipo} t={t} />
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="tipoId">{t('default.grupo', 'Grupo')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="grupo"
                  value={state.grupo}
                  variant="standard"
                  onChange={handleChangeGrupo}
                  multiple
                  input={<Input />}
                  renderValue={selected => grupos.filter(g => selected.includes(g.grupoId)).map(g => g.nombre).join(', ')}
                  inputProps={{
                    name: 'grupo',
                    id: 'grupoId',
                  }}
                >
                  {grupos.map(v => (
                    <MenuItem key={v.grupoId} value={v.grupoId}>
                      <Checkbox checked={state.grupo.indexOf(v.grupoId) > -1} />
                      <ListItemText primary={v.nombre} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="tipoId">{t('default.tipo', 'Tipo')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="tipo"
                  value={state.tipo}
                  variant="standard"
                  onChange={handleChangeTipo}
                  inputProps={{
                    name: 'tipo',
                    id: 'tipoId',
                  }}
                >
                  <MenuItem key={0} value={0} />
                  {tiposRectificador.map(v => (
                    <MenuItem key={v.tipoRectificadorId} value={v.tipoRectificadorId}>{v.nombre}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="zonaHorariaId">{t('default.zonaHoraria', 'Zona horaria')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="zonaHoraria"
                  value={state.zonaHoraria}
                  variant="standard"
                  onChange={handleChangeZonaHoraria}
                  inputProps={{
                    name: 'zonaHoraria',
                    id: 'zonaHorariaId',
                  }}
                >
                  {zonasHoraria.map(v => (
                    <MenuItem key={v.zonaHorariaId} value={v.zonaHorariaId}>{`${v.descripcion} - ${v.gmt}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* <div style={{ position: 'absolute', bottom: '20px', right: '20px' }}> */}
            {/* <div style={{ justifyContent: 'flex-end', display: 'flex', alignContent: 'flex-end' }}> */}
            <div className="clearfix" />
            <div style={{ width: '10%', margin: '50px auto' }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                // style={{ float: 'right', marginRight: '10%' }}
                onClick={() => setState({ ...state, openConfirm: true })}
                type="submit"
              >
                {t('default.guardar', 'Guardar')}
              </Button>
            </div>
            {/* </fieldset> */}
          </Grid>
        </Grid>
      </Row>
    </PageWrapper>
  );
};

NewDevice.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({
    t: props.t,
    enqueueSnackbar: props.enqueueSnackbar,
  })),
  lifecycle({
    componentDidMount() {
      const { rectificador } = this.props.location;
      if (rectificador) {
        store.dispatch(Actions.Rectifiers.getRectificadorById(rectificador.rectificadorID));
      } else {
        // store.dispatch(Actions.Rectifiers.getAvailableDevices());
      }
      store.dispatch(Actions.Rectifiers.getZonaHorarias());
      store.dispatch(Actions.Rectifiers.getTiposRectificador());
      store.dispatch(Actions.Maestros.getEmpresasAll());
      store.dispatch(Actions.Maestros.getMarcasAll());
      store.dispatch(Actions.Maestros.getTipoUnidadAll());
      store.dispatch(Actions.Maestros.getUCDisponibles());
    },
  }),
  connect(state => ({
    listaRectificadores: state.Rectifiers.availableDevices,
    grupos: state.Groups.docs,
    zonasHoraria: state.Rectifiers.zonasHoraria,
    tiposRectificador: state.Rectifiers.tiposRectificador,
    permisos: state.Users.userData.permisos,
    user: state.Users.userData,
    empresas: state.Maestros.empresas,
    marcas: state.Maestros.marcas,
    tiposUnidad: state.Maestros.tipoUnidad,
    unidadesComunicacion: state.Maestros.unidadesComunicacion,
  })),
)(NewDevice);

export default withSnackbar(translate('common')(AppCompose));
