const initialState = {
  Variables: [],
  VariablesMultipleSalidas: [],
  VariablesOperacion: [],
};

const reducer = (state = initialState, action) => {
  // let index = 0;
  switch (action.type) {
    case 'TABLEROS_SET_VARIABLES':
      return {
        ...state,
        Variables: action.payload.data,
      };
    case 'SET_VARIABLEMULTIPLESALIDA':
      return {
        ...state,
        VariablesMultipleSalidas: action.payload.data,
      };
    case 'SET_VARIABLESOPERACION':
      return {
        ...state,
        VariablesOperacion: action.payload.data,
      };
    case 'TABLEROS_UPDATE_VARIABLES':
      // index = state.Variables.findIndex(r => r.rectificadorID === action.payload.data.rectificadorID);

      if (!state.Variables || state.Variables.length === 0) {
        return {
          ...state.Variables,
        };
      }

      // eslint-disable-next-line no-case-declarations
      let newData = [];

      action.payload.data.salidas.forEach((s) => {
        const row = [];
        s.valores.forEach((v) => {
          row.push({
            rectificadorID: action.payload.data.rectificadorID,
            reporteID: action.payload.data.reporteID,
            rectificador: action.payload.data.nombre,
            fechaRecepcion: action.payload.data.fecha,
            reporteInformeID: v.reporteValorID,
            salidaNro: s.salidaID,
            configuracionReporteID: v.configuracionReporteID,
            nombre: v.nombreEs,
            nombreIngles: v.nombreEn,
            valor: v.valor,
            unidadMedida: v.unidadMedida,
            esAnomalo: false,
            seccion: 1,
          });
        });
        newData = newData.concat(row);
      });

      newData = newData.concat(state.Variables);

      return {
        ...state,
        Variables: newData,
      };
    default:
      return state;
  }
};

export default reducer;
