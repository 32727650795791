export const PROCESS_HISTORICO = 'PROCESS_HISTORICO';
export const PROCESS_ACTUAL = 'PROCESS_ACTUAL';

export const sendReporteHistorico = (rectificadoresId, language, fechaDesde, fechaHasta, emails, userId) => ({
  type: PROCESS_HISTORICO,
  payload: {
    rectificadoresId,
    language,
    fechaDesde,
    fechaHasta,
    emails,
    userId,
  },
});

export const sendReporteActual = reportes => ({
  type: 'PROCESS_ACTUAL',
  payload: reportes,
});

export default {
  sendReporteHistorico,
  sendReporteActual,
};
