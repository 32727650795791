import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import Actions from '../actions';

const { Universal } = Actions;

/** @description Llamada al EndPoint para validar acceso de usuario.
 * URL: Account/Login
*/
export const userLogin = apiClient => function* userLoginSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Account/Login',
      method: 'post',
      data: action.payload,
    };
    yield put(Actions.Users.resetError());
    yield put({ type: 'FETCH_MODULE', process: action.process || true });
    const response = yield call(apiClient, params);
    yield put({ type: 'FETCH_REQUEST_END' });

    yield put(Actions.Users.setData({
      usuarioId: response.data.usuarioId,
      userName: response.data.userName,
      nombre: response.data.nombre,
      apellido: response.data.apellido,
      legajo: response.data.legajo,
      dni: response.data.dni,
      idiomaId: response.data.idiomaId,
      idioma: response.data.idioma,
      vistaPreferidaId: response.data.vistaPreferidaId,
      vistaPreferida: response.data.vistaPreferida,
      mediosContacto: response.data.mediosContacto,
      entidadId: response.data.entidadId,
      mostrarBotonCrearEquipo: response.data.mostrarBotonCrearEquipo,
      profilePicture: response.data.profilePicture,
      permisos: response.data.permisos,
      esAdministrador: response.data.esAdministrador,
      empresaID: response.data.empresaID,
      zonaHoraria: response.data.zonaHorariaID,
    }));
    yield put(Actions.Users.setAuthToken(response.data.token));
    yield put({ type: 'INIT_APP_STATE' });
  } catch (error) {
    yield put({ type: 'USER_ERROR_LOGIN', payload: error });
    yield put({ type: 'FETCH_REQUEST_END' });
  }
};

/** @description Llamada al EndPoint para actualizar datos de usuario.
 * URL: api/Account/update
*/
export const fetchUpdateUser = apiClient => function* fetchUpdateUserSaga(action) {
  try {
    const state = yield select();
    if (!state.Users.token) return;
    const params = {
      query: {},
      microservice: '',
      module: 'api/Account/update',
      method: 'get',
      data: action.payload,
      token: state.Users.token,
    };

    yield put({ type: 'FETCH_MODULE', process: action.process || true });
    const response = yield call(apiClient, params);
    yield put({ type: 'FETCH_REQUEST_END' });
    if (response.status === 200) {
      yield put(Actions.Users.setData({ ...response.data, ...action.payload }));
    } else {
      yield put({ type: 'ERROR_UPDATEUSER' });
    }
    if (action.cb) yield call(action.cb);
  } catch (error) {
    yield put({ type: 'FETCH_REQUEST_END' });
    yield put({ type: 'USER_ERROR_LOGIN', payload: error });
  }
};

/** @description Llamada al EndPoint para guardar preferencias de usuario.
 * URL: Usuario/SaveUserPreference
*/
export const saveUserPreference = apiClient => function* saveUserPreferenceSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Usuario/SaveUserPreference',
      method: 'post',
      data: action.payload,
      process: true,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Users.setPreferences(action.payload));
    }

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para guardar datos de usuario.
 * URL: Usuario/SaveUserData
*/
export const saveUserData = apiClient => function* saveUserDataSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Usuario/SaveUserData',
      method: 'put',
      data: action.payload,
      process: true,
    };

    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Users.setSaveUserData(action.payload));
    } else {
      console.log(response);
    }

    return true;
  } catch (err) {
    if (err.response.data === 'PasswordMismatch') {
      yield put({ type: 'CUSTOMERROR_UPDATEUSER', payload: err.response.data });
    }

    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para guardar configuracion de proxy.
 * URL: Usuario/SaveProxySettings
*/
export const saveProxySettings = apiClient => function* saveProxySettingsSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Usuario/SaveProxySettings',
      method: 'post',
      data: action.payload,
      process: true,
    };
    yield call(apiClient, params);

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para cerrar sesion de usuario
*/
export const userLogout = () => function* userLogoutSaga(action) {
  try {
    window.sessionStorage.removeItem('userToken');
    window.localStorage.removeItem('userData');
    yield put({ type: 'USER_CLEAN_DATA' });
    yield put({ type: 'CHECKOUT_FLUSH' });
    setTimeout(() => {
      window.location = '/';
    }, 10);
  } catch (error) {
    yield put(Universal.requestError(action.module, {
      payload: { ...action.payload, error },
    }));
    throw error;
  }
};
