import React, {
  Fragment,
  useEffect,
} from 'react';

import {
  IconButton,
} from '@material-ui/core';

import { connect } from 'react-redux';
import { render } from 'react-dom';

// import { Link } from 'react-router-dom';

import {
  Map,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
  Tooltip,
} from 'react-leaflet';

import { translate } from 'react-i18next';

import L from 'leaflet';
// import MarkerClusterGroup from 'react-leaflet-markercluster';
import AwesomeIcon from '../AwesomeIcon';
import ModalMap from '../ModalMap';
// import CardViewItem from '../CardViewItem';

import { composePure, shouldUpdate, withProps } from '../../utils/composepure';
import propTypes from './props';

const defaultPosition = [-34.61315, -58.37723];
const greenPng = require('../../assets/images/iconGreen.svg');
const redPng = require('../../assets/images/iconRed.svg');

console.info('control: ', ZoomControl);

/** @description Componente generico para mostrar un mapa con datos pasados por parametro.
 * Acepta la inclusion de marcadores.
 */
const MapComponent = ({
  markers,
  history,
  language,
  t,
  userData,
}) => {
  const bounds = L.latLngBounds([defaultPosition]);
  useEffect(() => {
    console.info('useEffect called');
    const el = document.createElement('div');

    el.id = 'map-container';
    document.body.appendChild(el);
    el.style.width = '100%';
    el.style.height = '100%';
    el.style.position = 'fixed';

    const validaMarkers = markers.filter(x => x.longitud && x.latitud && x.longitud > -180 && x.longitud < 180 && x.latitud > -90 && x.latitud < 90);

    const markerComponents = validaMarkers.map((m) => {
      const iconGreen = new L.Icon({
        iconUrl: greenPng,
        iconRetinaUrl: greenPng,
        iconAnchor: [20, 40],
        popupAnchor: [0, -35],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(48, 48),
        // className: 'leaflet-div-icon',
      });

      const iconRed = new L.Icon({
        iconUrl: redPng,
        iconRetinaUrl: redPng,
        iconAnchor: [20, 40],
        popupAnchor: [0, -35],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(48, 48),
        // className: 'leaflet-div-icon',
      });

      bounds.extend([m.latitud, m.longitud]);
      return (
        <Marker
          position={[m.latitud, m.longitud]}
          key={m.uniqueId}
          icon={m.inAlert ? iconRed : iconGreen}
        >
          <Popup>
            <ModalMap key={m.uniqueId} t={t} rectify={m} language={language} history={history} />
            {/* <CardViewItem t={t} rectify={m} language={language} /> */}
          </Popup>
          <Tooltip direction="right" className="tooltipMap" permanent>
            {m.nombre}
          </Tooltip>
        </Marker>
      );
    });

    if (bounds.length === 1) {
      bounds.extend(defaultPosition);
    }

    const centerPosition = bounds.getCenter();

    /* const notLocation = (
      <h3 style={{ top: '40%', left: '40%', position: 'relative' }}>
        {
            markers.length > 0 && (
              `
              ${markers[0].nombre}
              sin ubicación
              `
            )
          }
      </h3>
    ); */

    const emptyMap = (
      <Map
        center={defaultPosition}
        zoom={6}
        dragging
        keyboard={false}
        scrollWheelZoom
        tap={false}
        whenReady={() => {
          console.log('Posicion: ', defaultPosition);
          console.info('mapa vacio cargado');
        }}
        minZoom={1.5}
        zoomControl={false}
      >
        <ZoomControl position="bottomright" />
        <TileLayer
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
      </Map>
    );

    const map = (
      <Fragment>
        { userData.permisos.findIndex(x => x.opcionCodigo === 'CRECT') > -1 && (
          <IconButton
            aria-label="left"
            style={{
              position: 'fixed',
              bottom: '10px',
              right: '10px',
              backgroundColor: '#007bff',
              color: '#fff',
              zIndex: '999999',
              display: userData.mostrarBotonCrearEquipo ? 'inline-block' : 'none',
            }}
            onClick={() => history.push('/newDevice')}
          >
            <AwesomeIcon icon="plus" />
          </IconButton>
        )}
        <Map
          bounds={bounds}
          center={centerPosition}
          dragging
          keyboard={false}
          scrollWheelZoom
          tap={false}
          whenReady={() => {
            console.log('Bounds: ', bounds);
            console.log('Center Position: ', centerPosition);
            console.info('mapa cargado');
          }}
          minZoom={2}
          zoomControl={false}
        >
          <ZoomControl position="bottomright" />
          <TileLayer
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          />
          {markerComponents}
        </Map>
      </Fragment>
    );

    const mapToRender = validaMarkers.length === 0 ? emptyMap : map;
    render(mapToRender, document.getElementById('map-container'));
    // render(markers.length === 1 && (!markers[0].latitud || !markers[0].longitud) ? notLocation : mapToRender, document.getElementById('map-container'));

    return () => {
      console.info('useEffect unmount called');
      document.body.removeChild(document.getElementById('map-container'));
    };
  });

  return (<Fragment />);
};

MapComponent.propTypes = propTypes;

export default translate('common')(composePure(
  withProps(props => ({ t: props.t })),
  connect(state => ({
    language: state.Language.current,
    userData: state.Users.userData,
  })),
  shouldUpdate((oldProps, newProps) => JSON.stringify(oldProps) !== JSON.stringify(newProps)),
)(MapComponent));
