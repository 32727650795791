import {
  put,
  select,
  call,
} from 'redux-saga/effects';

import Actions from '../actions';

/** @description Llamada al EndPoint para obtener comandos enviados.
 * URL: Comando/ComandosEnviadosByUser/${state.Users.userData.usuarioId}
*/
export const getComandosEnviados = requestModule => function* getComandosEnviadosSaga() {
  try {
    const state = yield select();
    const query = {};
    yield requestModule({
      dispatchAction: 'VISTACUADROS_SET_COMANDOS',
      module: `Comando/ComandosEnviadosByUser/${state.Users.userData.usuarioId}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener alarmas.
 * URL: Alarma/ByUser/${state.Users.userData.usuarioId}
*/
export const getAlarmas = requestModule => function* getAlarmassSaga() {
  try {
    const state = yield select();
    const query = {};
    yield requestModule({
      dispatchAction: 'VISTACUADROS_SET_ALARMAS',
      module: `Alarma/ByUser/${state.Users.userData.usuarioId}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener novedades.
 * URL: Novedad/ByUser/${state.Users.userData.usuarioId}
*/
export const getNovedades = requestModule => function* getNovedadesSaga() {
  try {
    const state = yield select();
    const query = {};
    yield requestModule({
      dispatchAction: 'VISTACUADROS_SET_NOVEDADES',
      module: `Novedad/ByUser/${state.Users.userData.usuarioId}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener novedades segun dispositivo.
 * URL: Novedad/ByRectificador/${action.payload.rectificadorID}
*/
export const getNovedadesByDevice = requestModule => function* getNovedadesByDeviceSaga(action) {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'VISTACUADROS_SET_NOVEDADES',
      module: `Novedad/ByRectificador/${action.payload.rectificadorID}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener configuracion de alarmas.
 * URL: Alarma/GetConfAlarmaByRectificador/${action.payload.rectificadorID}
*/
export const getConfAlarmas = requestModule => function* getConfAlarmasSaga(action) {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_CONFIGURACIONALARMAS',
      module: `Alarma/GetConfAlarmaByRectificador/${action.payload.rectificadorID}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para guardar configuracion de alarma.
 * URL: Alarma/ConfAlarmaInsert
*/
export const saveConfAlarma = apiClient => function* saveConfAlarmaSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Alarma/ConfAlarmaInsert',
      method: 'post',
      data: action.payload.confAlarma,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Cuadros.addConfAlarma(response.data));
    }

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para actualizar configuracion de alarma.
 * URL: Alarma/ConfAlarmaUpdate
*/
export const updateConfAlarma = apiClient => function* updateConfAlarmaSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Alarma/ConfAlarmaUpdate',
      method: 'put',
      data: action.payload,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Cuadros.setConfAlarma(action.payload));
    }

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para eliminar configuracion de alarma.
 * URL: Alarma/ConfAlarmaDelete/${action.payload.configuracionAlarmaID}
*/
export const deleteConfAlarma = apiClient => function* deleteConfAlarmaSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: `Alarma/ConfAlarmaDelete/${action.payload.configuracionAlarmaID}`,
      method: 'put',
      // data: action.payload.configuracionAlarmaID,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Cuadros.removeConfAlarma(action.payload.configuracionAlarmaID));
    }

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para agregar notas a los comandos.
 * URL: Comando/AddNote
*/
export const addNotaComando = apiClient => function* addNotaComandoSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Comando/AddNote',
      method: 'post',
      data: action.payload,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Cuadros.updateNotaComando(action.payload));
    }

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para remover novedades.
 * URL: Novedad/Delete/${action.payload.novedadId}
*/
export const removeNovedad = apiClient => function* removeNovedadSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: `Novedad/Delete/${action.payload.novedadId}`,
      method: 'delete',
      // data: action.payload.novedadId,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Cuadros.setRemoveNovedad(action.payload.novedadId));
    }

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para agregar nota a alarmas.
 * URL: Alarma/AddNote
*/
export const addNotaAlarma = apiClient => function* addNotaAlarmaSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Alarma/AddNote',
      method: 'post',
      data: action.payload,
    };
    // const response = yield call(apiClient, params);
    // if (response.status === 200) {
    //   yield put(Actions.Cuadros.setComando(action.payload));
    // }

    yield call(apiClient, params);

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para actualizar una novedad.
 * URL: Novedad/UpdateNovedad
*/
export const updateNovedad = apiClient => function* updateNovedadSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Novedad/UpdateNovedad',
      method: 'post',
      data: action.payload,
    };
    /* const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Cuadros.setNovedad(action.payload));
    } */

    yield call(apiClient, params);

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para guardar una novedad.
 * URL: Novedad/Create
*/
export const saveNovedad = apiClient => function* saveNovedadSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Novedad/Create',
      method: 'post',
      data: action.payload,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Cuadros.addNovedad(response.data));
    }

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export default {

};
