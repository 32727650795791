import React from 'react';
// import { connect } from 'react-redux';

import {
  Grid,
} from '@material-ui/core';
// import { translate, Trans } from 'react-i18next';
import FilterAlert from '../FilterAlert';
// import { composePure } from '../../utils/composepure';

import propTypes from './props';

/** @description pagina secundaria accedida desde mas Dispositivos.
 * Muestra detalle de alertas producidas segun dispositivo seleccionado.
 */
const RectifiersAlertList = ({
  alertas,
  uniqueId,
  language,
  isOutput,
  t,
}) => (
  <div>
    {alertas.length > 0 && (
      <ul className={isOutput ? 'OutputFilterAlert' : 'CardFilterAlert'}>
        {alertas.map(alerta => (
          <FilterAlert
            on={alerta.on}
            rectificadorID={alerta.rectificadorID}
            alertaID={alerta.alertaID}
            fechaInicioAlarma={alerta.fecheAlarmaInicio}
            nombre={language === 'EN' ? alerta.nombreEN : alerta.nombreES}
            key={`${uniqueId}-${alerta.alertaID}`}
            salidaID={alerta.salidaID}
            t={t}
          />
        ))}
      </ul>
    )}
    {alertas.length === 0 && (
      <Grid item xs={12} className="mt-2 mb-2 x-center">
        <span>{t('default.noAlerts', 'No se han encontrado Alertas')}</span>
      </Grid>
    )}
  </div>
);

RectifiersAlertList.propTypes = propTypes;
/* const AppCompose = composePure(
  connect(state => ({
    language: state.Language.current,
  })),
)(RectifiersAlertList); */

// export default translate('common')(React.memo(AppCompose));

export default React.memo(RectifiersAlertList);
