export const TABLEROS_FETCH_VARIABLES = 'TABLEROS_FETCH_VARIABLES';
export const TABLEROS_UPDATE_VARIABLES = 'TABLEROS_UPDATE_VARIABLES';
export const FETCH_VARIABLEMULTIPLESALIDA = 'FETCH_VARIABLEMULTIPLESALIDA';
export const FETCH_VARIABLESOPERACION = 'FETCH_VARIABLESOPERACION';

export const getVariablesByRectificador = (rectificadorID, esTendencia) => ({
  type: TABLEROS_FETCH_VARIABLES,
  payload: {
    rectificadorID,
    esTendencia,
  },
});

export const getVariableByRectAndConfReporte = (rectificadorID, configuracionReporteID, fechaDesde, fechaHasta) => ({
  type: FETCH_VARIABLEMULTIPLESALIDA,
  payload: {
    rectificadorID,
    configuracionReporteID,
    fechaDesde,
    fechaHasta,
  },
});

export const getVariablesOperacion = (rectificador, salidaA, variableA, salidaB, variableB) => ({
  type: FETCH_VARIABLESOPERACION,
  payload: {
    rectificador,
    salidaA,
    variableA,
    salidaB,
    variableB,
  },
});

export default {
  getVariablesByRectificador,
  getVariableByRectAndConfReporte,
  getVariablesOperacion,
};
