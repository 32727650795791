import {
  put,
} from 'redux-saga/effects';

/** @description Llamada al EndPoint para obtener variables segun rectificador.
 * URL: Rectificador/GetVariablesByRectificador/${rectificadorID}/${esTendencia || false}
*/
export const getVariablesByRectificador = requestModule => function* getDisponibilidadSaga(action) {
  try {
    const { rectificadorID, esTendencia } = action.payload;
    const query = {};
    yield requestModule({
      dispatchAction: 'TABLEROS_SET_VARIABLES',
      module: `Rectificador/GetVariablesByRectificador/${rectificadorID}/${esTendencia || false}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener variable segun configuracion del reporte.
 * URL: Rectificador/GetVariableByRectAndConfReporte
*/
export const getVariableByRectAndConfReporte = requestModule => function* getVariableByRectAndConfReporteSaga(action) {
  try {
    /* const {
      rectificadorID,
      configuracionReporteID,
      fechaDesde,
      fechaHasta,
    } = action.payload; */
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_VARIABLEMULTIPLESALIDA',
      // module: `Rectificador/GetVariableByRectAndConfReporte/${rectificadorID}/${configuracionReporteID}/${fechaDesde}/${fechaHasta}`,
      module: 'Rectificador/GetVariableByRectAndConfReporte',
      method: 'post',
      data: action.payload,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener variables para operaciones.
 * URL: Rectificador/GetOperacionVariableByRectificador/${rectificador}/${salidaA}/${variableA}/${salidaB}/${variableB}
*/
export const getVariablesOperacion = requestModule => function* getDisponibilidadSaga(action) {
  try {
    const {
      rectificador,
      salidaA,
      variableA,
      salidaB,
      variableB,
    } = action.payload;
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_VARIABLESOPERACION',
      module: `Rectificador/GetOperacionVariableByRectificador/${rectificador}/${salidaA}/${variableA}/${salidaB}/${variableB}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export default {

};
