import React from 'react';

import {
  Map,
  Marker,
  // Popup,
  TileLayer,
  // Tooltip,
} from 'react-leaflet';

// import L from 'leaflet';

// import { render } from 'react-dom';

// import { composePure, shouldUpdate } from '../../utils/composepure';
import propTypes from './props';

/** @description Componente de mapa reducido para incluir una posicion pasada por parametro.
 */
const Minimap = ({
  location,
}) => {
  const position = [location.latitud, location.longitud];
  const defaultCenterPosition = [-34.61315, -58.37723];
  const isValidPosition = location && location.longitud && location.latitud && location.longitud > -180 && location.longitud < 180 && location.latitud > -90 && location.latitud < 90;
  return (
    <Map center={isValidPosition ? position : defaultCenterPosition} zoom={13}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {isValidPosition && (
        <Marker position={position}>
          {/* <Popup>
            A pretty CSS3 popup.
            <br />
            Easily customizable.
          </Popup> */}
        </Marker>
      )}
    </Map>
  );
};

Minimap.propTypes = propTypes;

export default Minimap;

/* export default composePure(
  shouldUpdate((oldProps, newProps) => JSON.stringify(oldProps) !== JSON.stringify(newProps)),
)(Minimap); */
