export const CUADROS_FETCH_COMANDOS = 'CUADROS_FETCH_COMANDOS';
export const CUADROS_FETCH_ALARMAS = 'CUADROS_FETCH_ALARMAS';
export const CUADROS_FETCH_NOVEDADES = 'CUADROS_FETCH_NOVEDADES';
export const CUADROS_FETCH_NOTIFICACIONES = 'CUADROS_FETCH_NOTIFICACIONES';
export const CUADROS_ADDNOTA_COMANDOS = 'CUADROS_ADDNOTA_COMANDOS';
export const CUADROS_ADDNOTA_ALARMAS = 'CUADROS_ADDNOTA_ALARMAS';
export const VISTACUADROS_SET_COMANDOS = 'VISTACUADROS_SET_COMANDOS';
export const VISTACUADROS_SET_ALARMAS = 'VISTACUADROS_SET_ALARMAS';
export const CUADROS_UPDATE_NOVEDAD = 'CUADROS_UPDATE_NOVEDAD';
export const VISTACUADROS_SET_NOVEDADES = 'VISTACUADROS_SET_NOVEDADES';
export const VISTACUADROS_SET_NOTIFICACIONES = 'VISTACUADROS_SET_NOTIFICACIONES';
export const VISTACUADROS_UPDATE_COMANDO = 'VISTACUADROS_UPDATE_COMANDO';
export const FETCH_CONFIGURACIONALARMAS = 'FETCH_CONFIGURACIONALARMAS';
export const SET_CONFIGURACIONALARMAS = 'SET_CONFIGURACIONALARMAS';
export const SAVE_CONFIGURACIONALARMA = 'SAVE_CONFIGURACIONALARMA';
export const ADD_CONFIGURACIONALARMA = 'ADD_CONFIGURACIONALARMA';
export const UPDATE_CONFIGURACIONALARMA = 'UPDATE_CONFIGURACIONALARMA';
export const SET_CONFIGURACIONALARMA = 'SET_CONFIGURACIONALARMA';
export const DELETE_CONFIGURACIONALARMA = 'DELETE_CONFIGURACIONALARMA';
export const REMOVE_CONFIGURACIONALARMA = 'REMOVE_CONFIGURACIONALARMA';
export const FETCHNOVEDADES_BYDEVICE = 'FETCHNOVEDADES_BYDEVICE';
export const SAVE_NOVEDAD = 'SAVE_NOVEDAD';
export const ADD_NOVEDAD = 'ADD_NOVEDAD';
export const REMOVE_NOVEDAD = 'REMOVE_NOVEDAD';
export const SET_REMOVE_NOVEDAD = 'SET_REMOVE_NOVEDAD';
