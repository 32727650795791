/* const configDev = {
  // ENDPOINT_URL: 'http://telemetriasignalr.smartmonitor.com.ar:8108/api',
  ENDPOINT_URL: 'http://localhost:19842/api',
  API_KEY: '',
  UPLOAD_URL: 'http://200.80.220.11:8092/api/upload',
  SIGNALR_HUB: 'http://localhost:19842/Card',
  // SIGNALR_HUB: 'http://telemetriasignalr.smartmonitor.com.ar:8108/Card',
};

const configProd = {
  ENDPOINT_URL: 'http://telemetriasignalr.smartmonitor.com.ar:8108/api',
  // ENDPOINT_URL: 'http://localhost:8092/api',
  API_KEY: '',
  UPLOAD_URL: 'http://200.80.220.11:8092/api/upload',
  // SIGNALR_HUB: 'http://localhost:8092/Card',
  SIGNALR_HUB: 'http://telemetriasignalr.smartmonitor.com.ar:8108/Card',
}; */

const config = {
  ENDPOINT_URL: process.env.REACT_APP_ENDPOINT_URL,
  API_KEY: '',
  UPLOAD_URL: process.env.REACT_APP_UPLOAD_URL,
  SIGNALR_HUB: process.env.REACT_APP_SIGNALR_HUB,
};

// export default (process.env.NODE_ENV === 'development' ? configDev : configProd);

export default config;
