import React from 'react';
import { Grid } from '@material-ui/core';
import Image from '../Image';
import MainHeaderNav from '../MainHeaderNav';
import Props from './props';
import NotificationButton from '../NotificationButton';
import AccountButtonIcon from '../AccountButtonIcon';

/** @description Componente contenedor del menu cabecero de la WEB.
 */
const MainHeader = ({
  history,
}) => (
  <Grid container className="MainHeader">
    <Grid item xs={4} className="y-center gf-logo-smaller">
      <Image src="logo" fluid />
    </Grid>
    <Grid item xs={4} className="y-center gf-menu-smaller">
      <MainHeaderNav
        history={history}
      />
    </Grid>
    <Grid item xs={4} className="y-center x-end gf-notification-smaller">
      <Grid item className="x-center y-center gf-width">
        <NotificationButton />
      </Grid>
      <Grid item className="x-center y-center gf-width">
        <AccountButtonIcon />
      </Grid>
    </Grid>
  </Grid>
);

MainHeader.propTypes = Props;
export default MainHeader;
