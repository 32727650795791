import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
  IconButton,
  makeStyles,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';

import {
  Row,
  Col,
} from 'react-bootstrap';

import { connect } from 'react-redux';

import { withSnackbar } from 'notistack';
import { translate } from 'react-i18next';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import AwesomeIcon from '../AwesomeIcon';
import theme from '../../scss/theme/colors';

import store from '../../store';
import Actions from '../../actions';

import { composePure, withProps, lifecycle } from '../../utils/composepure';

import Props from './props';

const useStyles = makeStyles(th => ({
  button: {
    color: theme.blue.darker,
    marginTop: '10px',
  },
  iconButton: {
    padding: '0 5px 0 5px',
  },
  buttonModal: {
    backgroundColor: '#0083C7 !important',
  },
  formControl: {
    margin: th.spacing(1),
    minWidth: 120,
    width: '100%',
    marginTop: '16px',
  },
  FormGroup: {
    flexDirection: 'column',
  },
  FormControlLabel: {
    margin: 0,
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: '70vh',
  },
  textField: {
    margin: th.spacing(1),
    width: '100%',
    minWidth: '300px',
  },
  headerGrid: {
    background: 'black',
    color: 'white',
  },
}));

const emptyUC = {
  unidadComunicacionID: -1,
  empresaId: 0,
  marcaId: 0,
  tipoComunicacionId: 0,
  nroSerie: '',
  nit: '',
  direccionIP: '',
  deviceID: '',
  lineaID: 0,
};

const ModalUnidadComunicacion = ({
  t,
  enqueueSnackbar,
  empresas,
  marcas,
  tiposComunicacion = [],
  lineas = [],
  unidadesComunicacion,
  resultSaveUC,
  ucDB,
}) => {
  useEffect(() => {
    if (resultSaveUC === 'ok') {
      enqueueSnackbar(t('default.successSaveUC', 'La unidad de comunicación se guardó correctamente'), {
        variant: 'success',
      });
    }

    if (resultSaveUC === 'error') {
      enqueueSnackbar(t('default.errorSaveUC', 'Ocurrió un error al guardar la unidad de comunicación'), {
        variant: 'error',
      });
    }

    return () => {
      store.dispatch({ type: 'RESET_RESULTSAVEUC' });
    };
  }, [resultSaveUC]);

  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [ucDelete, setUCDelete] = useState();
  const [state, setState] = useState(emptyUC);

  const handleEditUC = (id) => {
    const ucSelected = unidadesComunicacion.filter(uc => uc.unidadComunicacionID === id)[0];
    console.log(ucSelected);
    setState({
      unidadComunicacionID: ucSelected.unidadComunicacionID,
      empresaId: ucSelected.empresaId,
      marcaId: ucSelected.marcaId,
      tipoComunicacionId: ucSelected.tipoComunicacionId,
      nroSerie: ucSelected.descripcion,
      nit: ucSelected.nit,
      direccionIP: ucSelected.direccionIP,
      deviceID: ucSelected.deviceId,
      lineaID: ucSelected.lineaID,
    });
  };

  const handleDeleteUC = (id) => {
    setUCDelete(id);
    setOpenConfirm(true);
  };

  const handleOpenModal = () => {
    if (ucDB > 0) {
      handleEditUC(ucDB);
    }
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const handleChangeEmpresa = (evt) => {
    setState({
      ...state,
      empresaId: evt.target.value,
    });
  };

  const handleChangeMarca = (evt) => {
    setState({
      ...state,
      marcaId: evt.target.value,
    });
  };

  const handleChangeTipoComunicacion = (evt) => {
    setState({
      ...state,
      tipoComunicacionId: evt.target.value,
    });
  };

  const handleChangeLinea = (evt) => {
    setState({
      ...state,
      lineaID: evt.target.value,
    });
  };

  const handleAddUpdateUC = () => {
    if (!state.nroSerie) {
      enqueueSnackbar(t('default.nroSerieRequerido', 'El número de serie es requerido'), {
        variant: 'error',
      });
      return;
    }

    if (!state.marcaId) {
      enqueueSnackbar(t('default.marcaRequerido', 'La marca es requerida'), {
        variant: 'error',
      });
      return;
    }

    if (!state.tipoComunicacionId) {
      enqueueSnackbar(t('default.tipoComunicacionRequerido', 'El tipo de comunicación es requerido'), {
        variant: 'error',
      });
      return;
    }

    if (!state.nit) {
      enqueueSnackbar(t('default.nitRequerido', 'El NIT es requerido'), {
        variant: 'error',
      });
      return;
    }

    if (!state.empresaId) {
      enqueueSnackbar(t('default.empresaRequerido', 'La empresa es requerida'), {
        variant: 'error',
      });
      return;
    }

    if (!state.lineaID) {
      enqueueSnackbar(t('default.lineaRequerido', 'La número de linea es requerido'), {
        variant: 'error',
      });
      return;
    }

    const dto = {
      ...state,
      descripcion: state.nroSerie,
      empresa: empresas.filter(x => x.empresaID === state.empresaId)[0].nombre,
      marca: marcas.filter(x => x.marcaID === state.marcaId)[0].nombre,
      tipoComunicacion: tiposComunicacion.filter(x => x.tipoComunicacionId === state.tipoComunicacionId)[0].tipoComunicacionDescrip,
      lineaNume: lineas.filter(x => x.lineaId === state.lineaID)[0].lineaNume,
    };

    store.dispatch(Actions.Maestros.saveUnidadComunicacion(dto));
    setState(emptyUC);
  };

  const confirmDeleteUC = () => {
    console.log(ucDelete);
    if (ucDelete) {
      store.dispatch(Actions.Maestros.deleteUC(ucDelete));
    }
    setUCDelete(null);
    setOpenConfirm(false);
  };

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t('default.pagPrev', 'página previa'),
    nextPageTitle: t('default.proxPag', 'próxima página'),
    firstPageTitle: t('default.primerPagina', 'primer página'),
    lastPageTitle: t('default.ultimaPagina', 'última página'),
  };

  const columns = [{
    headerClasses: 'gf-notas-hide',
    dataField: 'empresa',
    text: t('default.empresaProp', 'Empresa propietaria'),
    headerStyle: () => ({ width: '20%' }),
    sort: true,
    filter: textFilter({
      className: 'customTextFilter',
    }),
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: 'marca',
    text: t('default.marca', 'Marca'),
    headerStyle: () => ({ width: '20%' }),
    sort: true,
    filter: textFilter({
      className: 'customTextFilter',
    }),
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: 'tipoComunicacion',
    text: t('default.tipoComunicacion', 'Tipo de Comunicación'),
    headerStyle: () => ({ width: '30%' }),
    sort: true,
    filter: textFilter({
      className: 'customTextFilter',
    }),
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: 'descripcion',
    text: t('default.nroSerie', 'Número Serie'),
    headerStyle: () => ({ width: '20%' }),
    sort: true,
    filter: textFilter({
      className: 'customTextFilter',
    }),
  },
  {
    dataField: 'unidadComunicacionID',
    text: '',
    formatter: id => (
      <>
        <IconButton
          className={classes.iconButton}
          aria-label="Editar"
          onClick={() => handleEditUC(id)}
          title={t('default.editar', 'Editar')}
        >
          <AwesomeIcon icon="edit" />
        </IconButton>
        <IconButton
          className={classes.iconButton}
          aria-label="Eliminar"
          onClick={() => handleDeleteUC(id)}
          title={t('default.eliminar', 'Eliminar')}
        >
          <AwesomeIcon icon="trash" />
        </IconButton>
      </>
    ),
  }];

  return (
    <>
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('default.confirmDeleteUC', '¿Está seguro que desea eliminar la unidad de comunicación?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDeleteUC} color="primary" autoFocus>
            {t('default.si', 'Si')}
          </Button>
          <Button onClick={() => setOpenConfirm(false)} color="primary" autoFocus>
            {t('default.no', 'No')}
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        className={classes.button}
        aria-label="Unidad de comunicación"
        onClick={handleOpenModal}
        title={t('default.unidadComunicacion', 'Unidad de comunicación')}
      >
        <AwesomeIcon icon="settings" />
      </IconButton>
      <Dialog
        maxWidth="lg"
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t('default.unidadComunicacion', 'Unidad de comunicación')}</DialogTitle>
        <DialogContent>
          <Row>
            <Col xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="empresaId">{t('default.empresaProp', 'Empresa propietaria')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="empresaId"
                  value={state.empresaId}
                  variant="standard"
                  onChange={handleChangeEmpresa}
                >
                  {empresas.map(e => (
                    <MenuItem key={e.empresaID} value={e.empresaID}>{e.nombre}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="marcaId">{t('default.marca', 'Marca')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="marcaId"
                  value={state.marcaId}
                  variant="standard"
                  onChange={handleChangeMarca}
                >
                  {marcas.filter(x => state.unidadComunicacionID > -1 || !x.baja).map(m => (
                    <MenuItem key={m.marcaID} value={m.marcaID}>{m.nombre}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="tipoComunicacionId">{t('default.tipoComunicacion', 'Tipo Comunicación')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="tipoComunicacionId"
                  value={state.tipoComunicacionId}
                  variant="standard"
                  onChange={handleChangeTipoComunicacion}
                >
                  {tiposComunicacion.map(m => (
                    <MenuItem key={m.tipoComunicacionId} value={m.tipoComunicacionId}>{m.tipoComunicacionDescrip}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col xs={6}>
              <TextField
                id="nroSerie"
                label={t('default.nroSerie', 'Número Serie')}
                className={`${classes.textField}`}
                type="text"
                margin="normal"
                value={state.nroSerie}
                onChange={evt => setState({
                  ...state,
                  nroSerie: evt.target.value,
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <TextField
                id="nit"
                label={t('default.nit', 'NIT')}
                className={`${classes.textField}`}
                type="number"
                margin="normal"
                value={state.nit}
                onChange={evt => setState({
                  ...state,
                  nit: evt.target.value,
                })}
              />
            </Col>
            <Col xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="lineaId">{t('default.numeroLinea', 'Número de linea')}</InputLabel>
                <Select
                  className="basic-multi-select"
                  name="lineaId"
                  value={state.lineaID}
                  variant="standard"
                  onChange={handleChangeLinea}
                >
                  {lineas.map(l => (
                    <MenuItem key={l.lineaId} value={l.lineaId}>{l.lineaNume}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <TextField
                id="direccionIP"
                label={t('default.direccionIP', 'Dirección IP')}
                className={`${classes.textField}`}
                type="text"
                margin="normal"
                value={state.direccionIP}
                onChange={evt => setState({
                  ...state,
                  direccionIP: evt.target.value,
                })}
              />
            </Col>
            <Col xs={6}>
              <TextField
                id="deviceID"
                label="Device"
                className={`${classes.textField}`}
                type="text"
                margin="normal"
                value={state.deviceID}
                onChange={evt => setState({
                  ...state,
                  deviceID: evt.target.value,
                })}
              />
            </Col>
          </Row>
          <Row style={{ margin: '10px 0' }}>
            <Button
              onClick={handleAddUpdateUC}
              color="primary"
              variant="contained"
              className={classes.buttonModal}
            >
              {state.unidadComunicacionID === -1 ? 'Agregar' : 'Actualizar'}
            </Button>
            { state.unidadComunicacionID > -1 && (
              <Button
                onClick={() => setState(emptyUC)}
                color="primary"
                autoFocus
                variant="contained"
                className={classes.buttonModal}
                style={{ marginLeft: '10px' }}
              >
                {t('default.cancelar', 'Cancelar')}
              </Button>
            )}
          </Row>
          <Row>
            <Col xs={12} className="gf-modal-table">
              <BootstrapTable
                keyField="unidadComunicacionID"
                striped
                hover
                condensed
                bootstrap4
                headerClasses={classes.headerGrid}
                data={unidadesComunicacion}
                columns={columns}
                pagination={paginationFactory(options)}
                filter={filterFactory()}
              />
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            color="primary"
            variant="contained"
            className={classes.buttonModal}
          >
            {t('default.cerrar', 'Cerrar')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ModalUnidadComunicacion.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({
    t: props.t,
    enqueueSnackbar: props.enqueueSnackbar,
  })),
  lifecycle({
    componentDidMount() {
      store.dispatch(Actions.Maestros.getLineasAll());
      store.dispatch(Actions.Maestros.getTipoComunicacionAll());
    },
  }),
  connect(state => ({
    lineas: state.Maestros.lineas,
    tiposComunicacion: state.Maestros.tipoComunicacion,
    resultSaveUC: state.Maestros.resultSaveUC,
  })),
)(ModalUnidadComunicacion);

export default withSnackbar(translate('common')(AppCompose));
