import React from 'react';


import {
  Grid,
} from '@material-ui/core';

import Moment from 'moment';

import FilterAlert from '../FilterAlert';
import TendeciaLista from '../TendeciaLista';

import './style.css';
import Props from './props';

/** @description Componente para visualizar para cada item, salidas especificando nombre y tendencias.
 */
const SalidaItem = ({
  salida,
  language,
  t,
  variables,
  expired,
  rectificador,
  tendenciesAllOutputs,
}) => {
  console.log(salida);
  return (
    <Grid container>
      <Grid item xs={3} className="gf-media-body-salida">
        <ul className="listaValores gf-media-body-inside-float gf-media-body-half">
          <li className="y-center">
            <b><span className="ml-4">{`${language === 'EN' ? 'Output: ' : 'Salida: '}${salida.salidaID}`}</span></b>
          </li>
          {salida.valores.map(v => (
            <li key={v.reporteValorID} className="y-center">
              <span className="ml-4">{`${language === 'EN' ? v.nombreEn : v.nombreEs} : ${v.listaValorID ? v.listaValor : v.valor}`}</span>
            </li>
          ))}
        </ul>
        <ul style={{ marginTop: '25px', paddingLeft: '20px' }} className="gf-media-body-inside-float gf-media-body-half">
          {salida.alertas.map(a => (
            <FilterAlert
              on={a.on}
              rectificadorID={a.rectificadorID}
              alertaID={a.alertaID}
              fechaInicioAlarma={a.fecheAlarmaInicio}
              nombre={language === 'EN' ? a.nombreEN : a.nombreES}
              key={a.alertaID}
              salidaID={a.salidaID}
              t={t}
            />
          ))}
        </ul>
      </Grid>
      <Grid item xs={9} className="gf-media-body-tendencia">
        <fieldset className="panel">
          <legend className="panelTitulo">{t('default.tendencias', 'Tendencias')}</legend>
          {expired ? (<h4>{t('default.deviceInactive', 'El dispositivo está inactivo')}</h4>) : (
            <div>
              {
                variables.length > 0 && (
                  <Grid container>
                    <Grid item xs={2} />
                    <Grid item xs={10} style={{ borderTop: '2px solid black' }}>
                      <span style={{ float: 'left' }}>{Moment(variables[0].fechaRecepcion).format('DD/MM/YYYY HH:mm:ss')}</span>
                      <span style={{ float: 'right' }}>{Moment(variables[variables.length - 1].fechaRecepcion).format('DD/MM/YYYY HH:mm:ss')}</span>
                    </Grid>
                    <div className="clearfix" />
                  </Grid>
                )
              }
              <TendeciaLista
                salida={salida}
                language={language}
                rectificador={rectificador}
                variables={variables}
                tendenciesAllOutputs={tendenciesAllOutputs}
              />
            </div>
          )}
          {/* salida.valores.filter(x => !x.listaValorID).length === 0 && (<div />) */}
        </fieldset>
      </Grid>
    </Grid>
  );
};

SalidaItem.propTypes = Props;

export default SalidaItem;

// variables: state.Variables.Variables,
