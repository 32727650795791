import React from 'react';
// import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './checkboxStyle.css';
// import { makeStyles } from '@material-ui/core';

import CheckboxTree from 'react-checkbox-tree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faCheckSquare,
  faChevronRight,
  faChevronDown,
  // faPlusSquare,
  // faMinusSquare,
  // faSquare,
  // faFolder,
  // faFolderOpen,
  // faFile,
} from '@fortawesome/free-solid-svg-icons';
import Props from './props';

/* const useStyles = makeStyles({
  expandIcon: {
    background: 'transparent',
    border: 'none',
  },
}); */

/** @description Componente para mostrar iconos de cierre y apertura en los checkbox
 * de los rectificadores.
 */
const CheckboxTreeRectificadores = ({
  rectificadoresSeleccionados,
  handleCheck,
  handleExpand,
  checkedNodes,
  expandedNodes,
  t,
}) => {
  const nodes = rectificadoresSeleccionados.map(r => ({
    value: r.rectificadorID,
    label: r.nombre,
    children: r.salidas.map(s => ({
      value: `${r.rectificadorID}_${s.salidaID}`,
      label: `${t('default.salida', 'Salida')} Nro: ${s.numero}`,
    })),
  }));
  // const showCheckBox = true;
  return (
    <CheckboxTree
      nodes={nodes}
      checked={checkedNodes}
      expanded={expandedNodes}
      onCheck={handleCheck}
      onExpand={handleExpand}
      icons={{
        // check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare} />,
        // uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} />,
        // halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} />,
        expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon={faChevronRight} />,
        expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon={faChevronDown} />,
        // expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} />,
        // collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} />,
        // parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon={[]} />,
        // parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon={[]} />,
        // leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={[]} />,
      }}
    />
  );
};

CheckboxTreeRectificadores.propTypes = Props;
export default CheckboxTreeRectificadores;
