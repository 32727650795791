import React, {
  Fragment,
  useState,
  useEffect,
} from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import 'bootstrap/dist/css/bootstrap.css';
import Router from './Router';
import store from './store';
import Loader from './components/Loader';
import NetworkWorking from './components/NetworkWorking';


const Main = () => (
  <Fragment>
    <NetworkWorking />
    <Router />
  </Fragment>
);

window.onReportReceived = (data) => {
  store.dispatch({
    type: 'UPDATE_CARD_ITEM',
    payload: {
      data,
      id: data.rectificadorID,
    },
  });

  store.dispatch({
    type: 'TABLEROS_UPDATE_VARIABLES',
    payload: {
      data,
    },
  });
};

window.onNotifReceived = (data) => {
  store.dispatch({
    type: 'ADD_NOTIFICACION',
    payload: {
      data,
    },
  });
};

const App = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.addEventListener('load', () => {
      setTimeout(() => {
        setLoading(false);
        store.dispatch({ type: 'INIT_APP_STATE' });
      }, 500);
    });
  });

  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        {loading && (
          <Loader
            loading={loading}
          />
        )}
        {!loading && (
          <Main />
        )}
      </BrowserRouter>
    </Provider>
  );
};

export default hot(module)(App);
