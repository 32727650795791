import React, { Fragment } from 'react';

import {
  Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import MiniChart from '../MiniChart';

import Props from './Props';

/** @description Componente item reutilizable por la lista de tendencia para cada elemento del mismo.
 * Con un boton accion y un mini grafico.
 */
const TendenciaItem = ({
  filterData,
  v,
  language,
  rectificador,
  dataForChart,
}) => (
  <Fragment key={v.configuracionReporteID}>
    {filterData.length ? (
      <Grid container>
        <Grid item xs={2} style={{ position: 'relative' }} className="gf-tenden-item">
          <Link
            style={{ color: 'black' }}
            to={{
              pathname: '/variableDetail',
              data: dataForChart,
              language,
              rectificador: filterData[0].rectificador,
              device: rectificador,
              isMoreInfo: true,
              rectificadorID: rectificador.rectificadorID,
              variable: filterData[0].configuracionReporteID,
            }}
          >
            <button type="button" className="btn btn-primary gf-tenden-item-button" style={{ position: 'absolute', top: '35%', backgroundColor: '#0083C7' }}>
              {`${language === 'EN' ? v.nombreEn : v.nombreEs} ${v.valor.split('[').length === 2 ? `[${v.valor.split('[')[1]}` : ''}`}
            </button>
          </Link>
        </Grid>
        <Grid item xs={10} style={{ marginBottom: 10 }} className="gf-tenden-item-graph">
          <MiniChart tendencias={filterData} />
        </Grid>
      </Grid>
    ) : (<div />)}
  </Fragment>
);

TendenciaItem.propTypes = Props;

export default TendenciaItem;
