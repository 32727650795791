import {
  FILTER_FETCH_ALERT_TYPE,
  FILTER_SET_ALERT_TYPE_FILTER,
  FILTER_SET_KEYWORD_FILTER,
  FILTER_SET_GROUP_FILTER,
  FILTER_SET_GROUPDISPONIBILIDAD_FILTER,
  FILTER_SET_BRANDS_FILTER,
} from '../actionTypes/filters';

export const getAlertType = () => ({
  type: FILTER_FETCH_ALERT_TYPE,
});

export const setAlertType = (value = []) => ({
  type: FILTER_SET_ALERT_TYPE_FILTER,
  payload: {
    value,
  },
});

export const setKeyword = (value = '') => ({
  type: FILTER_SET_KEYWORD_FILTER,
  payload: {
    value,
  },
});

export const setGroup = (value = []) => ({
  type: FILTER_SET_GROUP_FILTER,
  payload: {
    value,
  },
});

export const setGroupDisponibilidad = (value = []) => ({
  type: FILTER_SET_GROUPDISPONIBILIDAD_FILTER,
  payload: {
    value,
  },
});

export const setBrands = (value = []) => ({
  type: FILTER_SET_BRANDS_FILTER,
  payload: {
    value,
  },
});

export default {
  setAlertType,
  setGroup,
  setKeyword,
  setBrands,
  setGroupDisponibilidad,
  getAlertType,
};
