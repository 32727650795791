import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';

import PageWrapper from '../../components/PageWrapper';

import { composePure, withProps } from '../../utils/composepure';

import __html from './staticPage.html';
import './style.css';
import Props from './props';

const template = { __html };

const Forbidden = ({
  history,
  t,
}) => (
  <PageWrapper history={history}>
    <div dangerouslySetInnerHTML={template} />
    <div>
      <p id="text">{t('default.prohibido403', '403 Prohibido')}</p>
      <Link
        style={{ textDecoration: 'underline' }}
        to="/"
      >
        <span>{t('default.goToMainPage', 'Volver a la página de inicio')}</span>
      </Link>
    </div>
  </PageWrapper>
);

Forbidden.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
)(Forbidden);

export default translate('common')(AppCompose);
