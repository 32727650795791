import React, { useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import {
  Row,
} from 'react-bootstrap';

import {
  Grid,
  Paper,
  makeStyles,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  TextField,
  Button,
  Container,
} from '@material-ui/core';

import AwesomeIcon from '../../components/AwesomeIcon';
import PageWrapper from '../../components/PageWrapper';

import {
  composePure,
  withProps,
} from '../../utils/composepure';

import Actions from '../../actions';
import store from '../../store';

import Props from './props';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  buttonModal: {
    backgroundColor: '#0083C7 !important',
  },
  paper: {
    height: 500,
    width: 400,
    overflow: 'auto',
  },
  control: {
    padding: theme.spacing(2),
  },
}));

/* const getUnique = (arr, comp) => {
  const unique = arr
    .map(e => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e]).map(e => arr[e]);

  return unique;
}; */

/** @description pagina que dispone de todo lo necesario para dar de alta, baja o modificacion
 * de un Grupo.
 */
const ABMGrupos = ({
  history,
  grupos,
  devices,
  t,
  enqueueSnackbar,
  availableDevices,
}) => {
  const classes = useStyles();
  const [groupSelected, setGroupSelected] = useState();
  const [deviceSelected, setDeviceSelected] = useState();
  const [groupName, setGroupName] = useState();
  const [subgrupoName, setSubgrupoName] = useState();
  const [openDialogNewGrupo, setOpenDialogNewGrupo] = useState(false);
  const handleCloseDialogNewGrupo = () => setOpenDialogNewGrupo(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  // const devicesFiltered = devices.filter(x => x.grupoId === groupSelected && groupSelected);
  const [devicesFiltered, setDevicesFiltered] = useState([]);
  // const devicesAvailable = !groupSelected ? [] : getUnique(devices.filter(x => devicesFiltered.findIndex(d => d.rectificadorID === x.rectificadorID) === -1), 'rectificadorID');
  const [availablesForGroup, setAvailablesForGroup] = useState([]);

  useEffect(() => {
    console.log(availableDevices);
    setAvailablesForGroup(availableDevices);
  }, [availableDevices]);

  const handleAddDevice = () => {
    if (devicesFiltered.findIndex(x => x.rectificadorID === deviceSelected) === -1 && deviceSelected) {
      const deviceFind = availableDevices.filter(x => x.rectificadorID === deviceSelected)[0];
      store.dispatch(Actions.Rectifiers.addDeviceToGroup(deviceFind, groupSelected));
      setDeviceSelected(null);
      const device = availablesForGroup.filter(x => x.rectificadorID === deviceSelected)[0];
      setDevicesFiltered([
        ...devicesFiltered,
        device,
      ]);
      setAvailablesForGroup(availablesForGroup.filter(x => x.rectificadorID !== deviceSelected));
    }
  };

  const handleRemoveDevice = () => {
    if (deviceSelected) {
      store.dispatch(Actions.Rectifiers.removeDeviceFromGroup(deviceSelected, groupSelected));
      const device = devicesFiltered.filter(x => x.rectificadorID === deviceSelected)[0];
      setAvailablesForGroup([
        ...availablesForGroup,
        device,
      ]);
      setDevicesFiltered(devicesFiltered.filter(x => x.rectificadorID !== deviceSelected));
      setDeviceSelected(null);
    }
  };

  const handleGuardarGrupo = () => {
    store.dispatch(Actions.Groups.createGroup(groupName, subgrupoName));
    handleCloseDialogNewGrupo();
    setSubgrupoName('');
    setSubgrupoName('');
  };

  const handleDeleteGroup = () => {
    if (!groupSelected) {
      // setAlertMessage(t('default.seleccioneGrupoEliminar', 'Seleccione el grupo que desea eliminar'));
      // setOpenAlertDialog(true);
      enqueueSnackbar(t('default.seleccioneGrupoEliminar', 'Seleccione el grupo que desea eliminar'), {
        variant: 'error',
      });
      return;
    }

    setOpenConfirm(true);
  };

  const handleEliminarGrupo = () => {
    store.dispatch(Actions.Groups.deleteGroup(groupSelected));
    setOpenConfirm(false);
    setGroupSelected(null);
    setDeviceSelected(null);
  };

  const handleDoubleClickAvailable = (rectificadorID) => {
    setDeviceSelected(rectificadorID);
    handleAddDevice();
  };

  const handleDoubleClickGroup = (rectificadorID) => {
    setDeviceSelected(rectificadorID);
    handleRemoveDevice();
  };

  const handleSelectGroup = (grupoId) => {
    setGroupSelected(grupoId);
    store.dispatch(Actions.Rectifiers.getAvailableDevicesForGroup(grupoId));
    setDevicesFiltered(devices.filter(x => x.grupoId === grupoId && grupoId));
  };

  return (
    <PageWrapper
      history={history}
    >
      <Row>
        <Container maxWidth="xl" className="FilterBarHeader">
          <Row>
            <Grid container>
              <Grid item xs={1}>
                <IconButton
                  aria-label="left"
                  onClick={() => history.push({
                    pathname: '/',
                  })}
                  style={{
                    border: '2px solid rgba(0, 0, 0, 0.54)',
                  }}
                >
                  <AwesomeIcon icon="left" />
                </IconButton>
              </Grid>
              <Grid item xs={5}>
                <h3>
                  {t('default.grupos', 'Grupos')}
                </h3>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
            </Grid>
          </Row>
        </Container>
      </Row>
      <Grid container spacing={2} style={{ marginTop: 100 }}>
        <Grid item={12}>
          <Grid container justify="center" style={{ margin: 'auto' }} spacing={2}>
            <Grid item>
              <Paper className={classes.paper} style={{ position: 'relative' }}>
                <ul className="listaValores">
                  <li style={{ textAlign: 'center', backgroundColor: 'gray', marginBottom: 25 }}>
                    <b>{t('default.grupos', 'Grupos')}</b>
                  </li>
                  {grupos.map(g => (
                    <li
                      key={g.grupoId}
                      // className="y-center"
                      style={{
                        padding: '5px 0 10px 20px',
                        backgroundColor: groupSelected === g.grupoId ? '#76C0E8' : 'transparent',
                      }}
                    >
                      <button
                        type="button"
                        onClick={() => handleSelectGroup(g.grupoId)}
                        style={{ backgroundColor: 'transparent', border: 'none', textAlign: 'left' }}
                      >
                        {g.nombre}
                      </button>
                    </li>
                  ))}
                </ul>
                <IconButton
                  aria-label="left"
                  style={{
                    backgroundColor: '#007bff',
                    color: '#fff',
                    position: 'sticky',
                    left: '70%',
                    bottom: 10,
                  }}
                  title="Crear grupo"
                  onClick={() => setOpenDialogNewGrupo(true)}
                >
                  <AwesomeIcon icon="plus" />
                </IconButton>
                <IconButton
                  aria-label="left"
                  style={{
                    backgroundColor: '#007bff',
                    color: '#fff',
                    position: 'sticky',
                    left: '85%',
                    bottom: 10,
                  }}
                  title="Eliminar grupo"
                  onClick={handleDeleteGroup}
                >
                  <AwesomeIcon icon="minus" />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <ul className="listaValores">
                  <li style={{ textAlign: 'center', backgroundColor: 'gray', marginBottom: 25 }}>
                    <b>{t('default.dispositivoEnGrupo', 'Dispositivos en grupo')}</b>
                  </li>
                  {devicesFiltered.map(d => (
                    <li
                      key={d.rectificadorID}
                      className="y-center"
                      style={{ padding: '5px 0 10px 20px', backgroundColor: deviceSelected === d.rectificadorID ? '#76C0E8' : 'transparent' }}
                    >
                      <button
                        type="button"
                        onClick={() => setDeviceSelected(d.rectificadorID)}
                        onDoubleClick={() => handleDoubleClickGroup(d.rectificadorID)}
                        style={{ backgroundColor: 'transparent', border: 'none', textAlign: 'left' }}
                      >
                        {d.nombre}
                      </button>
                    </li>
                  ))}
                </ul>
              </Paper>
            </Grid>
            <Grid item>
              <IconButton
                className={classes.button}
                aria-label="rigth"
                onClick={handleRemoveDevice}
                style={{ marginTop: 25 }}
              // title={t('default.reporteActual', 'Reporte Actual')}
              >
                <AwesomeIcon icon="right" />
              </IconButton>
              <br />
              <IconButton
                className={classes.button}
                aria-label="left"
                onClick={handleAddDevice}
              // title={t('default.reporteActual', 'Reporte Actual')}
              >
                <AwesomeIcon icon="left" />
              </IconButton>
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <ul className="listaValores">
                  <li style={{ textAlign: 'center', backgroundColor: 'gray', marginBottom: 25 }}>
                    <b>{t('default.dispositivos', 'Dispositivos')}</b>
                  </li>
                  {groupSelected && availablesForGroup.map(d => (
                    <li
                      key={d.rectificadorID}
                      className="y-center"
                      style={{ padding: '5px 0 10px 20px', backgroundColor: deviceSelected === d.rectificadorID ? '#76C0E8' : 'transparent' }}
                    >
                      <button
                        type="button"
                        onClick={() => setDeviceSelected(d.rectificadorID)}
                        onDoubleClick={() => handleDoubleClickAvailable(d.rectificadorID)}
                        style={{ backgroundColor: 'transparent', border: 'none', textAlign: 'left' }}
                      >
                        {d.nombre}
                      </button>
                    </li>
                  ))}
                </ul>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={openDialogNewGrupo}
        onClose={handleCloseDialogNewGrupo}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{t('default.crearGrupo', 'Crear grupo')}</DialogTitle>
        <DialogContent>
          <TextField
            id="txtgrupo"
            type="text"
            style={{ width: '100%' }}
            className={classes.textField}
            value={groupName}
            onChange={evt => setGroupName(evt.target.value)}
            label={t('default.grupo', 'Grupo')}
          />
          <TextField
            id="txtsubgrupo"
            type="text"
            style={{ width: '100%' }}
            className={classes.textField}
            inputProps={{
              readOnly: !groupName,
            }}
            value={subgrupoName}
            onChange={evt => setSubgrupoName(evt.target.value)}
            label={t('default.subgrupo', 'Subgrupo')}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" className={classes.buttonModal} onClick={handleCloseDialogNewGrupo}>{t('default.cerrar', 'Cerrar')}</Button>
          <Button color="primary" variant="contained" className={classes.buttonModal} onClick={handleGuardarGrupo}>{t('default.guardar', 'Guardar')}</Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog
        open={openAlertDialog}
        onClose={() => setOpenAlertDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlertDialog(false)} color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('default.confirmEliminarGrupo', '¿Está seguro de eliminar el grupo?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEliminarGrupo} color="primary" autoFocus>
            {t('default.si', 'Si')}
          </Button>
          <Button onClick={() => setOpenConfirm(false)} color="primary" autoFocus>
            {t('default.no', 'No')}
          </Button>
        </DialogActions>
      </Dialog>
    </PageWrapper>
  );
};

ABMGrupos.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({
    t: props.t,
    enqueueSnackbar: props.enqueueSnackbar,
  })),
  connect(state => ({
    grupos: state.Groups.docs,
    devices: state.Rectifiers.docs.filter(x => x.grupoId),
    availableDevices: state.Rectifiers.availableDevices,
  })),
)(ABMGrupos);

export default withSnackbar(translate('common')(AppCompose));

// export default ABMGrupos;
