import {
  DISPONIBILIDAD_CONSULTAR,
} from '../actionTypes/disponibilidad';

export const fetchDisponibilidad = (startDate, endDate, grupoSelected, userId) => ({
  type: DISPONIBILIDAD_CONSULTAR,
  payload: {
    startDate,
    endDate,
    grupoSelected,
    userId,
  },
});

export default {
  fetchDisponibilidad,
};
