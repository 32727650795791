import Moment from 'moment';

const initialState = {
  docs: [],
  inicioAlarmas: [],
  configuracionReportes: [],
  availableDevices: [],
  zonasHoraria: [],
  tiposRectificador: [],
};

const calcExpiration = (card) => {
  const end = Moment(card.fecha);
  const now = Moment();
  const duration = Moment.duration(now.diff(end));
  return duration.asDays() > 10;
};


const rectifierReducer = (state = null, action) => {
  switch (action.type) {
    case 'RECTIFIERS_SET_SELECTED':
      if (action.payload.value === state.uniqueId) {
        return {
          ...state,
          selected: !state.selected,
        };
      }
      return state;
    default:
      return state;
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATERECTIFIER_AFTERSAVE':
      // eslint-disable-next-line no-case-declarations
      let newDocs = [];
      // let updatedDocs = [];
      // let deletedDocs = [];
      // eslint-disable-next-line no-case-declarations
      const tipoUnidad = state.docs.filter(x => x.tipoUnidadMonitoreoID === action.payload.unitTypeID);
      if (action.payload.isNew) {
        newDocs = action.payload.agrupacionValorID.map(g => ({
          alertas: [],
          comandosEnviados: [],
          fecha: '1900-01-01T00:00:00',
          grupoId: g,
          latitud: action.payload.latitud,
          longitud: action.payload.longitud,
          nombre: action.payload.rectificadorNombre,
          rectificadorID: action.payload.rectificadorID,
          valores: [],
          salidas: [],
          cantidadSalidas: action.payload.equipo.salidas.length,
          tipoUnidadMonitoreoID: action.payload.unitTypeID,
          tipoUnidadMonitoreo: tipoUnidad.length > 0 ? tipoUnidad[0].tipoUnidadMonitoreo : '',
          inAlert: false,
          expired: true,
          uniqueId: `rectify-${action.payload.rectificadorID}-${Math.random()}`,
          selected: false,
        }));

        return {
          ...state,
          docs: [...state.docs, ...newDocs],
        };
      }

      // eslint-disable-next-line no-case-declarations
      const allDocs = [...state.docs];
      for (let i = 0; i < action.payload.agrupacionValorID.length; i += 1) {
        const index = state.docs.findIndex(x => x.rectificadorID === action.payload.rectificadorID && x.grupoId === action.payload.agrupacionValorID[i]);
        const doc = state.docs.filter(x => x.rectificadorID === action.payload.rectificadorID)[0];

        if (index === -1) {
          allDocs.push({
            ...doc,
            grupoId: action.payload.agrupacionValorID[i],
            latitud: action.payload.latitud,
            longitud: action.payload.longitud,
            nombre: action.payload.rectificadorNombre,
            cantidadSalidas: action.payload.equipo.salidas.length,
            tipoUnidadMonitoreoID: action.payload.unitTypeID,
            tipoUnidadMonitoreo: tipoUnidad.length > 0 ? tipoUnidad[0].tipoUnidadMonitoreo : '',
          });
        } else {
          console.log('Actualizado: ', doc);
          allDocs[index] = {
            ...doc,
            grupoId: action.payload.agrupacionValorID[i],
            latitud: action.payload.latitud,
            longitud: action.payload.longitud,
            nombre: action.payload.rectificadorNombre,
            cantidadSalidas: action.payload.equipo.salidas.length,
            tipoUnidadMonitoreoID: action.payload.unitTypeID,
            tipoUnidadMonitoreo: tipoUnidad.length > 0 ? tipoUnidad[0].tipoUnidadMonitoreo : '',
          };
        }
      }

      // eslint-disable-next-line no-case-declarations
      const docsExist = state.docs.filter(x => x.rectificadorID === action.payload.rectificadorID && !action.payload.agrupacionValorID.includes(x.grupoId));

      docsExist.forEach((item) => {
        console.log('Eliminado: ', item);
        const index = state.docs.findIndex(x => x.uniqueId === item.uniqueId);
        allDocs.splice(index, 1);
      });

      return {
        ...state,
        docs: [...allDocs],
        availableDevices: [action.payload],
      };
    case 'RECTIFIERS_SET_DOCS':
      return {
        ...state,
        docs: action.payload.data
          .map(c => ({
            ...c,
            inAlert: c.alertas.filter(a => a.on).length > 0,
            expired: calcExpiration(c),
            uniqueId: `rectify-${c.rectificadorID}-${Math.random()}`,
            selected: false,
          })),
      };
    case 'SET_RECTIFIERLASTREPORT':
      return {
        ...state,
        docs: [
          ...state.docs,
          {
            ...action.payload.data,
            uniqueId: `rectify-${action.payload.data.rectificadorID}-${Math.random()}`,
            inAlert: action.payload.data.alertas.filter(a => a.on).length > 0,
            expired: calcExpiration(action.payload.data),
          },
        ],
      };
    case 'RECTIFIERS_SET_SELECTED':
      return {
        ...state,
        docs: state.docs.map(doc => rectifierReducer(doc, action)),
      };
    case 'UPDATE_CARD_ITEM':
      return {
        ...state,
        docs: state.docs.map((r) => {
          if (r.rectificadorID === action.payload.id) {
            return {
              ...r,
              fecha: action.payload.data.fecha,
              valores: action.payload.data.valores,
              alarmas: action.payload.data.alarmas,
              salidas: action.payload.data.salidas,
            };
          }
          return r;
        }),
      };
    case 'SET_INICIOALARMA':
      return {
        ...state,
        inicioAlarmas: [...state.inicioAlarmas, action.payload.data],
      };
    case 'SET_CONFIGURACIONREPORTE':
      return {
        ...state,
        configuracionReportes: [...state.configuracionReportes, ...action.payload.data],
      };
    case 'SET_AVAILABLEDEVICES':
      return {
        ...state,
        availableDevices: action.payload.data,
      };
    case 'SET_LOCACIONDEVICE':
      // eslint-disable-next-line no-case-declarations
      const index = state.availableDevices.findIndex(r => r.rectificadorID === action.payload.data.rectificadorID);
      return {
        ...state,
        availableDevices: [
          ...state.availableDevices.slice(0, index),
          {
            ...state.availableDevices[index],
            latitudDevice: action.payload.data.latitud,
            longitudDevice: action.payload.data.longitud,
          },
          ...state.availableDevices.slice(index + 1),
        ],
      };
    case 'SET_DEVICEEDIT':
      return {
        ...state,
        availableDevices: [action.payload.data],
      };
    case 'SET_ZONAHORARIA':
      return {
        ...state,
        zonasHoraria: action.payload.data,
      };
    case 'SET_TIPOSRECTIFICADOR':
      return {
        ...state,
        tiposRectificador: action.payload.data,
      };
    case 'REMOVE_AVAILABLEDEVICE':
      return {
        ...state,
        availableDevices: state.availableDevices.filter(x => x.rectificadorID !== action.payload.equipo),
      };
    case 'REMOVE_DEVICE':
      // eslint-disable-next-line no-case-declarations
      const rectifiersToDelete = action.payload.split(',').map(x => parseInt(x, 10));
      return {
        ...state,
        docs: state.docs.filter(x => !rectifiersToDelete.includes(x.rectificadorID)),
      };
    case 'SET_ADDDEVICETOGROUP':
      // Si ya hay un registro del rectificador copiamos ese registro pero con el grupo nuevo
      // eslint-disable-next-line no-case-declarations
      const deviceExist = state.docs.filter(x => x.rectificadorID === action.payload.rectificador.rectificadorID)[0];
      // eslint-disable-next-line no-case-declarations
      let device = {};

      if (deviceExist) {
        device = {
          ...deviceExist,
          grupoId: action.payload.grupoId,
        };
      } else {
        const tipoUnidadMonitoreo = state.docs.filter(x => x.tipoUnidadMonitoreoID === action.payload.unitTypeID);
        const { rectificador } = action.payload;
        console.log(rectificador);
        device = {
          alertas: [],
          comandosEnviados: [],
          fecha: '1900-01-01T00:00:00',
          grupoId: action.payload.grupoId,
          latitud: rectificador.latitud,
          longitud: rectificador.longitud,
          nombre: rectificador.nombre,
          rectificadorID: rectificador.rectificadorID,
          valores: [],
          salidas: [],
          cantidadSalidas: 0,
          tipoUnidadMonitoreoID: rectificador.tipoUnidadMonitoreoID,
          tipoUnidadMonitoreo: tipoUnidadMonitoreo.length > 0 ? tipoUnidadMonitoreo[0].tipoUnidadMonitoreo : '',
          inAlert: false,
          expired: true,
          uniqueId: `rectify-${rectificador.rectificadorID}-${Math.random()}`,
          selected: false,
        };
      }

      return {
        ...state,
        docs: [
          ...state.docs,
          device,
        ],
      };
    case 'SET_REMOVEDEVICEFROMGROUP':
      // eslint-disable-next-line no-case-declarations
      const indexDeviceToRemoveGroup = state.docs.findIndex(x => x.rectificadorID === action.payload.rectificadorId && x.grupoId === action.payload.grupoId);
      console.log(indexDeviceToRemoveGroup);
      return {
        ...state,
        docs: [
          ...state.docs.slice(0, indexDeviceToRemoveGroup),
          {
            ...state.docs[indexDeviceToRemoveGroup],
            grupoId: null,
          },
          ...state.docs.slice(indexDeviceToRemoveGroup + 1),
        ],
      };
    default:
      return state;
  }
};

export default reducer;
