import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { composePure, withProps } from '../../utils/composepure';
import Image from '../Image';
import ButtonIcon from '../ButtonIcon';
import Props from './props';

/** @description Componente interno de AccountButton que dibuja la pagina slider por derecha.
 * Contiene las estructura de seccion del usuario con operaciones para acceder al perfil de usuario,
 * seccion ayuda y cierre de sesión.
 */
const AccountSection = ({
  userData,
  logoutHandle,
  t,
}) => (
  <Container fluid className="AccountSection">
    <Row className="xy-center">
      <Col xs={12} className="AccountSection__img">
        <Image src="profilePicture" roundedCircle className="w-100" />
        <span>{`${t('default.hola', 'Hola')} ${userData.nombre} ${userData.apellido}!`}</span>
      </Col>
      <Col xs={12} className="x-center">
        <Link to="/perfilUsuario">
          <ButtonIcon
            icon="settings"
            rigthText={t('default.miCuenta', 'Mi Cuenta')}
          />
        </Link>
      </Col>
      <Col xs={12} className="x-center">
        <Link to="/ayuda">
          <ButtonIcon
            icon="help"
            rigthText={t('default.ayuda', 'Ayuda')}
          />
        </Link>
      </Col>
      <Col xs={12} className="x-center">
        <ButtonIcon
          icon="logout"
          rigthText={t('default.cerrarSession', 'Cerrar Sesión')}
          onClick={() => logoutHandle()}
        />
      </Col>
    </Row>
  </Container>
);

AccountSection.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
)(AccountSection);

export default translate('common')(AppCompose);
