const theme = {
  yellow: {
    main: '#ECE400',
  },
  blue: {
    main: '#0083C7',
    dark: '#0F5C84',
  },
  green: {
    main: '#09EB18',
  },
  red: {
    main: '#F5160E',
  },
  gray: {
    lighter: '#E6E6E6',
    light: '#9C9B9B',
    main: '#787878',
    dark: '#636363',
    darker: '#272727',
  },
  white: {
    main: '#F5F5F5',
  },
};

export default theme;
