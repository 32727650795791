export const GET_NOTIFICACIONES = 'GET_NOTIFICACIONES';
export const DELETE_NOTIFICACIONES = 'DELETE_NOTIFICACIONES';
export const DELETE_NOTIFICACIONES_ALL = 'DELETE_NOTIFICACIONES_ALL';
export const DELETE_SET_NOTIFICATION = 'DELETE_SET_NOTIFICATION';
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const ADD_NOTIFICACION = 'ADD_NOTIFICACION';
export const SET_NOTIFICACIONES = 'SET_NOTIFICACIONES';
export const DELETE_SET_NOTIFICATION_ALL = 'DELETE_SET_NOTIFICATION_ALL';
export const MARK_READED = 'MARK_READED';
export const SET_MARK_READED = 'SET_MARK_READED';
// export const DELETE_SET_NOTIFICATION_ALL = 'DELETE_SET_NOTIFICATION_ALL';
