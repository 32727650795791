import React, { Fragment } from 'react';
import {
  Container,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';

/* import {
  Row,
  Col,
} from 'react-bootstrap'; */

import Moment from 'react-moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';

import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { translate, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import theme from '../../scss/theme/colors';
import AwesomeIcon from '../AwesomeIcon';
import ChildRowList from '../ChildRowList';

// import TablaBootstrap from '../TablaBootstrap';

import Actions from '../../actions';

import {
  composePure,
  shouldUpdate,
  withProps,
} from '../../utils/composepure';

import StatusBullet from '../StatusBullet';
import Props from './props';
// import language from '../../actions/language';

const useStyles = makeStyles({
  button: {
    color: theme.blue.darker,
  },
  buttonModal: {
    backgroundColor: '#0083C7 !important',
  },
  dialogPaper: {
    // minHeight: '80vh',
    maxHeight: '80vh',
  },
  textField: {
    width: '100%',
  },
  icon: {
    padding: '6px',
  },
});

/** @description Componente generico para mostrar informacion de los dispositivos en forma de lista.
 * Se muestra nombre, total de salidas, total de alertas, estado, señal, botones de mas info y
 * nuevo dispositivo.
 */
const ListView = ({
  filteredData,
  handleChange,
  // cardSelecteds,
  t,
  dataCount,
  equipos,
  language,
  userData,
}) => {
  const classes = useStyles();

  const seeMoreInfoButton = userData.permisos.findIndex(x => x.opcionCodigo === 'MI') > -1;
  const seeConfButton = userData.permisos.findIndex(x => x.opcionCodigo === 'CR') > -1;

  const handleSelectAll = (isSelected) => {
    equipos.forEach((val) => {
      /* if (rows.findIndex(x => x.uniqueId === val.uniqueId) === -1) {
        handleChange(val.uniqueId);
      } */
      if ((isSelected && !val.selected) || (!isSelected && val.selected)) {
        handleChange(val.uniqueId);
      }
    });
    return false;
  };

  const [page, setPage] = React.useState(1);

  const paginationOptions = {
    sizePerPageList: [
      // { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '20', value: 20 },
      { text: '30', value: 30 },
    ],
    prePageTitle: t('default.pagPrev', 'página previa'),
    nextPageTitle: t('default.proxPag', 'próxima página'),
    firstPageTitle: t('default.primerPagina', 'primer página'),
    lastPageTitle: t('default.ultimaPagina', 'última página'),
  };

  const expandRow = {
    renderer: row => (
      <ChildRowList row={row} language={language} />
    ),
    showExpandColumn: true,
  };

  const columns = [{
    dataField: 'nombre',
    text: t('default.nombre', 'Nombre'),
    // formatter: nameFormatter,
    headerStyle: () => ({ overflowWrap: 'break-word', width: '20%' }),
    style: () => ({ overflowWrap: 'break-word' }),
    sort: true,
  },
  {
    dataField: 'category.nombre',
    text: t('default.grupo', 'Grupo'),
    headerStyle: () => ({ overflowWrap: 'break-word', width: '20%' }),
    style: () => ({ overflowWrap: 'break-word' }),
    sort: true,
  },
  {
    dataField: 'salidas.length',
    text: t('default.totalSalidas', 'Total Salidas'),
    align: 'center',
    headerAlign: 'center',
    headerStyle: () => ({ overflowWrap: 'break-word' }),
    sort: true,
  },
  {
    dataField: 'alertas.length',
    text: t('default.totalAlertas', 'Total Alarmas'),
    align: 'center',
    headerAlign: 'center',
    sort: true,
  },
  {
    dataField: 'inAlert',
    text: t('default.estado', 'Estado'),
    formatter: inAlert => (<StatusBullet inAlert={inAlert} center />),
    align: 'center',
    headerAlign: 'center',
    headerStyle: () => ({ overflowWrap: 'break-word' }),
    sort: true,
  },
  {
    dataField: 'expired',
    text: t('default.señal', 'Señal'),
    formatter: expired => (expired && (<StatusBullet expired={expired} center />)) || (<Fragment />),
    align: 'center',
    headerAlign: 'center',
    headerStyle: () => ({ overflowWrap: 'break-word' }),
    sort: true,
  },
  {
    dataField: 'buttons',
    text: '',
    headerStyle: () => ({ width: '7%' }),
    formatter: (cell, row) => (
      <>
        { seeMoreInfoButton && (
          <Link to={{
            pathname: '/moreInfo',
            rectificador: row,
            t,
          }}
          >
            <IconButton
              className={classes.icon}
              aria-label="confDevice"
              title={t('default.informacionBasica', 'Más Información')}
            >
              <AwesomeIcon icon="plus" />
            </IconButton>
          </Link>
        )}
        { seeConfButton && (
          <Link to={{
            pathname: '/newDevice',
            rectificador: row,
            origin: 'list',
          }}
          >
            <IconButton
              className={classes.icon}
              aria-label="confDevice"
              title={t('default.confDevice', 'Configuración de Equipo')}
            >
              <AwesomeIcon icon="settings" />
            </IconButton>
          </Link>
        )}
      </>
    ),
  },
  {
    dataField: 'fecha',
    text: t('default.fecha', 'Fecha'),
    // formatter: fecha =>  Moment(fecha).fromNow(),
    formatter: fecha => (fecha.includes('1900-01-01') ? t('default.deviceNoData', 'Equipo sin transmisiones') : <Moment locale={language === 'EN' ? 'en-gb' : 'es'} fromNow>{fecha}</Moment>),
    style: () => ({ overflowWrap: 'break-word' }),
    headerStyle: () => ({ overflowWrap: 'break-word' }),
    align: 'center',
    headerAlign: 'center',
    sort: true,
  }];

  const onSortChange = () => {
    // console.log(sortName);
    // console.log(sortOrder);
    setPage(1);
  };

  const options = {
    page,
    onSortChange,
  };

  columns.forEach((c) => {
    // eslint-disable-next-line no-param-reassign
    c.onSort = onSortChange;
  });

  return (
    <Container maxWidth="xl" className="ListView">
      <Grid container>
        <Link to="/newDevice" style={{ display: userData.mostrarBotonCrearEquipo && userData.permisos.findIndex(x => x.opcionCodigo === 'CRECT') > -1 ? 'inline-block' : 'none' }}>
          <IconButton
            aria-label="left"
            style={{
              position: 'fixed',
              bottom: '60px',
              right: '10px',
              backgroundColor: '#007bff',
              color: '#fff',
              zIndex: '999999',
            }}
          >
            <AwesomeIcon icon="plus" />
          </IconButton>
        </Link>
        <Grid item xs={12}>
          {/* <TablaBootstrap
            filteredData={filteredData}
            columns={columns}
            handleChange={handleChange}
            handleSelectAll={handleSelectAll}
            paginationOptions={paginationOptions}
            page={page}
            setPage={setPage}
            expandRow={expandRow}
          /> */}
          <BootstrapTable
            keyField="uniqueId"
            striped
            hover
            condensed
            bootstrap4
            data={filteredData}
            columns={columns}
            options={options}
            selectRow={{
              mode: 'checkbox',
              clickToSelect: true,
              selected: filteredData.filter(fd => fd.selected).map(fd => fd.uniqueId),
              onSelect: rec => handleChange(rec.uniqueId),
              onSelectAll: handleSelectAll,
            }}
            expandRow={expandRow}
            pagination={paginationFactory(paginationOptions)}
          />
        </Grid>
      </Grid>
      {filteredData.length === 0 && dataCount > 0 && (
        <Grid container>
          <Grid item xs={12} className="x-center">
            <h3><Trans i18nKey="default.noCards">No se encontraron equipos</Trans></h3>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

ListView.propTypes = Props;

export default translate('common')(composePure(
  withProps(props => ({ t: props.t })),
  connect(state => ({
    dataCount: state.Rectifiers.docs.filter(x => x.grupoId).length,
    equipos: state.Rectifiers.docs.filter(x => x.grupoId),
    language: state.Language.current,
    userData: state.Users.userData,
  }),
  dispatch => ({
    handleChange: async id => dispatch(Actions.Rectifiers.setSelected(id)),
  })),
  shouldUpdate((old, newprop) => JSON.stringify(old) !== JSON.stringify(newprop)),
)(ListView));
