import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
} from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
// import PageWrapper from '../../components/PageWrapper';
import FilterBarTableros from '../../components/FilterBarTableros';
import ListVariableGraphic from '../../components/ListVariableGraphic';
// import VariableDetail from '../../components/VariableDetail';
// import VariableDetailAMC from '../../components/VariableDetailAMC';

import Actions from '../../actions';
import {
  composePure,
  withState,
} from '../../utils/composepure';

import store from '../../store';
import Props from './props';

/** @description pagina contenedora para la visualizacion de Vista Tableros.
 */
const VistaTableros = ({
  rectificadores,
  // rectificadoresAll,
  variables,
  history,
  language,
  location,
}) => {
  // const [salidas, setSalidas] = useState([]);
  // const [variablesFiltered, setVariablesFiltered] = useState([]);
  const [variableSelected, setVariableSelected] = useState();
  // const [salidaSelected, setSalidaSelected] = useState();

  // const variablesSalida = variables.length === 0 ? [] : variables[0].salidas.filter(v => v.salidaNro === salidaSelected.value);

  /* const handleChangeSalida = (newValue) => {
    setSalidaSelected(newValue);
    // const variableAux = variables[0].salidas.filter(v => v.salidaNro === newValue.value);
    // setVariablesFiltered(variableAux);
  }; */

  const handleChangeRectificador = (newValue) => {
    // setVariablesFiltered([]);
    // setSalidas([]);
    // setSalidaSelected(null);
    store.dispatch(Actions.Variables.getVariablesByRectificador(newValue.value));

    // const rectFilter = rectificadoresAll.filter(r => r.rectificadorID === newValue.value);
    /* const salidasFilter = rectFilter[0].salidas.map(s => ({
      value: s.salidaID,
      label: `${(language === 'EN' ? 'Output Nro ' : 'Salida Nro')} ${s.salidaID}`,
    })); */
    // setSalidas(salidasFilter);
    // setSalidaSelected(salidasFilter[0]);
    // handleChangeSalida(salidasFilter[0]);
  };

  const handleVariableSelected = (variable) => {
    setVariableSelected(variable);
    console.log(variableSelected);
  };

  return (
    <Fragment>
      <FilterBarTableros
        rectificadores={rectificadores}
        // salidas={salidas}
        onChangeRectificadores={handleChangeRectificador}
      // onChangeSalida={handleChangeSalida}
      // salidaSelected={salidaSelected}
        location={location}
      />
      <Container maxWidth="xl" style={{ marginTop: '15px', paddingLeft: 0, paddingRight: 0 }}>
        <Grid container>
          <Grid item xs={12}>
            {
              history.location.pathname === '/tableros' && variables && variables.length > 0 && (
                <ListVariableGraphic
                  variables={variables}
                  history={history}
                  handleChangeVariable={handleVariableSelected}
                  language={language}
                />
              )
            }
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

VistaTableros.propTypes = Props;

const AppCompose = composePure(
  withState('loading', 'setLoading', true),
  withState('loaded', 'setLoaded', false),
  connect(state => ({
    rectificadoresAll: state.Rectifiers.docs.filter(x => x.grupoId),
    rectificadores: state.Rectifiers.docs.filter(x => x.grupoId).map(r => ({
      value: r.rectificadorID,
      label: r.nombre,
    })),
    variables: state.Variables.Variables,
    language: state.Language.current,
  })),
)(VistaTableros);

export default hot(module)(AppCompose);
