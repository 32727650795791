import {
  COMANDOS_FETCH_COMANDOSDISPONIBLES,
  COMANDOS_SEND_COMANDOS,
  COMANDOS_SET_SENDCOMANDORESULT,
  FETCH_COMANDOSBYDEVICE,
  FETCH_COMANDOSBYTIPOUNIDAD,
} from '../actionTypes/comandos';

export const getComandosDisponibles = () => ({
  type: COMANDOS_FETCH_COMANDOSDISPONIBLES,
});

export const sendComandos = comandos => ({
  type: COMANDOS_SEND_COMANDOS,
  payload: comandos,
});

export const setComandoSendResult = message => ({
  type: COMANDOS_SET_SENDCOMANDORESULT,
  payload: message,
});

export const getComandosByDevice = rectificador => ({
  type: FETCH_COMANDOSBYDEVICE,
  payload: rectificador,
});

export const getComandosDisponiblesByTipoUnidad = tipoUnidadMonitoreo => ({
  type: FETCH_COMANDOSBYTIPOUNIDAD,
  payload: tipoUnidadMonitoreo,
});

export default {
  getComandosDisponibles,
  sendComandos,
  setComandoSendResult,
  getComandosByDevice,
  getComandosDisponiblesByTipoUnidad,
};
