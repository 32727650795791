import {
  LANGUAGE_CHANGE,
} from '../actionTypes/language';


export const changeLanguage = language => ({
  type: LANGUAGE_CHANGE,
  payload: language,
});

export default {
  changeLanguage,
};
