import React from 'react';
import {
  Carousel,
} from 'react-bootstrap';
/* import {
  Paper,
} from '@material-ui/core'; */

import './style.css';
import Props from './props';

/** @description Componente interno de una grilla utilizado al momento de obtener mas detalle,
 * expandir una fila.
 */
const ChildRowList = ({
  row,
  language,
}) => {
  const getMsg = (salida) => {
    let msg = `Salida ${salida.salidaID} | `;
    salida.valores.forEach((v) => {
      const listaValor = language === 'EN' ? v.listaValorIngles : v.listaValor;
      const valor = v.valor !== null ? v.valor : '';

      msg += `${language === 'EN' ? v.nombreEn : v.nombreEs} : ${v.listaValorID ? listaValor : valor} | `;
    });
    return msg;
  };

  return (
    <Carousel
      indicators={false}
      interval={null}
      nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}
      prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}
    >
      {row.salidas.map(s => (
        <Carousel.Item key={`${Math.random()}-${s.salidaID} `}>
          <div style={{ width: '80%', textAlign: 'center', margin: 'auto' }}>
            {getMsg(s)}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

ChildRowList.propTypes = Props;

export default ChildRowList;
