import * as signalR from '@aspnet/signalr';
import {
  takeEvery,
  takeLatest,
  put,
  all,
  select,
} from 'redux-saga/effects';

import config from '../config';

import Actions from '../actions';

import {
  requestModule,
  uploadImage,
} from './requestmodule';

import {
  getRectifiers,
  getGroups,
  getInicioAlarma,
  getConfReporte,
  getAvailableDevices,
  getZonaHorarias,
  getTiposRectificador,
  addDevice,
  removeDevice,
  getDeviceById,
  addDeviceToGroup,
  removeDeviceFromGroup,
  createGrupo,
  getLastReport,
  deleteGroup,
  getLocacionDevice,
  crearRectificador,
  getAvailableDevicesForGroup,
} from './rectifiers';

import {
  getTiposMedioContacto,
  getEmpresasAll,
  getMarcasAll,
  getTipoUnidadMonitoreoAll,
  getUnidadesComunicacionAll,
  getLineasAll,
  getTipoComunicacionAll,
  saveUnidadComunicacion,
  deleteUnidadComunicacion,
  getPlanesAll,
  getCuentasIPGateway,
  saveLinea,
  deleteLinea,
} from './maestros';

import {
  userLogin,
  userLogout,
  saveUserPreference,
  saveProxySettings,
  saveUserData,
} from './users';

import {
  getComandosEnviados,
  getAlarmas,
  getNovedades,
  // getNotificaciones,
  addNotaComando,
  addNotaAlarma,
  updateNovedad,
  getConfAlarmas,
  saveConfAlarma,
  updateConfAlarma,
  deleteConfAlarma,
  getNovedadesByDevice,
  saveNovedad,
  removeNovedad,
} from './cuadros';

import {
  getDisponibilidad,
} from './disponibilidad';

import {
  getVariablesByRectificador,
  getVariableByRectAndConfReporte,
  getVariablesOperacion,
} from './variables';

import {
  getFilterTypes,
} from './filters';

import {
  getComandosDisponibles,
  sendComando,
  getComandosByDevice,
  getComandosDisponiblesPorTipoUnidad,
  downloadComandResponse,
} from './comandos';

import {
  getNotificaciones,
  deleteNotificacion,
  deleteNotificacionAll,
  downloadReport,
  markReadNotificacion,
} from './notificaciones';

import {
  ejecutarReporteHistorico,
  ejecutarReporteActual,
} from './reportes';

import {
  apiClient,
} from '../utils/apiClient';

let connection = null;

const transport = signalR.HttpTransportType.WebSockets;
const protocol = new signalR.JsonHubProtocol();

const options = {
  transport,
  logMessageContent: false,
  // skipNegotiation: true,
  logger: signalR.LogLevel.Trace,
};

/** @description enviar mensajes
*/
function* sendMessage(action) {
  try {
    if (!connection) return;
    connection.invoke('send', 'react', action.payload);
    yield put({
      type: 'SENT',
    });
  } catch (err) {
    throw err;
  }
}

/** @description iniciar estados
*/
function* initAppState() {
  try {
    const state = yield select();
    if (!state.Users.logged) return;
    const {
      usuarioId,
      // proxyDomain,
      // proxyPassword,
      // proxyUserName,
      // proxyUrl,
    } = state.Users.userData;

    connection = new signalR.HubConnectionBuilder()
      .withUrl(config.SIGNALR_HUB, options)
      // .withUrl(config.SIGNALR_HUB, h => h.Proxy = webProxy)
      .withHubProtocol(protocol)
      .build();

    connection.on('broadcastCard', window.onReportReceived);
    connection.on('broadcastNotification', window.onNotifReceived);

    // connection.start();
    connection.start()
      .then(() => {
        connection.invoke('RegisterConnection', state.Users.userData.usuarioId);
      })
      .catch(err => console.error('SignalR Connection Error: ', err));

    yield put({
      type: 'CONNECTION_READY',
    });
    yield all([
      put(Actions.Rectifiers.fetchRectifiers(usuarioId)),
      put(Actions.Groups.fetchGroups(usuarioId)),
      put(Actions.Filters.getAlertType()),
      // put(Actions.Comandos.getComandosDisponibles()),
      put(Actions.Notificaciones.getNotificaciones(usuarioId)),
    ]);
  } catch (e) {
    console.error('Error initialState: ', e);
  }
}

// function* initVistaCuadrosState() {
//   try {
//     const state = yield select();
//     if (!state.Users.logged) return;
//     const { usuarioId } = state.Users.userData;
//     yield all([
//       put(Actions.Cuadros.getComandosEnviados(usuarioId)),
//       put(Actions.Cuadros.getAlarmas(usuarioId)),
//       put(Actions.Cuadros.getNovedades(usuarioId)),
//       put(Actions.Cuadros.getNotificaciones(usuarioId)),
//     ]);
//   } catch (e) {
//     console.error('Error initial vista cuadros: ', e);
//   }
// }

function* mySaga() {
  try {
    yield takeLatest('INIT_APP_STATE', initAppState);
    // yield takeLatest('INIT_VISTACUADROS_STATE', initVistaCuadrosState);

    yield takeEvery('CHAT_SEND_MESSAGE', sendMessage);
    yield takeEvery([
      'UNIVERSAL_REMOVE_MODULE',
      'UNIVERSAL_UPDATE_MODULE',
      'UNIVERSAL_INSERT_MODULE',
      'UNIVERSAL_FETCH_MODULE',
    ], requestModule);
    yield takeEvery('UNIVERSAL_UPLOAD_FILE', uploadImage);

    yield takeLatest('USER_LOGIN', userLogin(apiClient));
    yield takeEvery('USER_FETCH_LOGOUT', userLogout(apiClient));

    yield takeLatest('DISPONIBILIDAD_CONSULTAR', getDisponibilidad(requestModule));

    yield takeLatest('CUADROS_FETCH_COMANDOS', getComandosEnviados(requestModule));
    yield takeLatest('CUADROS_FETCH_ALARMAS', getAlarmas(requestModule));
    yield takeLatest('CUADROS_FETCH_NOVEDADES', getNovedades(requestModule));
    yield takeLatest('FETCH_CONFIGURACIONALARMAS', getConfAlarmas(requestModule));

    yield takeLatest('TABLEROS_FETCH_VARIABLES', getVariablesByRectificador(requestModule));
    yield takeLatest('FETCH_VARIABLEMULTIPLESALIDA', getVariableByRectAndConfReporte(requestModule));

    yield takeLatest('RECTIFIERS_FETCH_ALL', getRectifiers(requestModule));
    yield takeLatest('FETCH_CONFIGURACIONREPORTE', getConfReporte(requestModule));
    yield takeLatest('FETCH_GROUPS', getGroups(requestModule));
    yield takeLatest('FILTER_FETCH_ALERT_TYPE', getFilterTypes(requestModule));
    yield takeLatest('COMANDOS_FETCH_COMANDOSDISPONIBLES', getComandosDisponibles(requestModule));
    yield takeLatest('FETCH_COMANDOSBYTIPOUNIDAD', getComandosDisponiblesPorTipoUnidad(requestModule));
    yield takeLatest('FETCH_COMANDOSBYDEVICE', getComandosByDevice(requestModule));

    yield takeLatest('DOWNLOAD_COMMANDRESPONSE', downloadComandResponse());

    yield takeLatest('CUADROS_ADDNOTA_COMANDOS', addNotaComando(apiClient));
    yield takeLatest('CUADROS_ADDNOTA_ALARMAS', addNotaAlarma(apiClient));
    yield takeLatest('COMANDOS_SEND_COMANDOS', sendComando(apiClient));
    yield takeLatest('CUADROS_UPDATE_NOVEDAD', updateNovedad(apiClient));
    yield takeLatest('SAVE_CONFIGURACIONALARMA', saveConfAlarma(apiClient));
    yield takeLatest('UPDATE_CONFIGURACIONALARMA', updateConfAlarma(apiClient));
    yield takeLatest('DELETE_CONFIGURACIONALARMA', deleteConfAlarma(apiClient));
    yield takeLatest('SAVE_NOVEDAD', saveNovedad(apiClient));

    yield takeLatest('GET_NOTIFICACIONES', getNotificaciones(requestModule));
    yield takeLatest('MARK_READED', markReadNotificacion(requestModule));
    yield takeLatest('DELETE_NOTIFICACIONES', deleteNotificacion(requestModule));
    yield takeLatest('DELETE_NOTIFICACIONES_ALL', deleteNotificacionAll(requestModule));
    yield takeLatest('DOWNLOAD_REPORT', downloadReport());
    yield takeLatest('PROCESS_HISTORICO', ejecutarReporteHistorico(apiClient));
    yield takeLatest('PROCESS_ACTUAL', ejecutarReporteActual(apiClient));
    yield takeLatest('ADD_INICIOALARMA', getInicioAlarma(requestModule));
    yield takeLatest('FETCHNOVEDADES_BYDEVICE', getNovedadesByDevice(requestModule));
    yield takeLatest('REMOVE_NOVEDAD', removeNovedad(apiClient));

    yield takeLatest('FETCH_AVAILABLEDEVICES', getAvailableDevices(requestModule));
    yield takeLatest('FETCH_AVAILABLEDEVICES_FORGROUP', getAvailableDevicesForGroup(requestModule));

    yield takeLatest('FETCH_ZONAHORARIAS', getZonaHorarias(requestModule));
    yield takeLatest('FETCH_TIPOSRECTIFICADOR', getTiposRectificador(requestModule));
    yield takeLatest('ADD_DEVICE', addDevice(apiClient));
    yield takeLatest('GUARDAR_REACTIFICADOR', crearRectificador(apiClient));
    yield takeLatest('REMOVE_RECTIFICADOR', removeDevice(apiClient));
    yield takeLatest('FETCH_DEVICEBYID', getDeviceById(requestModule));
    yield takeLatest('ADD_RECTIFIERTOGROUP', addDeviceToGroup(apiClient));
    yield takeLatest('REMOVE_RECTIFIERTOGROUP', removeDeviceFromGroup(apiClient));

    yield takeLatest('GET_LASTREPORT', getLastReport(requestModule));

    yield takeLatest('FETCH_VARIABLESOPERACION', getVariablesOperacion(requestModule));

    yield takeLatest('CREATE_GROUP', createGrupo(apiClient));
    yield takeLatest('DELETE_GROUP', deleteGroup(apiClient));

    yield takeLatest('SAVE_PREFERENCES', saveUserPreference(apiClient));
    yield takeLatest('SAVE_PROXYSETTINGS', saveProxySettings(apiClient));
    yield takeLatest('SAVE_USERDATA', saveUserData(apiClient));

    yield takeLatest('GET_LOCATIONDEVICE', getLocacionDevice(requestModule));

    /* MAESTROS */
    yield takeLatest('FETCH_TIPOMEDIOCONTACTO', getTiposMedioContacto(requestModule));
    yield takeLatest('FETCH_EMPRESASALL', getEmpresasAll(requestModule));
    yield takeLatest('FETCH_MARCASALL', getMarcasAll(requestModule));
    yield takeLatest('FETCH_TIPOUNIDADALL', getTipoUnidadMonitoreoAll(requestModule));
    yield takeLatest('FETCH_UNIDADESCOMUNICACIONALL', getUnidadesComunicacionAll(requestModule));
    yield takeLatest('FETCH_LINEASALL', getLineasAll(requestModule));
    yield takeLatest('FETCH_TIPOCOMUNICACIONALL', getTipoComunicacionAll(requestModule));
    yield takeLatest('SAVE_UNIDADCOMUNICACION', saveUnidadComunicacion(apiClient));
    yield takeLatest('DELETE_UC', deleteUnidadComunicacion(apiClient));
    yield takeLatest('FETCH_PLANES', getPlanesAll(requestModule));
    yield takeLatest('FETCH_CUENTASIPGATEWAY', getCuentasIPGateway(requestModule));
    yield takeLatest('SAVE_LINEA', saveLinea(apiClient));
    yield takeLatest('DELETE_LINEA', deleteLinea(apiClient));
  } catch (err) {
    console.error('mySaga Error: ', err);
  }
}


export default mySaga;
