/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';

import {
  Card,
  Col,
  Row,
  Container,
} from 'react-bootstrap';

import Moment from 'react-moment';
import AwesomeIcon from '../AwesomeIcon';

import CardViewStatus from '../CardViewStatus';
import CardViewItemTabs from '../CardViewItemTabs';

import Props from './props';

/** @description Componenete modal para incluir en la vista del mapa.
 * Se utiliza para mostrar mas informacion referida a un punto especifico.
 */
const ModalMap = ({
  rectify,
  language,
  t,
  history,
}) => {
  const handleMoreInfo = () => {
    history.push({
      pathname: '/moreInfo',
      rectificador: rectify,
      t,
    });
  };

  return (
    <Card className="CardFilterData">
      <Container>
        <Row className="CardFilterData__header">
          <Col xs={12}>
            <Row className="y-center mt-2">
              <Col xs={2} className="xy-center" />
              <Col xs={6} className="CardFilterData__header__time y-center x-end m-0">
                <AwesomeIcon icon="clock" rigthText={<Moment locale={language === 'EN' ? 'en-gb' : 'es'} fromNow>{rectify.fecha}</Moment>} />
              </Col>
              <Col xs={4} className="CardFilterData__header__status x-end">
                <CardViewStatus
                  fecha={rectify.fecha}
                  inAlert={rectify.inAlert}
                  expired={rectify.expired}
                  t={t}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="y-center CardFilterData__header__title">
            <button
              type="button"
              style={{ backgroundColor: 'transparent', border: 'none', color: 'white' }}
              onClick={handleMoreInfo}
            >
              <span>{rectify.nombre}</span>
            </button>
          </Col>
          <Col xs={12} className="y-center CardFilterData__header__desc">
            <span>{`${rectify.category.nombre} - ${rectify.tipoUnidadMonitoreo} - Salidas: ${rectify.salidas.length}`}</span>
          </Col>
        </Row>
      </Container>
      <Card.Body>
        <CardViewItemTabs
          uniqueId={rectify.uniqueId}
          salidas={rectify.salidas}
          fecha={rectify.fecha}
          alertas={rectify.alertas}
          t={t}
          language={language}
        />
      </Card.Body>
    </Card>
  );
};

ModalMap.propTypes = Props;


export default ModalMap;
