import React, { Fragment } from 'react';
import Props from './props';

/** @description componente reutilizable que muestra el estado de un determinado rectificador.
 */
const StatusBullet = ({
  inAlert,
  expired,
  xs,
  center,
  t,
}) => {
  if (expired !== undefined) {
    return (<div title={t ? t('default.inactivo', 'Inactivo (10 dias)') : 'Inactivo (10 dias)'} className={`StatusRadio--expired ${center && 'center'}`} />);
  }
  return (
    <Fragment>
      {inAlert && <div className={`StatusRadio--inAlert ${xs && 'xs'} ${center && 'center'}`} />}
      {!inAlert && (<div className={`StatusRadio--normal ${xs && 'xs'} ${center && 'center'}`} />)}
    </Fragment>
  );
};

StatusBullet.propTypes = Props;
export default StatusBullet;
