import React from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Container,
  Grid,
} from '@material-ui/core';
import { translate } from 'react-i18next';
import Moment from 'moment';
import { composePure, withProps, lifecycle } from '../../utils/composepure';
import store from '../../store';
import Props from './props';

/** @description Componente especifico para mostrar las notificaciones efectuadas en forma de lista.
 * Se muestra evento, fecha y estado.
 */
const GridNotificaciones = ({
  notificaciones,
  t,
  filter,
  language,
}) => {
  const filteredData = notificaciones.filter(n => !filter || n.evento.toLowerCase().indexOf(filter.toLowerCase()) > -1);

  const columns = [{
    dataField: language === 'EN' ? 'eventoIngles' : 'evento',
    text: t('default.evento', 'Evento'),
    headerStyle: () => ({ width: '50%' }),
    sort: true,
  },
  {
    dataField: 'fecha',
    text: t('default.fecha', 'Fecha'),
    formatter: fecha => Moment(fecha).format('DD/MM/YYYY HH:mm:ss'),
    headerStyle: () => ({ width: '25%' }),
    sort: true,
  },
  {
    dataField: language === 'EN' ? 'estadoIngles' : 'estado',
    text: t('default.estado', 'Estado'),
    headerStyle: () => ({ width: '25%' }),
    sort: true,
  },
  {/*
    dataField: 'notas',
    text: t('default.notas', 'Notas'),
    headerStyle: () => ({ width: '45%' }),
    sort: true,
  */},
  ];

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t('default.pagPrev', 'página previa'),
    nextPageTitle: t('default.proxPag', 'próxima página'),
    firstPageTitle: t('default.primerPagina', 'primer página'),
    lastPageTitle: t('default.ultimaPagina', 'última página'),
  };

  return (
    <Container maxWidth="xl" className="ListView gf-font-and-padi">
      <Grid container>
        <Grid item xs={12}>
          <BootstrapTable
            keyField="notificacionId"
            striped
            hover
            condensed
            bootstrap4
            data={filteredData}
            columns={columns}
            pagination={paginationFactory(options)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

GridNotificaciones.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
  lifecycle({
    componentDidMount() {
      store.dispatch({ type: 'CUADROS_FETCH_NOTIFICACIONES' });
    },
  }),
  connect(state => ({
    working: state.Universal.request.process,
    notificaciones: state.Notificaciones.NotificacionesRecibidas,
    language: state.Language.current,
  })),
)(GridNotificaciones);

export default translate('common')(AppCompose);
