import React from 'react';
// import ListVariableGraphicItem from '../ListVariableGraphicItem';
// import AMChart from '../AMChart';
import GoogleChart from '../GoogleChart';
import './style.css';
import Props from './props';

/** @description Componente especifico para mostrar las variables graficadas con el componente
 * Google Chart.
 */
const ListVariableGraphic = ({
  variables,
  history,
  handleChangeVariable,
  language,
}) => {
  const distinctVariables = [...new Set(variables.map(x => x.configuracionReporteID))];
  return (
    <div style={{ marginTop: 50 }}>
      <div style={{ columnCount: 2, columnGap: '25px' }} className="gf-column-count">
        {distinctVariables.map((v) => {
          const filteredData = variables.filter(x => x.configuracionReporteID === v);
          return (
            <div className="chartContainer">
              <GoogleChart
                key={v}
                variable={v}
                filteredData={filteredData}
                handleChangeVariable={handleChangeVariable}
                history={history}
                language={language}
                goToDetail
              />
            </div>
            /* <GoogleChart
              key={v}
              variable={v}
              filteredData={filteredData}
              handleChangeVariable={handleChangeVariable}
              history={history}
              language={language}
              goToDetail
            /> */
          );
        })}
      </div>
    </div>
  );
};
// style={{ columnCount: 1, columnGap: '25px' }}
/*
  <AMChart
    key={v}
    // variable={v}
    filteredData={filteredData}
    handleChangeVariable={handleChangeVariable}
    history={history}
    language={language}
  />
*/

/*
  <ListVariableGraphicItem
    key={v.configuracionReporteID}
    history={history}
    variable={v}
    handleChangeVariable={handleChangeVariable}
  />
*/

ListVariableGraphic.propTypes = Props;
export default ListVariableGraphic;
