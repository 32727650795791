import React, { Fragment } from 'react';
import Chart from 'react-google-charts';
import { Link } from 'react-router-dom';
import Moment from 'moment';

// import Image from '../Image';

import Props from './props';

/** @description Componente de Google Generico para realizar distintos graficos soportados.
 */
const GoogleChart = ({
  // variable,
  filteredData,
  // handleChangeVariable,
  // goToDetail,
  // history,
  language,
}) => {
  const salidas = [...new Set(filteredData.map(x => x.salidaNro))];
  const reportes = [...new Set(filteredData.map(x => x.reporteID))];
  const headerData = ['Fecha'];
  const datasource = [];

  salidas.forEach((s) => {
    headerData.push(language === 'EN' ? `Output ${s}` : `Salida ${s}`);
    headerData.push({ type: 'string', role: 'style' });
    // headerData.push({ type: 'string', role: 'annotationText', p: { html: true } });
    headerData.push({ role: 'tooltip', type: 'string', p: { html: true } });
  });

  // headerData.push({ type: 'string', role: 'style' });
  // headerData.push({ role: 'tooltip', type: 'string', p: { html: true } });

  datasource.push(headerData);

  reportes.forEach((reporteID) => {
    const row = [];
    const filterByReport = filteredData.filter(x => x.reporteID === reporteID);
    row.push(new Date(filterByReport[0].fechaRecepcion.toString()));
    // row.push(new Date(Moment(filterByReport[0].fechaRecepcion).format('DD/MM/YYYY HH:mm:ss')));
    salidas.forEach((salida) => {
      const valorSalida = filterByReport.filter(x => x.salidaNro === salida);
      const valor = valorSalida.length > 0 ? parseFloat(valorSalida[0].valor.replace(',', '.')) : null;
      row.push(valor);
      row.push(valorSalida.length > 0 && valorSalida[0].esAnomalo ? 'point { size: 6 }' : null);
      row.push(`<div style="background-color: black; color: white; padding: 5px; width: 170px">
      <span>
      <b>Fecha:</b>
      ${Moment(filterByReport[0].fechaRecepcion).format('DD/MM/YYYY HH:mm:ss')}</span></br>
      <span><b>Valor:</b> ${valor}${filteredData[0].unidadMedida !== null ? filteredData[0].unidadMedida : ''}
      ${valorSalida.length > 0 && valorSalida[0].esAnomalo ? '<img width="24" height="24" src="https://cdn0.iconfinder.com/data/icons/super-mono-reflection/red/exclamation-circle_red.png" />' : ''}
      </span>
      </div>`);
    });
    datasource.push(row);
  });

  const options = {
    // title: `${language === 'EN' ? filteredData[0].nombreIngles : filteredData[0].nombre} ${filteredData[0].unidadMedida !== null ? filteredData[0].unidadMedida : ''}`,
    backgroundColor: '#DEE0E1',
    colors: ['cornflowerblue', 'tomato', 'green', 'yellow', 'purple'],
    // fontName: 'Open Sans',
    // focusTarget: 'category',
    curveType: 'function',
    legend: { position: 'bottom' },
    areaOpacity: 0.24,
    lineWidth: 3,
    hAxis: {
      format: 'dd/MM/yyyy',
      gridlines: {
        color: 'transparent',
      },
    },
    /* chartArea: {
      width: '85%',
    }, */
    chartArea: {
      left: 50,
      width: '100%',
      height: '80%',
    },
    pointSize: 1,
    animation: {
      startup: true,
      easing: 'linear',
      duration: 1500,
    },
    tooltip: { isHtml: true, trigger: 'visible' },
    series: {
      1: { curveType: 'function' },
    },
  };

  return (
    <Fragment>
      <Link
        style={{ textDecoration: 'underline' }}
        to={{
          pathname: '/variableDetail',
          data: filteredData,
          language,
          rectificador: filteredData[0].rectificador,
          rectificadorID: filteredData[0].rectificadorID,
          variable: filteredData[0].configuracionReporteID,
        }}
      >
        <span>{`${language === 'EN' ? filteredData[0].nombreIngles : filteredData[0].nombre} ${filteredData[0].unidadMedida !== null ? filteredData[0].unidadMedida : ''}`}</span>
      </Link>
      <Chart
        chartType="LineChart"
        data={datasource}
        graph_id={`LineChart_${filteredData[0].configuracionReporteID}`}
        width="100%"
        height="500px"
        style={{ marginBottom: '25px' }}
        options={options}
        /* chartEvents={[
          {
            eventName: 'select',
            callback: ({ chartWrapper }) => {
              if (goToDetail) {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                const [selectedItem] = selection;
                const { row } = selectedItem;
                const item = datasource[row + 1];
                // 2 es el indice del punto que indica si es anomalo
                // si no es anomalo está en null
                if (item[2]) {
                  handleChangeVariable(variable);
                  // history.push('/variableDetail');
                  history.push({
                    pathname: '/variableDetail',
                    data: filteredData,
                    language,
                    rectificador: filteredData[0].rectificador,
                  });
                }
              }
            },
          },
        ]} */
      />
    </Fragment>
  );
};

GoogleChart.propTypes = Props;

export default GoogleChart;
