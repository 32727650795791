import {
  put,
  call,
  select,
} from 'redux-saga/effects';

import axios from 'axios';
import { saveAs } from 'file-saver';

import Actions from '../actions';
import config from '../config';

/** @description Llamada al EndPoint para descargar reporte.
 * URL: /ReportePlanificado/DownloadReporte/${id}/${tipoReporte}
*/
export const downloadReport = () => function* downloadReportSaga(action) {
  const { id, tipoReporte, language } = action.payload;
  yield axios
    .get(`${config.ENDPOINT_URL}/ReportePlanificado/DownloadReporte/${id}/${tipoReporte}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    })
    .then((res) => {
      const blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel',
      });

      const currentReportName = language === 'EN' ? 'CurrentReport' : tipoReporte;
      const historicalReportName = language === 'EN' ? 'HistoricalReport' : tipoReporte;
      const reportName = `${tipoReporte === 'ReporteActual' ? currentReportName : historicalReportName}`;
      saveAs(blob, `${reportName}_${id}.xlsx`);
    });
};

/** @description Llamada al EndPoint para obtener notificaciones.
*/
export const getNotificaciones = requestModule => function* getNotificacionesSaga() {
  try {
    const state = yield select();
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_NOTIFICACIONES',
      module: `Notificacion/ByUser/${state.Users.userData.usuarioId}`,
      query,
      // process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para eliminar notificaciones.
 * URL: Notificacion/DeleteNotification/${action.payload.userId}/${action.payload.notificacionId}
*/
export const deleteNotificacion = apiClient => function* deleteNotificacionSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: `Notificacion/DeleteNotification/${action.payload.userId}/${action.payload.notificacionId}`,
      method: 'delete',
      // data: action.payload,
      process: true,
    };

    yield call(apiClient, params);
    yield put(Actions.Notificaciones.setDeleteNotificacion(action.payload.notificacionId));


    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para eliminar todas las notificaciones.
 * URL: Notificacion/DeleteNotificationAll
*/
export const deleteNotificacionAll = apiClient => function* deleteNotificacionSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Notificacion/DeleteNotificationAll',
      method: 'delete',
      data: action.payload,
      // dispatchAction: 'DELETE_SET_NOTIFICATION_ALL',
      process: true,
    };

    yield call(apiClient, params);
    yield put(Actions.Notificaciones.setDeleteNotificacionesAll(action.payload.notifications));


    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para lectura de notificaciones.
 * URL: Notificacion/MarkNotificacionsRead
*/
export const markReadNotificacion = apiClient => function* markReadNotificacionSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Notificacion/MarkNotificacionsRead',
      method: 'post',
      data: action.payload,
      // dispatchAction: 'DELETE_SET_NOTIFICATION_ALL',
      process: true,
    };

    yield call(apiClient, params);
    yield put(Actions.Notificaciones.setMarkReaded(action.payload.notifications));


    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

// COMANDOS_SEND_COMANDOS

export default {

};
