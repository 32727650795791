import {
  put,
  call,
} from 'redux-saga/effects';
import axios from 'axios';
import { saveAs } from 'file-saver';
import config from '../config';

/** @description Llamada al EndPoint para ejecucion de reporte de historico.
 * URL: ReportePlanificado/ProcesarReporteHistorico
*/
export const ejecutarReporteHistorico = apiClient => function* ejecutarReporteHistoricoSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'ReportePlanificado/ProcesarReporteHistorico',
      method: 'post',
      data: action.payload,
      process: true,
    };
    // const response =
    yield call(apiClient, params);
    /* if (response.status === 200) {
      yield put(Actions.Comandos.setComandoSendResult(response.data));
    } */

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para descargar reporte.
*/
const downloadReport = (notificacionID) => {
  axios
    .get(`${config.ENDPOINT_URL}/ReportePlanificado/DownloadReporte/${notificacionID}/ReporteActual`, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    })
    .then((res) => {
      const blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel',
      });

      saveAs(blob, `ReporteActual_${notificacionID}.xlsx`);
    });
};

/** @description Llamada al EndPoint para ejecutar reporte actual.
*/
export const ejecutarReporteActual = apiClient => function* ejecutarReporteActualSaga(action) {
  try {
    const data = {
      ...action.payload,
      Reportes: action.payload.Reportes.map(r => ({
        ...r,
        salidas: r.salidas.map(s => ({
          ...s,
          valores: s.valores.map(v => ({
            ...v,
            valor: v.valor.split('[')[0],
            nombreEs: `${v.nombreEs}${v.valor.split('[').length === 2 ? `[${v.valor.split('[')[1]}` : ''}`,
            nombreEn: `${v.nombreEn}${v.valor.split('[').length === 2 ? `[${v.valor.split('[')[1]}` : ''}`,
          })),
        })),
      })),
    };
    console.log(data);
    const params = {
      query: {},
      microservice: '',
      module: 'ReportePlanificado/ProcesarReporteActual',
      method: 'post',
      data,
      process: true,
    };
    // const response =
    const response = yield call(apiClient, params);

    if (response.status === 200) {
      downloadReport(response.data);
    }
    /* if (response.status === 200) {
      yield put(Actions.Comandos.setComandoSendResult(response.data));
    } */

    return true;
  } catch (err) {
    console.log(err);
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export default {

};
