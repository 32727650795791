import React from 'react';
import Select from 'react-select';
import { Row } from 'react-bootstrap';
import { Grid, Container } from '@material-ui/core';

import { translate } from 'react-i18next';
import { composePure, withProps, lifecycle } from '../../utils/composepure';

import Actions from '../../actions';
import store from '../../store';

import Props from './props';

/** @description Componente lista de Rectificadores para dicha vista.
 */
const FilterBarTableros = ({
  rectificadores,
  // salidas,
  onChangeRectificadores,
  // onChangeSalida,
  // salidaSelected,
  location,
  t,
}) => (
  <Row>
    <Container maxWidth="xl" className="FilterBarHeader">
      <Row>
        <Grid container>
          <Grid item xs={3} className="gf-expand-menu">
            <Select
              className="basic-multi-select"
              classNamePrefix="select"
              defaultValue={location && location.rectificador ? rectificadores.filter(x => x.value === location.rectificador)[0] : rectificadores[0]}
              name="rectificadores"
              placeholder={t('default.rectificadores', 'Rectificadores')}
              options={rectificadores}
              onChange={onChangeRectificadores}
            />
          </Grid>
          {/* <Grid item xs={3} className="mr-2">
            <Select
              className="basic-multi-select"
              classNamePrefix="select"
              defaultValue={salidas[0]}
              name="salidas"
              placeholder={t('default.salidas', 'Salidas')}
              options={salidas}
              onChange={onChangeSalida}
              value={salidaSelected}
            />
          </Grid> */}
        </Grid>
      </Row>
    </Container>
  </Row>
);

FilterBarTableros.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
  lifecycle({
    componentDidMount() {
      const { rectificadores, location } = this.props;
      const rectId = location && location.rectificador ? rectificadores.filter(x => x.value === location.rectificador)[0].value : rectificadores[0].value;
      store.dispatch(Actions.Variables.getVariablesByRectificador(rectId));
    },
  }),
)(FilterBarTableros);

export default translate('common')(AppCompose);

// export default FilterBarTableros;
