import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
} from '@material-ui/core';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { translate } from 'react-i18next';
import { composePure, withProps } from '../../utils/composepure';

// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableRow from '@material-ui/core/TableRow';


import Props from './props';

/** @description Componente especifico para mostrar Disponibilidad efectuada en forma de lista.
 * Se muestra dispositivo, salida, disponibilidad.
 */
const ListDisponibilidad = ({
  disponibilidades,
  t,
}) => {
  const disp = disponibilidades.map(d => ({
    rectificadorId: d.rectificadorId,
    rectificador: d.rectificador,
    datoValido: d.datoValido,
    porcentajeConectado: d.porcentajeConectado,
    horasConectado: `${t('default.horasConectado', 'Horas conectado')}: ${d.horasOperativoReal}hs / ${d.horasOperativoTeorico}hs (${(d.porcentajeConectado).toFixed(2)}%)`,
    salidaNro: d.salidaNro,
  }));
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    connected: {
      backgroundColor: '#86E876 !important',
    },
    disconnected: {
      backgroundColor: '#E85C80 !important',
    },
    incorrect: {
      backgroundColor: '#E7D118 !important',
    },
  }));
  const classes = useStyles();

  const columns = [{
    dataField: 'rectificador',
    text: t('default.dispositivo', 'Dispositivo'),
    headerStyle: () => ({ width: '40%' }),
    sort: true,
    editable: false,
  },
  {
    dataField: 'salidaNro',
    text: t('default.salida', 'Salida'),
    headerStyle: () => ({ width: '10%' }),
    sort: true,
    editable: false,
  },
  {
    dataField: 'horasConectado',
    text: t('default.disponibilidad', 'Disponibilidad'),
    headerStyle: () => ({ width: '50%' }),
    sort: true,
    editable: false,
  },
  ];

  const rowClasses = (row) => {
    let className = classes.connected;
    if (!row.datoValido) {
      className = classes.incorrect;
    } else if (row.porcentajeConectado < 80) {
      className = classes.disconnected;
    }

    return className;
  };

  return (
    <Container className="gf-font-and-padi">
      <BootstrapTable
        keyField="rectificadorId"
        striped
        hover
        condensed
        bootstrap4
        data={disp}
        columns={columns}
        rowClasses={rowClasses}
        pagination={paginationFactory()}
      />
      {/*
      <Table className={classes.table}>
        <TableBody>
          {disponibilidades.map(d => (
            <TableRow key={d.rectificadorId} className={d.datoValido ? classes.okClass : classes.alertClass}>
              <TableCell>{d.rectificador}</TableCell>
              <TableCell>
                {`Horas conectado: ${d.horasOperativoReal}hs / ${d.horasOperativoTeorico}hs (${(d.porcentajeConectado).toFixed(2)}%)`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> */}
    </Container>
  );
};

ListDisponibilidad.propTypes = Props;


const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
)(ListDisponibilidad);

export default translate('common')(AppCompose);
// export default ListDisponibilidad;
