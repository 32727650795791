import {
  COMANDOS_SET_SENDCOMANDORESULT,
  COMANDOS_SET_COMANDOSDISPONIBLES,
} from '../actionTypes/comandos';

const initialState = {
  ComandosDisponibles: [],
  SendMessageResult: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COMANDOS_SET_COMANDOSDISPONIBLES:
      return {
        ...state,
        ComandosDisponibles: action.payload.data,
      };
    case COMANDOS_SET_SENDCOMANDORESULT:
      return {
        ...state,
        SendMessageResult: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
