import Universal from './universal';
import Forms from './forms';
import Language from './language';
import Rectifiers from './rectifiers';
import Users from './users';
import Filters from './filters';
import Cuadros from './cuadros';
import Groups from './groups';
import Disponibilidad from './disponibilidad';
import Variables from './variables';
import Comandos from './comandos';
import Notificaciones from './notificaciones';
import Maestros from './maestros';

export default {
  Universal,
  Forms,
  Language,
  Rectifiers,
  Users,
  Filters,
  Cuadros,
  Groups,
  Disponibilidad,
  Variables,
  Comandos,
  Notificaciones,
  Maestros,
};
