import React, { useState, useEffect } from 'react';
// import Select from 'react-select';

import {
  Dialog,
  DialogContent,
  // DialogContentText,
  DialogActions,
  Button,
  // useTheme,
  // useMediaQuery,
  IconButton,
  makeStyles,
  FormGroup,
  Grid,
  FormControl,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControlLabel,
  withStyles,
  Checkbox,
} from '@material-ui/core';

import Moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import format from 'date-fns/format';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { translate, Trans } from 'react-i18next';

import CheckboxTreeRectificadores from '../CheckboxTreeRectificadores';

import { composePure, withProps } from '../../utils/composepure';

import AwesomeIcon from '../AwesomeIcon';
import theme from '../../scss/theme/colors';

import Actions from '../../actions';
import store from '../../store';

// import ComandoControl from '../ComandoControl';

import Props from './props';

const useStyles = makeStyles({
  button: {
    color: theme.blue.darker,
  },
  buttonModal: {
    backgroundColor: '#0083C7 !important',
  },
  FormGroup: {
    flexDirection: 'column',
  },
  formControl: {
    // margin: th.spacing(1),
    minWidth: 120,
    width: '84%',
    marginTop: '16px',
  },
  FormControlLabel: {
    margin: 0,
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
    maxWidth: '750px !important',
  },
  textField: {
    width: '100%',
  },
});

const getUnique = (arr, comp) => {
  const unique = arr
    .map(e => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e]).map(e => arr[e]);

  return unique;
};


// let salidasChecked = [];
const GrayCheckbox = withStyles({
  root: {
    color: '#E6E6E6',
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
    '&$checked': {
      color: theme.gray.light,
    },
    padding: '0.5rem',
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

class ESLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    // const loc = this.locale;
    // console.log('Locale: ', loc);
    return format(date, 'd MMMM yyyy', { locale: this.locale });
  }
}

/** @description Componente modal para el envio de comandos.
 */
const ModalSendComands = ({
  comandosDisponiblesAll,
  rectificadores,
  handleClickOpen,
  openCMD,
  handleCloseCMD,
  language,
  resultSendMessage,
  usuarioLogueado,
  t,
  enqueueSnackbar,
}) => {
  // const themes = useTheme();
  const classes = useStyles();
  // const fullScreen = useMediaQuery(themes.breakpoints.down('sm'));

  // const [openResult, setOpenResult] = useState(false);

  /* const handleCloseResultSend = () => {
    store.dispatch(Actions.Comandos.setComandoSendResult(''));
  }; */

  const [scheduledDate, setScheduledDate] = React.useState(new Date());
  const [controlModelSel, setControlModeSel] = useState();

  const handleOpenResultSend = () => {
    // setOpenResult(true);
    enqueueSnackbar(resultSendMessage, {
      variant: 'info',
    });
    handleCloseCMD();
    store.dispatch(Actions.Comandos.setComandoSendResult(''));
  };

  useEffect(
    () => {
      if (resultSendMessage.length > 0) {
        handleOpenResultSend();
      }
    },
    [resultSendMessage],
  );

  /* useEffect(() => {
    if (rectificadores) {
      const rectificadoresSeleccionados = rectificadores.filter(r => r.selected);
      store.dispatch({ type: 'FETCH_COMANDOSBYTIPOUNIDAD', payload: rectificadoresSeleccionados[0].tipoUnidadMonitoreoID });
    }
  }, []); */
  const rectificadoresSeleccionados = rectificadores.filter(r => r.selected);
  const [patternErrors, setPatternErrors] = useState([]);
  const [intervalErrors, setIntervalErrors] = useState([]);
  const [valoresComandos, setValoresComandos] = useState([]);

  useEffect(() => {
    setValoresComandos(comandosDisponiblesAll);
  }, [comandosDisponiblesAll]);

  // Si hay mas de un tipo de unidad monitoreo se oculta el icono porque no se pueden enviar los comandos a rectificadores de diferentes
  // tipo de unidad de monitoreo
  const hideIcon = Array.from(new Set(rectificadoresSeleccionados.map(r => r.tipoUnidadMonitoreoID))).length > 1;

  const comandosDisp = comandosDisponiblesAll.filter(cd => cd.tipoUnidadMonitoreoID === rectificadoresSeleccionados[0].tipoUnidadMonitoreoID).map(cd => ({
    ...cd,
    isPatternError: patternErrors.includes(cd.comandoID),
    isIntervalError: intervalErrors.includes(cd.ComandoID),
  }));

  // const requerimientos = comandosDisp.filter(cd => !cd.modoControlID);
  const comandoGrupos = getUnique(comandosDisp.filter(x => x.grupoId !== -1), 'grupoId').map(i => ({
    value: i.grupoId,
    labelES: i.grupoES,
    labelEN: i.grupoEN,
  }));

  const comandosModoControl = comandosDisp.filter(c => c.modoControlID);

  // const salidasSel = [];
  // const nodosExpandidos = [];
  /* rectificadoresSeleccionados.forEach((r) => {
    r.salidas.forEach((s) => {
      salidasSel.push(`${s.rectificadorID}_${s.salidaID}`);
      nodosExpandidos.push(r.rectificadorID).toString();
    });
  }); */

  const [salidasChecked, setSalidasChecked] = useState();

  // const [salidasChecked, setSalidasChecked] = useState([]);
  const [nodeExpanded, setNodeExpanded] = useState();

  const handleOpenModal = () => {
    handleClickOpen();
    const salidasSel = [];
    const nodosExpandidos = [];
    rectificadoresSeleccionados.forEach((r) => {
      r.salidas.forEach((s) => {
        salidasSel.push(`${s.rectificadorID}_${s.salidaID}`);
        nodosExpandidos.push(r.rectificadorID).toString();
      });
    });
    setSalidasChecked(salidasSel);
    setNodeExpanded(nodosExpandidos);

    if (rectificadores) {
      // const rectificadoresSeleccionados = rectificadores.filter(r => r.selected);
      store.dispatch({ type: 'FETCH_COMANDOSBYTIPOUNIDAD', payload: rectificadoresSeleccionados[0].tipoUnidadMonitoreoID });
    }
  };

  const handleExpandNode = (expanded) => {
    setNodeExpanded(expanded);
  };

  const handleCheckTree = (checked) => {
    setSalidasChecked(checked);
  };

  let modosControl = Array.from(new Set(comandosModoControl.map(c => c.modoControlID)))
    .map(id => ({
      value: id,
      label: language === 'EN' ? comandosModoControl.find(cmd => cmd.modoControlID === id).modoControlEN : comandosModoControl.find(cmd => cmd.modoControlID === id).modoControlES,
    }));

  modosControl = [{ value: 0, label: language === 'EN' ? 'None' : 'Ninguno' }, ...modosControl];
  modosControl = modosControl.filter(x => x.value >= 0 && x.label);

  const [comandosSel, setComandosSel] = useState([]);

  const handleChangeCheckbox = (comandoID) => {
    const indexComandoSelected = comandosSel.findIndex(p => p === comandoID);
    let copySelComandos = comandosSel.slice();

    if (indexComandoSelected > -1) {
      copySelComandos = copySelComandos.filter(x => x !== comandoID);
    } else {
      copySelComandos.push(comandoID);
    }

    console.log('comando id: ', comandoID);
    console.log('comandos sel copy: ', copySelComandos);

    setComandosSel(copySelComandos);
  };

  const [comandosMCDisplay, setComandosMCDisplay] = useState([]);

  const handleChangeModoControl = (newValue) => {
    // Limpiamos los valores al cambiar el modo de control
    const comandosMC = comandosModoControl.filter(c => c.modoControlID === newValue);
    const comandosFiltered = comandosMC
      .map(c => ({
        ...c,
        valor: '',
      }));
    setComandosMCDisplay(comandosFiltered);
  };

  const validatePattern = (comandoId, evt, pattern) => {
    if (evt.target.value && !evt.target.value.match(pattern)) {
      setPatternErrors([
        ...patternErrors,
        comandoId,
      ]);
    } else {
      setPatternErrors(patternErrors.filter(x => x !== comandoId));
    }
  };

  const handleChangeValueCommand = (comandoId, value, valorMinimo, valorMaximo) => {
    if (!Number.isNaN(value) && !Number.isNaN(valorMinimo) && !Number.isNaN(valorMaximo) && (parseFloat(value) > parseFloat(valorMaximo) || parseFloat(value) < parseFloat(valorMinimo))) {
      setIntervalErrors([
        ...intervalErrors,
        comandoId,
      ]);
    } else {
      setIntervalErrors(intervalErrors.filter(x => x !== comandoId));
      const index = valoresComandos.findIndex(x => x.comandoID === comandoId);

      const aux = [
        ...valoresComandos.slice(0, index),
        {
          ...valoresComandos[index],
          comandoID: comandoId,
          valor: value,
        },
        ...valoresComandos.slice(index + 1),
      ];
      setValoresComandos(aux);
    }
  };

  const handleChangeValueCommandMC = (comandoId, value, valorMinimo, valorMaximo) => {
    const indexComandoSelected = comandosMCDisplay.findIndex(x => x.comandoID === comandoId);

    let comandosFiltered = [];

    comandosFiltered = [
      ...comandosMCDisplay.slice(0, indexComandoSelected),
      {
        ...comandosMCDisplay[indexComandoSelected],
        valor: value,
        isError: !Number.isNaN(value) && !Number.isNaN(valorMinimo) && !Number.isNaN(valorMaximo) && (parseFloat(value) > parseFloat(valorMaximo) || parseFloat(value) < parseFloat(valorMinimo)),
        errorMessage: `El valor debe estar entre ${valorMinimo} y ${valorMaximo}`,
      },
      ...comandosMCDisplay.slice(indexComandoSelected + 1),
    ];

    setComandosMCDisplay(comandosFiltered);
    // setModoControlToSend(copyComandoToSend);
  };

  const handleClickEnviarComando = () => {
    if (valoresComandos.filter(x => x.isIntervalError || x.isPatternError).length > 0) {
      console.log(valoresComandos);
      return;
    }

    const rectificadoresAEnviar = salidasChecked.map(r => ({
      rectificadorId: r.split('_')[0],
      salidaId: r.split('_')[1],
    }));

    const comandosAEnviar = valoresComandos.filter(x => x.valor).map(c => ({
      comandoId: c.comandoID,
      valor: c.valor,
    }));

    const mcDisplay = comandosMCDisplay.filter(x => x.valor);
    const comandosMC = mcDisplay.map(c => ({
      comandoId: c.comandoID,
      // eslint-disable-next-line no-nested-ternary
      valor: c.tipoDato === 4 ? Moment(c.valor).format('DD/MM/YYYY') : (c.tipoDato === 6 ? Moment(c.valor).format('HH:mm') : c.valor),
    }));

    let setModoControl = [];

    if (comandosMC) {
      const mc = mcDisplay[0];
      console.log('Modo control: ', mc);
      if (mc && (mc.modoControlES.startsWith('Corriente')
      || mc.modoControlES.startsWith('Voltaje')
      || mc.modoControlES.startsWith('Potencial OFF')
      || mc.modoControlES.startsWith('Potencial ON'))) {
        setModoControl = [{
          comandoId: mc.modoControlComando,
          valor: mc.modoControlValor,
        }];
      }
    }

    const dataToSend = {
      rectificadores: rectificadoresAEnviar,
      requerimientos: comandosSel,
      comandos: [...comandosAEnviar, ...comandosMC, ...setModoControl],
      usuario: usuarioLogueado,
      language,
      scheduledDate: Moment(scheduledDate).format('YYYY-MM-DDTHH:mm:ss'),
    };

    enqueueSnackbar(`${t('default.enviandoComando', 'Enviando comandos')} ...`, {
      variant: 'info',
    });

    store.dispatch(Actions.Comandos.sendComandos(dataToSend));
    setComandosSel([]);
    const comandosFiltered = comandosMCDisplay.map(c => ({
      ...c,
      valor: '',
    }));
    setComandosMCDisplay(comandosFiltered);
    // handleCloseCMD();
  };

  return (
    <div>
      <div style={{ display: hideIcon ? 'none' : 'inline-block' }}>
        <IconButton
          className={classes.button}
          aria-label="commands"
          onClick={handleOpenModal}
          title={t('default.enviarComandos', 'Enviar comandos')}
        >
          <AwesomeIcon icon="settings" />
        </IconButton>
      </div>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        maxWidth="md"
        open={openCMD}
        onClose={handleCloseCMD}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <FormGroup row className={classes.FormGroup}>
            <span className="RectifierCMD__title">{t('default.rectificadores', 'Rectificadores')}</span>
            <CheckboxTreeRectificadores
              rectificadoresSeleccionados={rectificadoresSeleccionados}
              handleCheck={handleCheckTree}
              handleExpand={handleExpandNode}
              checkedNodes={salidasChecked}
              expandedNodes={nodeExpanded}
              t={t}
              // checkedNodes={checkedNodes}
            />
          </FormGroup>
          <MuiPickersUtilsProvider utils={language === 'EN' ? DateFnsUtils : ESLocalizedUtils} locale={language === 'EN' ? enLocale : esLocale}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label={t('default.scheduledDateCommand', 'Fecha para agendar comando')}
                format="dd/MM/yyyy"
                value={scheduledDate}
                onChange={(date) => {
                  setScheduledDate(date);
                }}
                cancelLabel={t('default.cancelar', 'Cancelar')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label={t('default.scheduledTimeCommand', 'Hora para agendar comando')}
                value={scheduledDate}
                onChange={(date) => {
                  setScheduledDate(date);
                }}
                cancelLabel={t('default.cancelar', 'Cancelar')}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          {modosControl.length > 1 && (
            <Grid container>
              <span className="RectifierCMD__title">{t('default.comandosModoControl', 'Comandos según el modo de control')}</span>
              <Grid container item xs={12} className="y-center mb-2">
                <Grid item xs={6}>
                  <span>{t('default.modoControl', 'Modo de Control')}</span>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.formControl}>
                    <Select
                      className="basic-multi-select"
                      name="modoControl"
                      value={controlModelSel}
                      onChange={(evt) => {
                        setControlModeSel(evt.target.value);
                        handleChangeModoControl(evt.target.value);
                      }}
                    >
                      {modosControl.map(mc => (
                        <MenuItem key={mc.value} value={mc.value}>{mc.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {comandosMCDisplay.map(c => (
                <Grid container item xs={12} className="y-center mb-2">
                  <Grid item xs={6}>
                    <span>{language === 'EN' ? c.nombreEN : c.nombreES}</span>
                  </Grid>
                  <Grid item xs={6}>
                    { c.listaValores && c.listaValores.length > 0 ? (
                      <FormControl className={classes.formControl} style={{ width: '100%' }}>
                        {/* <InputLabel htmlFor={`ddl_${c.comandoID}`} shrink>{language === 'EN' ? c.nombreEN : c.nombreES}</InputLabel> */}
                        <Select
                          className="basic-multi-select"
                          name={`ddl_${c.comandoID}`}
                          value={c.valor}
                          variant="standard"
                          onChange={(evt) => {
                            handleChangeValueCommandMC(c.comandoID, evt.target.value);
                          }}
                        >
                          {c.listaValores.map(lv => (
                            <MenuItem key={lv.valor} value={lv.valor}>{language === 'EN' ? lv.listaValorDescripIngles : lv.listaValorDescrip}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <>
                        {c.tipoDato === 4 && (
                          <MuiPickersUtilsProvider utils={language === 'EN' ? DateFnsUtils : ESLocalizedUtils} locale={language === 'EN' ? enLocale : esLocale}>
                            <Grid container justify="space-around">
                              <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                // label={t('default.scheduledDateCommand', 'Fecha para agendar comando')}
                                format="dd/MM/yyyy"
                                value={c.valor || null}
                                onChange={date => handleChangeValueCommandMC(c.comandoID, date, c.valorMinimo, c.valorMaximo)}
                                cancelLabel={t('default.cancelar', 'Cancelar')}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        )}

                        {c.tipoDato === 6 && (
                          <MuiPickersUtilsProvider utils={language === 'EN' ? DateFnsUtils : ESLocalizedUtils} locale={language === 'EN' ? enLocale : esLocale}>
                            <Grid container justify="space-around">
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                // label={t('default.scheduledTimeCommand', 'Hora para agendar comando')}
                                value={c.valor || null}
                                onChange={date => handleChangeValueCommandMC(c.comandoID, date, c.valorMinimo, c.valorMaximo)}
                                cancelLabel={t('default.cancelar', 'Cancelar')}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        )}

                        {c.tipoDato !== 4 && c.tipoDato !== 6 && (
                          <>
                            <TextField
                              id={`txt${c.comandoID}`}
                              type="text"
                              className={classes.textField}
                              onBlur={evt => validatePattern(c.comandoID, evt, c.pattern)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={c.valor}
                              onChange={evt => handleChangeValueCommandMC(c.comandoID, evt.target.value, c.valorMinimo, c.valorMaximo)}
                            />
                            <Grid item xs={6}>
                              <span style={{ color: 'red', display: c.isError ? 'inline-block' : 'none' }}>{c.errorMessage}</span>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                  {/* <ComandoControl
                    key={Math.random()}
                    cd={c}
                    handleChangeValue={handleChangeValueCommandMC}
                    language={language}
                    classes={classes}
                    handleChangeCheckbox={handleChangeCheckbox}
                    validatePattern={validatePattern}
                    value={comandosMCDisplay.filter(x => x.comandoID === c.comandoID)}
                  /> */ }
                </Grid>
              ))}
            </Grid>
          )}
          <FormGroup row className={classes.FormGroup}>
            { comandoGrupos.map(cg => (
              <>
                <span className="RectifierCMD__title">{language === 'ES' ? cg.labelES : cg.labelEN}</span>
                <Grid container>
                  {valoresComandos.filter(x => x.grupoId === cg.value).map((cd) => {
                    if (cd.listaValores && cd.listaValores.length > 0) {
                      return (
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor={`ddl_${cd.comandoID}`}>{language === 'EN' ? cd.nombreEN : cd.nombreES}</InputLabel>
                          <Select
                            className="basic-multi-select"
                            name={`ddl_${cd.comandoID}`}
                            value={cd.valor}
                            variant="standard"
                            onChange={(evt) => {
                              handleChangeValueCommand(cd.comandoID, evt.target.value);
                            }}
                          >
                            {cd.listaValores.map(lv => (
                              <MenuItem key={lv.valor} value={lv.valor}>{language === 'EN' ? lv.listaValorDescripIngles : lv.listaValorDescrip}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      );
                    }

                    if (cd.tipoDato === 7) {
                      return (
                        <Grid item xs={6}>
                          <FormControlLabel
                            className={classes.FormControlLabel}
                            control={
                              <GrayCheckbox key={`chk${cd.comandoID}`} onChange={evt => handleChangeCheckbox(parseInt(evt.target.value, 10))} value={cd.comandoID} />
                            }
                            label={language === 'EN' ? cd.nombreEN : cd.nombreES}
                          />
                        </Grid>
                      );
                    }

                    return (
                      <Grid key={cd.comandoID} item xs={12}>
                        <TextField
                          id={`txt${cd.comandoID}`}
                          type="text"
                          className={classes.textField}
                          onBlur={evt => validatePattern(cd.comandoID, evt, cd.pattern)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={cd.valor}
                          label={language === 'EN' ? cd.nombreEN : cd.nombreES}
                          onChange={evt => handleChangeValueCommand(cd.comandoID, evt.target.value, cd.valorMinimo, cd.valorMaximo)}
                        />
                        <Grid item xs={6}>
                          <span style={{ color: 'red', display: cd.isError ? 'inline-block' : 'none' }}>{cd.errorMessage}</span>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseCMD}
            color="primary"
            variant="contained"
            className={classes.buttonModal}
          >
            <Trans i18nKey="default.cerrar">Cerrar</Trans>
          </Button>
          {/*
          <Button onClick={handleCloseCMD} color="primary" autoFocus>
            Limpiar
          </Button>
          <Button onClick={handleCloseCMD} color="primary">
            Programar
          </Button>
          */}
          <Button
            onClick={handleClickEnviarComando}
            color="primary"
            autoFocus
            variant="contained"
            className={classes.buttonModal}
          >
            <Trans i18nKey="default.enviar">Enviar</Trans>
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup que muestra el mensaje del envio de comandos
      <Dialog
        open={openResult}
        onClose={handleCloseResultSend}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {resultSendMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResultSend} color="primary">
            <Trans i18nKey="default.cerrar">Cerrar</Trans>
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

ModalSendComands.propTypes = Props;

const AppCompose = composePure(
  connect(state => ({
    working: state.Universal.request.process,
    comandosDisponiblesAll: getUnique(state.Comandos.ComandosDisponibles, 'comandoID'),
    rectificadores: state.Rectifiers.docs.filter(x => x.grupoId),
    language: state.Language.current,
    resultSendMessage: state.Comandos.SendMessageResult,
    usuarioLogueado: state.Users.userData,
  })),
  withProps(props => ({
    t: props.t,
    enqueueSnackbar: props.enqueueSnackbar,
  })),
)(ModalSendComands);

export default withSnackbar(translate('common')(AppCompose));

// export default ModalSendComands;
