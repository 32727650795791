import React, { Fragment, useState } from 'react';

import { connect } from 'react-redux';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import {
  IconButton,
  Dialog,
  DialogContent,
  Grid,
} from '@material-ui/core';

import {
  composePure,
} from '../../utils/composepure';

import Actions from '../../actions';

import AwesomeIcon from '../AwesomeIcon';

import Props from './props';

/** @description Componente que permite la seleccion de un rectificador especifico en forma de lista.
*/
const SelectedRectifiers = ({
  classes,
  t,
  rectificadoresSeleccionados,
  handleChange,
}) => {
  const handleDelete = (row) => {
    handleChange(row.uniqueId);
  };

  const nameFilter = textFilter({
    placeholder: `${t('default.ingresarNombre', 'Ingrese el nombre')}`,
  });

  const columns = [{
    dataField: 'nombre',
    text: t('default.nombre', 'Nombre'),
    headerStyle: () => ({ width: '85%' }),
    sort: true,
    filter: nameFilter,
  },
  {
    headerClasses: 'gfMediaHideIcon',
    dataField: 'rectificadorID',
    text: t('default.eliminar', 'Eliminar'),
    formatter: (cell, row) => (
      <IconButton
        className="gfMediaResizeIcon"
        aria-label="trash"
        onClick={() => handleDelete(row)}
      >
        <AwesomeIcon icon="trash" />
      </IconButton>
    ),
    align: 'center',
    headerAlign: 'center',
  },
  ];

  const [openModal, setOpenModal] = useState(false);
  return (
    <Fragment>
      <IconButton
        className={classes.button}
        aria-label="deviceSelected"
        onClick={() => setOpenModal(true)}
        title={t('default.equiposSeleccionados', 'Equipos seleccionados')}
      >
        <AwesomeIcon icon="device" />
      </IconButton>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <BootstrapTable
                keyField="configuracionAlarmaID"
                striped
                hover
                condensed
                bootstrap4
                data={rectificadoresSeleccionados}
                columns={columns}
                filter={filterFactory()}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

SelectedRectifiers.propTypes = Props;

export default composePure(
  connect(state => ({
    rectificadoresSeleccionados: state.Rectifiers.docs.filter(x => x.selected),
  }),
  dispatch => ({
    handleChange: async id => dispatch(Actions.Rectifiers.setSelected(id)),
  })),
)(SelectedRectifiers);
