/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

/** @description Componente para subir foto al perfil del usuario. Utilizada en el account button
 */
class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const fileContent = URL.createObjectURL(event.target.files[0]);
    const { handleChange } = this.props;
    this.setState({
      file: fileContent,
    });

    const fr = new FileReader();

    fr.addEventListener('load', (e) => {
      handleChange(e.target.result);
    });

    fr.readAsDataURL(event.target.files[0]);
  }

  render() {
    const { file } = this.state;
    const { t } = this.props;
    return (
      <div>
        <label id="lblProfilePicture" htmlFor="fileProfilePicture" className="btn btn-primary">
          {t('default.imagenPerfil', 'Imagen de Perfil')}
          <input id="fileProfilePicture" type="file" accept="image/*" onChange={this.handleChange} hidden />
        </label>
        <img src={file} alt="Profile" style={{ display: file ? 'inline' : 'none' }} />
      </div>
    );
  }
}

Upload.propTypes = {
  handleChange: PropTypes.func,
  t: PropTypes.func,
};

export default Upload;
