import React, {
  useState,
} from 'react';
import {
  Tabs,
  Tab,
  Container,
  Row,
  Col,
} from 'react-bootstrap';

// import { translate } from 'react-i18next';
// import { composePure } from '../../utils/composepure';

import Props from './props';
import RectifiersAlertList from '../RectifiersAlertList';
import RectifiersOutputList from '../RectifiersOutputList';

/*
<Container>
  <Row>
    <Col xs={12}>
      <Tabs
        transition={false}
        id={`tabs-${uniqueId}`}
        className="CardViewItemTabs"
        activeKey={key}
        onSelect={k => setKey(k)}
      >
        <Tab eventKey="alerts" title="Home">
          <RectifiersAlertList
            alertas={alertas}
            uniqueId={uniqueId}
          />
        </Tab>
        <Tab eventKey="outputs" title="Salidas">
          <RectifiersOutputList
            salidas={salidas}
            fecha={fecha}
          />
        </Tab>
      </Tabs>
    </Col>
  </Row>
</Container>
*/

/** @description Componente reutilizable que dibuja para cada items: alertas y salidas.
 */
const CardViewItemTabs = ({
  salidas,
  fecha,
  uniqueId,
  alertas,
  t,
  language,
}) => {
  const [key, setKey] = useState('alarms');
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Tabs
            transition={false}
            id={`tabs-${uniqueId}`}
            className="CardViewItemTabs"
            activeKey={key}
            onSelect={k => setKey(k)}
          >
            <Tab eventKey="alarms" title={t('default.alarmas', 'Alarmas')}>
              <RectifiersAlertList
                alertas={alertas}
                uniqueId={uniqueId}
                t={t}
                language={language}
              />
            </Tab>
            <Tab eventKey="outputs" title={t('default.salidas', 'Salidas')}>
              <RectifiersOutputList
                salidas={salidas}
                fecha={fecha}
                t={t}
                language={language}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>

  );
};

CardViewItemTabs.propTypes = Props;

/* const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
)(CardViewItemTabs); */

// export default translate('common')(AppCompose);

export default CardViewItemTabs;
