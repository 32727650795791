import React, { useState, Fragment } from 'react';

import {
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Button,
} from '@material-ui/core';

import AwesomeIcon from '../AwesomeIcon';

import Actions from '../../actions';
import store from '../../store';

import Props from './props';

/** @description Componente modal para mostrar informacion de los rectificadores en reporte actual.
 */
const ModalReporteActual = ({
  openReporteActual,
  setOpenReporteActual,
  rectificadoresSeleccionados,
  userId,
  language,
  t,
  classes,
  email,
}) => {
  const [emails, setEmails] = useState([email]);

  const handleConsultarReporteActual = () => {
    // const rectificadoresSeleccionados = rectificadores.filter(r => r.selected);
    const param = {
      UserId: userId,
      Emails: emails.join(';'),
      Language: language,
      Reportes: rectificadoresSeleccionados,
    };
    store.dispatch(Actions.Reportes.sendReporteActual(param));
    setOpenReporteActual(false);
  };

  const addEmail = () => {
    setEmails([
      ...emails,
      '',
    ]);
  };

  const removeEmail = (index) => {
    setEmails([
      ...emails.splice(0, index),
      ...emails.splice(index + 1),
    ]);
  };

  const handleTextEmail = (index, value) => {
    setEmails(
      [
        ...emails.slice(0, index),
        value,
        ...emails.slice(index + 1),
      ],
    );
  };

  return (
    <Dialog
      // classes={{ paper: classes.dialogPaper }}
      open={openReporteActual}
      onClose={() => setOpenReporteActual(false)}
      aria-labelledby="responsive-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        <Grid item xs={12}>
          {emails.map((e, index) => (
            <Fragment>
              <TextField
                id={`${index}txtEmail`}
                type="email"
                autoComplete="email"
                style={{ width: '75%' }}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                value={e}
                onChange={evt => handleTextEmail(index, evt.target.value)}
                placeholder={`Emails (${t('default.opcional', 'Opcional')})`}
              />
              <IconButton
                style={{ width: '10%' }}
                // className={classes.button}
                aria-label="minus"
                onClick={() => removeEmail(index)}
              >
                <AwesomeIcon icon="minus" />
              </IconButton>
              <IconButton
                style={{ width: '10%' }}
                // className={classes.button}
                aria-label="plus"
                onClick={addEmail}
              >
                <AwesomeIcon icon="plus" />
              </IconButton>
            </Fragment>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" className={classes.buttonModal} onClick={() => setOpenReporteActual(false)}>{t('default.cerrar', 'Cerrar')}</Button>
        <Button color="primary" variant="contained" className={classes.buttonModal} onClick={handleConsultarReporteActual}>{t('default.exportar', 'Exportar')}</Button>
      </DialogActions>
    </Dialog>
  );
};

ModalReporteActual.propTypes = Props;

export default ModalReporteActual;
