import React from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  CardColumns,
  Button,
  Container,
} from 'react-bootstrap';

import {
  IconButton,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import { translate, Trans } from 'react-i18next';

// import ToggleButton from '@material-ui/lab/ToggleButton';
import AwesomeIcon from '../../components/AwesomeIcon';

import { composePure, withProps } from '../../utils/composepure';

import CardViewItem from '../../components/CardViewItem';

import './style.css';
import propTypes from './props';

/** @description pagina principal para visualizar dispositivos. Estados, alertas, salidas.
 * Agregar nuevos.
 */
const CardsView = ({
  filteredData,
  t,
  language,
  dataCount,
  userData,
  // history,
}) => {
  const [page, setPage] = React.useState(0);
  const pagedData = filteredData.slice(0, (page + 1) * 20);

  return (
    <Container fluid style={{ marginTop: '20px' }}>
      { userData.permisos.findIndex(x => x.opcionCodigo === 'CRECT') > -1 && (
        <Link to="/newDevice" style={{ display: userData.mostrarBotonCrearEquipo ? 'inline-block' : 'none' }}>
          <IconButton
            aria-label="left"
            style={{
              position: 'fixed',
              bottom: '60px',
              right: '10px',
              backgroundColor: '#007bff',
              color: '#fff',
              zIndex: '999999',
            }}
          >
            <AwesomeIcon icon="plus" />
          </IconButton>
        </Link>
      )}
      <Row>
        <Col xs={12}>
          <CardColumns>
            {pagedData.map(rec => (
              <CardViewItem
                key={rec.uniqueId}
                rectify={rec}
                t={t}
                language={language}
              />
            ))}
          </CardColumns>
        </Col>
      </Row>
      <Row className="x-center LoadMore">
        <Col xs={4}>
          {filteredData.length > pagedData.length && (
            <Button
              variant="primary"
              size="lg"
              block
              type="button"
              onClick={() => setPage(page + 1)}
            >
              {`${t('default.verMas', 'Ver más')}  (${filteredData.length - pagedData.length})`}
            </Button>
          )}
        </Col>
      </Row>
      {filteredData.length === 0 && dataCount > 0 && (
        <Row>
          <Col xs={12} className="x-center">
            <h3><Trans i18nKey="default.noCards">No se encontraron equipos</Trans></h3>
          </Col>
        </Row>
      )}
    </Container>
  );
};


CardsView.propTypes = propTypes;

export default translate('common')(composePure(
  withProps(props => ({ t: props.t })),
  connect(state => ({
    language: state.Language.current,
    dataCount: state.Rectifiers.docs.filter(x => x.grupoId).length,
    userData: state.Users.userData,
  })),
)(CardsView));

// export default translate('common')(React.memo(CardsView));
