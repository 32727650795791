import React from 'react';

import {
  Card,
  Col,
  Row,
  Container,
} from 'react-bootstrap';

import {
  IconButton,
  makeStyles,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import {
  composePure,
  shouldUpdate,
} from '../../utils/composepure';
import theme from '../../scss/theme/colors';
import Actions from '../../actions';
// import RectifierCheckbox from '../RectifierCheckbox';
import CardViewStatus from '../CardViewStatus';
import AwesomeIcon from '../AwesomeIcon';
import CardViewItemTabs from '../CardViewItemTabs';
import RectifierCheckbox from '../RectifierCheckbox';
import propTypes from './props';

const useStyles = makeStyles({
  button: {
    color: theme.blue.darker,
  },
  buttonModal: {
    backgroundColor: '#0083C7 !important',
  },
  dialogPaper: {
    // minHeight: '80vh',
    maxHeight: '80vh',
  },
  textField: {
    width: '100%',
  },
  icon: {
    display: 'block',
    padding: '6px',
  },
});

/** @description Componente reutilizable de las vistas que contienen tarjetas funcionales.
 * Vista Dispositivos.
 */
const CardItemView = ({
  rectify,
  handleChange,
  language,
  t,
  permisos,
  groups,
}) => {
  const classes = useStyles();
  const category = groups.filter(x => x.grupoId === rectify.grupoId);
  const completeDesc = `${category && category.length > 0 ? category[0].nombre : ''} - ${rectify.tipoUnidadMonitoreo} - ${t('default.salidas', 'Salidas')}: ${rectify.cantidadSalidas}`;
  const Componente = (
    <Card className="CardFilterData">
      <Container>
        <Row className="CardFilterData__header">
          <Col xs={12}>
            <Row className="y-center mt-2">
              <Col xs={2} className="xy-center">
                <RectifierCheckbox
                  state={rectify.selected}
                  handleChange={() => handleChange(rectify.uniqueId)}
                  value={rectify.rectificadorID}
                />
              </Col>
              <Col xs={6} className="CardFilterData__header__time y-center x-end m-0">
                <AwesomeIcon
                  icon="clock"
                  rigthText={!rectify.fecha || rectify.fecha.includes('1900-01-01')
                    ? t('default.deviceNoData', 'Equipo sin transmisiones')
                    : <Moment locale={language === 'EN' ? 'en-gb' : 'es'} fromNow>{rectify.fecha}</Moment>}
                />
              </Col>
              <Col xs={4} className="CardFilterData__header__status x-end">
                <CardViewStatus
                  fecha={rectify.fecha}
                  inAlert={rectify.inAlert}
                  expired={rectify.expired}
                  t={t}
                />
              </Col>
            </Row>
          </Col>
          <Row style={{ width: '100%' }}>
            <Col xs={8}>
              <Col xs={12} className="y-center CardFilterData__header__title">
                <span>{rectify.nombre}</span>
              </Col>
            </Col>
            <Col xs={1} />
            <Col xs={1}>
              { permisos.findIndex(x => x.opcionCodigo === 'MI') > -1 && (
                <Link to={{
                  pathname: '/moreInfo',
                  rectificador: rectify,
                  t,
                }}
                >
                  <IconButton
                    className={classes.icon}
                    aria-label="moreInfo"
                    title={t('default.informacionBasica', 'Más Información')}
                  >
                    <AwesomeIcon icon="plus" color="white" />
                  </IconButton>
                </Link>
              )}
            </Col>
            <Col xs={1}>
              { permisos.findIndex(x => x.opcionCodigo === 'CR') > -1 && (
                <Link to={{
                  pathname: '/newDevice',
                  rectificador: rectify,
                  origin: 'card',
                }}
                >
                  <IconButton
                    className={classes.icon}
                    aria-label="confDevice"
                    title={t('default.confDevice', 'Configuración de Equipo')}
                  >
                    <AwesomeIcon icon="settings" color="white" />
                  </IconButton>
                </Link>
              )}
            </Col>
          </Row>
          <Col xs={12} className="y-center CardFilterData__header__desc">
            <span title={completeDesc}>{completeDesc}</span>
          </Col>
        </Row>
      </Container>
      <Card.Body>
        <CardViewItemTabs
          uniqueId={rectify.uniqueId}
          salidas={rectify.salidas}
          fecha={rectify.fecha}
          alertas={rectify.alertas}
          t={t}
          language={language}
        />
      </Card.Body>
    </Card>
  );
  return Componente;
};

CardItemView.propTypes = propTypes;
export default composePure(
  connect(state => ({
    language: state.Language.current,
    permisos: state.Users.userData.permisos,
    groups: state.Groups.docs,
  }),
  dispatch => ({
    handleChange: async id => dispatch(Actions.Rectifiers.setSelected(id)),
  })),
  shouldUpdate((old, newprop) => (JSON.stringify(old) !== JSON.stringify(newprop))),
)(CardItemView);
