import React from 'react';
import {
  Drawer,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { composePure } from '../../utils/composepure';

import ButtonIcon from '../ButtonIcon';
import AccountDrawer from '../AccountDrawer';
import Props from './props';
import store from '../../store';
import Actions from '../../actions';

const useStyles = makeStyles(mui => ({
  margin: {
    margin: mui.spacing(2),
  },
}));

/** @description Componente Icono boton account, que contiene la información del usuario logueado.
  * Posibilidad de ver detalle de la cuenta, seccion ayuda, cerrar sesion, ajustes de pagina de inicio
  * y selección idioma.
 */
const AccountButtonIcon = ({
  userData,
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer = (side, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const closeRightSide = () => {
    setState({ ...state, right: false });
  };

  const swicthHandleChange = name => (event) => {
    setState({ ...state, [name]: event.target.checked });
    store.dispatch(Actions.Users.savePreferences(userData.usuarioId, name, event.target.checked));
  };

  const handleChangePaginaInicio = (evt) => {
    store.dispatch(Actions.Users.savePreferences(userData.usuarioId, 'vistaPreferidaId', parseInt(evt.target.value, 10)));
  };

  return (
    <div>
      <ButtonIcon
        className={classes.margin}
        icon="user"
        color="blue"
        onClick={toggleDrawer('right', true)}
      />
      <div>
        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer('right', false)}
        >
          <AccountDrawer
            side="right"
            toggleDrawer={() => toggleDrawer('right', false)}
            closeRightSide={closeRightSide}
            // state={state}
            swicthHandleChange={swicthHandleChange}
            handleChangePaginaInicio={handleChangePaginaInicio}
          />
        </Drawer>
      </div>
    </div>
  );
};

AccountButtonIcon.propTypes = Props;

const AppCompose = composePure(
  connect(state => ({
    userData: state.Users.userData,
  })),
)(AccountButtonIcon);

export default AppCompose;

// export default AccountButtonIcon;
