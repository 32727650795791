// import { createSelector } from 'reselect';


// const cards = state => state.Cards.Cards;
// const filterAlerts = state => state.Filters.alerts;
// const filterGroup = state => state.Filters.group;
// const filterKeyword = state => state.Filters.keyword;
// const filterUnitType = state => state.Filters.brands;

// const disponibilidades = state => state.Disponibilidad.Disponibilidad;
// const filterGroupDisponibilidad = state => state.Filters.groupDisponibilidad;

export const getGroupDisponibilidadFilter = (disponibilidades, group, filter) => {
  let result = [...disponibilidades];
  if (group.length > 0) {
    result = result.filter(d => group.find(g => g.value === d.grupoId));
  }

  if (filter) {
    result = result.filter(d => d.rectificador.toLowerCase().indexOf(filter.toLowerCase()) > -1);
  }

  return result;
};

export const getRectifiersFiltered = (rectifiers, group, alerts, keyword, brands) => {
  if (group.length === 0
    && alerts.length === 0
    && keyword.length === ''
    && brands.length === 0
  ) return rectifiers;

  let result = [...rectifiers];
  if (group.length > 0) {
    result = result.filter(card => group.find(g => g.value === card.grupoId));
  }

  if (brands.length > 0) {
    result = result.filter(card => brands.find(g => g.value === card.tipoUnidadMonitoreo));
  }

  if (alerts.length > 0) {
    alerts.forEach((a) => {
      if (a.value === 'alarma') {
        result = result.filter(card => card.inAlert);
      }

      if (a.value === 'normal') {
        result = result.filter(card => !card.inAlert && !card.expired);
      }

      if (a.value === 'inactivo') {
        result = result.filter(card => card.expired);
      }
    });
  }

  if (keyword.length > 0) {
    result = result.filter(card => card.nombre.toLowerCase().indexOf(keyword) > -1);
  }
  return result;
};

/*
export const getRectifiersFiltered = createSelector(
  [rectifiers, filterGroup, filterAlerts, filterKeyword, filterUnitType],
  (items, group = [], alerts = [], keyword = '', brands = []) => {
    if (group.length === 0 && alerts.length === 0 && keyword.length === '' && brands.length === 0) return items;

    let result = [...items];
    if (group.length > 0) {
      result = result.filter(card => group.find(g => g.value === card.grupoId));
    }

    if (brands.length > 0) {
      result = result.filter(card => brands.find(g => g.value === card.tipoUnidadMonitoreo));
    }

    if (alerts.length > 0) {
      alerts.forEach((a) => {
        if (a.value === 'alarma') {
          result = result.filter(card => card.inAlert);
        }

        if (a.value === 'normal') {
          result = result.filter(card => !card.inAlert && !card.expired);
        }

        if (a.value === 'inactivo') {
          result = result.filter(card => card.expired);
        }
      });
    }

    if (keyword.length > 0) {
      result = result.filter(card => card.nombre.toLowerCase().indexOf(keyword) > -1);
    }
    return result;
  },
);
*/

export const getUniqueUnitType = rectifiers => [...new Set(rectifiers.map(i => i.tipoUnidadMonitoreo))].map(i => ({
  value: i,
  label: i,
}));
/*
export const getUniqueUnitType = createSelector(
  [rectifiers],
  items => [...new Set(items.map(i => i.tipoUnidadMonitoreo))].map(i => ({
    value: i,
    label: i,
  })),
);
*/
export default {
  getRectifiersFiltered,
  getGroupDisponibilidadFilter,
};
