
const initialState = {
  request: {
    error: false,
    process: false,
  },
  isMobile: false,
};

const Universal = (state = initialState, action) => {
  switch (action.type) {
    case 'UNIVERSAL_IS_MOBILE':
      return {
        ...state,
        isMobile: action.payload,
      };
    case 'FETCH_MODULE':
      return {
        ...state,
        request: {
          error: false,
          process: true,
        },
      };

    case 'FETCH_REQUEST_END':
      return {
        ...state,
        request: {
          ...state.request,
          process: false,
        },
      };

    case 'UPDATE_MODULE_SUCCESS':
      return state;

    case 'FETCH_MODULE_SUCCESS':
      return {
        ...state,
        [(action.module === 'uniques' ? `unique-${action.payload.query.prop}` : action.module)]: {
          docs: action.payload.data.docs,
          page: action.payload.data.page,
          limit: action.payload.data.limit,
          totalPages: action.payload.data.totalPages,
          query: action.payload.query,
          count: action.payload.data.count,
        },
        request: {
          error: false,
          process: false,
          msg: null,
        },
      };

    case 'FETCH_MODULE_ERROR':
      return {
        ...state,
        request: {
          error: true,
          msg: action.payload.error,
          process: false,
        },
      };

    default:
      return state;
  }
};

export default Universal;
