import {
  DISPONIBILIDAD_SET_DISPONIBILIDAD,
} from '../actionTypes/disponibilidad';

const initialState = {
  Disponibilidad: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPONIBILIDAD_SET_DISPONIBILIDAD:
      return {
        ...state,
        Disponibilidad: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
