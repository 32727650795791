import React, { useState, useEffect } from 'react';

import {
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Button,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  // makeStyles,
} from '@material-ui/core';

import {
  Row,
} from 'react-bootstrap';

import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { composePure, lifecycle, withProps } from '../../utils/composepure';

import { getNextId } from '../../utils/random';

import PageWrapper from '../../components/PageWrapper';
import AwesomeIcon from '../../components/AwesomeIcon';
import ProfilePictureUpload from '../../components/ProfilePictureUpload';

import './style.css';
import store from '../../store';
import Actions from '../../actions';

import Props from './props';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  index: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
};

TabPanel.defaultProps = {
  children: '',
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/* const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
})); */


/** @description pagina para visualizar el perfil del usuario. Modificacion de datos personales,
 * cambio de contraseña, datos de contacto
 */
const PerfilUsuario = ({
  history,
  usuario,
  tiposMedioContacto,
  zonasHoraria,
  i18n,
  t,
  enqueueSnackbar,
  language,
  resultSavePerfil,
}) => {
  // const classes = useStyles();
  const [mediosContacto, setMediosContacto] = useState([]);
  const [tipoMedioContacto, setTipoMedioContacto] = useState();
  const [valorMedioContacto, setValorMedioContacto] = useState();
  const [textButtonSave, setTextButtonSave] = useState(language === 'EN' ? 'Add' : 'Agregar');
  const [isEdit, setIsEdit] = useState(false);
  const [medioContactoEditID, setMediosContactoEditID] = useState();
  const [finMedio, setFinMedio] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(
    () => {
      if (resultSavePerfil === 'ok') {
        enqueueSnackbar(t('default.successSavePerfil', 'Los datos del usuario se guardaron correctamente'), {
          variant: 'success',
        });
      }

      if (resultSavePerfil === 'error') {
        enqueueSnackbar(t('default.errorSavePerfil', 'Ocurrió un error al guardar los datos del usuario'), {
          variant: 'error',
        });
      }

      if (resultSavePerfil === 'PasswordMismatch') {
        enqueueSnackbar(t('default.passwordMismatch', 'La contraseña actual es incorrecta'), {
          variant: 'error',
        });
      }

      return () => {
        store.dispatch({ type: 'CUSTOMERROR_UPDATEUSER', payload: '' });
      };
    },
    [resultSavePerfil],
  );

  const handleChangeLanguage = (languageId) => {
    console.log('Cambio lenguaje en Perfil Usuario');
    const lng = languageId === '1' ? 'ES' : 'EN';
    store.dispatch(Actions.Language.changeLanguage(lng));
    localStorage.setItem('language', lng);
    i18n.changeLanguage(lng.toLowerCase());
    moment.locale(lng.toLocaleLowerCase());
  };

  const handleDelete = (row) => {
    setMediosContacto(mediosContacto.filter(x => x.medioContactoID !== row.medioContactoID));
    setTipoMedioContacto('');
    setValorMedioContacto('');
  };

  const handleEditar = (row) => {
    console.log(row);
    setTipoMedioContacto(row.tipoMedioContactoID);
    setValorMedioContacto(row.medioContactoValor);
    setTextButtonSave(language === 'EN' ? 'Update' : 'Actualizar');
    setIsEdit(true);
    setMediosContactoEditID(row.medioContactoID);
  };

  const columns = [{
    dataField: 'tipoMedioContacto',
    text: t('default.tipoContacto', 'Tipo'),
    headerStyle: () => ({ width: '35%' }),
    sort: true,
    editable: false,
  },
  {
    dataField: 'medioContactoValor',
    text: t('default.contacto', 'Contacto'),
    headerStyle: () => ({ width: '35%' }),
    sort: true,
    editable: false,
  },
  {
    // dataField: 'medioContactoID',
    dataField: getNextId(),
    text: t('default.editar', 'Editar'),
    formatter: (cell, row) => (
      <IconButton
        // className={classes.button}
        aria-label="edit"
        onClick={() => handleEditar(row)}
      >
        <AwesomeIcon icon="edit" />
      </IconButton>
    ),
    align: 'center',
    headerAlign: 'center',
  },
  {
    // dataField: 'medioContactoID',
    dataField: getNextId(),
    text: t('default.eliminar', 'Eliminar'),
    formatter: (cell, row) => (
      <IconButton
        // className={classes.button}
        aria-label="trash"
        onClick={() => handleDelete(row)}
      >
        <AwesomeIcon icon="trash" />
      </IconButton>
    ),
    align: 'center',
    headerAlign: 'center',
  },
  ];

  const handleAgregarMedioContacto = () => {
    if (!tipoMedioContacto) {
      enqueueSnackbar(t('default.selectContactMean', 'Seleccione un tipo de medio de contacto'), {
        variant: 'error',
      });

      return;
    }

    if (isEdit) {
      const indexMedioContactoEdit = mediosContacto.findIndex(x => x.medioContactoID === medioContactoEditID);

      setMediosContacto([
        ...mediosContacto.slice(0, indexMedioContactoEdit),
        {
          ...mediosContacto[indexMedioContactoEdit],
          tipoMedioContactoID: tipoMedioContacto,
          tipoMedioContacto: tiposMedioContacto.filter(x => x.value === tipoMedioContacto)[0].label,
          medioContactoValor: valorMedioContacto,
          finMedioID: finMedio,
        },
        ...mediosContacto.slice(indexMedioContactoEdit + 1),
      ]);
    } else {
      setMediosContacto([
        ...mediosContacto,
        {
          medioContactoID: mediosContacto.length === 0 ? 1 : Math.max(...mediosContacto.map(x => parseFloat(x.medioContactoID || 0))) + 1,
          tipoMedioContactoID: tipoMedioContacto,
          tipoMedioContacto: tiposMedioContacto.filter(x => x.value === tipoMedioContacto)[0].label,
          medioContactoValor: valorMedioContacto,
          finMedioID: finMedio,
        },
      ]);
    }

    setTipoMedioContacto('');
    setValorMedioContacto('');
    setIsEdit(false);
  };

  const [state, setState] = useState({
    nombre: '',
    apellido: '',
    legajo: '',
    dni: '',
    mediosContacto: [],
    zonaHoraria: '0',
    vistaPreferidaId: 1,
    idioma: 1,
    passwordNuevo: '',
    confirmarNuevoPassword: '',
    finMedio: 1,
  });

  if (usuario && firstLoad) {
    setState({
      nombre: usuario.nombre,
      apellido: usuario.apellido,
      dni: usuario.dni,
      legajo: usuario.legajo,
      zonaHoraria: usuario.zonaHoraria,
      vistaPreferidaId: usuario.vistaPreferidaId,
      idioma: usuario.idiomaId || 1,
    });

    setMediosContacto(usuario.mediosContacto);
    setFirstLoad(false);
  }

  const handleCancelarEdicion = () => {
    setTipoMedioContacto();
    setValorMedioContacto();
    setTextButtonSave(language === 'EN' ? 'Add' : 'Agregar');
  };

  const handleChangeVista = (evt) => {
    setState({ ...state, vistaPreferidaId: parseInt(evt.target.value, 10) });
  };

  const handleSave = () => {
    if (state.passwordNuevo && state.passwordNuevo.length < 4) {
      enqueueSnackbar(t('default.passwordTooShort', 'La contraseña es demasiado corta'), {
        variant: 'error',
      });
      return;
    }

    if (state.passwordNuevo !== state.confirmarNuevoPassword) {
      enqueueSnackbar(t('default.passwordNotEquals', 'Las contraseñas no coinciden'), {
        variant: 'error',
      });
      return;
    }

    if (!state.nombre) {
      enqueueSnackbar(t('default.ingreseNombre', 'Ingrese el nombre'), {
        variant: 'error',
      });
    }

    if (state.passwordNuevo && !state.passwordActual) {
      enqueueSnackbar(t('default.ingresePasswordActual', 'Ingrese su contraseña actual'), {
        variant: 'error',
      });
    }

    store.dispatch(Actions.Users.saveUserData(state.nombre, state.apellido, state.legajo, state.dni,
      state.zonaHoraria, state.vistaPreferidaId, state.idioma, state.passwordActual, state.passwordNuevo,
      usuario.usuarioId, usuario.userName, usuario.entidadId, mediosContacto, state.profilePicture));
  };

  const handleChangeProfilePicture = (picture) => {
    console.log(picture);
    setState({
      ...state,
      profilePicture: picture,
    });
  };

  const [tabSelected, setTabSelected] = useState(0);

  return (
    <PageWrapper
      history={history}
    >
      <Grid container style={{ marginTop: 20, position: 'relative' }}>
        <AppBar position="static">
          <Tabs value={tabSelected} className="tabPerfilUsuario" onChange={(event, newValue) => setTabSelected(newValue)}>
            <Tab className="gf-font-size-menu" label={t('default.datosBasicos', 'Datos básicos')} {...a11yProps(0)} />
            <Tab className="gf-font-size-menu" label={t('default.cambiarPassword', 'Cambiar Password')} {...a11yProps(1)} />
            <Tab className="gf-font-size-menu" label={t('default.mediosContacto', 'Medios de Contacto')} {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={tabSelected} index={0}>
          <ProfilePictureUpload handleChange={handleChangeProfilePicture} t={t} />
          <TextField
            id="nombreTxt"
            label={t('default.nombre', 'Nombre')}
            style={{ width: '90%' }}
            type="text"
            value={state.nombre}
            onChange={evt => setState({ ...state, nombre: evt.target.value })}
            margin="dense"
          />
          <TextField
            id="apellidoTxt"
            label={t('default.apellido', 'Apellido')}
            // className={classes.input}
            style={{ width: '90%' }}
            type="text"
            value={state.apellido}
            onChange={evt => setState({ ...state, apellido: evt.target.value })}
            margin="dense"
          />
          <TextField
            id="nroLegajoTxt"
            label={t('default.nroLegajo', 'Nº Legajo')}
            // className={classes.input}
            style={{ width: '90%' }}
            type="text"
            value={state.legajo}
            onChange={evt => setState({ ...state, legajo: evt.target.value })}
            margin="dense"
          />
          <TextField
            id="dniTxt"
            label={t('default.dni', 'Documento')}
            // className={classes.input}
            style={{ width: '90%' }}
            type="text"
            value={state.dni}
            onChange={evt => setState({ ...state, dni: evt.target.value })}
            margin="dense"
          />
          <FormControl style={{ width: '90%' }}>
            <InputLabel id="lblZonaHoraria">{`${t('default.zonaHoraria', 'Zona Horaria')}`}</InputLabel>
            <Select
              labelId="lblZonaHoraria"
              className="basic-multi-select"
              name="zonaHoraria"
              value={state.zonaHoraria}
              variant="standard"
              onChange={evt => setState({ ...state, zonaHoraria: evt.target.value })}
            >
              {zonasHoraria.map(zh => (
                <MenuItem key={zh.zonaHorariaId} value={zh.zonaHorariaId}>{`${zh.descripcion} - ${zh.gmt}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormLabel component="legend" style={{ marginTop: 50 }}>{t('default.paginaInicio', 'Página de Inicio')}</FormLabel>
          <RadioGroup aria-label="paginaInicio" name="paginaInicio" value={state.vistaPreferidaId} onChange={handleChangeVista} row>
            <FormControlLabel value={1} control={<Radio color="primary" />} label={t('default.tarjeta', 'Tarjeta')} />
            <FormControlLabel value={2} control={<Radio color="primary" />} label={t('default.lista', 'Lista')} />
            <FormControlLabel value={3} control={<Radio color="primary" />} label={t('default.mapa', 'Mapa')} />
          </RadioGroup>
          <FormLabel component="legend" style={{ marginTop: 8 }}>{t('default.idioma', 'Idioma')}</FormLabel>
          <RadioGroup
            aria-label="idioma"
            name="idioma"
            value={state.idioma}
            onChange={(evt) => {
              setState({ ...state, idioma: parseInt(evt.target.value, 10) });
              handleChangeLanguage(evt.target.value);
            }}
            row
          >
            <FormControlLabel value={1} control={<Radio color="primary" />} label="Español" />
            <FormControlLabel value={2} control={<Radio color="primary" />} label="English" />
          </RadioGroup>
        </TabPanel>
        <TabPanel value={tabSelected} index={1}>
          <TextField
            id="passwordActualtxt"
            label={t('default.passwordActual', 'Contraseña actual')}
            // className={classes.input}
            style={{ width: '90%' }}
            type="password"
            value={state.passwordActual}
            onChange={evt => setState({ ...state, passwordActual: evt.target.value })}
            margin="dense"
          />
          <TextField
            id="passwordNuevotxt"
            label={t('default.passwordNuevo', 'Contraseña nueva')}
            // className={classes.input}
            style={{ width: '90%' }}
            type="password"
            value={state.passwordNuevo}
            onChange={evt => setState({ ...state, passwordNuevo: evt.target.value })}
            margin="dense"
          />
          <TextField
            id="confirmarNuevoPasswordTxt"
            label={t('default.confirmarNuevoPassword', 'Confirmar nueva contraseña')}
            // className={classes.input}
            style={{ width: '90%' }}
            type="password"
            value={state.confirmarNuevoPassword}
            onChange={evt => setState({ ...state, confirmarNuevoPassword: evt.target.value })}
            margin="dense"
          />
        </TabPanel>
        <TabPanel value={tabSelected} index={2}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="lblTipoMedioContacto">{`${t('default.tipo', 'Tipo')}`}</InputLabel>
            <Select
              labelId="lblTipoMedioContacto"
              className="basic-multi-select"
              name="tipoMedioContacto"
              value={tipoMedioContacto || ''}
              variant="standard"
              onChange={evt => setTipoMedioContacto(evt.target.value)}
            >
              {tiposMedioContacto.map(tmc => (
                <MenuItem key={tmc.value} value={tmc.value}>{tmc.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormLabel component="legend" style={{ marginTop: 25 }}>{t('default.fin', 'Fin')}</FormLabel>
          <RadioGroup aria-label="idioma" name="idioma" value={finMedio} onChange={evt => setFinMedio(parseInt(evt.target.value, 10))} row>
            <FormControlLabel value={1} control={<Radio color="primary" />} label="Laboral" />
            <FormControlLabel value={2} control={<Radio color="primary" />} label="Personal" />
          </RadioGroup>
          <TextField
            id="valorMedioContactoTxt"
            label={t('default.contacto', 'Contacto')}
            style={{ width: '100%' }}
            type="text"
            value={valorMedioContacto || ''}
            onChange={evt => setValorMedioContacto(evt.target.value)}
            margin="dense"
          />
          <Row>
            <Button
              style={{
                backgroundColor: '#007bff',
                color: '#fff',
                marginTop: 20,
                display: isEdit ? 'inline-block' : 'none',
              }}
              onClick={() => handleCancelarEdicion()}
            >
              {t('default.cancelar', 'Cancelar')}
            </Button>
            <Button
              style={{
                backgroundColor: '#007bff',
                color: '#fff',
                marginTop: 20,
                marginLeft: 10,
              }}
              onClick={() => handleAgregarMedioContacto()}
            >
              {textButtonSave}
            </Button>
          </Row>
          {mediosContacto.length > 0 && (
            <Grid item xs={12} style={{ marginTop: 25, marginBottom: 20 }}>
              <BootstrapTable
                keyField="medioContactoID"
                striped
                hover
                condensed
                bootstrap4
                data={mediosContacto}
                columns={columns}
              />
            </Grid>
          )}
        </TabPanel>
        <div>
          <Button
            className="gf-position-button"
            style={{
              backgroundColor: '#007bff',
              color: '#fff',
            }}
            onClick={handleSave}
          >
            {t('default.guardar', 'Guardar')}
          </Button>
        </div>
      </Grid>
    </PageWrapper>
  );
};

PerfilUsuario.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({
    i18n: props.i18n,
    t: props.t,
    enqueueSnackbar: props.enqueueSnackbar,
  })),
  lifecycle({
    componentDidMount() {
      store.dispatch(Actions.Maestros.getTiposMedioContacto());
      store.dispatch(Actions.Rectifiers.getZonaHorarias());
    },
  }),
  connect(state => ({
    usuario: state.Users.userData,
    tiposMedioContacto: state.Maestros.tiposMedioContacto.map(tmc => ({
      value: tmc.tipoMedioContactoID,
      label: tmc.tipoMedioContactoDescrip,
    })),
    zonasHoraria: state.Rectifiers.zonasHoraria,
    language: state.Language.current,
    resultSavePerfil: state.Users.resultSavePerfil,
  })),
)(PerfilUsuario);

export default withSnackbar(translate('common')(AppCompose));
