import React, { useState, Fragment } from 'react';
// import { connect } from 'react-redux';
// import Select from 'react-select';
import {
  Row,
} from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Container,
  Paper,
  InputBase,
  IconButton,
} from '@material-ui/core';

// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { translate } from 'react-i18next';
// import PageWrapper from '../../components/PageWrapper';
import GridComandos from '../../components/GridComandos';
import GridAlarmas from '../../components/GridAlarmas';
import GridNovedades from '../../components/GridNovedades';
import GridNotificaciones from '../../components/GridNotificaciones';

import AwesomeIcon from '../../components/AwesomeIcon';

// import { composePure, withState } from '../../utils/composepure';
import theme from '../../scss/theme/colors';
// import store from '../../store';
import Props from './props';

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    boxShadow: 'none',
    border: `1px solid ${theme.gray.light}`,
    minHeight: 38,
  },
  input: {
    fontSize: 12,
    marginLeft: 8,
    flex: 1,
  },
});

/** @description Elemento de la cabecera generico utilizado por las paginas de Novedades, comando,
 * alarmas y notificaciones.
 */
const VistaCuadros = ({
  history,
  language,
  // t,
}) => {
  const classes = useStyles();
  const [filter, setFilter] = useState();
  const handleChangeInput = (evt) => {
    setFilter(evt.target.value);
  };

  /* const vistaTypes = [
    { value: 1, label: (<Trans i18nKey="default.novedades">Novedades</Trans>) },
    { value: 2, label: (<Trans i18nKey="default.comandos">Comandos</Trans>) },
    { value: 3, label: (<Trans i18nKey="default.alarmas">Alarmas</Trans>) },
    { value: 4, label: (<Trans i18nKey="default.notificaciones">Notificaciones</Trans>) },
    // { value: 1, label: language === 'EN' ? 'News' : 'Novedades' },
    // { value: 2, label: language === 'EN' ? 'Commands' : 'Comandos' },
    // { value: 3, label: language === 'EN' ? 'Alerts' : 'Alarmas' },
    // { value: 4, label: language === 'EN' ? 'Notifications' : 'Notificaciones' },
  ]; */
  /* const [selectedVista, setSelectedVista] = React.useState(vistaTypes[0]); */

  /* const [vistaSelected, setVistaSelected] = useState(history.location.pathname);

  const getDefaultVista = () => {
    let index = 0;
    switch (vistaSelected) {
      case '/cuadrosNovedades':
        index = 0;
        break;
      case '/cuadrosComandos':
        index = 1;
        break;
      case '/cuadrosAlarmas':
        index = 2;
        break;
      case '/cuadrosNotificaciones':
        index = 3;
        break;
      default:
    }
    return vistaTypes[index];
  }; */

  /* const handleChangeVista = (newValue) => {
    switch (newValue.value) {
      case 1:
        history.push('/cuadrosNovedades');
        setVistaSelected('/cuadrosNovedades');
        break;
      case 2:
        history.push('/cuadrosComandos');
        setVistaSelected('/cuadrosComandos');
        break;
      case 3:
        history.push('/cuadrosAlarmas');
        setVistaSelected('/cuadrosAlarmas');
        break;
      case 4:
        history.push('/cuadrosNotificaciones');
        setVistaSelected('/cuadrosNotificaciones');
        break;
      default:
    }
  }; */

  return (
    <Fragment>
      <Row>
        <Container maxWidth="xl" className="FilterBarHeader">
          <Row>
            <Grid container>
              <Grid item xs={1} className="gf-arrow-padding">
                <IconButton
                  // className={classes.button}
                  aria-label="left"
                  onClick={() => history.push({
                    pathname: '/',
                  })}
                  style={{
                    border: '2px solid rgba(0, 0, 0, 0.54)',
                  }}
                >
                  <AwesomeIcon icon="left" />
                </IconButton>
              </Grid>
              <Grid item xs={5}>
                <h3 className="gf-font-size">
                  { (history.location.pathname === '/cuadrosNovedades' && (language === 'EN' ? 'News' : 'Novedades'))
                  || (history.location.pathname === '/cuadrosComandos' && (language === 'EN' ? 'Commands' : 'Comandos'))
                  || (history.location.pathname === '/cuadrosAlarmas' && (language === 'EN' ? 'Alarms' : 'Alarmas'))
                  || (history.location.pathname === '/cuadrosNotificaciones' && (language === 'EN' ? 'Notifications' : 'Notificaciones'))
                  }
                </h3>
              </Grid>
              <Grid item xs={3} className="gf-hide-grid" />
              <Grid item xs={3} className="gf-pading">
                <Paper className={classes.root}>
                  <InputBase
                    className={classes.input}
                    type="text"
                    // placeholder={`${t('default.buscar', 'Buscar')}...`}
                    placeholder={language === 'EN' ? 'Search' : 'Buscar'}
                    inputProps={{ 'aria-label': language === 'EN' ? 'Search' : 'Buscar' }}
                    // inputProps={{ 'aria-label': <Trans i18nKey="default.buscar">Buscar</Trans> }}
                    onChange={handleChangeInput}
                    value={filter}
                  />
                </Paper>
              </Grid>
              {/*
              <Grid item xs={3}>
                <Select
                  defaultValue={getDefaultVista()}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="color"
                  options={vistaTypes}
                  onChange={handleChangeVista}
                />
              </Grid>
              */}
            </Grid>
          </Row>
        </Container>
      </Row>
      <Row>
        <Grid container>
          <Grid item xs={12}>
            {history.location.pathname === '/cuadrosNovedades' && (
              <GridNovedades filter={filter} />
            )}
            {history.location.pathname === '/cuadrosComandos' && (
              <GridComandos filter={filter} />
            )}
            {history.location.pathname === '/cuadrosAlarmas' && (
              <GridAlarmas filter={filter} />
            )}
            {history.location.pathname === '/cuadrosNotificaciones' && (
              <GridNotificaciones filter={filter} />
            )}
          </Grid>
        </Grid>
      </Row>
    </Fragment>
  );
};

VistaCuadros.propTypes = Props;

export default translate('common')(hot(module)(VistaCuadros));
