import {
  GROUPS_SET_DOCS,
  SET_ADDGROUP,
  SETDELETE_GROUP,
} from '../actionTypes/groups';

const initialState = {
  docs: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GROUPS_SET_DOCS:
      return {
        ...state,
        docs: action.payload.data,
      };
    case SET_ADDGROUP:
      return {
        ...state,
        docs: [
          ...state.docs,
          {
            grupoId: action.payload.grupoId,
            nombre: `${action.payload.grupo}${action.payload.subgrupo ? ` - ${action.payload.subgrupo}` : ''}`,
          },
        ],
      };
    case SETDELETE_GROUP:
      return {
        ...state,
        docs: state.docs.filter(x => x.grupoId !== action.payload.grupoId),
      };
    default:
      return state;
  }
};

export default reducer;
