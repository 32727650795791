import React from 'react';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import Props from './props';

const sum = (items, prop) => {
  let total = 0;
  for (let i = 0, _len = items.length; i < _len; i += 1) {
    if (items[i].datoValido) {
      total += items[i][prop];
    }
  }
  return total;
};

/** @description Componente grafico del tipo PIE para mostrar graficamente los resultados de
 * la disponibilidad de los equipos.
 */
class PieChartDisponibilidadAMC extends React.Component {
  componentDidMount() {
    const chart = am4core.create('PieChartDisponibilidad', am4charts.PieChart3D);
    chart.logo.height = -9999;
    const { filteredData, t } = this.props;
    const data = [{
      Estado: t('default.conectado', 'Conectado'),
      Value: sum(filteredData, 'horasOperativoReal'),
    },
    {
      Estado: t('default.desconectado', 'Desconectado'),
      Value: sum(filteredData, 'horasNoOperativo'),
    },
    ];

    chart.data = data;

    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries3D());
    pieSeries.dataFields.value = 'Value';
    pieSeries.dataFields.category = 'Estado';
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    pieSeries.slices.template.stroke = am4core.color('#fff');
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;

    // Disabling labels and ticks on inner circle
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    // Disable sliding out of slices
    pieSeries.slices.template.states.getKey('hover').properties.shiftRadius = 0;
    pieSeries.slices.template.states.getKey('hover').properties.scale = 1.1;

    pieSeries.slices.template.tooltipText = '{category} : {value.percent.formatNumber("#.0")}% {value.value} hs';

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'bottom';

    chart.innerRadius = am4core.percent(60);

    this.chart = chart;
  }

  componentDidUpdate(oldProps) {
    const { filteredData, t } = this.props;
    if (oldProps.filteredData !== filteredData) {
      const data = [{
        Estado: t('default.conectado', 'Conectado'),
        Value: sum(filteredData, 'horasOperativoReal'),
      },
      {
        Estado: t('default.desconectado', 'Desconectado'),
        Value: sum(filteredData, 'horasNoOperativo'),
      },
      ];

      this.chart.data = data;
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div
        id="PieChartDisponibilidad"
        style={{
          width: '100%',
          marginRight: '25px',
          height: '380px',
        }}
      />
    );
  }
}


PieChartDisponibilidadAMC.propTypes = Props;

export default PieChartDisponibilidadAMC;
