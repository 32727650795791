import React, { Fragment } from 'react';

import {
  Image as Img,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { composePure } from '../../utils/composepure';

// import { Link } from 'react-router-dom';
import Props from './props';
import Logo from '../../assets/images/kmt-logo.png';
import User from '../../assets/images/persona_cara_cuadrada.jpg';
import EN from '../../assets/images/us-flag.png';
import ES from '../../assets/images/es-flag.png';
import IconUsuario from '../../assets/images/iconoUsuario.png';
import PanelUsuario from '../../assets/images/panelUsuario.png';
import IconNotificacion from '../../assets/images/iconoNotificacion.png';
import MenuFiltros from '../../assets/images/menuFiltros.png';
import IconCards from '../../assets/images/iconCards.png';
import CardExample from '../../assets/images/cardsExample.png';
import IconList from '../../assets/images/iconList.png';
import ListDevices from '../../assets/images/listDevices.png';
import Map from '../../assets/images/map.png';
import Footer from '../../assets/images/footer.png';
import IconComandos from '../../assets/images/iconComandos.png';
import ModalComandos from '../../assets/images/modalComandos.png';
import IconReporteActual from '../../assets/images/iconReporteActual.png';
import ModalReporteActual from '../../assets/images/modalReporteActual.png';
import IconReporteHistorico from '../../assets/images/iconReporteHistorico.png';
import ModalReporteHistorico from '../../assets/images/modalReporteHistorico.png';
import MenuRegistro from '../../assets/images/menuRegistro.png';
import ListaAlarmas from '../../assets/images/listAlarmas.png';
import ComboTipoRegistro from '../../assets/images/comboTipoRegistro.png';
import MenuTableros from '../../assets/images/menuTableros.png';
import TableDisponibilidad from '../../assets/images/tableDisponibilidad.png';
import ChartDispositivos from '../../assets/images/chartDispositivos.png';

/** @description Componente especifico para uso de imagenes con su fuente.
 */
const Image = ({
  src,
  userData,
  ...props
}) => (
  <Fragment>
    {/* {src === 'logo' && <Link to="/"><Img src={Logo} {...props} /></Link>} */}
    {src === 'logo' && <a href="/"><Img src={Logo} {...props} /></a>}
    {src === 'user' && <Img src={User} {...props} />}
    {src === 'en' && <Img src={EN} {...props} />}
    {src === 'es' && <Img src={ES} {...props} />}
    {src === 'anomalo' && <Img width="24" height="24" src="https://cdn0.iconfinder.com/data/icons/super-mono-reflection/red/exclamation-circle_red.png" {...props} />}
    {src === 'iconUsuario' && <Img src={IconUsuario} {...props} /> }
    {src === 'panelUsuario' && <Img src={PanelUsuario} {...props} /> }
    {src === 'iconNotificacion' && <Img src={IconNotificacion} {...props} />}
    {src === 'menuFiltros' && <Img src={MenuFiltros} {...props} />}
    {src === 'iconCards' && <Img src={IconCards} {...props} />}
    {src === 'cardsExample' && <Img src={CardExample} {...props} />}
    {src === 'iconList' && <Img src={IconList} {...props} />}
    {src === 'listDevices' && <Img src={ListDevices} {...props} />}
    {src === 'map' && <Img src={Map} {...props} />}
    {src === 'footer' && <Img src={Footer} {...props} />}
    {src === 'iconComandos' && <Img src={IconComandos} {...props} />}
    {src === 'modalComandos' && <Img src={ModalComandos} {...props} />}
    {src === 'iconReporteActual' && <Img src={IconReporteActual} {...props} />}
    {src === 'modalReporteActual' && <Img src={ModalReporteActual} {...props} />}
    {src === 'iconReporteHistorico' && <Img src={IconReporteHistorico} {...props} />}
    {src === 'modalReporteHistorico' && <Img src={ModalReporteHistorico} {...props} />}
    {src === 'menuRegistro' && <Img src={MenuRegistro} {...props} />}
    {src === 'listaAlarmas' && <Img src={ListaAlarmas} {...props} />}
    {src === 'comboTipoRegistro' && <Img src={ComboTipoRegistro} {...props} />}
    {src === 'menuTableros' && <Img src={MenuTableros} {...props} />}
    {src === 'tableDisponibilidad' && <Img src={TableDisponibilidad} {...props} />}
    {src === 'chartDispositivos' && <Img src={ChartDispositivos} {...props} />}
    {src === 'profilePicture' && <Img src={userData.profilePicture || User} {...props} />}
  </Fragment>
);

Image.propTypes = Props;

export default composePure(
  connect(state => ({
    userData: state.Users.userData,
  })),
)(Image);

// export default Image;
