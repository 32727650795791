import React, { Fragment } from 'react';
import {
  Grid,
} from '@material-ui/core';
// import { translate, Trans } from 'react-i18next';
import propTypes from './props';
import CardCarrousel from '../CardCarousel';

/** @description pagina secundaria accedida desde mas Dispositivos.
 * Muestra las salidas de los dispositivos.
 */
const RectifiersOutputList = ({
  salidas,
  fecha,
  t,
  language,
}) => (
  <Fragment>
    {salidas && salidas.length > 0 && (
      <CardCarrousel
        salidas={salidas}
        fecha={fecha}
        t={t}
        language={language}
      />
    )}
    {salidas && salidas.length === 0 && (
      <Grid item xs={12} className="mt-2 mb-2 x-center">
        <span>{t('default.noOutputs', 'No se han encontrado Salidas')}</span>
      </Grid>
    )}
  </Fragment>
);

RectifiersOutputList.propTypes = propTypes;

// export default translate('common')(RectifiersOutputList);

export default RectifiersOutputList;
