import React from 'react';
import Select from 'react-select';
import {
  Grid,
  Container,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { translate, Trans } from 'react-i18next';

import ToggleGridButtons from '../../components/ToggleGridButtons';
import InputSearch from '../../components/InputSearch';
import propTypes from './props';

import {
  composePure,
  withProps,
} from '../../utils/composepure';

import Actions from '../../actions';

import {
  getUniqueUnitType,
} from '../../selectors';

/** @description Contenedor de nuestro pagina principal, donde encontraremos un submenu de busqueda
 * y accesos rapidos para poder filtrar o buscar dispositivos de forma agil.
 */
const FilterBarHeader = ({
  selectedCategory,
  selectedAlertTypes,
  onChangeGroup,
  onChangeAlertType,
  categories = [],
  alertTypes = [],
  selectedUnitTypes,
  uniqueUnitTypes = [],
  onChangeUnitType,
  history,
  t,
  // language,
}) => (
  <Container maxWidth={false} className="FilterBarHeader">
    <Grid container>
      <Grid container className="hideMobile" item xs={7} key="filterByAlertType">
        <Grid item xs={3} className="mr-2">
          <Select
            defaultValue={selectedAlertTypes}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            name="color"
            placeholder={<Trans i18nKey="default.estado">Estado</Trans>}
            options={alertTypes}
            onChange={onChangeAlertType}
          />
        </Grid>
        <Grid item xs={3} className="mr-2">
          <Select
            defaultValue={selectedUnitTypes}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            name="color"
            placeholder={<Trans i18nKey="default.tipoUnidad">Tipo de Unidad</Trans>}
            options={uniqueUnitTypes}
            onChange={onChangeUnitType}
          />
        </Grid>
        <Grid item xs={4} className="mr-2" style={{ maxHeight: '35px' }}>
          <Select
            defaultValue={selectedCategory}
            isMulti
            name="colors"
            options={categories}
            className="basic-multi-select w-100"
            classNamePrefix="select"
            placeholder={<Trans i18nKey="default.grupo">Grupo</Trans>}
            onChange={onChangeGroup}
          />
        </Grid>
      </Grid>
      <Grid container item xs={5} className="x-end showMobile" key="filterByGroup">
        <Grid item xs={6} className="mr-2 inputSearchMobile">
          <InputSearch />
        </Grid>
        <Grid item xs={4} className="p-0 max-content">
          <ToggleGridButtons
            history={history}
            t={t}
          />
        </Grid>
      </Grid>
    </Grid>
  </Container>
);

FilterBarHeader.propTypes = propTypes;

export default translate('common')(composePure(
  withProps(props => ({ t: props.t })),
  connect(state => ({
    // language: state.Language.current,
    categories: state.Groups.docs.map(cat => ({
      value: cat.grupoId,
      label: cat.nombre,
    })),
    alertTypes: state.Filters.alertTypes.map(at => ({
      value: at.value,
      label: state.Language.current === 'EN' ? at.labelEN : at.labelES,
    })),
    uniqueUnitTypes: getUniqueUnitType(state.Rectifiers.docs),
    selectedCategory: state.Filters.group,
    selectedUnitTypes: state.Filters.brands,
    selectedAlertTypes: state.Filters.alerts,
  }),
  dispatch => ({
    onChangeGroup: group => dispatch(Actions.Filters.setGroup(group || [])),
    onChangeAlertType: alertType => dispatch(Actions.Filters.setAlertType(alertType || [])),
    onChangeUnitType: utype => dispatch(Actions.Filters.setBrands(utype || [])),
  })),
)(FilterBarHeader));
