import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AwesomeIcon from '../AwesomeIcon';

import theme from '../../scss/theme/colors';
import Props from './props';

const useStyles = makeStyles({
  gfMedia: {/* --Hacer desaparecer el buscar al achicar-- */
    display: props => props.display,
    '@media (max-width:992px)': {
      display: 'none',
    },
  },
  gfMedia2: {
    paddingLeft: props => props.paddingLeft,
    '@media (max-width:992px)': {
      paddingLeft: '10%',
    },
  },
  toggleContainer: {
    margin: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    maxHeight: 38,
  },
  ToggleButtonGroup: {
    backgroundColor: theme.gray.light,
  },
  ToggleButton: {
    maxHeight: 38,
    border: 0,
    color: '#fff',
    outline: 'none',
    /* '&.Mui-selected': {
      backgroundColor: theme.gray.light,
      color: '#272727',
    }, */
  },
  SelectedButton: {
    backgroundColor: theme.gray.light,
    color: '#272727',
  },
});

/** @description Componente reutilizable en los item de cada salida en el listado de tendencia.
 * Encontramos funcionalidad de cada boton o grupo de botones
 */
const ToggleGridButtons = ({
  history,
  t,
}) => {
  const [alignment, setAlignment] = React.useState();
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={`${classes.toggleContainer} ${classes.gfMedia2}`}>
          <ToggleButtonGroup
            size="small"
            value={alignment}
            className={classes.ToggleButtonGroup}
            exclusive
            onChange={handleAlignment}
          >
            <ToggleButton
              value="grid"
              className={`${classes.ToggleButton} ${history.location.pathname === '/' ? classes.SelectedButton : ''} `}
              onClick={() => history.push('/')}
              title={t('default.tarjetas', 'Tarjetas')}
            >
              <AwesomeIcon icon="th" size="2x" />
            </ToggleButton>
            <ToggleButton
              value="list"
              className={`${classes.gfMedia} ${classes.ToggleButton} ${history.location.pathname === '/list' ? classes.SelectedButton : ''} `}
              onClick={() => history.push('/list')}
              title={t('default.lista', 'Lista')}
            >
              <AwesomeIcon icon="bars" size="2x" />
            </ToggleButton>
            <ToggleButton
              value="map"
              className={`${classes.ToggleButton} ${history.location.pathname === '/map' ? classes.SelectedButton : ''} `}
              onClick={() => history.push('/map')}
              title={t('default.mapa', 'Mapa')}
            >
              <AwesomeIcon icon="map" size="2x" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Grid>
    </Grid>
  );
};

ToggleGridButtons.propTypes = Props;

export default ToggleGridButtons;
