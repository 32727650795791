import React, { Fragment, useState } from 'react';
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  TextField,
} from '@material-ui/core';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { es, enUS } from 'date-fns/locale';
import AwesomeIcon from '../AwesomeIcon';
import { composePure, withProps } from '../../utils/composepure';
import Actions from '../../actions';
import store from '../../store';

import Props from './props';
// import { EditorFormatListBulleted } from 'material-ui/svg-icons';

registerLocale('es', es);
registerLocale('enUS', enUS);

/** @description Componente DatePicker utilizado en rectificadores, tambien agrega botones para
 * agregar o quitar e-mail.
 */
const RectifierDatePicker = ({
  classes,
  openCMD,
  handleCloseCMD,
  // handleChange,
  toggleCalendar,
  language,
  rectificadores,
  userId,
  t,
  enqueueSnackbar,
  rectificadorID,
}) => {
  /* const {
    startDate,
    isOpen,
  } = state; */
  const actualDate = new Date();
  const minDate = (new Date()).setMonth(actualDate.getMonth() - 12);
  const oneMonthAgeDate = (new Date()).setMonth(actualDate.getMonth() - 1);
  const [startDate, setStartDate] = useState(oneMonthAgeDate);
  const [endDate, setEndDate] = useState(actualDate);
  const [emails, setEmails] = useState(['']);
  const themes = useTheme();
  const fullScreen = useMediaQuery(themes.breakpoints.down('sm'));
  // const [openResult, setOpenResult] = useState(false);

  const reInitializateDates = () => {
    setStartDate(oneMonthAgeDate);
    setEndDate(actualDate);
  };

  const addEmail = () => {
    setEmails([
      ...emails,
      '',
    ]);
  };

  const removeEmail = (index) => {
    setEmails([
      ...emails.splice(0, index),
      ...emails.splice(index + 1),
    ]);
  };

  const handleTextEmail = (index, value) => {
    setEmails(
      [
        ...emails.slice(0, index),
        value,
        ...emails.slice(index + 1),
      ],
    );
  };

  // const handleOpenResultSend = () => setOpenResult(true);
  /* const handleCloseResultSend = () => {
    setOpenResult(false);
    handleCloseCMD();
  }; */

  const handleConsultar = () => {
    const rectificadoresSeleccionados = rectificadores.filter(r => r.selected);
    const rectificadoresId = rectificadorID || rectificadoresSeleccionados.map(r => r.rectificadorID).join(',');
    const fechaDesde = new Date(startDate);
    fechaDesde.setHours(0);
    fechaDesde.setMinutes(0);
    fechaDesde.setSeconds(0);
    const fechaHasta = new Date(endDate);
    fechaHasta.setHours(23);
    fechaHasta.setMinutes(59);
    fechaHasta.setSeconds(59);
    store.dispatch(Actions.Reportes.sendReporteHistorico(rectificadoresId, language, Moment(fechaDesde).format('YYYY-MM-DDTHH:mm:ss'), Moment(fechaHasta).format('YYYY-MM-DDTHH:mm:ss'), emails.join(';'), userId));
    // handleOpenResultSend();
    enqueueSnackbar(t('default.reporteAgendado', 'Generando reporte...'), {
      variant: 'info',
    });
    reInitializateDates();
    handleCloseCMD();
  };

  return (
    <Fragment>
      <IconButton
        className={classes.button}
        aria-label="calendar"
        onClick={toggleCalendar}
        title={t('default.reporteHistorico', 'Reporte histórico')}
      >
        <AwesomeIcon icon="calendar" />
      </IconButton>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        fullScreen={fullScreen}
        open={openCMD}
        onClose={handleCloseCMD}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={4}>
              <DatePicker
                id="dpStartDate"
                selected={startDate}
                selectsStart
                dateFormat="dd/MM/yyyy"
                minDate={minDate}
                maxDate={endDate}
                onChange={date => setStartDate(date)}
                locale={language === 'EN' ? enUS : es}
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={4}>
              <DatePicker
                id="dpEndDate"
                selected={endDate}
                selectsEnd
                dateFormat="dd/MM/yyyy"
                maxDate={endDate}
                minDate={startDate}
                onChange={date => setEndDate(date)}
                locale={language === 'EN' ? enUS : es}
              />
            </Grid>
          </Grid>
          {/* <TextField
            style={{ marginTop: '265px' }}
            id="txtEmails"
            type="email"
            autoComplete="email"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            value={emails}
            onChange={evt => setEmails(evt.target.value)}
            placeholder="Emails..."
          /> */}
          <Grid item xs={12} style={{ marginTop: '265px' }}>
            {emails.map((e, index) => (
              <Fragment>
                <TextField
                  id={`${index}txtEmail`}
                  type="email"
                  autoComplete="email"
                  style={{ width: '75%' }}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={e}
                  onChange={evt => handleTextEmail(index, evt.target.value)}
                  placeholder={`Emails (${t('default.opcional', 'Opcional')})`}
                />
                <IconButton
                  style={{ width: '10%' }}
                  // className={classes.button}
                  aria-label="minus"
                  onClick={() => removeEmail(index)}
                >
                  <AwesomeIcon icon="minus" />
                </IconButton>
                <IconButton
                  style={{ width: '10%' }}
                  // className={classes.button}
                  aria-label="plus"
                  onClick={addEmail}
                >
                  <AwesomeIcon icon="plus" />
                </IconButton>
              </Fragment>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonModal}
            onClick={() => {
              reInitializateDates();
              handleCloseCMD();
            }}
          >
            {t('default.cerrar', 'Cerrar')}
          </Button>
          <Button variant="contained" color="primary" className={classes.buttonModal} onClick={handleConsultar}>{t('default.exportar', 'Exportar')}</Button>
        </DialogActions>
      </Dialog>
      {/* Popup que muestra el mensaje del envio de comandos
      <Dialog
        open={openResult}
        onClose={handleCloseResultSend}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('default.reporteAgendado', 'Generando reporte...')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResultSend} variant="contained" className={classes.buttonModal} color="primary">
            {t('default.cerrar', 'Cerrar')}
          </Button>
        </DialogActions>
      </Dialog> */}
    </Fragment>
  );
};

RectifierDatePicker.propTypes = Props;

const AppCompose = composePure(
  connect(state => ({
    rectificadores: state.Rectifiers.docs,
    language: state.Language.current,
    userId: state.Users.userData.usuarioId,
  })),
  withProps(props => ({
    t: props.t,
    enqueueSnackbar: props.enqueueSnackbar,
  })),
)(RectifierDatePicker);

export default withSnackbar(translate('common')(AppCompose));
// export default RectifierDatePicker;
