import {
  ADD_NOTIFICACION,
  DELETE_SET_NOTIFICATION,
  SET_NOTIFICACIONES,
  DELETE_SET_NOTIFICATION_ALL,
  SET_MARK_READED,
} from '../actionTypes/notificaciones';

const initialState = {
  NotificacionesRecibidas: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICACION:
      return {
        ...state,
        NotificacionesRecibidas: [...state.NotificacionesRecibidas, action.payload.data],
      };
    case DELETE_SET_NOTIFICATION:
      return {
        ...state,
        NotificacionesRecibidas: state.NotificacionesRecibidas.filter(n => n.notificacionId !== action.payload.notificacionId),
      };
    case SET_NOTIFICACIONES:
      return {
        ...state,
        NotificacionesRecibidas: action.payload.data,
      };
    case DELETE_SET_NOTIFICATION_ALL:
      // const newNotificaciones = state.NotificacionesRecibidas.filter(x => action.payload.notificaciones.findIndex(y => y.notificacionId === x.notificacionId) === -1);
      // eslint-disable-next-line no-case-declarations
      const notificacionesEliminadas = state.NotificacionesRecibidas.map(n => ({
        ...n,
        eliminada: action.payload.notificaciones.findIndex(x => x.notificacionId === n.notificacionId) > -1 ? true : n.eliminada,
      }));
      return {
        ...state,
        NotificacionesRecibidas: notificacionesEliminadas,
      };
    case SET_MARK_READED:
      // eslint-disable-next-line no-case-declarations
      const newNotificacionesRead = state.NotificacionesRecibidas.map(n => ({
        ...n,
        leido: action.payload.notificaciones.findIndex(x => x.notificacionId === n.notificacionId) > -1 ? true : n.leido,
      }));
      return {
        ...state,
        NotificacionesRecibidas: newNotificacionesRead,
      };
    default:
      return state;
  }
};

export default reducer;
