import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTh,
  faThLarge,
  faCrosshairs,
  faList,
  faTrash,
  faPlus,
  faBell,
  faSearch,
  faAngleLeft,
  faCog,
  faSignOutAlt,
  faUser,
  faMap,
  faCalendar,
  faDownload,
  faBroom,
  faEdit,
  faArrowLeft,
  faMinus,
  faArrowRight,
  faExclamationTriangle,
  faBookOpen,
  faLaptopCode,
  faQuestion,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import {
  faClock,
} from '@fortawesome/free-regular-svg-icons';
import Props from './props';

/** @description Componente que contiene iconos para utilizar en la WEB.
 */
const AwesomeIcon = ({
  icon,
  rigthText,
  ...iconProps
}) => (
  <Fragment>
    {icon === 'th' && <FontAwesomeIcon icon={faTh} {...iconProps} />}
    {icon === 'th-large' && <FontAwesomeIcon icon={faThLarge} {...iconProps} />}
    {icon === 'clock' && <FontAwesomeIcon icon={faClock} {...iconProps} />}
    {icon === 'cross' && <FontAwesomeIcon icon={faCrosshairs} {...iconProps} />}
    {icon === 'bars' && <FontAwesomeIcon icon={faList} {...iconProps} />}
    {icon === 'trash' && <FontAwesomeIcon icon={faTrash} {...iconProps} />}
    {icon === 'minus' && <FontAwesomeIcon icon={faMinus} {...iconProps} />}
    {icon === 'plus' && <FontAwesomeIcon icon={faPlus} {...iconProps} />}
    {icon === 'map' && <FontAwesomeIcon icon={faMap} {...iconProps} />}
    {icon === 'bell' && <FontAwesomeIcon icon={faBell} {...iconProps} />}
    {icon === 'search' && <FontAwesomeIcon icon={faSearch} {...iconProps} />}
    {icon === 'arrow' && <FontAwesomeIcon icon={faAngleLeft} {...iconProps} />}
    {icon === 'settings' && <FontAwesomeIcon icon={faCog} {...iconProps} />}
    {icon === 'logout' && <FontAwesomeIcon icon={faSignOutAlt} {...iconProps} />}
    {icon === 'user' && <FontAwesomeIcon icon={faUser} {...iconProps} />}
    {icon === 'calendar' && <FontAwesomeIcon icon={faCalendar} {...iconProps} />}
    {icon === 'download' && <FontAwesomeIcon icon={faDownload} {...iconProps} />}
    {icon === 'clear' && <FontAwesomeIcon icon={faBroom} {...iconProps} />}
    {icon === 'edit' && <FontAwesomeIcon icon={faEdit} {...iconProps} />}
    {icon === 'left' && <FontAwesomeIcon icon={faArrowLeft} {...iconProps} />}
    {icon === 'right' && <FontAwesomeIcon icon={faArrowRight} {...iconProps} />}
    {icon === 'confAlarma' && <FontAwesomeIcon icon={faExclamationTriangle} {...iconProps} />}
    {icon === 'read' && <FontAwesomeIcon icon={faBookOpen} {...iconProps} />}
    {icon === 'device' && <FontAwesomeIcon icon={faLaptopCode} {...iconProps} />}
    {icon === 'help' && <FontAwesomeIcon icon={faQuestion} {...iconProps} />}
    {icon === 'excel' && <FontAwesomeIcon icon={faFileExcel} {...iconProps} />}
    {rigthText && <span className="ml-2">{rigthText}</span>}
  </Fragment>
);

AwesomeIcon.propTypes = Props;
export default AwesomeIcon;
