import {
  FORMS_UPDATE_FIELD_VALUE,
  FORMS_SET_DEFAULT_VALUES,
} from '../actionTypes/forms';

const initialState = {
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FORMS_SET_DEFAULT_VALUES:
      return {
        ...state,
        [action.form]: action.value,
      };
    case FORMS_UPDATE_FIELD_VALUE:
      return {
        ...state,
        [action.form]: {
          ...state[action.form],
          [action.key]: action.value,
        },
      };
    default:
      return state;
  }
};

export default reducer;
