import {
  put,
  call,
} from 'redux-saga/effects';

import Actions from '../actions';

/** @description Llamada al EndPoint para obtener tipos de medios de contacto.
 * URL: maestro/getTipoMedioContacto
*/
export const getTiposMedioContacto = requestModule => function* getTiposMedioContactoSaga() {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_TIPOSMEDIOCONTACTO',
      module: 'maestro/getTipoMedioContacto',
      query,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export const getEmpresasAll = requestModule => function* getEmpresasAllSaga() {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_EMPRESASALL',
      module: 'maestro/EmpresasAll',
      query,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export const getMarcasAll = requestModule => function* getMarcasAllSaga() {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_MARCASALL',
      module: 'maestro/MarcasAll',
      query,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export const getTipoUnidadMonitoreoAll = requestModule => function* getTipoUnidadMonitoreoAllSaga() {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_TIPOUNIDADALL',
      module: 'Rectificador/TipoUnidadMonitoreoAll',
      query,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export const getUnidadesComunicacionAll = requestModule => function* getUnidadesComunicacionAllSaga() {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_UNIDADESCOMUNICACION',
      module: 'maestro/UnidadComunicacionAll',
      query,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export const getLineasAll = requestModule => function* getLineasAllSaga() {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_LINEASALL',
      module: 'maestro/LineasAll',
      query,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export const getTipoComunicacionAll = requestModule => function* getTipoComunicacionAllSaga() {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_TIPOCOMUNICACION',
      module: 'maestro/TipoComunicacionAll',
      query,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export const getPlanesAll = requestModule => function* getPlanesAllSaga() {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_PLANES',
      module: 'maestro/PlanesAll',
      query,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export const getCuentasIPGateway = requestModule => function* getCuentasIPGatewaySaga() {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'SET_CUENTASIPGATEWAY',
      module: 'maestro/CuentasIPGatewayAll',
      query,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export const saveUnidadComunicacion = apiClient => function* saveUnidadComunicacionSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'maestro/GuardarUnidadComunicacion',
      method: 'post',
      data: action.payload,
      // dispatchAction: 'DELETE_SET_NOTIFICATION_ALL',
      process: true,
    };

    const response = yield call(apiClient, params);

    if (response.status === 200) {
      if (action.payload.unidadComunicacionID === -1) {
        yield put(Actions.Maestros.setNewUC(response.data));
      } else {
        yield put(Actions.Maestros.updateUC(response.data));
      }
    }

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    yield put({ type: 'SET_RESULTSAVEUC_ERROR' });
    return Promise.reject(err);
  }
};

export const saveLinea = apiClient => function* saveLineaSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'maestro/GuardarLinea',
      method: 'post',
      data: action.payload,
      // dispatchAction: 'DELETE_SET_NOTIFICATION_ALL',
      process: true,
    };

    const response = yield call(apiClient, params);

    if (response.status === 200) {
      if (action.payload.lineaId === -1) {
        yield put(Actions.Maestros.setNewLinea(response.data));
      } else {
        yield put(Actions.Maestros.updateLinea(response.data));
      }
    }

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    yield put({ type: 'SET_RESULTSAVELINEA_ERROR' });
    return Promise.reject(err);
  }
};

export const deleteUnidadComunicacion = apiClient => function* deleteUnidadComunicacionSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: `maestro/EliminarUnidadComunicacion/${action.payload}`,
      method: 'delete',
      // data: action.payload,
      process: true,
    };

    yield call(apiClient, params);
    yield put(Actions.Maestros.setDeleteUC(action.payload));


    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export const deleteLinea = apiClient => function* deleteLineaSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: `maestro/EliminarLinea/${action.payload}`,
      method: 'delete',
      // data: action.payload,
      process: true,
    };

    yield call(apiClient, params);
    yield put(Actions.Maestros.setDeleteLinea(action.payload));


    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

export default {

};
