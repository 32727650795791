import {
  put,
  call,
} from 'redux-saga/effects';

import axios from 'axios';
import { saveAs } from 'file-saver';

import Actions from '../actions';
import config from '../config';

/** @description Llamada al EndPoint para obtener comandos disponibles.
 * URL: Comando/ComandosDisponiblesAll
*/
export const getComandosDisponibles = requestModule => function* getComandosDisponiblesSaga() {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'COMANDOS_SET_COMANDOSDISPONIBLES',
      module: 'Comando/ComandosDisponiblesAll',
      query,
      // process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para descargar la respuesta a un comando.
 * URL: /Comando/DownloadCommandResponse/${reporteID}/${language}
*/
export const downloadComandResponse = () => function* downloadComandResponseSaga(action) {
  const { reporteID, language } = action.payload;
  yield axios
    .get(`${config.ENDPOINT_URL}/Comando/DownloadCommandResponse/${reporteID}/${language}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    })
    .then((res) => {
      const blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel',
      });

      saveAs(blob, 'respuestaComando.xlsx');
    });
};

/** @description Llamada al EndPoint para obtener comandos disponibles por tipo de unidad de monitoreo.
 * URL: Comando/ComandosDisponiblesAll
*/
export const getComandosDisponiblesPorTipoUnidad = requestModule => function* getComandosDisponiblesPorTipoUnidadSaga(action) {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'COMANDOS_SET_COMANDOSDISPONIBLES',
      module: `Comando/ByTipoUnidadMonitoreo/${action.payload}`,
      query,
      process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para obtener comandos disponibles segun dispositivo.
 * URL: Comando/ComandosEnviadosByDevice/${action.payload}
*/
export const getComandosByDevice = requestModule => function* getComandosDisponiblesSaga(action) {
  try {
    const query = {};
    yield requestModule({
      dispatchAction: 'VISTACUADROS_SET_COMANDOS',
      module: `Comando/ComandosEnviadosByDevice/${action.payload}`,
      query,
      // process: true,
    });
    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

/** @description Llamada al EndPoint para enviar comando.
 * URL: Comando/EnviarComandos
*/
export const sendComando = apiClient => function* sendComandoSaga(action) {
  try {
    const params = {
      query: {},
      microservice: '',
      module: 'Comando/EnviarComandos',
      method: 'post',
      data: action.payload,
      process: true,
    };
    const response = yield call(apiClient, params);
    if (response.status === 200) {
      yield put(Actions.Comandos.setComandoSendResult(response.data));
    }

    return true;
  } catch (err) {
    yield put({ type: 'FETCH_REQUEST_END' });
    return Promise.reject(err);
  }
};

// COMANDOS_SEND_COMANDOS

export default {

};
