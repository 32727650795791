import React from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Container,
  Grid,
} from '@material-ui/core';
import { translate } from 'react-i18next';
import Moment from 'moment';
import { composePure, withProps, lifecycle } from '../../utils/composepure';
import store from '../../store';
import Props from './props';

/** @description Componente especifico para mostrar las alertas efectuadas en forma de lista.
 * Se muestra evento, fecha, dispositivo y notas.
 */
const GridAlarmas = ({
  alarmas,
  t,
  language,
  filter,
}) => {
  const filteredName = alarmas.filter(a => !filter || (language === 'EN' ? a.nombreEN.toLowerCase().indexOf(filter.toLowerCase()) > -1 : a.nombreES.toLowerCase().indexOf(filter.toLowerCase()) > -1));
  const filteredDevice = alarmas.filter(a => !filter || a.dispositivo.toLowerCase().indexOf(filter) > -1);
  const filteredData = [...new Set([...filteredName, ...filteredDevice])];

  const handleAddNote = (oldValue, newValue, row) => {
    if (oldValue !== newValue) {
      store.dispatch({ type: 'CUADROS_ADDNOTA_ALARMAS', payload: row });
    }
  };

  const cellEdit = cellEditFactory({
    mode: 'dbclick',
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row) => handleAddNote(oldValue, newValue, row),
  });

  const columns = [{
    headerClasses: 'gf-notas-hide',
    dataField: language === 'EN' ? 'nombreEN' : 'nombreES',
    text: t('default.evento', 'Evento'),
    headerStyle: () => ({ width: '20%' }),
    sort: true,
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: 'fechaRecepcion',
    text: t('default.fecha', 'Fecha'),
    formatter: fechaRecepcion => Moment(fechaRecepcion).format('DD/MM/YYYY HH:mm:ss'),
    headerStyle: () => ({ width: '15%' }),
    sort: true,
  },
  {
    headerClasses: 'gf-notas-hide',
    dataField: 'dispositivo',
    text: t('default.dispositivo', 'Dispositivo'),
    headerStyle: () => ({ width: '15%' }),
    sort: true,
  },
  /* {
    dataField: 'estado',
    text: t('default.estado', 'Estado'),
    headerStyle: () => ({ width: '15%' }),
    sort: true,
  }, */
  {
    headerClasses: 'd-none d-md-block d-lg-block',
    dataField: 'notas',
    text: t('default.notas', 'Notas'),
    headerStyle: () => ({ width: '35%' }),
    sort: true,
    title: () => t('default.editTooltip', 'Doble click para editar'),
  },
  ];

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t('default.pagPrev', 'página previa'),
    nextPageTitle: t('default.proxPag', 'próxima página'),
    firstPageTitle: t('default.primerPagina', 'primer página'),
    lastPageTitle: t('default.ultimaPagina', 'última página'),
  };

  return (
    <Container maxWidth="xl" className="ListView gf-font-and-padi">
      <Grid container>
        <Grid item xs={12}>
          <BootstrapTable
            keyField="alarmaID"
            striped
            hover
            condensed
            bootstrap4
            data={filteredData}
            columns={columns}
            cellEdit={cellEdit}
            pagination={paginationFactory(options)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

GridAlarmas.propTypes = Props;

const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
  lifecycle({
    componentDidMount() {
      store.dispatch({ type: 'CUADROS_FETCH_ALARMAS' });
    },
  }),
  connect(state => ({
    language: state.Language.current,
    working: state.Universal.request.process,
    alarmas: state.Cuadros.Alarmas,
  })),
)(GridAlarmas);

export default translate('common')(AppCompose);
