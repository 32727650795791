import React from 'react';
// import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

import Moment from 'react-moment';
// import { composePure } from '../../utils/composepure';
import RectifiersAlertList from '../RectifiersAlertList';

import props from './props';

/** @description componente item para componente carousel. Son los elementos que componen las
 * tarjetas del carousel.
 */
const CardCarouselItem = ({
  out,
  fecha,
  language,
  t,
}) => (
  <Container fluid className="CardCarouselItem">
    <Row>
      <Col xs={12} key={out.salidaID}>
        <Row className="CardCarouselItem__title">
          <Col xs={6}>
            <span>{out.nombre.replace('Salida Nro:', '#')}</span>
          </Col>
          <Col xs={6} className="xy-center">
            <p className="CardCarouselItem__title__time"><Moment locale="es" format="lll">{fecha}</Moment></p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="CardCarouselItem__outs">
            <ul className="list-none">
              {out.valores.map((v) => {
                const listaValor = language === 'EN' ? v.listaValorIngles : v.listaValor;
                const valor = v.valor !== null ? v.valor : '';
                return (
                  <li key={v.reporteValorID} className="y-center">
                    <span className="ml-4">{`${language === 'EN' ? v.nombreEn : v.nombreEs} : ${v.listaValorID ? listaValor : valor}`}</span>
                  </li>
                );
              })}
            </ul>
            <RectifiersAlertList
              alertas={out.alertas}
              uniqueId={out.salidaID}
              isOutput
              t={t}
              language={language}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
);


CardCarouselItem.propTypes = props;

/* const AppCompose = composePure(
  connect(state => ({
    language: state.Language.current,
  })),
)(CardCarouselItem);

export default AppCompose; */

export default CardCarouselItem;
