import React from 'react';

import TendenciaItem from '../TendenciaItem';

import Props from './Props';

/** @description Componente especifico para mostrar las tendencias en forma de lista.
 * utilizada para visualizar las mismas desde cada salida.
 */
const TendeciaLista = ({
  salida,
  language,
  rectificador,
  variables,
  tendenciesAllOutputs,
}) => (
  <div>
    {
      salida.valores.filter(x => !x.listaValorID).map((v) => {
        const filterData = variables.filter(x => x.configuracionReporteID === v.configuracionReporteID);
        return (
          <TendenciaItem
            filterData={filterData}
            v={v}
            language={language}
            rectificador={rectificador}
            dataForChart={tendenciesAllOutputs ? tendenciesAllOutputs.filter(x => x.configuracionReporteID === v.configuracionReporteID) : []}
          />
        );
      })
    }
  </div>
);

TendeciaLista.propTypes = Props;
export default TendeciaLista;
