import React, { Fragment } from 'react';
import {
  makeStyles,
  Chip,
  Container,
} from '@material-ui/core';
import {
  Row,
  Col,
} from 'react-bootstrap';

import { connect } from 'react-redux';

import Actions from '../../actions';

import { composePure } from '../../utils/composepure';
import theme from '../../scss/theme/colors';
import RectifierActionButtons from '../../components/RectifierActionButtons';
import Props from './props';

const useStyles = makeStyles(themes => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    padding: themes.spacing(0.5),
    position: 'fixed',
    // position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: theme.white.main,
    boxShadow: '0px -2px 5px 0px rgba(143,143,143,1)',
  },
  chip: {
    margin: themes.spacing(0.5),
    '& .MuiChip-deleteIcon': {
      color: theme.gray.main,
    },
  },
}));

/** @description pagina para configurar los diferentes modos de visualizacion de los dispositivos
 * y sus estados.
 */
const RectifierSelectionBar = ({
  rectifiers,
  handleChange,
}) => {
  const classes = useStyles();
  const plusLength = rectifiers && rectifiers.length > 0 ? rectifiers.length - 5 : 0;
  return (
    <Fragment>
      {rectifiers.length > 0 && (
        <Container maxWidth="xl" className={classes.root} style={{ maxHeight: '50px' }}>
          <Row className="w-100">
            <Col xs={10} className="gf-display-none">
              {rectifiers.slice(0, 5).map(data => (
                <Chip
                  key={data.uniqueId}
                  label={data.nombre}
                  onDelete={() => handleChange(data.uniqueId)}
                  className={classes.chip}
                />
              ))}
              {plusLength > 0 && (
                <Chip
                  key="plusChip"
                  label={`+${plusLength}`}
                  className={classes.chip}
                />
              )}
            </Col>
            <Col xs={2} className="y-end x-end gf-max-width">
              <RectifierActionButtons />
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};

RectifierSelectionBar.propTypes = Props;
export default composePure(
  connect(state => ({
    rectifiers: state.Rectifiers.docs.filter(f => f.selected),
  }),
  dispatch => ({
    handleChange: async id => dispatch(Actions.Rectifiers.setSelected(id)),
  })),
)(RectifierSelectionBar);
