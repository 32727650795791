import {
  VISTACUADROS_SET_COMANDOS,
  VISTACUADROS_SET_ALARMAS,
  VISTACUADROS_SET_NOVEDADES,
  VISTACUADROS_SET_NOTIFICACIONES,
  VISTACUADROS_UPDATE_COMANDO,
  SET_CONFIGURACIONALARMAS,
  ADD_CONFIGURACIONALARMA,
  SET_CONFIGURACIONALARMA,
  REMOVE_CONFIGURACIONALARMA,
  ADD_NOVEDAD,
  SET_REMOVE_NOVEDAD,
} from '../actionTypes/cuadros';

const initialState = {
  Comandos: [],
  Alarmas: [],
  Novedades: [],
  Notificaciones: [],
  ConfiguracionAlarmas: [],
};

const reducer = (state = initialState, action) => {
  let indexComandoSelected = 0;
  let indexConfAlarmaSelected = 0;
  let indexNovedadToDelete = 0;

  switch (action.type) {
    case VISTACUADROS_SET_COMANDOS:
      return {
        ...state,
        Comandos: action.payload.data,
      };
    case VISTACUADROS_SET_ALARMAS:
      return {
        ...state,
        Alarmas: action.payload.data,
      };
    case VISTACUADROS_SET_NOVEDADES:
      return {
        ...state,
        Novedades: action.payload.data,
      };
    case VISTACUADROS_SET_NOTIFICACIONES:
      return {
        ...state,
        Notificaciones: action.payload.data,
      };
    case SET_CONFIGURACIONALARMAS:
      return {
        ...state,
        ConfiguracionAlarmas: action.payload.data,
      };
    case ADD_CONFIGURACIONALARMA:
      return {
        ...state,
        ConfiguracionAlarmas: [
          ...state.ConfiguracionAlarmas,
          action.payload.confAlarma,
        ],
      };
    case SET_CONFIGURACIONALARMA:
      indexConfAlarmaSelected = state.ConfiguracionAlarmas.findIndex(ca => ca.configuracionAlarmaID === action.payload.configuracionAlarmaID);
      return {
        ...state,
        ConfiguracionAlarmas: [
          ...state.ConfiguracionAlarmas.slice(0, indexConfAlarmaSelected),
          {
            ...state.ConfiguracionAlarmas[indexConfAlarmaSelected],
            nombre: action.payload.nombre,
            limiteInferior: action.payload.limiteInferior,
            limiteSuperior: action.payload.limiteSuperior,
            tiempoEntreAvisos: action.payload.tiempoEntreAvisos,
            variable: action.payload.variableID,
            ackOperador: action.payload.ackOperador,
            emails: action.payload.emails,
          },
          ...state.ConfiguracionAlarmas.slice(indexConfAlarmaSelected + 1),
        ],
      };
    case REMOVE_CONFIGURACIONALARMA:
      indexConfAlarmaSelected = state.ConfiguracionAlarmas.findIndex(ca => ca.configuracionAlarmaID === action.payload.configuracionAlarmaID);
      return {
        ...state,
        ConfiguracionAlarmas: [
          ...state.ConfiguracionAlarmas.slice(0, indexConfAlarmaSelected),
          ...state.ConfiguracionAlarmas.slice(indexConfAlarmaSelected + 1),
        ],
      };
    case SET_REMOVE_NOVEDAD:
      indexNovedadToDelete = state.Novedades.findIndex(n => n.novedadId === action.payload.novedadId);
      return {
        ...state,
        Novedades: [
          ...state.Novedades.slice(0, indexNovedadToDelete),
          ...state.Novedades.slice(indexNovedadToDelete + 1),
        ],
      };
    case VISTACUADROS_UPDATE_COMANDO:
      indexComandoSelected = state.Comandos.findIndex(p => p.comandoEnviadoID === action.payload.comandoEnviadoID);
      return {
        ...state,
        Comandos: [
          ...state.Comandos.slice(0, indexComandoSelected), // everything before current post
          {
            ...state.Comandos[indexComandoSelected],
            notas: action.payload.notas,
          },
          ...state.Comandos.slice(indexComandoSelected + 1), // everything after current post
        ],
      };
    case ADD_NOVEDAD:
      return {
        ...state,
        Novedades: [
          action.payload,
          ...state.Novedades,
        ],
      };
    default:
      return state;
  }
};

export default reducer;
