import React from 'react';
// import { translate } from 'react-i18next';
import Moment from 'moment';
// import { composePure, withProps } from '../../utils/composepure';
import StatusBullet from '../StatusBullet';
import Props from './props';

/** @description Componente filtro para las alertas obtenidas.
 */
const FilterAlert = ({
  on,
  nombre,
  fechaInicioAlarma,
  t,
}) => {
  const getTooltip = () => {
    if (!on) {
      return '';
    }

    if (!fechaInicioAlarma) {
      return t('default.noData', 'No hay datos disponibles');
    }

    return `${t('default.enAlarmaDesde', 'En alarma desde el')} ${Moment(fechaInicioAlarma).format('DD/MM/YYYY HH:mm:ss')}`;
  };
  return (
    <li title={getTooltip()} className="mb-1 y-center FilterAlert">
      <StatusBullet
        xs
        inAlert={on}
      />
      <span className="ml-4">{nombre}</span>
    </li>
  );
};
FilterAlert.propTypes = Props;

/* const AppCompose = composePure(
  withProps(props => ({ t: props.t })),
)(FilterAlert); */

// export default translate('common')(AppCompose);

export default FilterAlert;
