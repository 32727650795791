import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import StatusBullet from '../StatusBullet';
import Props from './props';

/** @description Componente vista reutilizable que contiene el estado de un elemento.
 */
const CardViewStatus = ({
  inAlert,
  expired,
  t,
}) => (
  <Grid container className="CardViewStatus">
    <Grid item xs={12} className="CardViewStatus__status x-end">
      {/* !inAlert && (<StatusBullet inAlert={!inAlert} />) */}
      {/* inAlert && <StatusBullet inAlert={inAlert} /> */}
      <StatusBullet inAlert={inAlert} t={t} />
      {expired && (<StatusBullet expired={expired} t={t} />)}
    </Grid>
  </Grid>
);

CardViewStatus.propTypes = Props;
export default CardViewStatus;
